import {
	Box,
	IconButton,
	MenuItem,
	Select,
	Switch,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography
} from "@mui/material";
import useCssTheme, { themes } from "../../../hooks/use-css-theme";
import styles from "./preferences.module.css";
import { useTranslation } from "react-i18next";
import { languageKeys } from "../../../lib/localization/i18n";
import useLocalStorage from "use-local-storage";
import Table from "../../../components/table";
import {Add, DeleteForever} from "@mui/icons-material";
import {Link} from "react-router-dom";

/**
 * Component for managing user preferences.
 * Allows users to customize language, appearance, geoserver settings, and delete account data.
 */
export default function Preferences() {
	/**
	 * State for controlling whether to show geoserver data.
	 */
	const [showGeoData, setShowGeoData] = useLocalStorage("showGeoData", false);
	/**
	 * State for managing available geoservers.
	 */
	const [availableGeoservers, setAvailableGeoservers] = useLocalStorage<{ name: string, URL: string }[]>("availableGeoservers", []);
	/**
	 * State for managing theme selection.
	 */
	const { theme, setTheme } = useCssTheme();
	const { t, i18n } = useTranslation();

	return (
		<Box id={styles.scrollable} className={styles.prefsContainer}>
			<Box className={styles.selectPreference}>
				<Box>
					<Typography component="h3">{t("preferences.language")}</Typography>
					<Typography component="p">{t("preferences.languageHint")}</Typography>
				</Box>
				<Select onChange={(event) => void i18n.changeLanguage(event.target.value)} value={i18n.language}>
					{languageKeys.map((lang, index) => (
						<MenuItem key={index} value={lang}>
							{t(`languages.${lang}`)}
						</MenuItem>
					))}
				</Select>
			</Box>
			<Box className={styles.selectPreference}>
				<Box>
					<Typography component="h3">{t("preferences.appearance")}</Typography>
					<Typography component="p">{t("preferences.appearanceHint")}</Typography>
				</Box>
				<Select onChange={(event) => {
					setTheme(event.target.value);

				}} value={theme}>
					{themes.map((th, index) => (
						<MenuItem key={index} value={th}>
							{t(`preferences.${th}`)}
						</MenuItem>
					))}
				</Select>
			</Box>
			<Box className={styles.buttonPreference}>
				<Box>
					<Typography component="h3">{t("preferences.deleteData")}</Typography>
				</Box>
				<Link to={"/purgeAccount"} state={{ from: "/dashboard" }}>
					<DeleteForever />
					<Typography component="h3">{t("preferences.deleteAccount")}</Typography>
				</Link>
			</Box>
			<Box className={styles.multiLevelPreference}>
				<Box>
					<Typography component="h3">{t("preferences.geoserver")}</Typography>
					<Typography component="p">{t("preferences.geoserverHint")}</Typography>
				</Box>
				<Box className={styles.switchPreference}>
					<Box>
						<Box>
							<Typography component="h3">{t("preferences.geoserverSwitchLabel")}</Typography>
							<Typography component="p">{t("preferences.geoserverSwitchHint")}</Typography>
						</Box>
						<Switch checked={showGeoData} onChange={(event, checked) => setShowGeoData(checked)} />
					</Box>
					{showGeoData &&
						<Box className={styles.serverConfigure}>
							<Typography component="h4">{t("preferences.geoserverCredentialsLabel")}</Typography>
							<Box>
								<Table>
									<>
										<TableHead>
											<TableRow>
												<TableCell>{t("geoserverName")}</TableCell>
												<TableCell>{t("geoserverURL")}</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{availableGeoservers.map((g, index) => (
												<TableRow key={index}>
													<TableCell>{g.name}</TableCell>
													<TableCell>{g.URL}</TableCell>
												</TableRow>
											))}
										</TableBody>
									</>
								</Table>
								<IconButton onClick={() => setAvailableGeoservers([...availableGeoservers, { name: "serverA", URL: "http://localhost:8080/geoserver" }])}>
									<Add />
								</IconButton>
							</Box>
						</Box>
					}
				</Box>
			</Box>
		</Box>
	);
}
