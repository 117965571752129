import DomainDataAvailable from "../../features/sidebar/form/Domaindata_en";
import {evaluate} from "mathjs";
import {z} from "zod";

export const DomainDataValueItem = z.object({
    id: z.string().optional(),
    value: z.string()
});
const ListItem = z.object({
    id: z.string(),
    label: z.string()
})

interface DomainDataTemplateInterface {
    id: string,
    inputType: string,
    isAdditional: boolean,
    labelText: string,
    visible?: boolean,
    calcString?: string | null,
    defaultValue?: string | null,
    withLabel?: boolean,
    isSelected?: boolean,
    calculations?: string[] | null,
    sectionValues?: string[] | null,
    additionalTemplate?: DomainDataTemplateInterface | null,
    additionalCalcValues?: { [x: string]: { [y: string]: string } },
    listValues?: z.infer<typeof ListItem>[],
    mapValues?: Map<z.infer<typeof ListItem>, z.infer<typeof ListItem>[]>,
    additional?: {
        item: string,
        defaultValue: string,
        withLabel: boolean
    },
    values?: string[] | { id: string, label: string }[] | { [x: string]: { label: string, values: { id: string, label: string }[] } }
}

export const DomainDataTemplate: z.ZodType<DomainDataTemplateInterface> = z.lazy(() => z.object({
    id: z.string(),
    inputType: z.string(),
    isAdditional: z.boolean(),
    labelText: z.string(),
    visible: z.boolean().optional(),
    calcString: z.string().nullable().optional(),
    defaultValue: z.string().nullable().optional(),
    withLabel: z.boolean().optional(),
    isSelected: z.boolean().optional(),
    calculations: z.array(z.string()).nullable().optional(),
    sectionValues: z.array(z.string()).nullable().optional(),
    additionalTemplate: DomainDataTemplate.nullable().optional(),
    additionalCalcValues: z.record(z.record(z.string(), z.string())).optional(),
    listValues: z.array(ListItem).optional(),
    mapValues: z.map(ListItem, z.array(ListItem)).optional(),
    additional: z.object({
        item: z.string(),
        defaultValue: z.string(),
        withLabel: z.boolean()
    }),
    values: z.union([
        z.array(z.string()),
        z.array(z.object({id: z.string(), label: z.string()})),
        z.record(z.object({
            label: z.string(),
            values: z.array(z.object({id: z.string(), label: z.string()}))
        }), z.string(),)
    ]).optional()
}));
export type DomainDataTemplateType = z.infer<typeof DomainDataTemplate>;

interface DomainDataInterface {
    id: string,
    labelText: string,
    inputType?: string,
    value?: z.infer<typeof DomainDataValueItem> | null,
    valueMapChild?: z.infer<typeof DomainDataValueItem> | null,
    sectionItems?: DomainDataInterface[] | null,
    calculations?: {
        standing_solid_meter?: number,
        lying_solid_meter?: number,
    } | null,
    additional?: DomainDataInterface | null
}

export const DomainData: z.ZodType<DomainDataInterface> = z.lazy(() => z.object({
    id: z.string(),
    labelText: z.string(),
    inputType: z.string().optional(),
    value: DomainDataValueItem.nullable().optional(),
    valueMapChild: DomainDataValueItem.nullable().optional(),
    calculations: z.object({
        standing_solid_meter: z.number().optional(),
        lying_solid_meter: z.number().optional(),
    }).nullable().optional(),
    additional: DomainData.nullable().optional(),
    sectionItems: z.array(DomainData).nullable().optional()
}));
export type DomainDataType = z.infer<typeof DomainData>;

export function getCalcValue(calcString: string | undefined | null, calcName: string, parentId: string, domainData: DomainDataType[]): number {
    if (!calcString) return -1;
    let str = calcString.substring(0);
    const res = calcString.match(/\{[\w_|]*\}/g);
    if (!res) return -1;
    res.forEach((el) => {
        const v = el.replace(/[{}]/gi, "");
        const [id, unit] = v.split("|");
        const parent = domainData.find((e) => e.id === parentId);
        if (!parent) return;
        // treespecies needs to be handled differently
        if (v.split("|").length === 3 && v.split("|")[0] === "additional") {
            const [childName, k] = v.split("|").slice(1);
            const child = parent.sectionItems?.find((s) => s.id === childName);
            if (!child) return;
            const value = child.value?.value;
            if (!value) return;
            const templateItem = DomainDataAvailable.data.find((el) => el.id === calcName);
            if (!templateItem || !templateItem.additionalCalcValues) return;
            const additionalCalculation = templateItem.additionalCalcValues[value] ?? templateItem.additionalCalcValues.default;
            if (!additionalCalculation) return;
            const additionalCalcString = additionalCalculation[k];
            if (!additionalCalcString) return;
            if (additionalCalcString.includes("{")) {
                str = str.replace(el, String(getCalcValue(additionalCalcString, k, parentId, domainData)));
            } else {
                str = str.replace(el, additionalCalcString);
            }
        } else {
            const child = parent.sectionItems?.find((s) => s.id === id);
            if (!child || !child.value) return;
            const value = Number(child.value.value);
            if (child.additional && child.additional.value && child.additional.value.value !== unit) {
                const storedUnit = child.additional.value.value;
                const actualValue = value * (unit === "m" ? storedUnit === "cm" ? 1 / 100 : 1 / 10 : unit === "dm" ? storedUnit === "m" ? 10 : 1 / 10 : storedUnit === "m" ? 100 : 10);
                str = str.replace(el, String(actualValue));
            } else {
                str = str.replace(el, String(value));
            }
        }
    });
    return Math.round(Number(evaluate(str)) * 1000) / 1000;
}

export function compareDomainData(a: DomainDataType, b: DomainDataType) {
    if (a.inputType === "Section" && b.inputType !== "Section") return -1;
    if (b.inputType === "Section" && a.inputType !== "Section") return 1;
    return a.id.localeCompare(b.id);
}