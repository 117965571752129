import { createContext, ReactNode, useContext, useState } from "react";
import { Alert, Slide, SlideProps, Snackbar } from "@mui/material";
import styles from "./feedback.module.css";

/** Type representing feedback with a message and severity level. */
export type Feedback = {
	message: string;
	severity: "default" | "info" | "warning" | "success" | "error";
};

/** Interface representing the value provided by the Feedback context. */
export interface FeedbackContextValue {
	/** Current feedback message and severity. */
	feedback: Feedback | null;
	/**
	 * Function to set the feedback message and severity.
	 * @param message - The feedback message.
	 * @param severity - The severity level: 'default', 'info', 'warning', 'success', or 'error'.
	 */
	setFeedback: (
		message: string,
		severity: "default" | "info" | "warning" | "success" | "error"
	) => void;
}

/** Context for providing feedback throughout the application. */
const FeedbackContext = createContext<FeedbackContextValue | undefined>(
	undefined
);

/**
 * Custom transition component for the feedback Snackbar.
 * @param props - SlideProps for configuring the transition.
 */
function SlideTransition(props: SlideProps) {
	return <Slide {...props} direction="left" />;
}

/**
 * Component to provide feedback throughout the application.
 * @param children - Child components wrapped by the provider.
 */
export function ProvideFeedback({ children }: { children: ReactNode }) {
	const [feedback, setFeedback] = useState<Feedback | null>(null);

	/**
	 * Sets the feedback message and severity.
	 * @param message - The feedback message.
	 * @param severity - The severity level: 'default', 'info', 'warning', 'success', or 'error'.
	 */
	function handleFeedback(
		message: string,
		severity: "default" | "info" | "warning" | "success" | "error" = "default"
	) {
		setFeedback({ message, severity });
	}

	return (
		<FeedbackContext.Provider value={{ feedback, setFeedback: handleFeedback }}>
			{feedback === null || feedback.severity === "default" ? (
				<Snackbar
					className={styles.snackbar}
					TransitionComponent={SlideTransition}
					anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
					open={feedback !== null}
					onClose={() => setFeedback(null)}
					message={feedback?.message}
				/>
			) : (
				<Snackbar
					className={styles.snackbar}
					TransitionComponent={SlideTransition}
					anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
					open={true}
					onClose={() => setFeedback(null)}
				>
					<Alert
						onClose={() => setFeedback(null)}
						sx={{ width: "100%" }}
						severity={feedback.severity}
					>
						{feedback.message}
					</Alert>
				</Snackbar>
			)}
			{children}
		</FeedbackContext.Provider>
	);
}

/**
 * Hook to access the feedback context.
 * @returns FeedbackContextValue - The feedback context value.
 * @throws Error - If the hook is used outside the scope of ProvideFeedback.
 */
export function useFeedback() {
	const context = useContext(FeedbackContext);
	if (!context) {
		throw Error("Context can only be consumed by children of ProvideFeedback.");
	}
	return context;
}
