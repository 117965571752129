import { Box, Typography } from "@mui/material";
import styles from "./popover.module.css";
import { IconType } from "react-icons";
import { useTranslation } from "react-i18next"; 

/**
 * Popover component to display information with an icon, title, and optional subtitle.
 * @param {string} title - The title to display.
 * @param {string | undefined} subtitle - An optional subtitle to display.
 * @param {IconType | any} Icon - The icon component to display.
 * @param {() => void} onMoreClicked - Callback function triggered when "Mehr anzeigen..." is clicked.
 * @returns {JSX.Element} Popover component JSX element.
 */
export default function Popover({
	title,
	subtitle,
	Icon,
	onMoreClicked,
}: {
	title: string;
	subtitle?: string;
	Icon: IconType | any;
	onMoreClicked: () => void;
}) {
	const {t} = useTranslation();

	return (
		<Box className={styles.popover}>
			<Box>
				<Box className={styles.iconBox}>
					<Icon />
				</Box>
				<Box className={styles.textBox}>
					<Typography component="h2">{title}</Typography>
					{subtitle && <Typography component="h4">{subtitle}</Typography>}
					<Box onClick={onMoreClicked}>
						<Typography component="p">{t("showMore")}</Typography>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
