import { z } from "zod";
import { MetaDataSchema } from "./meta-data";
import {MapPositionSchema} from "../map-position";


/**
 * Enum for different Path Types.
 * @category Enum
 */
export enum PathType {
	Road,
	Alley,
	Route,
}

/**
 * Schema for Paths in ForestManager.
 * @category Schema
 */
export const PathSchema = MetaDataSchema.extend({
	name: z.string().nullable(),
	description: z.string().nullable(),
	points: MapPositionSchema.array(),
	width: z.number().min(1),
	type: z.nativeEnum(PathType),
});

/**
 * Type for Path in ForestManager.
 * Inferred from zod Schema.
 * @category Schema_Type
 */
type Path = z.infer<typeof PathSchema>;
export default Path;


/**
 * Is-Function to check whether the parameter is actually a Path
 * @param path
 * @returns {boolean}
 */
export function isPath(path: unknown): path is Path {
	return PathSchema.safeParse(path).success;
}
