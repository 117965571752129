import {Box, Dialog, DialogContent, DialogTitle, TableCell, TableRow, Typography} from "@mui/material";
import {useMemo, useState} from "react";
import SharePartner from "../../../types/share-partner";
import {useTranslation} from "react-i18next";
import Entities from "../../../types/entities";
import store from "../../../store/store";
import EntityCard from "../../../components/cards/entity-card";
import {getElementsForSharepartner} from "../../../lib/model/utils/share";

/**
 * Represents a row in the Share Partner table.
 * Displays information about the partner and allows expanding to view shared elements.
 * @param partner - The SharePartner object representing the partner.
 */
export default function SharePartnerRow({
	partner,
}: {
	partner: SharePartner;
}) {
	/** State to manage the anchor element for popover */
	const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
	/** Memoized shared elements */
	const elements = useMemo(() => getElementsForSharepartner(partner, store.getState()), [partner.sharedElementList]);
	/** State to manage the dialog's open state */
	const [open, setOpen] = useState(false);
	const {t} = useTranslation();

	return (
		<>
			<TableRow
				id="partner-row"
				aria-label="partner-row"
				aria-haspopup={true}
				aria-expanded={anchorElement ? "true" : undefined}
				onClick={(event) => {
					if (event.defaultPrevented) {
						return;
					}
					setOpen(true);
					setAnchorElement(event.currentTarget);
				}}
				style={{ cursor: "pointer" }}
			>
				<TableCell component="td" scope="row">
					{partner.name}
				</TableCell>
				<TableCell component="td" scope="row">
					{partner.email ?? t("shareOptions.notPublic")}
				</TableCell>
				<TableCell component="td" scope="row">
					{Object.values(elements).flat(2).length} Elemente
				</TableCell>
				<TableCell component="td" scope="row">
					{Object.values(partner.ownElementList).flat(2).length} Elemente
				</TableCell>
			</TableRow>
			<Dialog open={open} onClose={() => setOpen(false)}>
				<DialogTitle>
					<Typography>Geteilt von {partner.name}</Typography>
				</DialogTitle>
				<DialogContent>
					{(Object.keys(elements) as Entities[]).map((en, index) => {
						if (elements[en].length === 0) return <></>;
						return (
							<Box key={index}>
								<Typography>{t("general." + en)}</Typography>
								<ul style={{display: "flex", flexDirection: "column", gap: 8}}>
									{elements[en].map((el, index) => (
										<li key={index}>
											<EntityCard item={el}/>
										</li>
									))}
								</ul>
							</Box>
						)
					})}
				</DialogContent>
			</Dialog>
		</>
	);
}
