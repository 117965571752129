import {createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {FilterOptions} from "../../types/filter-options";
import Note from "../../types/entities/note";
import {RootState} from "../../store/store";
import {selectNotes} from "../../store/slices/note-slice";
import Entities, {Entity, EntityList} from "../../types/entities";
import {selectAreas} from "../../store/slices/area-slice";
import Category from "../../types/entities/category";
import Area from "../../types/entities/area";
import {selectAllElements} from "../../store/slices";

/**
 * State interface for the filter feature, containing filter configuration and settings.
 */
interface FilterState {
	/** Indicates whether the filter is active or not. */
	filterOn: boolean;
	/** Indicates whether the filter bar is visible or not. */
	filterBarVisible: boolean;
	/** The current filter options applied. */
	filterOptions: FilterOptions | null;
	/** Array of Firestore UIDs for individual layers that are hidden. */
	hiddenIndividualLayers: string[];
}

/**
 * Initial state for the filter feature.
 */
const initialState: FilterState = {
	filterOn: false,
	filterBarVisible: false,
	filterOptions: null,
	hiddenIndividualLayers: [],
};

/**
 * Redux slice for managing filter state and actions.
 */
const filterSlice = createSlice({
	name: "filter",
	initialState,
	reducers: {
		/** Action to activate the filter. */
		activateFilter(state) {
			state.filterOn = true;
		},
		/** Action to deactivate the filter. */
		disableFilter(state) {
			state.filterOn = false;
		},
		/** Action to show the filter bar. */
		showFilterBar(state) {
			state.filterBarVisible = true;
		},
		/** Action to hide the filter bar. */
		hideFilterBar(state) {
			state.filterBarVisible = false;
		},
		/**
		 * Action to set the filter options.
		 * @param {PayloadAction<FilterOptions>} action - Payload containing the filter options.
		 */
		setFilterOptions(state, action: PayloadAction<FilterOptions>) {
			state.filterOn = true;
			state.filterOptions = action.payload;
		},
		/** Action to reset the filter options. */
		resetFilter(state) {
			state.filterOn = false;
			state.filterOptions = null;
		},
		/**
		 * Action to show an individual layer.
		 * @param {PayloadAction<string>} action - Payload containing the Firestore UID of the layer to show.
		 */
		showIndividualLayer(state, action: PayloadAction<string>) {
			const set = new Set(state.hiddenIndividualLayers);
			if (set.has(action.payload)) {
				set.delete(action.payload);
			}
			state.hiddenIndividualLayers = Array.from(set);
		},
		/**
		 * Action to hide an individual layer.
		 * @param {PayloadAction<string>} action - Payload containing the Firestore UID of the layer to hide.
		 */
		hideIndividualLayer(state, action: PayloadAction<string>) {
			const set = new Set(state.hiddenIndividualLayers);
			set.add(action.payload);
			state.hiddenIndividualLayers = Array.from(set);
		},
	},
	extraReducers: () => {},
});

/** Exporting actions from filterSlice */
export const {
	disableFilter,
	activateFilter,
	showFilterBar,
	hideFilterBar,
	setFilterOptions,
	resetFilter,
	showIndividualLayer,
	hideIndividualLayer,
} = filterSlice.actions;

/** Exporting the reducer function from filterSlice */
export default filterSlice.reducer;

/**
 * Selector function to retrieve filtered notes based on current filter options.
 * @param {RootState} state - The Redux state.
 * @returns {Note[]} Filtered array of notes.
 */
export const selectFilteredNotes: (state: RootState) => Note[] = createSelector(
	[selectNotes, (state: RootState) => state.filter],
	(notes, filter) => {
		if (!filter.filterOn || !filter.filterOptions) return notes;
		return filterNotes(notes, filter.filterOptions);
	}
);

/**
 * Selector function to retrieve filtered areas based on current filter options.
 * @param {RootState} state - The Redux state.
 * @returns {Area[]} Filtered array of areas.
 */
export const selectFilteredAreas: (state: RootState) => Area[] = createSelector(
	[selectAreas, (state: RootState) => state.filter],
	(areas, filter) => {
		if (!filter.filterOn || !filter.filterOptions) return areas;
		return filterAreas(areas, filter.filterOptions);
	}
);

/**
 * Selector function to retrieve all elements with filter applied.
 * @param {RootState} state - The Redux state.
 * @returns {EntityList} Filtered array of elements grouped by entity type.
 */
export const selectAllElementsWithFilter: (state: RootState) => EntityList = createSelector(
	[selectAllElements, selectFilteredNotes, selectFilteredAreas],
	(data, notes, areas) => {
		const elements = {
			...data,
			notes,
			areas
		}
		return Object.entries(elements).reduce((acc, [key, value]) => {
			acc[key as Entities] = value.filter((e) => !e.deletedAt);
			return acc;
		}, {} as { [e in Entities]: Entity[] }) as EntityList;
	}
);

/**
 * Function to filter notes based on provided filter options.
 * @param {Note[]} notes - Array of notes to filter.
 * @param {FilterOptions} filterOptions - The filter options to apply.
 * @returns {Note[]} Filtered array of notes.
 */
export function filterNotes(notes: Note[], filterOptions: FilterOptions) {
	return notes.filter((e) => {
		if (!e) return false;
		if (filterOptions.filterNotes) {
			if (filterOptions.includedNotesFireStoreUid.includes(e.firestoreUid)) {
				return true;
			}
		}
		if (filterOptions.filterArea) {
			if (
				!e.areaUid ||
				!filterOptions.includedAreasFireStoreUid.includes(e.areaUid)
			) {
				return false;
			}
		}
		if (filterOptions.filterCategory) {
			if (
				!e.categoryUid ||
				!filterOptions.includedCategoriesFirestoreUid.includes(e.categoryUid)
			) {
				return false;
			}
		}
		if (filterOptions.filterContent) {
			if (filterOptions.includeTitle && filterOptions.includeTitle !== "") {
				if (
					!e.name
						.toLowerCase()
						.includes(filterOptions.includeTitle.toLowerCase())
				) {
					return false;
				}
			}
			if (filterOptions.excludeTitle && filterOptions.excludeTitle !== "") {
				if (
					e.name
						.toLowerCase()
						.includes(filterOptions.excludeTitle.toLowerCase())
				) {
					return false;
				}
			}
			if (
				filterOptions.includeFulltext &&
				filterOptions.includeFulltext !== ""
			) {
				if (
					!e.description
						.toLowerCase()
						.includes(filterOptions.includeFulltext.toLowerCase())
				) {
					return false;
				}
			}
			if (
				filterOptions.excludeFulltext &&
				filterOptions.excludeFulltext !== ""
			) {
				if (
					e.description
						.toLowerCase()
						.includes(filterOptions.includeFulltext.toLowerCase())
				) {
					return false;
				}
			}
		}
		if (filterOptions.filterDate) {
			if (filterOptions.from) {
				const from = filterOptions.from;
				if (e.createdAt < from) {
					return false;
				}
			}
			if (filterOptions.until) {
				const until = filterOptions.until;
				if (e.createdAt > until) {
					return false;
				}
			}
		}
		return true;
	});
}

/**
 * Function to filter areas based on provided filter options.
 * @param {Area[]} areas - Array of areas to filter.
 * @param {FilterOptions} filterOptions - The filter options to apply.
 * @returns {Area[]} Filtered array of areas.
 */
export function filterAreas(areas: Area[], filterOptions: FilterOptions) {
	return areas.filter((e) => {
		return !(
			filterOptions.filterArea &&
			!filterOptions.includedAreasFireStoreUid.includes(e.firestoreUid)
		);
	});
}

/**
 * Function to filter categories based on provided filter options.
 * @param {Category[]} categories - Array of categories to filter.
 * @param {FilterOptions} filterOptions - The filter options to apply.
 * @returns {Category[]} Filtered array of categories.
 */
export function getFilteredCategories(
	categories: Category[],
	filterOptions: FilterOptions
) {
	return categories.filter((e) => {
		return !(
			filterOptions.filterCategory &&
			!filterOptions.includedCategoriesFirestoreUid.includes(e.firestoreUid)
		);
	});
}
