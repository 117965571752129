import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField, Typography, Grid} from "@mui/material";
import {useNavigate} from "react-router";
import {useAuth} from "../../hooks/use-auth";
import {useEffect, useState} from "react";
import Navigation from "../../features/navigation";
import HorizontalDivider from "../../components/dividers/horizontal-divider";
import {Link} from "react-router-dom";
import styles from "./error.module.css";
import {useTranslation} from "react-i18next";
import { useFeedback } from "../../features/feedback/use-feedback";
import PurgeAccount from "../purge";

/**
 * This page is shown when a user is authenticated but does not have a valid license to access this application.
 * Licenses can be managed using firebase console or https://admin.forestmanager.de.
 * TODO: Organize the content here better
 * @constructor
 * @category Page
 */
export default function NoLicensePage() {
    const { user, validLicense, activateLicense } = useAuth();
    const navigate = useNavigate();
    const [dialogOpen, setDialogOpen] = useState(false);
    const {t} = useTranslation();
    const deleteAccountDataPath = "/purgeAccount";

    useEffect(() => {
        if (validLicense) {
            navigate('/map');
        }
    }, [validLicense]);

    // user should always be able to delete his account, even with no active license
    if(window.location.pathname == deleteAccountDataPath) {
        return <PurgeAccount />;
    }

    return (
        <Box className={styles.container}>
            <Box className={styles.header}>
                <img className={styles.logo} src="/images/fm-logo.png" alt="Logo" />
                <Navigation />
            </Box>
            <Box className={styles.info}>
                <Typography className={styles.infoText} variant="h2">
                    {t("licensePage.title")}
                </Typography>
                <Typography className={styles.infoText} variant="h5">
                    {t("licensePage.subtitle")}
                </Typography>
            </Box>
            <Box className={styles.gridContainer}>
                <Box className={styles.gridItem}>
                    <Typography component="h3">
                        {t("licensePage.basic")}
                    </Typography>
                    <Box className={styles.features}>
                        <ul>
                            <li>{t("licensePage.features.print")}</li>
                            <li>{t("licensePage.features.more")}</li>
                            <li>{t("licensePage.features.pattern")}</li>
                            <li>{t("licensePage.features.import")}</li>
                            <li>{t("licensePage.features.manage")}</li>
                        </ul>
                        <Typography>{t("licensePage.notIncluded")}</Typography>
                        <ul>
                            <li>{t("licensePage.features.export")}</li>
                            <li>{t("licensePage.features.unlimitedNotes")}</li>
                            <li>{t("licensePage.features.offline")}</li>
                            <li>{t("licensePage.features.custom")}</li>
                            <li>{t("licensePage.features.adfree")}</li>
                            <li>{t("licensePage.features.cloud")}</li>
                        </ul>
                    </Box>
                    <Typography className={styles.prices}>
                        {t("licensePage.free")}
                    </Typography>
                </Box>
                <Box className={styles.gridItem}>
                    <Typography component="h3">
                        {t("licensePage.premium")}
                    </Typography>
                    <Box className={styles.features}>
                        <ul>
                            <li>{t("licensePage.features.unlimitedNotes")}</li>
                            <li>{t("licensePage.features.export")}</li>
                            <li>{t("licensePage.features.print")}</li>
                            <li>{t("licensePage.features.offline")}</li>
                            <li>{t("licensePage.features.custom")}</li>
                            <li>{t("licensePage.features.more")}</li>
                            <li>{t("licensePage.features.pattern")}</li>
                            <li>{t("licensePage.features.import")}</li>
                            <li>{t("licensePage.features.manage")}</li>
                            <li>{t("licensePage.features.adfree")}</li>
                        </ul>
                        <Typography>{t("licensePage.notIncluded")}</Typography>
                        <ul>
                            <li>{t("licensePage.features.cloud")}</li>
                        </ul>
                    </Box>
                    <Typography className={styles.prices}>
                        {t("licensePage.prices.premium")}
                        <Typography>{t("licensePage.prices.year")}</Typography>
                    </Typography>
                </Box>
                <Box className={styles.gridItem}>
                    <Typography component="h3">
                        {t("licensePage.sync")}
                    </Typography>
                    <Box className={styles.features}>
                        <ul>
                            <li>{t("licensePage.features.unlimitedNotes")}</li>
                            <li>{t("licensePage.features.export")}</li>
                            <li>{t("licensePage.features.print")}</li>
                            <li>{t("licensePage.features.offline")}</li>
                            <li>{t("licensePage.features.custom")}</li>
                            <li>{t("licensePage.features.more")}</li>
                            <li>{t("licensePage.features.pattern")}</li>
                            <li>{t("licensePage.features.import")}</li>
                            <li>{t("licensePage.features.manage")}</li>
                            <li>{t("licensePage.features.adfree")}</li>
                            <li className={styles.highlighted}>{t("licensePage.features.cloud")}</li>
                        </ul>
                    </Box>
                    <Typography className={styles.prices}>
                        {t("licensePage.prices.sync")}
                        <Typography>{t("licensePage.prices.year")}</Typography>
                    </Typography>
                </Box>
                <Box className={styles.gridItem}>
                    <Typography component="h3">
                        {t("licensePage.team")}
                    </Typography>
                    <Box className={styles.features}>
                        <ul>
                            <li>{t("licensePage.features.unlimitedNotes")}</li>
                            <li>{t("licensePage.features.export")}</li>
                            <li>{t("licensePage.features.print")}</li>
                            <li>{t("licensePage.features.offline")}</li>
                            <li>{t("licensePage.features.custom")}</li>
                            <li>{t("licensePage.features.more")}</li>
                            <li>{t("licensePage.features.pattern")}</li>
                            <li>{t("licensePage.features.import")}</li>
                            <li>{t("licensePage.features.manage")}</li>
                            <li>{t("licensePage.features.adfree")}</li>
                            <li className={styles.highlighted}>{t("licensePage.features.cloud")}</li>
                            <li className={styles.highlighted}>{t("licensePage.features.webapp")}</li>
                        </ul>
                    </Box>
                    <Typography className={styles.prices}>
                        {t("licensePage.prices.team")}
                        <Typography>{t("licensePage.prices.year")}</Typography>
                    </Typography>
                </Box>
            </Box>
            <Box className={styles.footnotes}>
                <Typography>
                    * {t("licensePage.footnotes.team")}
                </Typography>
            </Box>
            <HorizontalDivider width="40%" />
            <Box className={styles.footer}>
                <Typography className={styles.footerText} variant="h6">
                    {t("licensePage.already")}
                </Typography>
                {user ? (
                    <div className={styles.linkTextContainer} >
                        <Typography
                            className={styles.linkText}
                            variant="h6"
                            onClick={() => setDialogOpen(true)}
                        >
                        {t("licensePage.link")}
                        </Typography>
                        <Divider sx={{ borderWidth: "1px", marginLeft: "20px", marginRight: "20px", borderColor: "var(--text)" }} orientation="vertical" variant="middle" flexItem />
                        <Typography
                            className={styles.linkText}
                            variant="h6"
                            onClick={() => navigate(deleteAccountDataPath)}
                        >
                            {t("preferences.deleteAccountPage.title")}
                        </Typography>
                        <LicenseLinkDialog
                            open={dialogOpen}
                            onClose={() => setDialogOpen(false)}
                            onConfirm={activateLicense}
                        />
                    </div>
                ) : (
                    <Link to="/login" className={styles.link}>
                        <Typography className={styles.linkText} variant="body1">
                            {t("licensePage.login")}
                        </Typography>
                    </Link>
                )}
            </Box>
        </Box>
    );
}

export interface LicenseLinkDialogProps {
    open: boolean;
    onClose: () => void;
    onConfirm: (licenseKey: string) => void;
}

function LicenseLinkDialog(props: LicenseLinkDialogProps) {
    const { onClose, onConfirm, open, ...other } = props;
    const [licenseKey, setLicenseKey] = useState("");
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [showError, setShowError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { setFeedback } = useFeedback();
    const {t} = useTranslation();
    
    const handleCancel = () => {
        setShowError(false);
        onClose();
    };

    const handleOk = async () => {
        try {
            setIsButtonEnabled(false);
            setIsLoading(true);
            await onConfirm(licenseKey);
            setIsButtonEnabled(true);
            setIsLoading(false);
            setFeedback(t("licensePage.dialog.success"), "info");
            onClose();
        }
        catch(err) {
            setIsButtonEnabled(true);
            setIsLoading(false);
            setShowError(true);
        }
    };

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="xs"
            open={open}
            {...other}
        >
            <DialogTitle>{t("licensePage.dialog.link")}</DialogTitle>
            <DialogContent>
                <Typography>{t("licensePage.dialog.code")}</Typography>
                <TextField fullWidth value={licenseKey} onChange={(event) => {
                    setLicenseKey(event.target.value);
                    setIsButtonEnabled(event.target.value.length > 0);
                    setShowError(false);
                    }} variant="outlined" />
                {showError && 
                    <Typography className={styles.errorText}>{t("licensePage.dialog.error")}</Typography>
                }
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel}>
                    {t("cancel")}
                </Button>
                <Button disabled={!isButtonEnabled} onClick={handleOk}>
                    {isLoading ? t("licensePage.dialog.loading") : t("submit")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
