import { z } from "zod";
import { MetaDataSchema } from "./meta-data";
import {latitude, longitude} from "../map-position";

/**
 * Schema for LayerGroups in ForestManager.
 * These are used to display Data from Geoservers.
 * @category Schema
 */
const LayerSchema = z.object({
	firestoreUid: z.string(),
	name: z.string(),
	fileName: z.string(),
	workspace: z.string().optional(),
	bounds: z.tuple([longitude, latitude, longitude, latitude]),
	styles: z.string().array(),
});

export const LayerGroupSchema = MetaDataSchema.extend({
	name: z.string(),
	description: z.string(),
	provider: z.string(),
	layers: z.object(LayerSchema.shape).array(),
});

/**
 * Type for LayerGroup in ForestManager.
 * Inferred from zod Schema.
 * @category Schema_Type
 */
type LayerGroup = z.infer<typeof LayerGroupSchema>;
export default LayerGroup;
export type Layer = z.infer<typeof LayerSchema>;

/**
 * Is-Function to check whether the parameter is actually a LayerGroup
 * @param layerGroup
 * @returns {boolean}
 */
export function isLayerGroup(
	layerGroup: unknown
): layerGroup is LayerGroup {
	return LayerGroupSchema.safeParse(layerGroup).success;
}
