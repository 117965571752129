import BorderButton from "../buttons/border-button";
import { Box, Button, Popover, Typography } from "@mui/material";
import styles from "./sections.module.css";
import {
	bindPopover,
	bindTrigger,
	usePopupState,
} from "material-ui-popup-state/hooks";
import { Action } from "../../hooks/actions";
import { useTranslation } from "react-i18next";
import { Fragment } from "react";

/**
 * ButtonSection Component
 *
 * A component for rendering a section of buttons, each associated with an action.
 * Supports actions that require confirmation through a popover.
 *
 * @component
 * @param {Object} props - The properties of the ButtonSection component.
 * @param {Action[]} props.actions - An array of Action objects representing the buttons to be displayed.
 *
 * @returns {JSX.Element} - The rendered ButtonSection component.
 *
 * @example
 * // Usage of ButtonSection component
 * <ButtonSection
 *   actions={[
 *     { name: 'edit', symbol: <EditIcon />, callback: handleEdit, disabled: false, requiresConfirm: false },
 *     { name: 'delete', symbol: <DeleteIcon />, callback: handleDelete, disabled: false, requiresConfirm: true },
 *   ]}
 * />
 */
export default function ButtonSection({ actions }: { actions: Action[] }) {
	const { t } = useTranslation();
	const popupState = usePopupState({ variant: "popover", popupId: "confirm" });

	return (
		<Box className={`${styles.section} ${styles.buttons}`}>
			{actions.map((action, index) => {
				return action.requiresConfirm && !action.disabled ? (
					<Fragment key={index}>
						<BorderButton
							Icon={action.symbol}
							label={t(`sidebar.actions.${action.name}`)}
							{...bindTrigger(popupState)}
						/>
						<Popover
							{...bindPopover(popupState)}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "center",
							}}
							transformOrigin={{
								vertical: "top",
								horizontal: "center",
							}}
							slotProps={{
								paper: {
								  className: styles.popover
								}
							  }}
						>
							<Typography>{t("confirmCheck")}</Typography>
							<Button
								variant="contained"
								onClick={() => {
									!action.disabled && action.callback();
									popupState.close();
								}}
							>
								{t("confirm")}
							</Button>
						</Popover>
					</Fragment>
				) : (
					<BorderButton
						key={index}
						Icon={action.symbol}
						label={t(`sidebar.actions.${action.name}`)}
						onClick={action.callback}
						disabled={action.disabled}
					/>
				);
			})}
		</Box>
	);
}
