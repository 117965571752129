import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import useSidebarParams from "../sidebar/use-sidebar-params";
import { useControl, useMap } from "react-map-gl";
import { setTempArea, setTempPath } from "./map-slice";
import { useEffect, useState } from "react";
import Entities from "../../types/entities";
import { isArea } from "../../types/entities/area";
import { mapPositionArrayToLngLatArray } from "../../types/map-position";
import { isPath } from "../../types/entities/path";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { TbPolygon, TbVector, TbVectorSpline } from "react-icons/tb";
import { DirectionsWalk, Room } from "@mui/icons-material";
import NoteMarkers from "./items/note-markers";
import AreaPolygons from "./items/area-polygons";
import PathLines from "./items/path-lines";
import { useTranslation } from "react-i18next";
import styles from "./map.module.css";

/**
 * Represents the user interface for drawing elements on the map.
 * @returns JSX for the DrawUI component.
 */
export default function DrawUI() {
	const [showMarkers, setShowMarkers] = useState(false);
	const [showAreas, setShowAreas] = useState(false);
	const [showPaths, setShowPaths] = useState(false);
	const { t } = useTranslation();

	return (
		<>
			<Box className={styles.drawUi}>
				<Box className={styles.drawInfo}>
					<Typography>{t("map.draw.info")}</Typography>
					<ul>
						<li>{t("map.draw.click")}</li>
						<li>{t("map.draw.enter")}</li>
						<li>{t("map.draw.drag")}</li>
						<li>{t("map.draw.escape")}</li>
					</ul>
				</Box>
				<Box className={styles.drawButtons}>
					<Tooltip title={t("map.draw.tooltips.markers").toString()}>
						<IconButton
							onClick={() => setShowMarkers(!showMarkers)}
							style={showMarkers ? { color: "var(--accent)" } : {}}
						>
							<Room />
						</IconButton>
					</Tooltip>
					<Tooltip title={t("map.draw.tooltips.areas").toString()}>
						<IconButton
							onClick={() => setShowAreas(!showAreas)}
							style={showAreas ? { color: "var(--accent)" } : {}}
						>
							<TbPolygon />
						</IconButton>
					</Tooltip>
					<Tooltip title={t("map.draw.tooltips.paths").toString()}>
						<IconButton
							onClick={() => setShowPaths(!showPaths)}
							style={showPaths ? { color: "var(--accent)" } : {}}
						>
							<DirectionsWalk />
						</IconButton>
					</Tooltip>
					<span />
					<DrawControl />
				</Box>
			</Box>
			{showMarkers && <NoteMarkers />}
			{showAreas && <AreaPolygons />}
			{showPaths && <PathLines />}
		</>
	);
}

/**
 * Represents the control for drawing elements on the map.
 * @returns JSX for the DrawControl component.
 */
function DrawControl() {
	const [draw] = useState(
		new MapboxDraw({
			controls: {
				point: false,
				line_string: false,
				polygon: true,
				trash: false,
				combine_features: false,
				uncombine_features: false,
			},
		})
	);
	const {tab, selectCurrentElement} = useSidebarParams();
	const { tempArea, tempPath } = useAppSelector((state) => state.map);
	const element = useAppSelector((state) => selectCurrentElement(state));
	useControl(() => draw);
	const { m1: map } = useMap();
	const {t} = useTranslation();
	const dispatch = useAppDispatch();

	/**
	 * Initializes the draw UI, sets the mode depending on the passed argument and registers callbacks for update and create events.
	 */
	useEffect(() => {
		if ((!tempArea && !tempPath) || !map) {
			return;
		}
		if (element && tab === Entities.Areas && isArea(element)) {
			const id = draw.add({
				type: "Polygon",
				coordinates: [mapPositionArrayToLngLatArray(element.points)],
			});
			draw.changeMode("simple_select", { featureIds: id });
		} else if (element && tab === Entities.Paths && isPath(element)) {
			const id = draw.add({
				type: "LineString",
				coordinates: mapPositionArrayToLngLatArray(element.points),
			});
			draw.changeMode("simple_select", { featureIds: id });
		} else {
			if (tempArea) {
				draw.changeMode("draw_polygon");
			} else {
				draw.changeMode("draw_line_string");
			}
		}
		map.on("draw.update", updateTempElement);
		map.on("draw.create", updateTempElement);
	}, []);

	/**
	 * Removes Listeners when the component is removed from the Tree.
	 */
	useEffect(() => {
		return () => {
			map?.off("draw.update", updateTempElement);
			map?.off("draw.create", updateTempElement);
		};
	}, []);

	/**
	 * Updates the temporary drawing element on the map.
	 * @param event - The event object containing drawing features.
	 */
	function updateTempElement(event: any) {
		if (event.features[0].geometry.coordinates.length === 0) return;
		if (tempArea) {
			dispatch(
				setTempArea({
					...tempArea,
					points: event.features[0].geometry.coordinates,
				})
			);
		} else if (tempPath) {
			dispatch(
				setTempPath({
					...tempPath,
					points: [event.features[0].geometry.coordinates],
				})
			);
		}
	}

	return (
		<Tooltip title={t("map.draw.tooltips.drawMode").toString()}>
			<IconButton
				onClick={() => {
					if (!draw || draw.getMode() !== "simple_select") return;
					if (tempArea && tempArea.points.length === 0) {
						draw.changeMode("draw_polygon");
					} else if (tempPath && tempPath.points.length === 0) {
						draw.changeMode("draw_line_string");
					}
				}}
				className={styles.switchDraw}
			>
				{tempArea ? <TbVector /> : <TbVectorSpline />}
			</IconButton>
		</Tooltip>
	);
}
