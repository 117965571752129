import {useCallback, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {addNote} from "../../../store/slices/note-slice";
import {addArea, selectAreas} from "../../../store/slices/area-slice";
import {addPath, selectPaths} from "../../../store/slices/path-slice";
import {useTranslation} from "react-i18next";
import {FeatureCollection, GeoJSON} from "geojson";
import togeojson from "togeojson";
import {v4} from "uuid";
import Entities, {Entity, resolveEntityTypeForItem} from "../../../types/entities";
import {mapPositionArrayToLngLatArray} from "../../../types/map-position";
import togpx from "../../../lib/model/utils/to_gpx";
import tokml from "tokml";
import { shapeZipToGeojson, shapeShpToGeojson } from "../../../lib/model/utils/shape-parse";
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    DialogContentText,
    CircularProgress,
    Grid
} from "@mui/material";
import Dropzone from "react-dropzone";
import EntityCard from "../../../components/cards/entity-card";
import {TbDragDrop} from "react-icons/tb";
import { IoIosArrowDropdown } from "react-icons/io";
import transformGeoJSON from "../../../lib/model/utils/transform-geojson";
import {addFirestoreElement} from "../../../lib/firebase/firestore";
import useRequireAuth from "../../../hooks/use-require-auth";
import Note from "../../../types/entities/note";
import Area from "../../../types/entities/area";
import Path from "../../../types/entities/path";
import styles from "../dashboard.module.css";

const MAX_FEATURES = 1000;

/**
 * Component for managing data import and export.
 * Enables users to import GPX/KML files, select entities for export, and download GPX/KML files.
 */
export default function DataManagement() {
    /**
     * State for managing selected export tab, export format, imported entities, and selected entity IDs
     */
    const [selectedExportTab, setSelectedExportTab] = useState<Entities.Paths | Entities.Areas>(Entities.Paths);
    const [selectedExportFormat, setSelectedExportFormat] = useState<"gpx" | "kml">("gpx");
    const areas = useAppSelector((state) => selectAreas(state));
    const paths = useAppSelector((state) => selectPaths(state));
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const user = useRequireAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [importResult, setImportResult] = useState<Entity[] | null>([]);
    const [importErrorDialogOpen, setImportErrorDialogOpen] = useState(false);
    const [importErrorMessage, setImportErrorMessage] = useState<string | null>(null);
    const [importErrorCode, setImportErrorCode] = useState<string | null>(null);
    const supportedFiles = ["kml", "gpx", "zip", "shp"];

    /**
     * Reset selected entity IDs when changing the export tab
     */
    useEffect(() => {
        setSelectedIds([]);
    }, [selectedExportTab])

    /**
     * Callback function for handling file drop for import
     * @param acceptedFiles - Accepted files dropped by the user
     */
    const onDrop = useCallback( async (acceptedFiles: File[]) => {
        if (!user) return;
        const importedEntities: Entity[] = [];
        acceptedFiles.forEach((file) => {
            const fileType = file.name.split(".").slice(-1)[0].toLowerCase();
            if(!supportedFiles.includes(fileType)) {
                setImportErrorMessage(t("dashboard.dataManagement.filetypeNotSupported"));
                setImportErrorCode("Filetype not supported");
                setImportErrorDialogOpen(true);
                return;
            }
            const reader = new FileReader();
            let geojson: GeoJSON | Array<GeoJSON>;
            reader.onload = async function(e) {
                setIsLoading(true);
                if(fileType === 'zip') {
                    const arrayBuffer = e.target?.result;
                    if(arrayBuffer) {
                        try {
                            const res: {data: GeoJSON | Array<GeoJSON>, featureCount: number, isLatLng: boolean} = await shapeZipToGeojson(arrayBuffer);
                            geojson = res.data;
                            if(res.featureCount > MAX_FEATURES) {
                                setImportErrorDialogOpen(true);
                                setImportErrorMessage(t("dashboard.dataManagement.tooManyElements"));
                                setImportErrorCode("Too many elements");
                                setIsLoading(false);
                                return;
                            }
                            if(!res.isLatLng) {
                                setImportErrorDialogOpen(true);
                                setImportErrorMessage(t("dashboard.dataManagement.coordSystemNotSupported"));
                                setImportErrorCode("CoordSystem not supported");
                                setIsLoading(false);
                                return;
                            }
                        }
                        catch(err) {
                            setIsLoading(false);
                            console.error(err);
                            setImportErrorDialogOpen(true);
                            if(isErrorMessage(err))
                                setImportErrorCode(err.message);
                        }
                    }
                }
                else if(fileType === 'shp') {
                    const arrayBuffer: any = e.target?.result;
                    if(arrayBuffer) {
                        try {
                            const res: {geojson: GeoJSON, featureCount: number, isLatLng: boolean} = shapeShpToGeojson(arrayBuffer);
                            geojson = res.geojson;
                            if(res.featureCount > MAX_FEATURES) {
                                setImportErrorDialogOpen(true);
                                setImportErrorMessage(t("dashboard.dataManagement.tooManyElements"));
                                setImportErrorCode("Too many elements");
                                setIsLoading(false);
                                return;
                            }
                            if(!res.isLatLng) {
                                setImportErrorDialogOpen(true);
                                setImportErrorMessage(t("dashboard.dataManagement.coordSystemNotSupported"));
                                setImportErrorCode("CoordSystem not supported");
                                setIsLoading(false);
                                return;
                            }
                        }
                        catch(err) {
                            setIsLoading(false);
                            setImportErrorDialogOpen(true);
                            if(isErrorMessage(err))
                                setImportErrorCode(err.message);
                        }
                    }
                }
                else {
                    const xml = e.target?.result as string;
                    if (!xml) {
                        setIsLoading(false);
                        return;
                    }
                    const document = new DOMParser().parseFromString(xml, "text/xml");
                    geojson = (fileType === "gpx") ? togeojson.gpx(document) : togeojson.kml(document);
                }
                
                let entities: Entity[] | null = null;

                if(Array.isArray(geojson)) {
                    for(let i = 0; i < geojson.length; i++) {
                        entities = transformGeoJSON(geojson[i], () => ({
                            firestoreUid: v4(),
                            sharePartnerUid: user.uid,
                            createdAt: new Date().getTime(),
                            updatedAt: new Date().getTime(),
                            changedBy: user.uid,
                            deletedAt: null,
                        }), undefined);
    
                        if (entities) {
                            importedEntities.push(...entities);
                        }
                        
                    }
                }
                else {
                    entities = transformGeoJSON(geojson, () => ({
                        firestoreUid: v4(),
                        sharePartnerUid: user.uid,
                        createdAt: new Date().getTime(),
                        updatedAt: new Date().getTime(),
                        changedBy: user.uid,
                        deletedAt: null,
                    }), undefined);
    
                    if (entities) {
                        importedEntities.push(...entities);
                    }
                }
                setImportResult(importedEntities);
                setIsLoading(false);
            };
            if(fileType === 'zip' || fileType === 'shp')
                reader.readAsArrayBuffer(file);
            else
            reader.readAsText(file);
        })
    }, [user]);

    /**
     * Function to check if a catched err object has the string property message.
     * @param err - error object to check.
     * @returns true if the error object has a string property 'message'.
     */
    function isErrorMessage(err: unknown): err is { message: string } {
        return typeof (err as any).message === 'string';
    }

    /**
     * Function for handling import error dialog close. Resets state variables used in import error dialog.
     */
    function handleImportErrorDialogClose() {
        setImportErrorDialogOpen(false);
        setImportErrorMessage(null);
        setImportErrorCode(null);
    }

    /**
     * Function for handling download of selected entities in GPX or KML format
     */
    function handleDownload() {
        // Constructing FeatureCollection based on selected entities
        const featureCollection: FeatureCollection = {
            type: "FeatureCollection",
            features: selectedIds.map((id) => {
                if (selectedExportTab === Entities.Areas) {
                    const area = areas.find((a) => a.firestoreUid === id) as Area;
                    return {
                        type:  "Feature",
                        geometry: {
                            type: "Polygon",
                            coordinates: [mapPositionArrayToLngLatArray(area.points)],
                        },
                        properties: {
                            name: area.name,
                            desc: area.description
                        }
                    }
                } else {
                    const path = paths.find((a) => a.firestoreUid === id) as Path;
                    return {
                        type: "Feature",
                        geometry: {
                            type: "LineString",
                            coordinates: mapPositionArrayToLngLatArray(path.points),
                        },
                        properties: {
                            name: path.name ?? t(`path.${path.type}`),
                            desc: path.description ?? t(`path.${path.type}`)
                        }
                    }
                }
            })
        }
        // Generating GPX or KML content based on selected format
        let fileContent;
        if (selectedExportFormat === "gpx") {
            fileContent = togpx(featureCollection);
        } else {
            fileContent = tokml(featureCollection);
        }
        // Creating a download link and triggering download
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(fileContent));
        element.setAttribute('download', `fm-${selectedExportTab}s.${selectedExportFormat}`);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    return (

        <Box id={styles.scrollable}>
          <Box className={styles.dataManagement}>
            <Box>
                <Box className={styles.dropzoneSection}>
                    <Grid id={styles.grid} container alignItems="center">
                        <Typography sx={{fontSize: 23}} component="h2">{t("dashboard.dataManagement.import")}</Typography>
                        {isLoading && <CircularProgress size={30} id={styles.loadingCircle} />}
                    </Grid>
                    <Accordion style={{marginBottom: "20px"}}>
                        <AccordionSummary
                            expandIcon={<IoIosArrowDropdown />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            >
                            <Typography component="h3">{t("dashboard.dataManagement.importInfo")}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                {t("dashboard.dataManagement.importinfoText1")}
                            </Typography>

                            <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                                <li>KML</li>
                                <li>GPX</li>
                                <li>Shape-ZIP</li>
                                <li>SHP</li>
                            </ul>

                            <Typography>
                                {t("dashboard.dataManagement.importinfoText2")}
                            </Typography>
                            <Typography>
                                {t("dashboard.dataManagement.importinfoText3")}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Dropzone onDrop={(acceptedFiles) => {
                        onDrop(acceptedFiles);
                    }}>
                        {({getRootProps, getInputProps, isDragActive}) => (
                            <section>
                                <div className={styles.dropzone} {...getRootProps()} style={{ backgroundColor: isDragActive ? "var(--card-focus)" : "var(--card)" }}>
                                    <input {...getInputProps({multiple: true})} />
                                    <Typography>{t("dashboard.dataManagement.dropPrompt")}</Typography>
                                    <TbDragDrop />
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </Box>
            </Box>
            {importErrorDialogOpen &&
            <ImportErrorDialog message={importErrorMessage} errorCode={importErrorCode} onClose={() => handleImportErrorDialogClose()}></ImportErrorDialog>
            }
            <Box>
                <Box>
                    <Typography component="h2">{t("dashboard.dataManagement.export")}</Typography>
                    <Select className={styles.entitySelection} value={selectedExportTab} onChange={(event) => setSelectedExportTab(event.target.value as Entities.Paths | Entities.Areas)}>
                        <MenuItem value={Entities.Paths}>{t("general.paths")}</MenuItem>
                        <MenuItem value={Entities.Areas}>{t("general.areas")}</MenuItem>
                    </Select>
                    <ul className={styles.cardList}>
                        {(selectedExportTab === Entities.Paths ? paths : areas).map((c) => (
                            <EntityCard
                                key={c.firestoreUid}
                                item={c}
                                checked={selectedIds.includes(c.firestoreUid)}
                                onCheck={(checked) => {
                                    const si = [...selectedIds];
                                    if (checked) {
                                        si.push(c.firestoreUid);
                                    } else {
                                        si.splice(si.indexOf(c.firestoreUid), 1);
                                    }
                                    setSelectedIds(si);
                                }}
                            />
                        ))}
                    </ul>
                    <Box className={styles.downloadSection}>
                        <Select style={{ }} value={selectedExportFormat} onChange={(event) => setSelectedExportFormat(event.target.value as "gpx" | "kml")}>
                            <MenuItem value="gpx">GPX</MenuItem>
                            <MenuItem value="kml">KML</MenuItem>
                        </Select>
                        <Button disabled={selectedIds.length === 0} onClick={() => handleDownload()} variant="contained">{t("dashboard.dataManagement.importedFileSelection")}</Button>
                    </Box>
                </Box>
            </Box>
            {importResult && 
            <ImportSelectionDialog entities={importResult}
                                    onCancel={() => setImportResult(null)}
                                    onSubmit={(entities) => {
                                        entities.forEach((e) => {
                                            const entityType = resolveEntityTypeForItem(e);
                                            if (entityType === Entities.Areas) {
                                                dispatch(addArea(e as Area));
                                                addFirestoreElement(user.uid, Entities.Areas, e);
                                            } else if (entityType === Entities.Paths) {
                                                dispatch(addPath(e as Path));
                                                addFirestoreElement(user.uid, Entities.Paths, e);
                                            } else if (entityType === Entities.Notes) {
                                                dispatch(addNote(e as Note));
                                                addFirestoreElement(user.uid, Entities.Notes, e);
                                            }
                                        });
                                        setImportResult(null);
                                    }}
            />
            }
          </Box>
        </Box>
    )
}

/**
 * Dialog component for selecting imported entities to add to the application.
 * @param entities - The list of imported entities.
 * @param onCancel - Callback function invoked when the dialog is canceled.
 * @param onSubmit - Callback function invoked when the dialog's submit button is clicked.
 */
function ImportSelectionDialog({ entities, onCancel, onSubmit }: { entities: Entity[], onCancel: () => void, onSubmit: (entities: Entity[]) => void }) {
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const {t} = useTranslation();
    const failedImports = entities.filter((e) => !e).length;

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: 800, maxHeight: 800 } }}
            open={entities.length > 0}>
            <DialogTitle>{t("dashboard.dataManagement.importedFileSelection")}</DialogTitle>
            <DialogContent>
                { failedImports !== 0 &&
                    <Box style={{ width: "60%", borderRadius: 8, backgroundColor: "#e15858", color: "#fff", textAlign: "center", padding: 8 }}>
                        {failedImports} {t("dashboard.dataManagement.couldNotImport")}
                    </Box>
                }
                <span style={{ display: "grid", width: "100%", alignItems: "center", gridTemplateColumns: "3.5fr 1fr" }}>
                    <Typography>{t("dashboard.dataManagement.selectAll")}</Typography>
                    <Checkbox checked={selectedItems.length === entities.filter((e) => e).length}
                              onChange={(event, checked) =>
                                  setSelectedItems(checked ? entities.filter((e) => e).map((e) => e.firestoreUid) : [])}
                    />
                </span>
                <ul className={styles.importList}>
                    {entities.filter((e) => e).map((e, index) => (
                        <EntityCard key={index} item={e} checked={selectedItems.includes(e.firestoreUid)}
                                    onCheck={(checked) => {
                                        if (checked) setSelectedItems([...selectedItems, e.firestoreUid])
                                        else {
                                            const arr = [...selectedItems];
                                            arr.splice(selectedItems.indexOf(e.firestoreUid), 1);
                                            setSelectedItems(arr);
                                        }
                                    }}
                        />
                    ))}
                </ul>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={onCancel}>
                    {t("cancel")}
                </Button>
                <Button onClick={() => onSubmit(entities.filter((e) => selectedItems.includes(e.firestoreUid)))}>{t("submit")}</Button>
            </DialogActions>
        </Dialog>
    )
}

/**
 * Dialog component to show if an error occured during import.
 * @param message - The error message displayed in the dialog.
 * @param errorCode - The error code displayed in the dialog.
 * @param onClose - Callback function invoked when the dialog is closed.
 */
function ImportErrorDialog({message, errorCode, onClose}: { message: string | null, errorCode: string | null, onClose: () => void}) {
    const {t} = useTranslation();

    const handleCloseClick = () => {
        onClose();
    }
    return (
        <>
        <Dialog
        open={true}
        onClose={handleCloseClick}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {t("dashboard.dataManagement.importErrorTitle")}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                {message ? message : t("dashboard.dataManagement.importUnknownError")} {t("dashboard.dataManagement.importNoteInfoPromt")} <a href={`mailto:${t("help.infoMail")}`}>{t("help.infoMail")}</a>
                </DialogContentText>
                <DialogContentText id={styles.importErrorMessage}>
                {t("dashboard.dataManagement.importErrorCodeLabel")}: {errorCode ? errorCode : t("dashboard.dataManagement.importUnknownErrorCode")}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
          <Button onClick={handleCloseClick} autoFocus>
            {t("close")}
          </Button>
        </DialogActions>
        </Dialog>
        </>
    );
}