import LogoutPage from "../../../pages/logout";
import { ProvideAuth } from "../../../hooks/use-auth";

/**
 * Dedicated Logout route
 * @constructor
 * @category route
 */
export default function LogoutRoute() {
	return (
		<ProvideAuth>
			<LogoutPage />
		</ProvideAuth>
	);
}
