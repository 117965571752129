import Note from "../../../types/entities/note";
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormGroup,
	FormLabel,
	IconButton,
	Input,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import {Dispatch, SetStateAction, Suspense, useCallback, useEffect, useMemo, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {selectCategories, selectCategoryById,} from "../../../store/slices/category-slice";
import {selectAreas} from "../../../store/slices/area-slice";
import CategoryCard from "../../../components/cards/category-card";
import {addNote, selectNotesByAreaId, selectNotesByCategoryId, updateNote,} from "../../../store/slices/note-slice";
import styles from "../sidebar.module.css";
import mapStyles from "../../map/map.module.css";
import ImageCard, {ImageCardSkeleton,} from "../../../components/cards/image-card";
import getStaticMapPreview, {PreviewType,} from "../../../lib/model/utils/static-maps";
import {Add, CancelRounded, Check, Delete, Edit, Room} from "@mui/icons-material";
import Map, {Marker, useMap} from "react-map-gl";
import useMapProps from "../../map/use-map-props";
import store from "../../../store/store";
import {removeTempNote, setTempMarker} from "../../map/map-slice";
import {MarkerElement} from "../../map/items/note-markers";
import {getRgb} from "../../../lib/model/utils/colors";
import {TbPolygon, TbTags} from "react-icons/tb";
import AreaPolygons from "../../map/items/area-polygons";
import {v4} from "uuid";
import {addFirestoreElement, updateFirestoreElement,} from "../../../lib/firebase/firestore";
import Entities from "../../../types/entities";
import FormItemCover from "../../../components/sections/form-item-cover";
import {useTranslation} from "react-i18next";
import {useAuth} from "../../../hooks/use-auth";
import DomainDataAvailable from "./Domaindata_en";
import HorizontalDivider from "../../../components/dividers/horizontal-divider";
import useSidebarParams from "../use-sidebar-params";
import {compareDomainData, DomainDataTemplateType, DomainDataType} from "../../../types/entities/domain-data";

/**
 * Component representing a form for creating or updating a note.
 * @param {Object} props - The component props.
 * @param {Note} props.note - The note object if editing an existing note.
 * @returns {JSX.Element} The rendered component.
 */
export function NoteForm({ note }: { note?: Note }) {
	const {closeForm, setTab, setEditElement } = useSidebarParams();
	const categories = useAppSelector(selectCategories);
	const areas = useAppSelector(selectAreas);
	const tempMarker = useAppSelector((state) => state.map.tempMarker);
	const [name, setName] = useState(note ? note.name : "");
	const [description, setDescription] = useState(note ? note.description : "");
	const [categorySelectOpen, setCategorySelectOpen] = useState(false);
	const [categoryUid, setCategoryUid] = useState(
		note ? note.categoryUid : undefined
	);
	const [areaSelectOpen, setAreaSelectOpen] = useState(false);
	const [areaUid, setAreaUid] = useState(
		note && note.areaUid
			? note.areaUid
			: null
	);
	const [showAreaPolygons, setShowAreaPolygons] = useState(false);
	const { mapPosition, style } = useMapProps();
	const { m1: map, m_form: formMap } = useMap();
	const canSubmit = useMemo(() => {
		if (note) {
			return (
				name !== note.name ||
				description !== note.description ||
				categoryUid !== note.categoryUid ||
				areaUid !== note.areaUid ||
				(tempMarker && (tempMarker.lng !== note.lng || tempMarker.lat !== note.lat))
			);
		}
		return name !== "" && categoryUid && tempMarker;
	}, [note, name, description, categoryUid, areaUid, tempMarker]);
	const auth = useAuth();
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const [domainDataSelectionOpen, setDomainDataSelectionOpen] = useState(false);
	const [selectedDomainDataEntries, setSelectedDomainDataEntries] = useState<string[]>(note?.domainData ? note.domainData.map((e) => e.id) : []);
	const [domainData, setDomainData] = useState<DomainDataType[] | undefined>(note?.domainData ? JSON.parse(JSON.stringify(note.domainData)) as DomainDataType[] : undefined);

	/**
	 * Effect to format domain data from selected templates.
	 */
	useEffect(() => {
		const formattedData = selectedDomainDataEntries.map((id) => formatDataFromTemplate(id));
		if (domainData) {
			const dd = [...domainData].filter((d) => selectedDomainDataEntries.includes(d.id));
			formattedData.forEach((fd) => {
				const index = domainData.findIndex((k) => k.id === fd.id);
				if (index === -1) {
					dd.push(fd);
				}
			});
			setDomainData(dd.sort(compareDomainData));
			return;
		}
		setDomainData(formattedData.sort(compareDomainData));
	}, [selectedDomainDataEntries]);

	/**
	 * Update the Marker whenever the selected category changes
	 */
	useEffect(() => {
		if (!tempMarker && map && categoryUid) {
			const { lng, lat } = note ?? map.getCenter();
			dispatch(setTempMarker({ categoryUid, lng, lat }));
		}
	}, [categoryUid]);

	/**
	 * Delete the temporary Marker when the Form is removed from component tree
	 */
	useEffect(() => {
		return () => {
			dispatch(removeTempNote());
		};
	}, []);

	/**
	 * Have the small preview map jump to the center of the tempMarker whenever it is updated
	 */
	useEffect(() => {
		if (!tempMarker || !formMap) return;
		formMap.panTo([tempMarker.lng, tempMarker.lat]);
	}, [formMap, tempMarker]);

	/**
	 * Formats domain data from template.
	 * @param {string} entryId - The ID of the domain data template entry.
	 * @param {string} [defaultValue] - The default value for the entry.
	 * @returns {DomainDataType} The formatted domain data.
	 */
	function formatDataFromTemplate(entryId: string, defaultValue?: string): DomainDataType {
		const fromTemplate = DomainDataAvailable.data.find((d) => d.id === entryId) as DomainDataTemplateType;
		const sectionItems = fromTemplate.inputType === "Section" ? (fromTemplate.values as string[]).map((id) => formatDataFromTemplate(id)) : undefined;
		const value : { id?: string, value: string } | undefined =
			fromTemplate.inputType !== "Section" ?
				fromTemplate.inputType !== "Integer" ?
					fromTemplate.inputType === "Map" ?
						fromTemplate.values ? { id: Object.keys(fromTemplate.values)[0], value: Object.keys(fromTemplate.values)[0] } : undefined
						:
						{ id: defaultValue ?? fromTemplate.defaultValue ?? "", value: defaultValue ?? fromTemplate.defaultValue ?? "" }
					:
					{ value: "" }
				: undefined;
		let calculations: { [x:string]: number } | undefined;
		if (fromTemplate.calculations) {
			calculations = {};
			fromTemplate.calculations.forEach((c) => {
				const calcItem = DomainDataAvailable.data.find((d) => d.id === c);
				if (!calcItem || !calculations) return;
				calculations[c] = 0;
			})
		}
		const additional = fromTemplate.additional ? formatDataFromTemplate(fromTemplate.additional.item, fromTemplate.additional.defaultValue) : undefined;
		let valueMapChild;
		if (fromTemplate.inputType === "Map" && fromTemplate.values) {
			const values = fromTemplate.values as { [x:string]: { label: string, values: { id: string, label: string }[] } };
			valueMapChild = value && value.id ? { id: values[value.id].values[0].id, value: values[value.id].values[0].id } : undefined;
		}

		return {
			id: entryId,
			inputType: fromTemplate.inputType,
			labelText: fromTemplate.labelText,
			sectionItems,
			value,
			additional,
			valueMapChild,
			calculations
		}
	}

	/**
	 * Sets the value for a specific domain data item.
	 * @param value The new value to set.
	 * @param index The index of the domain data item to update.
	 * @param ids An array of IDs representing the path to the specific domain data item.
	 */
	function setDomainDataValue(value: string, index: number, ids: string[]) {
		if (!domainData) return;
		/**
		 * Recursively finds the domain data item based on the provided IDs.
		 * @param item The current domain data item to search within.
		 * @param ids The remaining IDs to traverse.
		 * @returns An object containing the found domain data item and a flag indicating if it needs to be mapped as a child.
		 */
		const getChildById = (item: DomainDataType | undefined, ids: string[]): { item: DomainDataType | undefined, toMapChild?: boolean } => {
			if (item === undefined || ids.length === 0) return { item };
			const child = item.additional?.id === ids[0] ? item.additional : item.sectionItems?.find((d) => d.id === ids[0]);
			if (!child && ids.includes("mapChild")) {
				return { item, toMapChild: true };
			}
			return getChildById(child, ids.slice(1));
		}
		const { item, toMapChild } = getChildById(domainData[index], ids.slice(1));
		if (!item) return;
		if (toMapChild && item.valueMapChild) {
			item.valueMapChild = { value, id: value };
		} else {
			item.value = { value, id: item.value?.id ? value : undefined };
		}
		setDomainData([...domainData]);
	}

	/**
	 * Renders the temporary Marker into the map whenever it or the map changes
	 */
	const TempMarker = useCallback(() => {
		if (!tempMarker) return <></>;
		const category = selectCategoryById(store.getState(), tempMarker.categoryUid ?? "");
		if (!category) return <></>;
		return (
			<Marker
				onClick={() => map?.panTo([tempMarker.lng, tempMarker.lat])}
				longitude={tempMarker.lng}
				latitude={tempMarker.lat}
			>
				<MarkerElement
					inMap={true}
					color={getRgb(category)}
					iconName={category.symbol_name}
				/>
			</Marker>
		);
	}, [map, tempMarker]);

	/**
	 * Update or add a note depending on whether an Element was passed into the component
	 */
	function handleSubmit() {
		if (!canSubmit || !categoryUid || !tempMarker || !auth || !auth.user) return;
		if (note) {
			const changes = {
				name,
				description,
				areaUid,
				categoryUid,
				lng: tempMarker.lng,
				lat: tempMarker.lat,
				lastChangedBy: auth.user.uid,
				center: {
					longitude: tempMarker.lng,
					latitude: tempMarker.lat,
					altitude: 0,
				},
				version: note.version ? note.version + 1 : 0,
				updatedAt: Date.now(),
				domainData
			};
			dispatch(
				updateNote({
					id: note.firestoreUid,
					changes,
				})
			);
			updateFirestoreElement(auth.user.uid, Entities.Notes, {
				...note,
				...changes,
			});
		} else {
			const newNote: Note = {
				firestoreUid: v4(),
				sharePartnerUid: auth.user.uid,
				accuracy: 1,
				areaUid,
				categoryUid,
				lng: tempMarker.lng,
				lat: tempMarker.lat,
				description,
				name,
				lastChangedBy: auth.user.uid,
				center: {
					longitude: tempMarker.lng,
					latitude: tempMarker.lat,
					altitude: 0,
				},
				createdAt: Date.now(),
				updatedAt: Date.now(),
				deletedAt: null,
				domainData,
				version: 0,
			};
			dispatch(addNote(newNote));
			addFirestoreElement(auth.user.uid, Entities.Notes, newNote);
		}
		closeForm();
	}

	return (
		<>
			<Box className={styles.listButtonContainer}>
				<IconButton
					disabled={!canSubmit}
					className={canSubmit ? styles.buttonSubmittable : ""}
					onClick={handleSubmit}
				>
					<Check />
				</IconButton>
				<IconButton onClick={() => closeForm()}>
					<CancelRounded />
				</IconButton>
			</Box>
			<Box className={styles.formControlContainer}>
				<FormControl fullWidth>
					<InputLabel className={styles.inputLabel} htmlFor="name">
						{t("name")}
					</InputLabel>
					<Input
						required={true}
						className={styles.input}
						id="name"
						type="text"
						value={name}
						onChange={(event) => setName(event.target.value)}
					/>
				</FormControl>
				<FormControl fullWidth>
					<InputLabel className={styles.inputLabel} htmlFor="description">
						{t("description")}
					</InputLabel>
					<Input
						required={true}
						className={styles.input}
						id="description"
						type="text"
						value={description}
						onChange={(event) => setDescription(event.target.value)}
					/>
				</FormControl>
				<FormItemCover
					onDisplay={() => {
						setCategoryUid(categories[0].firestoreUid);
						setCategorySelectOpen(true);
					}}
					initialVisibility={note !== undefined}
					label={"Kategorie zuweisen *"}
					Icon={TbTags}
				>
					<FormControl fullWidth>
						<InputLabel className={`${styles.inputLabel} ${styles.inputLabelOpaque}`} htmlFor={"category"}>
							{t("model.category")} auswählen
						</InputLabel>
						<Select
							open={categorySelectOpen}
							onOpen={() => setCategorySelectOpen(true)}
							onClose={() => setCategorySelectOpen(false)}
							MenuProps={{
								MenuListProps: { className: styles.cardSelectMenu },
							}}
							className={styles.cardSelect}
							id="category"
							value={categoryUid}
							onChange={(event) => {
								setCategoryUid(event.target.value);
								tempMarker &&
								dispatch(
									setTempMarker({
										...tempMarker,
										categoryUid: event.target.value,
									})
								);
							}}
						>
							{categories.map((c, index) => (
								<MenuItem key={index} value={c.firestoreUid}>
									<CategoryCard
										category={c}
										numOfNotes={
											selectNotesByCategoryId(store.getState(), c.firestoreUid)
												.length
										}
										onClick={() => {}}
									/>
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</FormItemCover>
				<FormItemCover
					initialVisibility={note !== undefined && note.areaUid !== undefined}
					label={"Gebiet zuweisen"}
					Icon={TbPolygon}
					onDisplay={() => {
						if (areas.length > 0) {
							setAreaSelectOpen(true);
						}
					}}
				>
					<FormControl fullWidth>
						<InputLabel className={`${styles.inputLabel} ${styles.inputLabelOpaque}`} htmlFor="area">
							{t("model.area")} auswählen
						</InputLabel>
						{areas.length > 0 ? (
							<Select
								open={areaSelectOpen}
								onOpen={() => setAreaSelectOpen(true)}
								onClose={() => setAreaSelectOpen(false)}
								MenuProps={{
									MenuListProps: { className: styles.cardSelectMenu },
								}}
								className={styles.cardSelect}
								id="area"
								value={areaUid}
								onChange={(event) => setAreaUid(event.target.value)}
							>
								<MenuItem className={styles.noSelection} onClick={() => {
									setAreaSelectOpen(false);
									setAreaUid(null);
								}}>
									{t("sidebar.form.dontAssign", { entity: t("model.area") })}
								</MenuItem>
								{areas.map((a, index) => (
									<MenuItem key={index} value={a.firestoreUid}>
										<Suspense fallback={<ImageCardSkeleton />}>
											<ImageCard
												id={a.firestoreUid}
												name={a.name}
												type={Entities.Areas}
												color={a.color}
												metaText={
													<Typography className={styles.metaText}>
														<Room />{" "}
														{t("cards.assignedNotes", {
															num: selectNotesByAreaId(
																store.getState(),
																a.firestoreUid
															).length,
														})}
													</Typography>
												}
												onClick={() => {}}
											/>
										</Suspense>
									</MenuItem>
								))}
							</Select>
						) : (
							<Box>
								<Typography>{t("sidebar.form.noAreasYet")}</Typography>
								<Button
									onClick={() => {
										setTab(Entities.Areas);
										setEditElement(null);
									}}
								>
									{t("sidebar.form.toAreaForm")}
								</Button>
							</Box>
						)}
					</FormControl>
				</FormItemCover>
				<Box className={styles.mapPreview}>
					<label>{t("preview")}</label>
					<Map
						mapboxAccessToken={import.meta.env.VITE_MAPBOX_ACCESS_TOKEN}
						interactive={false}
						id="m_form"
						mapStyle={style.url}
						initialViewState={mapPosition}
					>
						<TempMarker />
						<IconButton
							style={showAreaPolygons ? {
								color: "var(--accent)",
							} : {}}
							onClick={() => setShowAreaPolygons(!showAreaPolygons)}
							className={`${mapStyles.mapControlButton} ${styles.previewToggle}`}
						>
							<TbPolygon />
						</IconButton>
						{showAreaPolygons && <AreaPolygons disableClick={true} />}
					</Map>
					<Box className={styles.locationInfo}>
						<Typography>
							{t("map.longitude")} {tempMarker?.lng.toFixed(4)}
						</Typography>
						<Typography>
							{t("map.latitude")} {tempMarker?.lat.toFixed(4)}
						</Typography>
					</Box>
				</Box>
				<Box style={{ marginTop: 70, width: "100%" }}>
					{!domainData ?
						<Button onClick={() => setDomainDataSelectionOpen(true)}>Erweiterte Daten hinzufügen</Button>
						:
						<Box style={{ width: "100%" }}>
							<Typography display="inline">Erweiterte Daten</Typography>
							<IconButton onClick={() => setDomainDataSelectionOpen(true)}><Edit /></IconButton>
							<Box style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "start", gap: 24, marginTop: 16 }}>
								{domainData.map((d, index) => (
									<Box key={index} style={{ display: "flex", flexDirection: "column", gap: 12 }}>
										{(d.inputType !== "Section" && (index === 0 || domainData.findIndex((d) => d.inputType !== "Section") === index))
											&& <Typography style={{ fontWeight: "bold", fontSize: 14 }}>Individual Data</Typography>
										}
										<DomainDataItem item={d}
														withSectionTitle={domainData.findIndex((e) => e.id === d.id) === index}
														setValue={(value, ids) => setDomainDataValue(value, index, ids)}
														addSection={(sectionId) =>
															setDomainData([...domainData, formatDataFromTemplate(sectionId)].sort(compareDomainData))
														}
														handleRemoveSection={() => {
															const d = [...domainData];
															d.splice(index, 1);
															setDomainData(d);
														}}
										/>
									</Box>
								))}
							</Box>
						</Box>
					}
					<DomainDataSelectionDialog
						selectedDomainDataEntries={selectedDomainDataEntries}
						setSelectedDomainDataEntries={setSelectedDomainDataEntries}
						open={domainDataSelectionOpen}
						onClose={() => setDomainDataSelectionOpen(false)}
						onConfirm={() => setDomainDataSelectionOpen(false)}
					/>
				</Box>
			</Box>
		</>
	);
}

/**
 * Renders a domain data item component based on its type.
 * @param param0 An object containing the domain data item and optional props.
 */
function DomainDataItem({ item, withSectionTitle, setValue, addSection, handleRemoveSection }: { item: DomainDataType, withSectionTitle?: boolean, setValue: (value: string, ids: string[]) => void, addSection?: (sectionId: string) => void, handleRemoveSection?: () => void  }) {
	const inputType = useMemo(() => {
		return item.inputType ?? DomainDataAvailable.data.find((e) => e.id === item.id)?.inputType ?? "";
	}, [item]);
	const [open, setOpen] = useState(false);

	switch (inputType) {
		case "Integer":
			if (item.additional) {
				return (
					<Box style={{ display: "grid", gridTemplateColumns: "4fr 1fr", gap: 8 }}>
						<TextField label={item.labelText} value={item.value?.value} onChange={(event) => setValue(event.target.value, [item.id])} type="number" placeholder={item.labelText} />
						<DomainDataItem item={item.additional} setValue={(value, ids) => setValue(value, [item.id, ...ids])} />
					</Box>
				)
			}
			return <TextField label={item.labelText} value={item.value?.value} onChange={(event) => setValue(event.target.value, [item.id])} type="number" fullWidth placeholder={item.labelText} />
		case "Section":
			return (
				<>
					<FormGroup style={{ display: "flex", flexDirection: "column", gap: 12 }}>
						{withSectionTitle ? <Box style={{ display: "grid", gridTemplateColumns: "6fr 0.5fr", alignItems: "center" }}>
							<Typography display="inline" style={{ fontWeight: "bold", fontSize: 14 }}>{item.labelText}</Typography>
							<IconButton onClick={() => addSection && addSection(item.id) }>
								<Add />
							</IconButton>
						</Box> :
							<Box style={{ display: "grid", gridTemplateColumns: "6fr 0.5fr", alignItems: "center" }}>
								<HorizontalDivider />
								<IconButton onClick={() => setOpen(true) }>
									<Delete />
								</IconButton>
							</Box>
						}
						{item.sectionItems!.map((childItem, index) => {
							if (!childItem) return <></>
							return (
								<DomainDataItem key={index} item={childItem} setValue={(value, ids) => setValue(value, [item.id, ...ids])} />
							)
						})}
					</FormGroup>
					<Dialog
						sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
						maxWidth="xs"
						open={open}
						onClose={() => setOpen(false)}
					>
						<DialogTitle>Eintrag entfernen?</DialogTitle>
						<DialogActions>
							<Button autoFocus onClick={() => setOpen(false)}>
								Abbrechen
							</Button>
							<Button onClick={() => {
								setOpen(false);
								handleRemoveSection && handleRemoveSection();
							}
							}>Bestätigen</Button>
						</DialogActions>
					</Dialog>
				</>
			)
		case "List":
			return (
				<DomainDataListItem setValue={(value) => setValue(value, [item.id])} item={item} />
			)
		case "Map":
			return (
				<DomainDataMapItem item={item} setValue={(value, toChild) => {
					let ids = [item.id];
					if (toChild) ids = [...ids, "mapChild"];
					setValue(value, ids);
				}} />
			)
		default:
			return <TextField value={item.value?.value} onChange={(event) => setValue(event.target.value, [item.id])} fullWidth placeholder={item.labelText} />
	}
}

/**
 * Renders a component for a map type domain data item.
 * @param param0 An object containing the domain data item and a function to set its value.
 */
function DomainDataMapItem({ item, setValue }: {
	item: DomainDataType,
	setValue: (value: string, toChild?: boolean) => void
}) {
	const v = useMemo(() => {
		const templateItem = DomainDataAvailable.data.find((d) => d.id === item.id) as DomainDataTemplateType;
		const values = templateItem.values as { [x:string]: { label: string, values: { id: string, label: string }[] } }
		return (Object.keys(values) as Array<keyof typeof values>).reduce((accumulator, current) => {
			accumulator.push(values[current]);
			return accumulator;
		}, [] as (typeof values[keyof typeof values])[]);
	}, [item]);

	return (
		<>
			<FormLabel>{item.labelText}</FormLabel>
			<Select value={item.value?.value} onChange={(event) => {
				setValue(event.target.value);
				setValue(v.find((d) => d.label === event.target.value)!.values[0].id, true);
			}}>
				{v.map((parentItem, index) => (
					<MenuItem value={parentItem.label} key={index}>{parentItem.label}</MenuItem>
				))}
			</Select>
			<FormLabel>{item.value?.value}</FormLabel>
			<Select value={item.valueMapChild?.value} onChange={(event) => setValue(event.target.value, true) }>
				{v[v.findIndex((d) => d.label === item.value?.value)].values.map((childItem, index) => (
					<MenuItem key={index} value={childItem.id}>{childItem.label}</MenuItem>
				))}
			</Select>
		</>
	)
}

/**
 * Renders a component for a list type domain data item.
 * @param param0 An object containing the domain data item and a function to set its value.
 */
function DomainDataListItem({ item, setValue }: { item: DomainDataType, setValue: (value: string) => void }) {
	const values = useMemo(() => {
		return (DomainDataAvailable.data.find((d) => d.id === item.id) as DomainDataTemplateType).values;
	}, [item]);

	return (
		<Select value={item.value?.value} onChange={(event) => {
			setValue(event.target.value);
		}}>
			{(values as {id: string, label: string}[]).map((v, index) => (
				<MenuItem key={index} value={v.id}>{v.label}</MenuItem>
			))}
		</Select>
	)
}

export interface ConfirmationDialogProps {
	open: boolean;
	onClose: () => void;
	onConfirm: () => void;
	selectedDomainDataEntries: string[];
	setSelectedDomainDataEntries: Dispatch<SetStateAction<string[]>>
}

/**
 * Renders a dialog component for selecting domain data entries.
 * @param props Props for the domain data selection dialog.
 */
function DomainDataSelectionDialog(props: ConfirmationDialogProps) {
	const { selectedDomainDataEntries, setSelectedDomainDataEntries, onClose, onConfirm, open, ...other } = props;
	const [selected, setSelected] = useState([...selectedDomainDataEntries]);

	const handleCancel = () => {
		onClose();
	};

	const handleOk = () => {
		setSelectedDomainDataEntries([...selected]);
		onConfirm();
		onClose();
	};

	return (
		<Dialog
			sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
			maxWidth="xs"
			open={open}
			{...other}
		>
			<DialogTitle>Weitere Daten hinzufügen</DialogTitle>
			<DialogContent>
				<DomainDataSelection selected={selected} setSelected={setSelected} />
			</DialogContent>
			<DialogActions>
				<Button autoFocus onClick={handleCancel}>
					Abbrechen
				</Button>
				<Button onClick={handleOk}>Bestätigen</Button>
			</DialogActions>
		</Dialog>
	)
}

/**
 * Renders a component for selecting domain data entries.
 * @param param0 An object containing the selected domain data entries and a function to update the selection.
 */
function DomainDataSelection({ selected, setSelected }: { selected: string[], setSelected: Dispatch<SetStateAction<string[]>> }) {
	const sections = DomainDataAvailable.data.filter((d) => d.inputType === "Section");
	const individual = DomainDataAvailable.data.filter((d) => d.inputType !== "Section" && !d.isAdditional);

	function handleClick(id: string) {
		const searchIndex = selected.indexOf(id);
		if (searchIndex === -1) {
			setSelected([...selected, id]);
		} else {
			const cp = [...selected];
			cp.splice(searchIndex, 1);
			setSelected([...cp]);
		}
	}

	function SelectionItem({ label, isSelected, onChange }: { label: string, isSelected: boolean, onChange: (value: boolean) => void }) {
		return (
			<Box style={{ height: 50, display: "grid", alignItems: "center", gridTemplateColumns: "5fr 1fr" }}>
				<Typography display="inline">{label}</Typography>
				<Checkbox style={{ height: 50, width: 50 }} checked={isSelected} onChange={(event, checked) => onChange(checked)} />
			</Box>
		)
	}

	return (
		<Box>
			<Box>
				<Typography style={{ fontSize: 14, color: "var(--text-hint)" }}>Gruppendaten</Typography>
				<ul>
					{sections.map((section, index) => (
						<li key={index}>
							<SelectionItem label={section.labelText} isSelected={selected.includes(section.id)} onChange={() => handleClick(section.id)} />
						</li>
					))}
				</ul>
			</Box>
			<Box>
				<Typography style={{ fontSize: 14, color: "var(--text-hint)" }}>Einzeldaten</Typography>
				<ul>
					{individual.map((individual, index) => (
						<li key={index}>
							<SelectionItem label={individual.labelText} isSelected={selected.includes(individual.id)} onChange={() => handleClick(individual.id)} />
						</li>
					))}
				</ul>
			</Box>
		</Box>
	)
}
