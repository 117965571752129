import {createAsyncThunk, createEntityAdapter, createSlice,} from "@reduxjs/toolkit";
import Path from "../../types/entities/path";
import type {RootState} from "../store";
import {byLength, chronologicalCreated, chronologicalUpdated,} from "../sort-comparers";
import {entityAsyncThunk} from "./index";
import Entities from "../../types/entities";

/**
 * This file defines the slice for the Paths Entity.
 * The general layout will be the same for all entity slices.
 *
 * Adapter: Manages list of all elements for this entity and supplies standard functions like addOne and updateOne
 * AsyncThunks: Asynchronously fetches data for this collection name from firestore and populates list initially
 * Selectors: Exports reusable selectors for this slice
 * Comparators: Exports the comparators that are applicable to this entity type
 *
 * @category Slice
 */

const pathAdapter = createEntityAdapter<Path>({
	selectId: (path) => path.firestoreUid,
	sortComparer: (a, b) => chronologicalCreated(a, b),
});

export const fetchPaths = createAsyncThunk(
	"paths/fetchPaths",
	async (userId: string) => entityAsyncThunk(Entities.Paths)(userId)
);

const initialState = pathAdapter.getInitialState({
	status: "idle",
});

const pathSlice = createSlice({
	name: "paths",
	initialState,
	reducers: {
		addPath: pathAdapter.addOne,
		updatePath: pathAdapter.updateOne,
		removePath: pathAdapter.removeOne,
		addPaths: pathAdapter.addMany
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchPaths.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchPaths.fulfilled, (state, action) => {
				pathAdapter.setAll(state, action.payload as Path[]);
				state.status = "idle";
			});
	},
});

export const { addPath, updatePath, removePath, addPaths } = pathSlice.actions;

export default pathSlice.reducer;

export const { selectAll: selectPaths, selectById: selectPathById } =
	pathAdapter.getSelectors<RootState>((state) => state.data.paths);

const pathSortComparers = [
	{ name: "Creation Date", function: chronologicalCreated },
	{ name: "Last Update", function: chronologicalUpdated },
	{ name: "By Length", function: byLength },
];

export { pathSortComparers };
