import {Box, Button, Typography, useTheme} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import getStaticMapPreview, {PreviewType,} from "../../lib/model/utils/static-maps";
import {drawerWidth} from "./index";
import {selectCategoryById} from "../../store/slices/category-slice";
import {getRgb} from "../../lib/model/utils/colors";
import {Numbers, Person, Room} from "@mui/icons-material";
import {TbChartArea, TbChartLine, TbFileTime, TbGps, TbPolygon, TbTags,} from "react-icons/tb";
import {PiPathBold} from "react-icons/pi";
import {selectAreaById} from "../../store/slices/area-slice";
import TitleSection from "../../components/sections/title-section";
import ButtonSection from "../../components/sections/button-section";
import OverviewSection, {OverviewItem,} from "../../components/sections/overview-section";
import {Fragment, Suspense, useMemo} from "react";
import Note, {isNote} from "../../types/entities/note";
import Category from "../../types/entities/category";
import Area from "../../types/entities/area";
import Path from "../../types/entities/path";
import TurningPoint from "../../types/entities/turning-point";
import {selectNotesByAreaId, selectNotesByCategoryId,} from "../../store/slices/note-slice";
import {calculateBoundingBoxFromMinMaxBounds, calculateLength, calculateSurfaceArea,} from "../../lib/model/utils/geo";
import styles from "./sidebar.module.css";
import DescriptionSection from "../../components/sections/description-section";
import ContentLoader from "react-content-loader";
import Entities, {Entity} from "../../types/entities";
import store from "../../store/store";
import NoteCard from "../../components/cards/note-card";
import ImageLayer from "../../types/entities/image-layer";
import LayerGroup from "../../types/entities/layer-group";
import {formatGeoURL} from "../../lib/geoserver";
import useElementOwner from "../../hooks/use-element-owner";
import {selectMediaByNoteId} from "../../store/slices/media-slice";
import {isMedia} from "../../types/entities/media";
import useActions from "../../hooks/actions";
import {useTranslation} from "react-i18next";
import DomainDataAvailable from "./form/Domaindata_en";
import {RxWidth} from "react-icons/rx";
import Grid from "../../types/entities/grid";
import useSidebarParams from "./use-sidebar-params";
import {getCalcValue} from "../../types/entities/domain-data";
import { t } from "i18next";

/**
 * Custom hook to extract detailed information about an entity.
 * @param element The entity for which details are to be extracted.
 * @returns Object containing detailed information about the entity.
 */
function useDetail(element: Entity) {
	const {tab, titleImageIndex: titleTabIndex} = useSidebarParams();
	const { selectedLayerStyles } = useAppSelector((state) => state.map);
	const noteCategory = useAppSelector((state) =>
		selectCategoryById(state, isNote(element) ? element.categoryUid : "")
	);
	const noteArea = useAppSelector((state) =>
		selectAreaById(state, isNote(element) ? element.areaUid ?? "" : "")
	);
	const numOfNotesByCategory = useAppSelector(
		(state) =>
			selectNotesByCategoryId(state, element?.firestoreUid ?? "").length
	);
	const numOfNotesByArea = useAppSelector(
		(state) => selectNotesByAreaId(state, element?.firestoreUid ?? "").length
	);
	const owner = useElementOwner(element);
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const {setTab, setDetail} = useSidebarParams();

	// Calculate detailed information based on the entity type
	const v = useMemo(() => {
		const subtitle = `${t("lastChangedAt")} ${new Date(
			element?.updatedAt ?? Date.now()
		).toLocaleDateString()}`;
		switch (tab) {
			case Entities.Notes: {
				const note = element as Note;
				return {
					title: note.name,
					subtitle,
					imgSrc: getStaticMapPreview(
						[{ longitude: note.lng, latitude: note.lat, altitude: 0 }],
						PreviewType.Marker,
						{ color: noteCategory ? getRgb(noteCategory) : "var(--text)", width: drawerWidth, height: 250 }
					),
					description: note.description,
					overviewItems: [
						{
							text: `${t("measurements.longitude").toString()}: ${note.lng.toFixed(
								2
							)}, ${t("measurements.latitude").toString()}: ${note.lat.toFixed(2)}`,
							icon: TbGps,
						},
						{ 
							text: noteCategory ? noteCategory.name : t("errors.categoryNotFound").toString(),
							icon: TbTags,
							onClick: noteCategory ? () => {
								setTab(Entities.Categories);
								setDetail(noteCategory.firestoreUid);
							} : undefined
						},
						{
							text: noteArea?.name ?? t("cards.notAssignedToArea").toString(),
							icon: TbPolygon,
							onClick: noteArea?.name ? () => {
								setTab(Entities.Areas);
								setDetail(noteArea.firestoreUid);
							} : undefined
						},
						{
							text:
								owner === "Vorführer" ? owner : "name" in owner ? owner.name : owner.displayName ?? t("errors.noOwnerFound").toString(),
							icon: Person,
						},
						{
							text: `${t("createdAt")} ${new Date(
								note.createdAt
							).toLocaleDateString()}`,
							icon: TbFileTime,
						},
					],
					childItems: selectMediaByNoteId(store.getState(), note.firestoreUid),
				};
			}
			case Entities.Categories: {
				const category = element as Category;
				return {
					title: category.name,
					subtitle,
					imgSrc: undefined,
					symbol: category.symbol_name,
					color: getRgb(category),
					description: undefined,
					overviewItems: [
						{ text: `${t("cards.assignedNotes", { num: numOfNotesByCategory }).toString()}`, icon: Room },
						{
							text:
								owner === "Vorführer" ? owner : "name" in owner ? owner.name : owner.displayName ?? t("errors.noOwnerFound").toString(),
							icon: Person,
						},
						{
							text: `${t("createdAt").toString()} ${new Date(
								category.createdAt
							).toLocaleDateString()}`,
							icon: TbFileTime,
						},
					],
					childItems: selectNotesByCategoryId(
						store.getState(),
						category.firestoreUid
					),
				};
			}
			case Entities.Areas: {
				const area = element as Area;
				return {
					title: area.name,
					subtitle,
					imgSrc: getStaticMapPreview(area.points, PreviewType.Polygon, {
						width: drawerWidth,
						height: 250,
						color: area.color,
					}),
					description: area.description,
					overviewItems: [
						{ text: `${t("cards.assignedNotes", { num: numOfNotesByArea }).toString()}`, icon: Room },
						{
							text: `${calculateSurfaceArea(
								area.points.map((p) => [p.longitude, p.latitude])
							).toFixed(0)} qm²`,
							icon: TbChartArea,
						},
						{
							text:
								owner === "Vorführer" ? owner : "name" in owner ? owner.name : owner.displayName ?? t("errors.noOwnerFound").toString(),
							icon: Person,
						},
						{
							text: `${t("createdAt").toString()} ${new Date(
								area.createdAt
							).toLocaleDateString()}`,
							icon: TbFileTime,
						},
					],
					childItems: selectNotesByAreaId(store.getState(), area.firestoreUid),
				};
			}
			case Entities.Paths: {
				const path = element as Path;
				return {
					title: path.name ?? t(`path.${path.type}`),
					subtitle,
					imgSrc: getStaticMapPreview(path.points, PreviewType.Line, {
						width: drawerWidth,
						height: 250,
					}),
					description: path.description,
					overviewItems: [
						{
							text: `${calculateLength(path).toFixed(0)} m`,
							icon: TbChartLine,
						},
						{
							text: t(`path.${path.type}`),
							icon: PiPathBold,
						},
						{
							text: `Breite: ${path.width} m`,
							icon: RxWidth,
						},
						{
							text:
								owner === "Vorführer" ? owner : "name" in owner ? owner.name : owner.displayName ?? t("errors.noOwnerFound").toString(),
							icon: Person,
						},
						{
							text: `${t("createdAt").toString()} ${new Date(
								path.createdAt
							).toLocaleDateString()}`,
							icon: TbFileTime,
						},
					],
				};
			}
			case Entities.TurningPoints: {
				const turningPoint = element as TurningPoint;
				return {
					title: turningPoint.name ?? turningPoint.number ?? t("model.turningPoint").toString(),
					subtitle,
					imgSrc: getStaticMapPreview(
						[
							{
								longitude: turningPoint.long,
								latitude: turningPoint.lat,
								altitude: 0,
							},
						],
						PreviewType.Marker,
						{ width: drawerWidth, height: 250 }
					),
					description: undefined,
					overviewItems: [
						{ text: String(turningPoint.number), icon: Numbers },
						{
							text: `${t("measurements.longitude").toString()}: ${turningPoint.long.toFixed(
								2
							)}, ${t("measurements.latitude").toString()}: ${turningPoint.lat.toFixed(2)}`,
							icon: TbGps,
						},
						{
							text:
								owner === "Vorführer" ? owner : "name" in owner ? owner.name : owner.displayName ?? t("errors.noOwnerFound").toString(),
							icon: Person,
						},
						{
							text: `${t("createdAt").toString()} ${new Date(
								turningPoint.createdAt
							).toLocaleDateString()}`,
							icon: TbFileTime,
						},
					],
				};
			}
			case Entities.ImageLayer: {
				const imageLayer = element as ImageLayer;
				return {
					title: imageLayer["file-name"],
					subtitle,
					imgSrc: imageLayer["firestore-uri"],
					description: undefined,
					overviewItems: [],
				};
			}
			case Entities.LayerGroup: {
				const layerGroup = element as LayerGroup;
				return {
					title: layerGroup.name,
					subtitle,
					description: layerGroup.description,
					imgSrc: layerGroup.layers.map((layer) =>
						formatGeoURL(layerGroup.provider, layer.fileName, layer.bounds, {
							workspace: layer.workspace,
						})
					),
					overviewItems: [
						{
							text: `Ø ${layerGroup.layers
								.map((layer) =>
									calculateSurfaceArea(
										calculateBoundingBoxFromMinMaxBounds(layer.bounds)
									)
								)
								.sort()[0]
								.toFixed(0)} qm²`,
							icon: TbChartArea,
						},
						{
							text:
								owner === "Vorführer" ? owner : "name" in owner ? owner.name : owner.displayName ?? t("errors.noOwnerFound").toString(),
							icon: Person,
						},
						{
							text: `${t("createdAt").toString()} ${new Date(
								layerGroup.createdAt
							).toLocaleDateString()}`,
							icon: TbFileTime,
						},
					],
					additionalSections: [
					],
				};
			}
			case Entities.Grids: {
				const grid = element as Grid;
				return {
					title: grid.name,
					subtitle,
					description: grid.description,
					imgSrc: getStaticMapPreview([], PreviewType.Grid, { grid, width: drawerWidth, height: 250 }),
					overviewItems: [
						{
							text: grid.areaUid ? selectAreaById(store.getState(), grid.areaUid)!.name : "Noch keinem Gebiet zugewiesen",
							icon: TbPolygon,
							onClick: grid.areaUid ? () => {
								setTab(Entities.Areas);
								setDetail(grid.areaUid!);
							} : undefined
						},
						{
							text:
								owner === "Vorführer" ? owner : "name" in owner ? owner.name : owner.displayName ?? t("errors.noOwnerFound").toString(),
							icon: Person,
						},
						{
							text: `${t("createdAt").toString()} ${new Date(
								grid.createdAt
							).toLocaleDateString()}`,
							icon: TbFileTime,
						}
					],
					additionalSections: [
					],
				}
			}
			default: {
				return {
					title: "",
					subtitle,
					imgSrc: undefined,
					description: undefined,
					overviewItems: [],
				};
			}
		}
	}, [
		noteArea,
		noteCategory,
		dispatch,
		element,
		numOfNotesByArea,
		numOfNotesByCategory,
		owner,
		selectedLayerStyles,
		tab,
		titleTabIndex,
	]);

	return { element, ...v };
}

function DetailSkeleton() {
	const theme = useTheme();

	return (
		<ContentLoader
			speed={2}
			width={drawerWidth}
			height={window.innerHeight - 10}
			backgroundColor={theme.palette.grey["900"]}
			foregroundColor={theme.palette.grey.A100}
			className={styles.skeleton}
		>
			<rect x={0} y={0} rx={0} ry={0} width={drawerWidth} height={250} />
			<rect
				x="10"
				y="285"
				rx="5"
				ry="5"
				width={drawerWidth / 1.5}
				height="10"
			/>
			<rect
				x="10"
				y="305"
				rx="5"
				ry="5"
				width={drawerWidth / 2.5}
				height="10"
			/>
			{[...Array(4)].map((n, index) => (
				<Fragment key={"c_" + index}>
					<circle cx={140 + index * 70} cy={405} r={30} />
					<rect
						x={110 + index * 70}
						y={440}
						rx="5"
						ry="5"
						width={60}
						height="10"
					/>
				</Fragment>
			))}
			{[...Array(3)].map((n, index) => (
				<rect
					key={"r_" + index}
					x="20"
					y={510 + index * 20}
					rx="5"
					ry="5"
					width={drawerWidth - 40}
					height="10"
				/>
			))}
			{[...Array(5)].map((n, index) => (
				<Fragment key={"c_" + index}>
					<circle cx={80} cy={640 + index * 35} r={10} />
					<rect
						x="120"
						y={635 + index * 35}
						rx="5"
						ry="5"
						width={250}
						height="10"
					/>
				</Fragment>
			))}
		</ContentLoader>
	);
}

export default function DetailView() {
	const {selectCurrentElement} = useSidebarParams();
	const {tab, setDetail} = useSidebarParams();
	const {t} = useTranslation();
	const element = useAppSelector((state) => selectCurrentElement(state));

	if (!element) {
		return <Box className={styles.nothingHereBox}>
			<Box>
				{/* TODO: Attribution https://www.freepik.com/free-vector/active-tourist-hiking-mountain-man-wearing-backpack-enjoying-trekking-looking-snowcapped-peaks-vector-illustration-nature-wilderness-adventure-travel-concept_10606146.htm#query=illustrations&position=1&from_view=keyword&track=sph */}
				<img alt={t("info.nothingHereYet")} src={"/images/nothing_here.png"} width={400} height={225} />
				<Typography component="h6">{t("info.elementNotFound", { entityType: t("general." + tab) })}</Typography>
				<Button onClick={() => setDetail(null)} variant="contained">{t("back")}</Button>
			</Box>
		</Box>
	}

	return (
		<Detail element={element} />
	)
}

function Detail({element}: { element: Entity }) {
	const { tab: entityType, setTab, setDetail} = useSidebarParams();
	const {
		title,
		subtitle,
		imgSrc,
		description,
		overviewItems,
		symbol,
		color,
		childItems,
		additionalSections,
	} = useDetail(element);
	const actions = useActions(element);
	const { t } = useTranslation();

	return (
		<Suspense fallback={<DetailSkeleton />}>
			<Box
				className={styles.detailContainer}
			>
				<TitleSection
					title={title ? title.toString() : ""}
					subtitle={subtitle}
					imgSrc={imgSrc}
					symbol={symbol}
					color={color}
				/>
				<ButtonSection actions={actions} />
				{description && <DescriptionSection description={description} />}
				{overviewItems.length > 0 && (
					<OverviewSection>
						{overviewItems.map((item, index) => (
							// @ts-ignore
							<OverviewItem key={index} text={item.text} Icon={item.icon} onClick={item.onClick ? item.onClick : undefined} />
						))}
					</OverviewSection>
				)}
				{childItems &&
					(entityType === Entities.Categories ? (
						<div>
							<label className={styles.label}>{t("general.notes")}</label>
							<ul>
								{childItems.map((child, index) => {
									if (!element) return <></>;
									if (!isNote(child)) return <></>;
									const category = selectCategoryById(
										store.getState(),
										element.firestoreUid
									);
									const area = child.areaUid
										? selectAreaById(store.getState(), child.areaUid)
										: undefined;
									return (
										<div className={styles.entityChildrenLi} key={"div" + index}>
											<NoteCard
												key={index}
												onClick={() => {
													setTab(Entities.Notes);
													setDetail(child.firestoreUid);
												}}
												note={child}
												category={category}
												area={area}
										/>
										</div>
									);
								})}
							</ul>
						</div>
					) : entityType === Entities.Areas ? (
						<div>
							<label className={styles.label}>{t("general.notes")}</label>
							<ul>
								{childItems.map((child, index) => {
									if(!element) return <></>;
									if(!isNote(child)) return <></>;
									const category = selectCategoryById(
										store.getState(),
										child.categoryUid
									);
									return (
										<div className={styles.entityChildrenLi} key={"div" + index}>
											<NoteCard
												key={index}
												onClick={() => {
													setTab(Entities.Notes);
													setDetail(child.firestoreUid);
												}}
												note={child}
												category={category}
												area={selectAreaById(store.getState(), element.firestoreUid)}
											/>
										</div>
									);
								})}
							</ul>
						</div>
					) : (
						<div className={styles.mediaContainer}>
							{childItems.map((media, index) => {
								if (!isMedia(media)) return <></>;
								return (
									<img
										key={index}
										src={String(media.firestoreUri)}
										alt={`Attached to Note`}
									/>
								);
							})}
						</div>
					))}
				{entityType === Entities.Notes && "domainData" in element &&
					<DomainDataView note={element} />
				}
				{additionalSections && additionalSections.length > 0 && (
					<>{additionalSections}</>
				)}
			</Box>
		</Suspense>
	);
}

/**
 * Renders a view for displaying domain-specific data associated with a note.
 * @param note - The note object containing domain data.
 */
function DomainDataView({ note }: { note: Note }) {
	/**
	 * Memoized domain data to prevent unnecessary re-renders.
	 */
	const domainData = useMemo(() => {
		return note.domainData;
	}, [note]);

	return (
		<div className={styles.domainDataContainer}>
			<Typography className={styles.domainDataHeading}>{t("sidebar.detailView.moreData")}</Typography>
			<div className={styles.sectionContainer}>
				{domainData &&
					domainData
						.filter((d) => d.inputType === 'Section')
						.map((d, index) => (
							<div className={styles.sectionItem} key={index}>
								<Fragment>
									<Typography className={styles.domainDataHeading}>{d.labelText}</Typography>
									{d.calculations && (
										<div className={styles.calculations}>
											<Typography>{t("sidebar.detailView.calculations")}</Typography>
											<ul className={styles.calcList}>
												{d.calculations.lying_solid_meter && (
													<li className={styles.calcItem}>
														<Typography className={styles.domainDataLabel} display="inline">
														{t("sidebar.detailView.lyingSolidMeter")}
														</Typography>
														<Typography className={styles.domainDataValue} display="inline">
															{getCalcValue(
																DomainDataAvailable.data.find((e) => e.id === 'lying_solid_meter')?.calcString,
																'lying_solid_meter',
																d.id,
																domainData
															)}
														</Typography>
													</li>
												)}
												{d.calculations.standing_solid_meter && (
													<li className={styles.calcItem}>
														<Typography className={styles.domainDataLabel} display="inline">
														{t("sidebar.detailView.standingSolidMeter")}
														</Typography>
														<Typography className={styles.domainDataValue} display="inline">
															{getCalcValue(
																DomainDataAvailable.data.find((e) => e.id === 'standing_solid_meter')?.calcString,
																'standing_solid_meter',
																d.id,
																domainData
															)}
														</Typography>
													</li>
												)}
											</ul>
										</div>
									)}
									<div className={styles.gridContainer}>
										{d.sectionItems?.map((item, index) => (
											<div className={styles.gridItem} key={index}>
												<Typography className={styles.domainDataLabel}>{item.labelText}</Typography>
												<div>
													<Typography className={styles.domainDataValue} display="inline">
														{item.value?.value}{' '}
													</Typography>
													{item.additional && (
														<Typography className={styles.domainDataValue} display="inline">
															{item.additional.value?.value}
														</Typography>
													)}
												</div>
											</div>
										))}
									</div>
								</Fragment>
							</div>
						))}
			</div>
			<div>
				<Typography>{t("sidebar.detailView.individualData")}</Typography>
				<ul className={styles.individualData}>
					{note.domainData
						?.filter((d) => d.inputType !== 'Section')
						.map((d, index) => (
							<li className={styles.individualItem} key={index}>
								<Typography className={styles.domainDataLabel}>{d.labelText}</Typography>
								<Typography className={styles.domainDataValue}>{d.value?.value}</Typography>
							</li>
						))}
				</ul>
			</div>
		</div>
	);
}
