import LayerGroup, { Layer } from "../../../types/entities/layer-group";
import { useEffect, useState } from "react";
import styles from "../sidebar.module.css";
import {
	Box,
	Button,
	Fab,
	FormControl,
	IconButton,
	Input,
	InputLabel,
	MenuItem,
	Paper,
	Popover,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { Add, CancelRounded, Check } from "@mui/icons-material";
import { useAppDispatch } from "../../../store/hooks";
import { bindPopover, bindTrigger, usePopupState, } from "material-ui-popup-state/hooks";
import { getLayersFromCapabilities } from "../../../lib/geoserver";
import { addLayerGroup, updateLayerGroup, } from "../../../store/slices/layer-group-slice";
import { v4 } from "uuid";
import { addFirestoreElement, updateFirestoreElement, } from "../../../lib/firebase/firestore";
import Entities from "../../../types/entities";
import { useTranslation } from "react-i18next";
import useLocalStorage from "use-local-storage";
import { useAuth } from "../../../hooks/use-auth";
import useSidebarParams from "../use-sidebar-params";

/**
 * Renders a form for creating or editing a layer group.
 * @param {LayerGroup | undefined} layerGroup - The layer group to edit, if any.
 */
export default function LayerGroupForm({
	layerGroup,
}: {
	layerGroup?: LayerGroup;
}) {
	// State variables for form inputs and selections
	const [name, setName] = useState(layerGroup ? layerGroup.name : "");
	const [description, setDescription] = useState(
		layerGroup ? layerGroup.description : ""
	);
	const [availableGeoservers] = useLocalStorage<{ name: string, URL: string }[]>("availableGeoservers", []);
	const [selectedServerIndex, setSelectedServerIndex] = useState(0);
	const [layers, setLayers] = useState(layerGroup ? layerGroup.layers : []);
	const [availableLayers, setAvailableLayers] = useState<
		{
			fileName: string;
			styles: string[];
			workspace: string | undefined;
			bounds: [number, number, number, number];
		}[]
	>([]);
	const [providerSubmit, setProviderSubmit] = useState(false);
	// Hooks for authentication and dispatch
	const auth = useAuth();
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const { closeForm } = useSidebarParams();

	// Fetch available layers when the provider is selected or changed
	useEffect(() => {
		handleProviderSet();
	}, [availableGeoservers, selectedServerIndex]);

	// Function to handle form submission
	function handleSubmit() {
		// Check if authentication is available and all required fields are filled
		if (
			!auth || !auth.user ||
			name === "" ||
			description === "" ||
			layers.length === 0
		) {
			return;
		}
		// Update or add the layer group based on existence
		if (layerGroup) {
			const updatedGroup = {
				name,
				description,
				provider: availableGeoservers[selectedServerIndex].URL,
				layers,
				version: (layerGroup.version ?? 0) + 1,
				changedBy: auth.user.uid,
				updatedAt: Date.now(),
			};
			dispatch(
				updateLayerGroup({
					id: layerGroup.firestoreUid,
					changes: updatedGroup,
				})
			);
			updateFirestoreElement(auth.user.uid, Entities.LayerGroup, {
				...layerGroup,
				...updatedGroup,
			});
		} else {
			const newLayerGroup = {
				firestoreUid: v4(),
				sharePartnerUid: auth.user.uid,
				name,
				description,
				provider: availableGeoservers[selectedServerIndex].URL,
				layers,
				createdAt: Date.now(),
				updatedAt: Date.now(),
				version: 0,
				deletedAt: null,
				changedBy: auth.user.uid,
			};
			dispatch(addLayerGroup(newLayerGroup));
			addFirestoreElement(
				auth.user.uid,
				Entities.LayerGroup,
				newLayerGroup
			);
		}
		closeForm();
	}

	// Function to fetch available layers from the selected geoserver
	function handleProviderSet() {
		getLayersFromCapabilities(availableGeoservers[selectedServerIndex].URL)
			.then((layers) => {
				setAvailableLayers(layers);
				setProviderSubmit(true);
			})
			.catch(() => {
				setAvailableLayers([]);
				setProviderSubmit(false);
				setLayers([]);
			});
	}

	// Function to update the list of selected layers
	function handleLayersSet(layers: Layer[]) {
		setLayers([...layers]);
	}

	return (
		<>
			{/* Container for form action buttons */}
			<Box className={styles.listButtonContainer}>
				<IconButton onClick={handleSubmit}>
					<Check />
				</IconButton>
				<IconButton onClick={() => closeForm()}>
					<CancelRounded />
				</IconButton>
			</Box>
			{/* Container for form inputs */}
			<Box className={styles.formControlContainer}>
				<FormControl fullWidth>
					<InputLabel className={styles.inputLabel} htmlFor="name">
						{t("name")}
					</InputLabel>
					<Input
						required={true}
						className={styles.input}
						id="name"
						type="text"
						value={name}
						onChange={(event) => setName(event.target.value)}
					/>
				</FormControl>
				{/* Form input for layer group description */}
				<FormControl fullWidth>
					<InputLabel className={styles.inputLabel} htmlFor="description">
						{t("description")}
					</InputLabel>
					<Input
						required={true}
						className={styles.input}
						id="description"
						type="text"
						value={description}
						onChange={(event) => setDescription(event.target.value)}
					/>
				</FormControl>
				{/* Dropdown for selecting the provider (geoserver) */}
				<FormControl fullWidth>
					<InputLabel className={styles.inputLabel} htmlFor="provider">
						{t("provider")}
					</InputLabel>
					<Select value={selectedServerIndex} onChange={(event) => {
						setSelectedServerIndex(Number(event.target.value));
					}}>
						{availableGeoservers.map((server, index) => (
							<MenuItem value={index} key={index}>
								{server.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				{/* Table for managing selected layers */}
				<LayerTable
					availableLayers={availableLayers}
					locked={!providerSubmit}
					layers={layers}
					setLayers={handleLayersSet}
				/>
			</Box>
		</>
	);
}

/**
 * Renders a table for managing selected layers.
 * @param {Object} props - Component props.
 * @param {Object[]} props.availableLayers - Available layers to select from.
 * @param {boolean} props.locked - Flag indicating if the provider is submitted.
 * @param {Layer[]} props.layers - Selected layers.
 * @param {function} props.setLayers - Function to update selected layers.
 */
function LayerTable({
	availableLayers,
	locked,
	layers,
	setLayers,
}: {
	availableLayers:
		| {
				fileName: string;
				styles: string[];
				workspace?: string;
				bounds: [number, number, number, number];
		  }[]
		| undefined;
	locked: boolean;
	layers: Layer[];
	setLayers: (layers: Layer[]) => void;
}) {
	// Popup state for adding new layers
	const popupState = usePopupState({
		variant: "popover",
		popupId: "demoPopover",
	});
	const { t } = useTranslation();

	return (
		<TableContainer
			component={Paper}
			className={styles.layerTableContainer}
		>
			{locked && (
				<span>
					{t("sidebar.form.validProvider")}
				</span>
			)}
			<Table aria-label="token-table" className={styles.layerTable}>
				<TableHead>
					<TableRow>
						<TableCell>{t("name")}</TableCell>
						<TableCell>{t("sidebar.form.workspace")}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{layers.map((layer, index) => (
						<TableRow key={index}>
							<TableCell>{layer.name}</TableCell>
							<TableCell>{layer.workspace}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			<Fab
				className={styles.tableFab}
				{...bindTrigger(popupState)}
			>
				<Add />
			</Fab>
			<Popover
				{...bindPopover(popupState)}
				className={styles.layerPopoverContainer}
				anchorOrigin={{
					vertical: "center",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "center",
					horizontal: -40,
				}}
			>
				{availableLayers && (
					<FormatDialog
						availableLayers={availableLayers.filter(
							(a) => !layers.find((f) => a.fileName === f.fileName)
						)}
						onSubmit={(index, name) => {
							popupState.close();
							const { fileName, workspace, bounds, styles } =
								availableLayers[index];
							setLayers([
								...layers,
								{
									firestoreUid: v4(),
									styles: [...new Set(styles)],
									name,
									fileName,
									workspace,
									bounds,
								},
							]);
						}}
					/>
				)}
			</Popover>
		</TableContainer>
	);
}

/**
 * Renders a dialog for selecting and adding a new layer.
 * @param {Object} props - Component props.
 * @param {Object[]} props.availableLayers - Available layers to select from.
 * @param {function} props.onSubmit - Function to handle submission of the new layer.
 */
function FormatDialog({
	availableLayers,
	onSubmit,
}: {
	availableLayers: {
		fileName: string;
		workspace?: string;
		bounds: [number, number, number, number];
	}[];
	onSubmit: (index: number, type: string) => void;
}) {
	// State variables for dialog inputs and selections
	const [type, setType] = useState("");
	const [selectedLayer, setSelectedLayer] = useState(0);
	const { t } = useTranslation();

	return (
		<Box className={styles.layerPopover}>
			<Typography component="h3">
				{t("sidebar.form.newFormat")}
			</Typography>
			<FormControl fullWidth>
				<InputLabel className={styles.inputLabel} htmlFor="name">
					{t("name")}
				</InputLabel>
				<Input
					autoFocus
					id="name"
					type="text"
					fullWidth
					required={true}
					onChange={(event) => setType(event.target.value)}
					value={type}
				/>
			</FormControl>
			<FormControl fullWidth>
				<InputLabel
					className={styles.inputLabel}
					id="layer-select-label"
				>
					{t("sidebar.form.selectLayer")}
				</InputLabel>
				<Select
					id="layer-select"
					required={true}
					className={styles.layerSelect}
					value={selectedLayer}
					onChange={(event) => setSelectedLayer(Number(event.target.value))}
				>
					{availableLayers.map((layer, index) => (
						<MenuItem
							key={index}
							value={index}
						>
							{layer.fileName}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			<Button
				onClick={(event) => {
					event.preventDefault();
					onSubmit(selectedLayer, type);
				}}
				type="submit"
				variant="contained"
			>
				{t("submit")}
			</Button>
		</Box>
	);
}
