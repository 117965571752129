import { useAppSelector } from "../../../store/hooks";
import { useMemo } from "react";
import Entities from "../../../types/entities";
import { NoteForm } from "./note-form";
import Note from "../../../types/entities/note";
import styles from "../sidebar.module.css";
import { Box, Typography } from "@mui/material";
import AreaForm from "./area-form";
import Area from "../../../types/entities/area";
import PathForm from "./path-form";
import Path from "../../../types/entities/path";
import CategoryForm from "./category-form";
import Category from "../../../types/entities/category";
import LayerGroupForm from "./layer-group-form";
import LayerGroup from "../../../types/entities/layer-group";
import { useTranslation } from "react-i18next";
import TurningPointForm from "./turning-point-form";
import TurningPoint from "../../../types/entities/turning-point";
import GridForm from "./grid-form";
import Grid from "../../../types/entities/grid";
import useSidebarParams from "../use-sidebar-params";

/**
 * Renders a form component based on the selected tab in the sidebar.
 */
export default function SidebarForm() {
	// Get current tab and selected element from sidebar parameters
	const {tab, selectCurrentElement} = useSidebarParams();
	const element = useAppSelector((state) => selectCurrentElement(state));
	const { t } = useTranslation();

	// Select and memoize the appropriate form component based on the tab
	const component = useMemo(() => {
		switch (tab) {
			case Entities.Notes:
				return <NoteForm note={element as Note} />;
			case Entities.Categories:
				return <CategoryForm category={element as Category} />;
			case Entities.Areas:
				return <AreaForm area={element as Area} />;
			case Entities.Paths:
				return <PathForm path={element as Path} />;
			case Entities.LayerGroup:
				return <LayerGroupForm layerGroup={element as LayerGroup} />;
			case Entities.TurningPoints:
				return <TurningPointForm turningPoint={element as TurningPoint} />
			case Entities.Grids:
				return <GridForm grid={element as Grid} />
			default:
				return <></>;
		}
	}, [tab, element]);

	return (
		<Box className={styles.form}>
			<Typography component="h2">{t(`general.${tab}`)}</Typography>
			<Typography component="p">
				{element
					? t("sidebar.form.changeExisting")
					: t("sidebar.form.addNew")}
			</Typography>
			{component}
		</Box>
	);
}
