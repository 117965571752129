import {
	AnyAction,
	combineReducers,
	configureStore,
	createSlice,
	PayloadAction,
} from "@reduxjs/toolkit";
import sidebarReducer from "../features/sidebar/sidebarSlice";
import dataReducer from "./slices";
import mapReducer from "../features/map/map-slice";
import filterReducer from "../features/filter/filter-slice";
import dashboardReducer from "../features/dashboard/dashboard-slice";
import * as Sentry from "@sentry/react";

/**
 * This file defines the global store for this application.
 * We make use of 'redux' and 'redux-toolkit' to manage the state globally and organize actions into different reducers.
 * New state and features can be added by creating a new reducer for the state and following the other reducers patterns,
 * then adding them to the combineReducers-Invocation.
 */

/**
 * Slice to handle User Logout
 * @category Slice
 */
const userSlice = createSlice({
	name: "user",
	initialState: {},
	reducers: {
		logout: () => {},
	},
});

const combinedReducer = combineReducers({
	data: dataReducer,
	sidebar: sidebarReducer,
	map: mapReducer,
	filter: filterReducer,
	dashboard: dashboardReducer,
	user: userSlice.reducer,
});

export type RootState = ReturnType<typeof combinedReducer>;

// TODO: Remove this ignore
// @ts-ignore
const rootReducer: typeof combinedReducer = (
	state: RootState,
	action: PayloadAction<AnyAction>
) => {
	// Resets the Rootstate when the logout action is dispatched
	if (action.type === "user/logout") {
		state = {} as RootState;
	}
	return combinedReducer(state, action);
};

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const store = configureStore({
	reducer: rootReducer,
	enhancers: defaultEnhancers => [
		...defaultEnhancers,
		sentryReduxEnhancer
	]
});

export default store;

export const { logout } = userSlice.actions;

export type AppDispatch = typeof store.dispatch;
