import { Timestamp } from "firebase/firestore";

export type SimpleTimestamp = {
	_seconds: number;
	_nanoseconds: number;
};

/**
 * Parses different timestamp formats and returns a Firestore Timestamp.
 * Used for ensuring type safety.
 * @param timestamp - The timestamp to parse, can be of type Timestamp, SimpleTimestamp, or undefined.
 * @returns The parsed Timestamp.
 */
export function parsePotentialTimestamp(
	timestamp: Timestamp | SimpleTimestamp | undefined
) {
	if (timestamp) {
		if (timestamp instanceof Timestamp) {
			return timestamp;
		} else {
			return new Timestamp(timestamp._seconds, timestamp._nanoseconds);
		}
	}
	return Timestamp.now();
}

/**
 * Translates Firestore Timestamps to JavaScript Date objects.
 * @param data - The data object to translate.
 * @returns The data object with translated timestamps to Date objects.
 */
export function translateTimestampsToDate(data: any) {
	Object.keys(data).forEach((key) => {
		const value = data[key];
		if (
			value &&
			typeof value === "object" &&
			(value instanceof Timestamp ||
				("_seconds" in value && "_nanoseconds" in value))
		) {
			if ("toDate" in value && typeof value.toDate === "function") {
				try {
					data[key] = value.toDate().getTime();
				} catch (err) {
					console.log(value);
				}
			} else {
				data[key] = new Date(value._seconds * 1000).getTime();
			}
		}
	});
	return data;
}

const timestampFields = ["createdAt", "deletedAt", "updatedAt"];

/**
 * Translates JavaScript Date objects to Firestore Timestamps based on specified timestamp fields.
 * @param data - The data object to translate.
 * @returns The data object with translated Date objects to Timestamps.
 */
export function translateDatesToTimestamps(data: any) {
	if (!data) return data;
	const res = { ...data };
	timestampFields.forEach((t) => {
		if (t in res && res[t]) {
			res[t] = Timestamp.fromDate(new Date(data[t]));
		}
	});
	return res;
}
