import {createAsyncThunk, createEntityAdapter, createSlice,} from "@reduxjs/toolkit";
import Area, {isArea} from "../../types/entities/area";
import type {RootState} from "../store";
import {
	alphabetical,
	byNumOfAssignedNotes,
	bySize,
	chronologicalCreated,
	chronologicalUpdated,
} from "../sort-comparers";
import Entities from "../../types/entities";
import {entityAsyncThunk} from "./index";

/**
 * This file defines the slice for the Area Entity.
 * The general layout will be the same for all entity slices.
 *
 * Adapter: Manages list of all elements for this entity and supplies standard functions like addOne and updateOne
 * AsyncThunks: Asynchronously fetches data for this collection name from firestore and populates list initially
 * Selectors: Exports reusable selectors for this slice
 * Comparators: Exports the comparators that are applicable to this entity type
 *
 * @category Slice
 */


const areaAdapter = createEntityAdapter<Area>({
	selectId: (area) => area.firestoreUid,
	sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const fetchAreas = createAsyncThunk(
	"areas/fetchAreas",
	async (userId: string) => {
		const fn = entityAsyncThunk(Entities.Areas);
		return fn(userId);
	}
);

const initialState = areaAdapter.getInitialState({
	status: "idle",
});

const areaSlice = createSlice({
	name: "areas",
	initialState,
	reducers: {
		addArea: areaAdapter.addOne,
		updateArea: areaAdapter.updateOne,
		removeArea: areaAdapter.removeOne,
		addAreas: areaAdapter.addMany
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchAreas.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchAreas.fulfilled, (state, action) => {
				areaAdapter.setAll(state, (action.payload as Area[]).filter((e) => isArea(e)));
				state.status = "idle";
			});
	},
});

export const { addArea, updateArea, removeArea, addAreas } = areaSlice.actions;

export default areaSlice.reducer;

export const { selectAll: selectAreas, selectById: selectAreaById } =
	areaAdapter.getSelectors<RootState>((state) => state.data.areas);

const areaSortComparers = [
	{ name: "Alphabetically", function: alphabetical },
	{ name: "Creation Date", function: chronologicalCreated },
	{ name: "Last Update", function: chronologicalUpdated },
	{ name: "Assigned Notes", function: byNumOfAssignedNotes },
	{ name: "Size", function: bySize },
];

export { areaSortComparers };
