import { CircularProgress, Typography } from "@mui/material";
import styles from "./loading.module.css";
import { AnimatePresence, motion } from "framer-motion";

/**
 * LoadingScreen Component
 *
 * A component to display a loading screen with optional features like fullscreen,
 * opacity, and splash animation.
 *
 * @component
 * @param {Object} props - The properties of the LoadingScreen component.
 * @param {boolean} props.isVisible - Indicates whether the loading screen should be visible.
 * @param {boolean} props.isFullscreen - Specifies whether the loading screen should cover the entire screen.
 * @param {boolean} props.isOpaque - Specifies whether the loading screen should be opaque.
 * @param {boolean} [props.isSplash] - Indicates whether to display a splash animation. Defaults to `false`.
 * @param {string} [props.message] - An optional message to display alongside the loading animation.
 *
 * @returns {JSX.Element|null} - The rendered LoadingScreen component if `isVisible` is `true`, otherwise `null`.
 *
 * @example
 * // Usage of LoadingScreen component
 * <LoadingScreen
 *   isVisible={loading}
 *   isFullscreen={true}
 *   isOpaque={true}
 *   isSplash={true}
 *   message="Loading..."
 * />
 */
export default function LoadingScreen({
	isVisible,
	isFullscreen,
	isOpaque,
	isSplash,
	message,
}: {
	isVisible: boolean;
	isFullscreen: boolean;
	isOpaque: boolean;
	isSplash?: boolean;
	message?: string;
}): JSX.Element | null {

	//TODO: Non-splash is bad
	return (
		<AnimatePresence>
			{isVisible && (
				<motion.div
					className={`${styles.loading} ${isFullscreen ? styles.fullscreen : ""} ${isOpaque ? styles.opaque : ""}`}
					initial={{ opacity: 1 }}
					transition={{ duration: 1.0, times: [0.8, 1.0] }}
					exit={{ opacity: 0 }}
				>
					{!isSplash ? (
						<div className={styles.ldsRoller}>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					) : (
						<motion.div
							initial={{ y: 0 }}
							exit={{ y: ["-10%", "100%"] }}
							transition={{ duration: 0.8, times: [0.1, 0.8], bounce: 30 }}
							className={styles.splash}
						>
							<CircularProgress
								thickness={1.4}
								size={400}
								className={styles.spin}
							/>
							<img
								width={300}
								alt={"company-logo"}
								src={`/images/fm-logo.png`}
							/>
						</motion.div>
					)}
					{message && <Typography>{message}</Typography>}
				</motion.div>
			)}
		</AnimatePresence>
	);
}
