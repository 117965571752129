import styles from "../sidebar.module.css";
import { Box, FormControl, IconButton, Input, InputLabel } from "@mui/material";
import { CancelRounded, Check } from "@mui/icons-material";
import Map, { useMap } from "react-map-gl";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import useMapProps from "../../map/use-map-props";
import Area from "../../../types/entities/area";
import { removeTempArea, setTempArea } from "../../map/map-slice";
import { PolygonLayer } from "../../map/items/area-polygons";
import {
	calculateBoundingBox,
	calculateCenter,
	compareMapPositionArrays,
	getBoundsZoomLevel,
	lngLatArrayToMapPositionArray,
	lngLatToMapPosition,
} from "../../../lib/model/utils/geo";
import { v4 } from "uuid";
import { addArea, updateArea } from "../../../store/slices/area-slice";
import { SliderPicker } from "react-color";
import { hexToRgba } from "../../../lib/model/utils/colors";
import { mapPositionArrayFromLngLatArray, mapPositionArrayToLngLatArray, } from "../../../types/map-position";
import { addFirestoreElement, updateFirestoreElement, } from "../../../lib/firebase/firestore";
import Entities from "../../../types/entities";
import FormItemCover from "../../../components/sections/form-item-cover";
import { TbColorPicker } from "react-icons/tb";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../hooks/use-auth";
import useSidebarParams from "../use-sidebar-params";

/**
 * Renders a form for creating or updating an area on the map.
 * @param area - The area object to be updated if provided.
 */
export default function AreaForm({ area }: { area?: Area }) {
	// Local state for form inputs
	const tempArea = useAppSelector((state) => state.map.tempArea);
	const [name, setName] = useState(area ? area.name : "");
	const [description, setDescription] = useState(area ? area.description : "");
	const [color, setColor] = useState(
		area && area.color ? area.color : "#208C20"
	);
	// Map-related state and utilities
	const { mapPosition, style } = useMapProps();
	const { m1: map, m_form: formMap } = useMap();
	const canSubmit = useMemo(() => {
		if (area) {
			return (
				name !== area.name ||
				description !== area.description ||
				color !== area.color ||
				(tempArea &&
					!compareMapPositionArrays(
						area.points,
						lngLatArrayToMapPositionArray(tempArea.points[0])
					))
			);
		}
		return (
			name !== "" &&
			tempArea &&
			tempArea.points[0] &&
			tempArea.points[0].length > 3
		);
	}, [area, color, description, name, tempArea]);
	// Authentication and translation hooks
	const auth = useAuth();
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const { closeForm } = useSidebarParams();

	// Effects for initializing and cleaning up form data
	useEffect(() => {
		if (!tempArea && map) {
			const points = area ? [mapPositionArrayToLngLatArray(area.points)] : [];
			dispatch(
				setTempArea({
					points,
					color,
				})
			);
			area &&
				map.fitBounds(
					calculateBoundingBox(mapPositionArrayToLngLatArray(area.points)),
					{ padding: 200 }
				);
		}
	}, []);

	useEffect(() => {
		return () => {
			dispatch(removeTempArea());
		};
	}, []);

	useEffect(() => {
		if (tempArea && tempArea.points[0] && formMap) {
			const bbox = calculateBoundingBox(tempArea.points[0]);
			formMap.fitBounds(bbox, { padding: 20 });
			formMap.on("click", "temp_area", () =>
				map?.fitBounds(bbox, { padding: 200 })
			);
		}
	}, [tempArea, formMap]);

	useEffect(() => {
		tempArea &&
			tempArea.points[0] &&
			tempArea.points[0].length > 3 &&
			dispatch(setTempArea({ ...tempArea, color }));
	}, [color]);

	// Handles form submission
	function handleSubmit() {
		if (!canSubmit || !tempArea || !auth.user) {
			return;
		}
		if (area) {
			const updatedArea = {
				name,
				description,
				color,
				points: mapPositionArrayFromLngLatArray(tempArea.points[0]),
				updatedAt: Date.now(),
				version: area.version ? area.version + 1 : 0,
				changedBy: auth.user.uid,
			};
			dispatch(
				updateArea({
					id: area.firestoreUid,
					changes: updatedArea,
				})
			);
			updateFirestoreElement(auth.user.uid, Entities.Areas, {
				...area,
				...updatedArea,
			});
		} else {
			const newArea: Area = {
				firestoreUid: v4(),
				sharePartnerUid: auth.user.uid,
				deletedAt: null,
				name,
				description,
				color,
				points: tempArea.points[0].map((p) => ({
					longitude: p[0],
					latitude: p[1],
					altitude: 0,
				})),
				createdAt: Date.now(),
				updatedAt: Date.now(),
				changedBy: auth.user.uid,
				version: 0,
			};
			dispatch(addArea(newArea));
			addFirestoreElement(auth.user.uid, Entities.Areas, newArea);
		}
		closeForm();
	}

	return (
		<>
			<Box className={styles.listButtonContainer}>
				<IconButton
					disabled={!canSubmit}
					style={canSubmit ? { color: "var(--accent)" } : {}}
					onClick={handleSubmit}
				>
					<Check />
				</IconButton>
				<IconButton onClick={() => closeForm()}>
					<CancelRounded />
				</IconButton>
			</Box>
			<Box className={styles.formControlContainer}>
				<FormControl fullWidth>
					<InputLabel className={styles.inputLabel} htmlFor="name">
						{t("name")}
					</InputLabel>
					<Input
						required={true}
						className={styles.input}
						id="name"
						type="text"
						value={name}
						onChange={(event) => setName(event.target.value)}
					/>
				</FormControl>
				<FormControl fullWidth>
					<InputLabel className={styles.inputLabel} htmlFor="description">
						{t("description")}
					</InputLabel>
					<Input
						className={styles.input}
						id="description"
						type="text"
						value={description}
						onChange={(event) => setDescription(event.target.value)}
					/>
				</FormControl>
				<FormItemCover label={t("sidebar.form.customColor")} Icon={TbColorPicker}>
					<Box className={styles.colorSelection}>
						<label className={styles.label}>{t("color")}</label>
						<SliderPicker
							color={color}
							onChange={(c) => {
								setColor(c.hex);
							}}
							className={styles.colorSlider}
						/>
					</Box>
				</FormItemCover>
				<Box className={`${styles.mapPreview}`}>
					<label className={styles.label}>{t("preview")}</label>
					<Map
						mapboxAccessToken={import.meta.env.VITE_MAPBOX_ACCESS_TOKEN}
						interactive={false}
						id="m_form"
						mapStyle={style.url}
						initialViewState={
							area
								? {
										...lngLatToMapPosition(calculateCenter(area.points)),
										zoom: getBoundsZoomLevel(
											calculateBoundingBox(
												mapPositionArrayToLngLatArray(area.points)
											)
										),
								  }
								: mapPosition
						}
					>
						{tempArea && (
							<PolygonLayer
								id={"temp_area"}
								coordinates={tempArea.points}
								color={hexToRgba(color + "80")}
							/>
						)}
					</Map>
				</Box>
			</Box>
		</>
	);
}
