import {Suspense} from "react";
import {Navigate, Outlet, useLocation} from "react-router";
import LoadingScreen from "../components/loading/loading-screen";
import {ProvideFeedback} from "../features/feedback/use-feedback";

/**
 * Required for Routing Purposes
 * Shows LoadingScreen until content of site to render is ready
 * Outlet Element will be populated depending on requested resource
 * Each Page within the App should have a route and a page component
 * @constructor
 */
function Root() {
	return (
		<div className="App">
			<Suspense
				fallback={
					<LoadingScreen
						isSplash={true}
						isVisible={true}
						isFullscreen={true}
						isOpaque={true}
					/>
				}
			>
				<ProvideFeedback>
					<PathResolve />
				</ProvideFeedback>
			</Suspense>
		</div>
	);
}

function PathResolve() {
	const {pathname} = useLocation();

	if (pathname === "" || pathname === "/") {
		return <Navigate to={"/map"} />
	}

	return (
		<Outlet />
	)
}

export default Root;
