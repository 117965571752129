// eslint-disable-next-line import/no-extraneous-dependencies
import * as PropTypes from "prop-types";
import { ReactNode } from "react";
import { Box, Typography } from "@mui/material";
import styles from "./layout.module.css";

/**
 * TabPanel Component
 *
 * A component representing a tab panel within a tabbed interface.
 *
 * @component
 * @param {Object} props - The properties of the TabPanel component.
 * @param {ReactNode} props.children - The content to be displayed within the tab panel.
 * @param {number} props.value - The current value of the tab panel.
 * @param {number} props.index - The index of the tab panel.
 * @param {string} [props.classes] - Additional classes to be applied to the tab panel.
 * @param {any} [props.rest] - Additional properties to be spread onto the underlying Box component.
 *
 * @returns {ReactNode} - The rendered TabPanel component.
 *
 * @example
 * // Usage of TabPanel component
 * <TabPanel value={currentValue} index={0} classes="custom-class">
 *   Content for the first tab panel
 * </TabPanel>
 */
export const TabPanel = ({
	children,
	value,
	index,
	classes,
	...rest
}: {
	children: ReactNode;
	value: number;
	index: number;
	classes?: string;
	[x: string]: any;
}): ReactNode => {
	return (
		<Box
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			className={`${styles.tabpanel} ${classes ?? ""}`}
			{...rest}
		>
			{value === index && (
				<Box p={3}>
					<Typography component={"span"} variant={"body2"}>
						{children}
					</Typography>
				</Box>
			)}
		</Box>
	);
};

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};
