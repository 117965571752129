import {Box, FormControl, IconButton, Input, InputLabel, MenuItem, Select,} from "@mui/material";
import styles from "../sidebar.module.css";
import {CancelRounded, Check} from "@mui/icons-material";
import Map, {useMap} from "react-map-gl";
import {useEffect, useMemo, useState} from "react";
import Path, {PathType} from "../../../types/entities/path";
import useMapProps from "../../map/use-map-props";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {removeTempPath, setTempPath} from "../../map/map-slice";
import {
	calculateCenter,
	calculateLineStringBoundingBox,
	compareMapPositionArrays,
	getBoundsZoomLevel,
	lngLatArrayToMapPositionArray,
	lngLatToMapPosition,
} from "../../../lib/model/utils/geo";
import {DefaultColors, LineLayer} from "../../map/items/path-lines";
import {v4} from "uuid";
import {addPath, updatePath} from "../../../store/slices/path-slice";
import {mapPositionArrayFromLngLatArray, mapPositionArrayToLngLatArray,} from "../../../types/map-position";
import {addFirestoreElement, updateFirestoreElement,} from "../../../lib/firebase/firestore";
import Entities from "../../../types/entities";
import {useTranslation} from "react-i18next";
import {useAuth} from "../../../hooks/use-auth";
import useSidebarParams from "../use-sidebar-params";

const DefaultWidths = {
	[PathType.Road]: 3,
	[PathType.Alley]: 1,
	[PathType.Route]: 5,
};

/**
 * Component for rendering a form for creating or editing a path.
 * @param path The path object if editing an existing path, undefined if creating a new path.
 */
export default function PathForm({ path }: { path?: Path }) {
	const tempPath = useAppSelector((state) => state.map.tempPath);
	const [name, setName] = useState(path ? path.name ?? "" : "");
	const [description, setDescription] = useState(path ? path.description : "");
	const [type, setType] = useState<PathType>(path ? path.type : PathType.Road);
	const [width, setWidth] = useState(
		path && path.width ? path.width : DefaultWidths[type]
	);
	const { style, mapPosition } = useMapProps();
	const {closeForm} = useSidebarParams();
	const { m1: map, m_form: formMap } = useMap();
	const dispatch = useAppDispatch();
	const canSubmit = useMemo(() => {
		if (path) {
			return (
				name !== path.name ||
				description !== path.description ||
				type !== path.type ||
				width !== path.width ||
				(tempPath &&
					!compareMapPositionArrays(
						path.points,
						lngLatArrayToMapPositionArray(tempPath.points[0])
					))
			);
		}
		return (
			tempPath &&
			tempPath.points[0] &&
			tempPath.points[0].length > 1 &&
			(type === PathType.Alley || name !== "")
		);
	}, [path, tempPath, name, description, type, width]);
	const auth = useAuth();
	const { t } = useTranslation();

	/**
	 * Effect to initialize a temporary path when the Component is rendered.
	 */
	useEffect(() => {
		if (!tempPath && map) {
			const points = path ? [mapPositionArrayToLngLatArray(path.points)] : [];
			dispatch(
				setTempPath({
					points,
					width,
				})
			);
			path &&
				map.fitBounds(
					calculateLineStringBoundingBox(
						mapPositionArrayToLngLatArray(path.points)
					),
					{ padding: 200 }
				);
		}
	}, []);

	/**
	 * Deletes the path from state when the component is removed from the component tree
	 */
	useEffect(() => {
		return () => {
			dispatch(removeTempPath());
		};
	}, []);

	/**
	 * Pans both maps to the new bounding box whenever the temporary path is updated.
	 */
	useEffect(() => {
		if (tempPath && formMap) {
			const bbox = calculateLineStringBoundingBox(tempPath.points[0]);
			formMap.fitBounds(bbox, { padding: 20 });
			formMap.on("click", "temp_path", () =>
				map?.fitBounds(bbox, { padding: 200 })
			);
		}
	}, [tempPath]);

	/**
	 * Adds or Updates a Path depending on whether an Element was passed into the component
	 */
	function handleSubmit() {
		if (!canSubmit || !tempPath || !auth || !auth.user) return;
		if (path) {
			const changes = {
				version: path.version ? path.version + 1 : 1,
				name: type !== PathType.Alley ? name : null,
				type,
				width,
				description,
				points: mapPositionArrayFromLngLatArray(tempPath.points[0]),
				updatedAt: Date.now(),
			};
			dispatch(
				updatePath({
					id: path.firestoreUid,
					changes,
				})
			);
			updateFirestoreElement(auth.user.uid, Entities.Paths, {
				...path,
				...changes,
			});
		} else {
			const path: Path = {
				firestoreUid: v4(),
				version: 0,
				sharePartnerUid: auth.user.uid,
				name: type !== PathType.Alley ? name : null,
				type,
				width,
				description,
				createdAt: Date.now(),
				updatedAt: Date.now(),
				deletedAt: null,
				points: tempPath.points[0].map((p) => ({
					longitude: p[0],
					latitude: p[1],
					altitude: 0,
				})),
			};
			dispatch(addPath(path));
			addFirestoreElement(auth.user.uid, Entities.Paths, path);
		}
		closeForm();
	}

	/**
	 * Updates the paths type to a new preset. Changes color and width.
	 * @param type - The next path type enum value
	 */
	function handlePresetChange(type: PathType) {
		setType(type);
		setWidth(DefaultWidths[type]);
	}

	return (
		<>
			<Box className={styles.listButtonContainer}>
				<IconButton
					disabled={!canSubmit}
					className={canSubmit ? styles.buttonSubmittable : ""}
					onClick={handleSubmit}
				>
					<Check />
				</IconButton>
				<IconButton onClick={() => closeForm()}>
					<CancelRounded />
				</IconButton>
			</Box>
			<Box className={styles.formControlContainer}>
				<FormControl fullWidth>
					<InputLabel className={styles.input} htmlFor="type">
						{t("sidebar.form.pathTypeSelection")}
					</InputLabel>
					<Select
						MenuProps={{
							MenuListProps: { className: styles.selectMenu },
						}}
						id="type"
						value={type}
						onChange={(event) => {
							handlePresetChange(Number(event.target.value) as PathType);
						}}
					>
						{Object.values(PathType)
							.slice(Object.values(PathType).length / 2)
							.map((value, index) => (
								<MenuItem key={index} value={value}>
									{t(`path.${value}`)}
								</MenuItem>
							))}
					</Select>
				</FormControl>
				{type !== PathType.Alley && (
					<FormControl fullWidth>
						<InputLabel className={styles.inputLabel} htmlFor="name">
							{t("name")}
						</InputLabel>
						<Input
							required={true}
							className={styles.input}
							id="name"
							type="text"
							value={name}
							onChange={(event) => setName(event.target.value)}
						/>
					</FormControl>
				)}
				<FormControl fullWidth>
					<InputLabel className={styles.inputLabel} htmlFor="description">
						{t("description")}
					</InputLabel>
					<Input
						required={true}
						className={styles.input}
						id="description"
						type="text"
						value={description}
						onChange={(event) => setDescription(event.target.value)}
					/>
				</FormControl>
				<FormControl fullWidth>
					<InputLabel className={styles.inputLabel} htmlFor="width">
						{t("sidebar.form.pathWidth")}
					</InputLabel>
					<Box className={styles.pathWidthInput}>
						<Input
							required={true}
							id="width"
							type="number"
							value={width}
							onChange={(event) =>
								Number(event.target.value) > 0 &&
								setWidth(Number(event.target.value))
							}
						/>
						<div>{t("measurements.meters")}</div>
					</Box>
				</FormControl>
				<Box className={styles.mapPreview}>
					<label>{t("preview")}</label>
					<Map
						mapboxAccessToken={import.meta.env.VITE_MAPBOX_ACCESS_TOKEN}
						interactive={false}
						id="m_form"
						mapStyle={style.url}
						initialViewState={
							path
								? {
										...lngLatToMapPosition(calculateCenter(path.points)),
										zoom: getBoundsZoomLevel(
											calculateLineStringBoundingBox(
												mapPositionArrayToLngLatArray(path.points)
											)
										),
								  }
								: mapPosition
						}
					>
						{tempPath && (
							<LineLayer
								color={DefaultColors[type]}
								id={"temp_path"}
								coordinates={tempPath.points[0]}
								width={width - 2}
							/>
						)}
					</Map>
				</Box>
			</Box>
		</>
	);
}
