import { Box, Button, Typography } from "@mui/material";
import TokenTable from "./token-table";
import { ShowcaseToken } from "../../../types/showcase-token";
import styles from "./share.module.css";
import { useTranslation } from "react-i18next";
import { Add, Delete, Sort } from "@mui/icons-material";
import { useEffect, useState } from "react";
import ShareCreationDialog, {SelectedShare} from "./share-creation-dialog";
import {generateToken} from "../../../lib/firebase/functions";

/**
 * Represents the tab for managing showcase tokens.
 * @param tokens - The list of showcase tokens.
 */
export default function ShowcaseTab({ tokens }: { tokens: ShowcaseToken[] }) {
	/** State to manage the dialog's open state */
	const [open, setOpen] = useState(false);
	/** State to manage the loading state */
	const [loading, setLoading] = useState(false);
	/** State to manage the showcase token being edited */
	const [tokenToEdit, setTokenToEdit] = useState<ShowcaseToken | null>(null);
	const { t } = useTranslation();

	/**
	 * Effect hook to handle opening the dialog when a token is being edited.
	 */
	useEffect(() => {
		if (tokenToEdit) {
			setOpen(true);
		}
	}, [tokenToEdit]);

	/**
	 * Effect hook to reset the tokenToEdit state when the dialog is closed.
	 */
	useEffect(() => {
		if (!open) {
			setTokenToEdit(null);
		}
	}, [open]);

	/**
	 * Handles the form submission for creating or editing showcase tokens.
	 * @param data - The data containing the selected share details.
	 */
	async function handleSubmit(data: SelectedShare) {
		setLoading(true);
		const result = await generateToken(data);
		setLoading(false);
		setOpen(false);
	}

	return (
		<>
			<Box className={styles.tab}>
				<Box className={styles.tabHeader}>
					<Box>
						<Typography component="h3">{t("shareOptions.showcase")}</Typography>
						<Typography component="p">{t("shareOptions.showcaseInfo")}</Typography>
					</Box>
					<Box className={styles.listButtonContainer}>
						<Button
							disabled={import.meta.env.VITE_DEMO}
							onClick={() => {
								if (import.meta.env.VITE_DEMO) return;
								setOpen(true);
							}}
						>
							<Add />
							<Typography>{t("add")}</Typography>
						</Button>
						<Button
							onClick={() => {
							}}
							disabled={true}
						>
							<Sort />
							<Typography>{t("sort")}</Typography>
						</Button>
						<Button
							onClick={() => {
							}}
							disabled={true}
						>
							<Delete />
							<Typography>{t("deactivateAll")}</Typography>
						</Button>
					</Box>
				</Box>
				<TokenTable tokens={tokens} tokenToEdit={tokenToEdit} setTokenToEdit={setTokenToEdit} />
				{open && <ShareCreationDialog open={open} setOpen={setOpen} onSubmit={handleSubmit} loading={loading} />}
			</Box>
		</>
	);
}
