import { useNavigate, useRouteError } from "react-router";
import { Box } from "@mui/material";
import styles from "./error.module.css";
import { auth } from "../../lib/firebase";
import { useTranslation } from "react-i18next";

/**
 * Should be displayed when an Error occurs during routing.
 * Currently conflicts with page-error.tsx.
 * TODO: Unify this and page-error.tsx using Error Handling from this and Layout from other component
 * @constructor
 * @category Page
 */
export default function ErrorPage() {
	const navigate = useNavigate();
	const error = useRouteError() as {
		status?: number;
		statusText?: string;
		message?: string;
	};
	const { t } = useTranslation();

	return (
		<Box className={styles.error}>
			<h1>{error.status}</h1>
			<p>{error.statusText || error.message}</p>
			<button
				onClick={() => navigate("/" + auth.currentUser ? "map" : "login")}
			>
				{t("navigation.navigate", {
					route: auth.currentUser ? "map" : "login",
				})}
			</button>
		</Box>
	);
}
