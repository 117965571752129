import { Box } from "@mui/material";
import ShowcaseTab from "./showcase-tab";
import { useAppSelector } from "../../../store/hooks";
import styles from "./share.module.css";

/**
 * Component for sharing tokens and showcases.
 * Displays a showcase tab with tokens.
 */
export default function Share() {
	/**
	 * Selects tokens from the dashboard state.
	 */
	const { tokens } = useAppSelector((state) => state.dashboard);

	return (
		<Box className={styles.share} id={styles.scrollable}>
			<ShowcaseTab tokens={tokens} />
		</Box>
	);
}
