import {createAsyncThunk, createEntityAdapter, createSlice,} from "@reduxjs/toolkit";
import {RootState} from "../store";
import Media from "../../types/entities/media";
import {entityAsyncThunk} from "./index";
import Entities from "../../types/entities";

/**
 * This file defines the slice for the Media Entity.
 * The general layout will be the same for all entity slices.
 *
 * Adapter: Manages list of all elements for this entity and supplies standard functions like addOne and updateOne
 * AsyncThunks: Asynchronously fetches data for this collection name from firestore and populates list initially
 * Selectors: Exports reusable selectors for this slice
 * Comparators: Exports the comparators that are applicable to this entity type
 *
 * @category Slice
 */

const mediaAdapter = createEntityAdapter<Media>({
	selectId: (media) => media.firestoreUid,
	sortComparer: (a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0),
});

export const fetchMedia = createAsyncThunk(
	"media/fetchMedia",
	async (userId: string) => entityAsyncThunk(Entities.Media)(userId)
);

const initialState = mediaAdapter.getInitialState({
	status: "idle",
});

const mediaSlice = createSlice({
	name: "media",
	initialState,
	reducers: {
		addMedia: mediaAdapter.addOne,
		updateMedia: mediaAdapter.updateOne,
		removeMedia: mediaAdapter.removeOne,
		addMedias: mediaAdapter.addMany
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchMedia.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchMedia.fulfilled, (state, action) => {
				mediaAdapter.setAll(state, action.payload as Media[]);
				state.status = "idle";
			});
	},
});

export const { addMedia, updateMedia, removeMedia, addMedias } = mediaSlice.actions;

export default mediaSlice.reducer;

export const { selectAll: selectMedia, selectById: selectMediaById } =
	mediaAdapter.getSelectors<RootState>((state) => state.data.media);

export const selectMediaByNoteId = (state: RootState, noteUid: string) => {
	return mediaAdapter
		.getSelectors<RootState>((state) => state.data.media)
		.selectAll(state)
		.filter((m) => m.noteUid === noteUid);
};
