import reportWebVitals from "../../reportWebVitals";
import * as Sentry from "@sentry/react";
import {useEffect} from "react";
import {matchRoutes, useLocation} from "react-router";
import {createRoutesFromChildren, useNavigationType} from "react-router-dom";

/**
 * Configure Sentry to report to server @sentry.rbitech.de
 * Plugin won't be initialized if VITE_DEV is set to true
 */
export default function initSentry() {
    if (!import.meta.env.VITE_DEV) {
        reportWebVitals();
        Sentry.init({
            dsn: "https://23502a4dac2d445db48e864ae1e00309@sentry.rbitech.de/3",
            normalizeDepth: 10,
            integrations: [
                new Sentry.BrowserTracing({
                    routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                        useEffect,
                        useLocation,
                        useNavigationType,
                        createRoutesFromChildren,
                        matchRoutes
                    ),
                }),
                new Sentry.Replay()
            ],
            tracesSampleRate: 1.0,
            tracePropagationTargets: ["localhost", /^https:\/\/sentry.rbitech.de\/api/],
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        });
    }
}