import Entities, {Entity} from "../../../types/entities";
import {Feature, GeoJSON, Geometry, LineString, Polygon, Position, Point} from "geojson";
import MetaData from "../../../types/entities/meta-data";
import Note from "../../../types/entities/note";
import Area from "../../../types/entities/area";
import {closePolygonRing} from "./geo";
import {bboxPolygon} from "@turf/turf";
import Path, {PathType} from "../../../types/entities/path";

/**
 * Transforms GeoJSON data into an array of entities.
 * @param geojson - The GeoJSON data to transform.
 * @param getMetaData - A function to retrieve metadata for the entities.
 * @param entityType - Optional. The type of entity to transform to.
 * @returns An array of entities or null if the GeoJSON type is not supported.
 */
export default function transformGeoJSON(geojson: GeoJSON, getMetaData: () => MetaData, entityType?: Entities): Entity[] | null {
    if (!geojson.type) return null;
    if (geojson.type === "LineString" || geojson.type === "Polygon" || geojson.type === "Feature" || geojson.type === "Point") {
        return [extracted(geojson, entityType, getMetaData) as Entity];
    } else if (geojson.type === "MultiPolygon" || geojson.type === "MultiLineString" || geojson.type === "GeometryCollection" || geojson.type === "MultiPoint") {
        // Not yet supported
        return null;
    }
    return geojson.features.map((f) => extracted(f, entityType, getMetaData) as Entity);
}

/**
 * Extracts entity data from a GeoJSON feature.
 * @param geojson - The GeoJSON feature to extract data from.
 * @param entityType - Optional. The type of entity to extract.
 * @param getMetaData - A function to retrieve metadata for the entities.
 * @returns An entity object.
 */
function extracted(geojson: LineString | Polygon | Feature | Point, entityType: Entities | undefined, getMetaData: () => MetaData) {
    // TODO choose category from import file description?
    const defaultCategoryId = "9kJsFKj-Ra@9FboBkePlxblH";
    // Default property data
    const propertyData = {name: "importiert", description: "", color: "#3faeba"};
    // Override default property data if present in GeoJSON properties
    if (geojson.type === "Feature" && geojson.properties) {
        if ("name" in geojson.properties) 
            propertyData.name = geojson.properties.name.length > 0 ? geojson.properties.name : propertyData.name;
        if ("description" in geojson.properties) propertyData.description = geojson.properties.description;
        if ("color" in geojson.properties) propertyData.color = geojson.properties.color;
    }
    // Determine the return type based on the provided entity type or the geometry of the feature
    const geometry = (geojson.type === "Feature") ? geojson.geometry : geojson;
    const returnType = entityType ?? getEntityTypeByGeometry(geometry);
    // Extract entity data based on the return type
    switch (returnType) {
        case Entities.Notes:
            // Extract note data
            const lat = geometry.type === "Point" ? geometry.coordinates[1] : -1;
            const lng = geometry.type === "Point" ? geometry.coordinates[0] : -1;
            const note: Note = {
                ...getMetaData(),
                ...propertyData,
                accuracy: 1,
                categoryUid: defaultCategoryId,
                lat: lat,
                lng: lng,
                center: {longitude: lng, latitude: lat, altitude: 0}
            }
            return note;
        case Entities.Areas:
            // Extract area data
            const coordinates = geometry.type === "LineString" ? [geometry.coordinates] : "coordinates" in geometry ? geometry.coordinates as Position[][] : bboxPolygon(geometry.bbox!).geometry.coordinates;
            const points = [closePolygonRing(coordinates[0])];
            if (!points) break;
            const area: Area = {
                ...getMetaData(),
                ...propertyData,
                points: points[0].map((p) => ({longitude: p[0], latitude: p[1], altitude: 0}))
            }
            return area;
        case Entities.Paths:
            // Extract path data
            const ppoints = "coordinates" in geometry ? geometry.coordinates as number[][] : null;
            if (!ppoints) break;
            const path: Path = {
                ...getMetaData(),
                ...propertyData,
                type: PathType.Road,
                width: 4,
                points: ppoints.map((p) => ({longitude: p[0], latitude: p[1], altitude: 0}))
            }
            return path;
    }
}

/**
 * Determines the entity type based on the geometry type.
 * @param geometry - The geometry object.
 * @returns The entity type.
 */
function getEntityTypeByGeometry(geometry: Geometry) {
    switch (geometry.type) {
        case "Polygon":
        case "MultiPolygon":
            return Entities.Areas;
        case "LineString":
            const coordsNum = geometry.coordinates.length;
            if(checkCoordinatesEqual(geometry.coordinates[0], geometry.coordinates[coordsNum - 1]))
                return Entities.Areas;
            return Entities.Paths;
        case "MultiLineString":
            return Entities.Paths;
        case "Point":
        case "MultiPoint":
            return Entities.Notes;
        default: 
            return Entities.Paths;
    }
}

/**
 * Checks if two given 2D coordinates are equal.
 * @param coord1 - The first 2D coordinate.
 * @param coord2 - The second 2D coordinate.
 * @returns true, if coord1 and coord2 are equal.
 */
function checkCoordinatesEqual(coord1: Position, coord2: Position) {
    return coord1[0] === coord2[0] && coord1[1] === coord2[1];
}