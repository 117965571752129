import SharePartner from "../../../types/share-partner";
import {RootState} from "../../../store/store";
import Entities, {Entity} from "../../../types/entities";
import {selectElementForEntityId} from "../../../store/slices";

/**
 * Retrieves elements associated with a specific SharePartner from the application state.
 * @param sharePartner - SharePartner object for which elements are being retrieved.
 * @param state - RootState object representing the current application state.
 * @returns An object containing arrays of elements for each entity type associated with the SharePartner.
 */
export function getElementsForSharepartner(sharePartner: SharePartner, state: RootState) {
     const list: { [e in Entities]: Entity[] } = {
          [Entities.Areas]: [],
          [Entities.Categories]: [],
          [Entities.ImageLayer]: [],
          [Entities.LayerGroup]: [],
          [Entities.Notes]: [],
          [Entities.Paths]: [],
          [Entities.TurningPoints]: [],
          [Entities.Grids]: [],
          [Entities.Media]: []
     };
     (Object.keys(sharePartner.sharedElementList) as Entities[]).forEach((en) => {
          list[en] = sharePartner.sharedElementList[en].map((id) => selectElementForEntityId(state, id, en)).filter((el) => el) as Entity[]
     });
     return list;
}
