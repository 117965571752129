import { Box } from "@mui/material";
import { useMap } from "react-map-gl";
import { MdGpsFixed, MdGpsNotFixed } from "react-icons/md";
import styles from "../map.module.css";

/**
 * Component rendering GPS control for focusing on user's current location on the map.
 * @returns {JSX.Element} GpsControl component JSX element.
 */
export default function GpsControl() {
	const { m1: map } = useMap();

	/**
	 * Handles the click event to focus on the user's current location.
	 */
	function handleFocus() {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((position) => {
				map?.flyTo({
					center: [position.coords.longitude, position.coords.latitude],
				});
			});
		}
	}

	return (
		<Box className={styles.gps} onClick={handleFocus}>
			{navigator.geolocation ? <MdGpsFixed /> : <MdGpsNotFixed />}
		</Box>
	);
}
