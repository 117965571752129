import {motion} from "framer-motion";
import {Close, DirectionsWalk, Grid3x3, Public, Room, SwapVert,} from "@mui/icons-material";
import {IconButton, Typography} from "@mui/material";
import {TbDrone, TbPolygon, TbTags} from "react-icons/tb";
import {useAppSelector} from "../../store/hooks";
import {drawerWidth} from "./index";
import styles from "./sidebar.module.css";
import Entities from "../../types/entities";
import {useTranslation} from "react-i18next";
import useLocalStorage from "use-local-storage";
import useSidebarParams from "./use-sidebar-params";
import { selectGrids } from "../../store/slices/grid-slice";
import { selectAreaById } from "../../store/slices/area-slice";
import store from "../../store/store";
import Grid from "../../types/entities/grid";
import { updateElementForEntityType } from "../../store/slices";
import { updateFirestoreElement } from "../../lib/firebase/firestore";
import { useAuth } from "../../hooks/use-auth";
import { User } from "firebase/auth";

// Array of tabs with their corresponding names and icons
const tabs = [
	{ name: Entities.Notes, icon: Room },
	{ name: Entities.Categories, icon: TbTags },
	{ name: Entities.Areas, icon: TbPolygon },
	{ name: Entities.Paths, icon: DirectionsWalk },
	{ name: Entities.TurningPoints, icon: SwapVert },
	{ name: Entities.ImageLayer, icon: TbDrone },
	{ name: Entities.LayerGroup, icon: Public },
	{ name: Entities.Grids, icon: Grid3x3 }
];

/**
 * Component for displaying an overview of tabs in the sidebar.
 */
export default function TabOverview() {
	// Extracting values from the sidebar parameters hook
	const { sidebarOpen: isOpen, tab, elementId, formOpen, setDetail, closeSidebar, closeForm, setTab } = useSidebarParams();
	const { filterBarVisible } = useAppSelector((state) => state.filter);
	const { t: translate } = useTranslation();
	const [showGeoData] = useLocalStorage("showGeoData", false);
	const grids = useAppSelector(state => selectGrids(state));
	const user = useAuth().user as User | null;

	return (
		<motion.div
			initial={{ left: 10 }}
			animate={{
				left: isOpen
					? filterBarVisible
						? drawerWidth * 2 + 10
						: drawerWidth + 10
					: 10,
			}}
			transition={{ duration: 0.2, ease: "easeInOut", repeat: 0 }}
			style={{ top: isOpen ? 5 : 58 }}
			className={styles.tabOverview}
		>
			{isOpen && (
				<div className={styles.tabItem} id={styles.closeButton}>
					<IconButton onClick={() => {
						setDetail(null);
						closeForm();
						closeSidebar();
					}}>
						<Close fontSize="inherit" />
					</IconButton>
				</div>
			)}
			{tabs.filter((t) => t.name !== Entities.LayerGroup || showGeoData).map((t, index) => (
				<div
					key={index}
					className={styles.tabItem}
					style={
						!formOpen && elementId === null && isOpen && tab === t.name
							? { color: "rgba(90,183,81,0.9)" }
							: {}
					}
					onClick={() => {
						if(t.name === Entities.Grids) {
							deleteInvalidGrids(user, grids);
						}
						setTab(t.name);
					}}
				>
					<t.icon />
					<Typography component="p">
						{translate(`general.${t.name}`)}
					</Typography>
				</div>
			))}
		</motion.div>
	);
}

/**
 * Checks if every grid has a valid area assigned to it. If not, the grid is deleted.
 * @param user - The currently authenticated user, or null.
 * @param grids - The grids to check for valid assigned areas.
 */
const deleteInvalidGrids = (user: User | null, grids: Grid[]) => {
	for(let i = 0; i < grids.length; i++) {
		if(grids[i].areaUid === undefined) {
			updateElementForEntityType({...grids[i], deletedAt: new Date().getTime()}, Entities.Grids);
			updateFirestoreElement(user!.uid, Entities.Grids, {...grids[i], deletedAt: new Date().getTime()});
			continue;
		}
		const area = selectAreaById(store.getState(), grids[i].areaUid!);
		if(!area) {
			updateElementForEntityType({...grids[i], deletedAt: new Date().getTime()}, Entities.Grids);
			updateFirestoreElement(user!.uid, Entities.Grids, {...grids[i], deletedAt: new Date().getTime()});
		}
	}
}
