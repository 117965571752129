import {AreaSchema} from "../../types/entities/area";
import ImageCard from "./image-card";
import getStaticMapPreview, {PreviewType,} from "../../lib/model/utils/static-maps";
import {Box, Checkbox, IconButton, Popover, Typography} from "@mui/material";
import styles from "./cards.module.css";
import {Image, MoreVert, Numbers, Room} from "@mui/icons-material";
import {selectNotesByAreaId, selectNotesByCategoryId,} from "../../store/slices/note-slice";
import store from "../../store/store";
import {CategorySchema} from "../../types/entities/category";
import CategoryCard from "./category-card";
import {ImageLayerSchema} from "../../types/entities/image-layer";
import {TbChartArea, TbChartLine, TbPolygon} from "react-icons/tb";
import {calculateLength, calculateSurfaceArea,} from "../../lib/model/utils/geo";
import {mapPositionArrayToLngLatArray} from "../../types/map-position";
import {LayerGroupSchema} from "../../types/entities/layer-group";
import {NoteSchema} from "../../types/entities/note";
import {selectCategoryById} from "../../store/slices/category-slice";
import {useMemo} from "react";
import {selectAreaById} from "../../store/slices/area-slice";
import NoteCard from "./note-card";
import {PathSchema} from "../../types/entities/path";
import {TurningPointSchema} from "../../types/entities/turning-point";
import Entities, {Entity, resolveEntityTypeForItem,} from "../../types/entities";
import {useTranslation} from "react-i18next";
import {Action} from "../../hooks/actions";
import {bindPopover, bindTrigger, usePopupState,} from "material-ui-popup-state/hooks";
import {GridSchema} from "../../types/entities/grid";
import useSidebarParams from "../../features/sidebar/use-sidebar-params";

/**
 * card for entities
 * @param item
 * @param onClick
 * @param actions
 * @param checked
 * @param onCheck
 * @constructor
 */
export default function EntityCard({
	item,
	onClick,
	actions,
	checked,
	onCheck,
}: {
	item: Entity;
	onClick?: (id: string, type: Entities) => void;
	actions?: Action[];
	checked?: boolean;
	onCheck?: (checked: boolean) => void;
}) {
	const { t } = useTranslation();
	const { setTab, setDetail } = useSidebarParams();
	const popupState = usePopupState({
		variant: "popover",
		popupId: "quickActions",
	});
	const children = useMemo(() => {
		return onCheck ? (
			<Checkbox checked={checked} onChange={(event, c) => onCheck(c)} />
		) : (
			actions && (
				<Box onClick={(event) => event.stopPropagation()}>
					<IconButton
						className={styles.moreButton}
						{...bindTrigger(popupState)}
					>
						<MoreVert />
					</IconButton>
					<Popover
						{...bindPopover(popupState)}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "center",
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "center",
						}}
						slotProps={{
							paper: {
							  className: styles.actionPopover
							}
						  }}
					>
						{actions
							.filter((a) => !a.disabled && !a.requiresConfirm)
							.map((action, index) => {
								const Icon = action.symbol;
								return (
									<Box
										key={index}
										onClick={() => {
											popupState.close();
											action.callback();
										}}
									>
										<Icon />
										<Typography>
											{t(`sidebar.actions.${action.name}`)}
										</Typography>
									</Box>
								);
							})}
					</Popover>
				</Box>
			)
		);
	}, [actions, checked, onCheck, popupState]);
	const type = useMemo(() => {
		return resolveEntityTypeForItem(item);
	}, [item]);

	function handleClick(id: string) {
		onClick && type && onClick(id, type);
	}

	switch (type) {
		case Entities.Areas: {
			const el = AreaSchema.parse(item);
			return (
				<ImageCard
					onClick={handleClick}
					id={el.firestoreUid}
					key={el.firestoreUid}
					name={el.name}
					type={Entities.Areas}
					color={el.color}
					metaText={
						<Typography className={styles.metaText}>
							<Room />{" "}
							{t("cards.assignedNotes", {
								num: selectNotesByAreaId(store.getState(), el.firestoreUid)
									.length,
							})}
						</Typography>
					}
				>
					{children}
				</ImageCard>
			);
		}
		case Entities.Categories: {
			const el = CategorySchema.parse(item);
			return (
				<CategoryCard
					onClick={handleClick}
					category={el}
					numOfNotes={
						selectNotesByCategoryId(store.getState(), el.firestoreUid).length
					}
				>
					{children}
				</CategoryCard>
			);
		}
		case Entities.ImageLayer: {
			const el = ImageLayerSchema.parse(item);
			return (
				<ImageCard
					onClick={handleClick}
					id={el.firestoreUid}
					imageSrc={el["firestore-uri"]}
					name={el["file-name"].split(".")[0]}
					type={Entities.ImageLayer}
					metaText={
						<Typography className={styles.metaText}>
							<TbChartArea />{" "}
							{calculateSurfaceArea(
								mapPositionArrayToLngLatArray(el.bounds)
							).toFixed(2)}{" "}
							{t("measurements.squareMeters")}
						</Typography>
					}
				>
					{children}
				</ImageCard>
			);
		}
		case Entities.Notes: {
			const el = NoteSchema.parse(item);
			const category = selectCategoryById(store.getState(), el.categoryUid);
			const area = selectAreaById(store.getState(), el.areaUid ?? "");
			return (
				<NoteCard
					onClick={handleClick}
					key={el.firestoreUid}
					note={el}
					category={category}
					area={area}
				>
					{children}
				</NoteCard>
			);
		}
		case Entities.Paths: {
			const el = PathSchema.parse(item);
			return (
				<ImageCard
					onClick={handleClick}
					id={el.firestoreUid}
					key={el.firestoreUid}
					name={el.name ?? t(`path.${el.type}`)}
					type={Entities.Paths}
					metaText={
						<Typography className={styles.metaText}>
							<TbChartLine /> {calculateLength(el)} {t("measurements.meters")}
						</Typography>
					}
				>
					{children}
				</ImageCard>
			);
		}
		case Entities.TurningPoints: {
			const el = TurningPointSchema.parse(item);
			return (
				<ImageCard
					onClick={handleClick}
					id={el.firestoreUid}
					key={el.firestoreUid}
					name={
						el.name || (el.number !== null && el.number !== undefined)
							? el.number !== null ? el.number.toString() : t("model.turningPoint").toString()
							: t("model.turningPoint").toString()
					}
					type={Entities.TurningPoints}
					metaText={
						<Typography className={styles.metaText}>
							<Numbers /> {el.number}
						</Typography>
					}
				>
					{children}
				</ImageCard>
			);
		}
		case Entities.LayerGroup: {
			const el = LayerGroupSchema.parse(item);
			const layerString =
				(el.layers[0].workspace ? el.layers[0].workspace + ":" : "") +
				el.layers[0].fileName;
			const bbox = el.layers[0].bounds.join(",");
			//TODO: Should be a constant somewhere
			const url = `${el.provider}/${
				el.layers[0].workspace ?? ""
			}/wms?service=WMS&version=1.1.1&request=GetMap&format=image/png&transparent=true&srs=EPSG:4326&bbox=${bbox}&width=512&height=512&layers=${layerString}`;
			return (
				<ImageCard
					onClick={handleClick}
					id={el.firestoreUid}
					imageSrc={url}
					name={el.name}
					type={Entities.LayerGroup}
					metaText={
						<Typography className={styles.metaText}>
							<Image /> {t("cards.assignedLayers", { num: el.layers.length })}
						</Typography>
					}
				>
					{children}
				</ImageCard>
			);
		}
		case Entities.Grids: {
			const grid = GridSchema.parse(item);
			return (
				<ImageCard onClick={handleClick} id={grid.firestoreUid}
						   key={grid.firestoreUid}
						   name={grid.name}
						   type={Entities.Grids}
						   color={grid.color}
						   metaText={<Typography 
								onClick={(event) => {
									event.stopPropagation();
									const area = selectAreaById(store.getState(), grid.areaUid!);
									setTab(Entities.Areas);
									setDetail(area?.firestoreUid!);
								}}
								className={styles.cardLink}
								component="p"
							>
								<TbPolygon /> {grid.areaUid ? selectAreaById(store.getState(), grid.areaUid)!.name : t("cards.notAssignedToArea")}
							</Typography> }
				>
					{children}
				</ImageCard>
			)
		}
	}
	return <></>;
}
