import CategoryIcon from "./index";
import styles from "./icons.module.css";

/**
 * returns an icon avatar depending on given symbol
 * from assets
 * @param color - String of symbol color
 * @param symbol - string | undefined name of symbol
 * @param width - number | undefined
 * @param height - number | undefined
 * @constructor
 */
export default function IconAvatar({
	color,
	symbol,
	width,
	height,
}: {
	color: string;
	symbol?: string;
	width?: number;
	height?: number;
}) {
	return (
		<div className={styles.avatar} style={{ borderColor: color }}>
			<CategoryIcon width={width} height={height} iconName={symbol} />
		</div>
	);
}
