import MapComponent from "../../features/map";
import Sidebar from "../../features/sidebar";
import { MapProvider } from "react-map-gl";
import styles from "../page.module.css";

/**
 * Page that displays the Map to the User.
 * Also renders a Sidebar and wraps both components in MapProvider to enable inter-component Communication and Map Manipulation within Sidebar.
 * @constructor
 * @category Page
 */
export default function MapPage() {
	return (
		<div className={styles.page}>
			<MapProvider>
				<MapComponent />
				<Sidebar />
			</MapProvider>
		</div>
	);
}
