import {Box, Button, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import SharePartnerTable from "./share-partner-table";
import styles from "./share.module.css";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../store/hooks";
import {Add, Delete} from "@mui/icons-material";
import {useEffect, useState} from "react";
import ShareCreationDialog, {SelectedShare} from "./share-creation-dialog";
import {collection, doc, getDoc, getDocs, setDoc, Timestamp} from "firebase/firestore";
import {firestore} from "../../../lib/firebase";
import {v4} from "uuid";
import {useAuth} from "../../../hooks/use-auth";
import {FilterOptions} from "../../../types/filter-options";
import ElementList from "../../../types/element-list";
import {Sharelink} from "../../../types/share-partner";

/**
 * Represents a tab for managing share partners and share links.
 * Allows users to add, deactivate, and view share partners and share links.
 */
export default function SharePartnerTab() {
	/** Share partners retrieved from the application state */
	const sharePartners = useAppSelector((state) => state.dashboard.sharePartners);
	/** Share links associated with the current user */
	const [shareLinks, setShareLinks] = useState<Sharelink[]>([]);
	/** State to manage the dialog's open state */
	const [open, setOpen] = useState(false);
	/** State to manage loading state */
	const [loading, setLoading] = useState(false);
	/** Current user */
	const {user} = useAuth();
	const { t } = useTranslation();

	/**
	 * Effect hook to fetch share links associated with the current user.
	 */
	useEffect(() => {
		if (!user) return;
		void getDocs(collection(firestore, "users", user.uid, "sharelink")).then(async (querySnapshot) => {
			const d = querySnapshot.docs.map(async (document) => ({
				...document.data(),
				filteroptions: (await getDoc(doc(firestore, "pre-shared-elements", document.id, "filteroptions", "filteroptions"))).data() as FilterOptions,
				sharedElements: (await getDoc(doc(firestore, "pre-shared-elements", document.id, "filteroptions", "sharedElements"))).data() as ElementList,
			}));
			setShareLinks(await Promise.all(d) as Sharelink[]);
		});
	}, []);

	/**
	 * Handles the submission of share creation data.
	 * @param data - The selected share data.
	 */
	async function handleSubmit(data: SelectedShare) {
		if (!user) return;
		setLoading(true);
		const key = v4();
		await setDoc(doc(firestore, "pre-shared-elements", key), { key, expiry: Timestamp.fromDate(data.expires), creation: Timestamp.now(), comment: data.comment, active: true });
		const promises = [
			setDoc(doc(firestore, "pre-shared-elements", key, "filteroptions", "filteroptions"), data.filteroptions),
			setDoc(doc(firestore, "pre-shared-elements", key, "filteroptions", "sharedElements"), data.elementList),
			setDoc(doc(firestore, "users", user.uid, "sharelink", key), { key, name: user.displayName })
		]
		await Promise.all(promises);
		setLoading(false);
		setOpen(false);
	}

	return (
		<>
			<Box className={styles.tab} id={styles.scrollable}>
				<Box className={styles.tabHeader}>
					<Box>
						<Typography component="h3">{t("shareOptions.sharePartners")}</Typography>
						<Typography>{t("shareOptions.sharePartnerInfo")}</Typography>
					</Box>
					<Box className={styles.listButtonContainer}>
						<Button
							onClick={() => setOpen(true)}
						>
							<Add />
							<Typography>{t("add")}</Typography>
						</Button>
						{/*<Button
							onClick={() => {}}
							disabled={true}
						>
							<Sort />
							<Typography>{t("sort")}</Typography>
						</Button>*/}
						<Button
							onClick={() => {}}
							disabled={true}
						>
							<Delete />
							<Typography>{t("deactivateAll")}</Typography>
						</Button>
					</Box>
				</Box>
				<Box className={styles.sharePartnerTabGrid}>
					<SharePartnerTable sharePartners={sharePartners} />
					<Box style={{ backgroundColor: "var(--card)", borderRadius: 8, padding: 8, boxShadow: "var(--shadow)", gridRowStart: 1, gridRowEnd: 3 }}>
						<Typography style={{ fontWeight: 600, fontSize: 15 }}>Aktive Sharelinks</Typography>
						<Box className={styles.tableContainer} style={{ maxHeight: 500, overflowY: "auto" }}>
							<Table>
								<>
									<TableHead>
										<TableRow style={{ color: "var(--accent)" }}>
											<TableCell style={{ color: "inherit" }}>Verwendet von</TableCell>
											<TableCell style={{ color: "inherit" }}>Comment</TableCell>
											<TableCell style={{ color: "inherit" }}>Expiry</TableCell>
											<TableCell style={{ color: "inherit" }}>Creation</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{shareLinks.map((sl, index) => (
											<TableRow key={index}>
												<TableCell>{sharePartners.find((p) => p.sharelink === sl.key)?.name ?? "Nicht verwendet"}</TableCell>
												<TableCell>{sl.comment ?? "No Comment"}</TableCell>
												<TableCell>{sl.expiry?.toDate().toLocaleDateString() ?? "No Date set"}</TableCell>
												<TableCell>{sl.creation?.toDate().toLocaleDateString() ?? "NA"}</TableCell>
											</TableRow>
										))}
									</TableBody>
								</>
							</Table>
						</Box>
					</Box>
				</Box>
				{open && <ShareCreationDialog open={open} setOpen={setOpen} onSubmit={handleSubmit} loading={loading} />}
			</Box>
		</>
	);
}
