import {auth} from "../../../lib/firebase";
import {LicenseMember} from "../../../types/license";
import {ListItemIcon, ListItemText, Menu, MenuItem, TableCell, TableRow} from "@mui/material";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import styles from "./team.module.css";
import {Check, PersonRemove, PhoneAndroid, Web} from "@mui/icons-material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";

/**
 * Component for rendering a row in the member table with various management options.
 * @param member - The member object containing information about the user.
 * @param promoteMember - Function to promote the member to admin.
 * @param removeMember - Function to remove the member from the team.
 * @param grantMobileAccess - Function to grant mobile access to the member.
 * @param grantWebAccess - Function to grant web access to the member.
 * @param revokeMobileAccess - Function to revoke mobile access from the member.
 * @param revokeWebAccess - Function to revoke web access from the member.
 */
export default function MemberTableRow({
	member,
	promoteMember,
	removeMember,
	grantMobileAccess,
	grantWebAccess,
	revokeMobileAccess,
	revokeWebAccess
}: {
	member: LicenseMember & { access: string[] };
	promoteMember: (userId: string) => void;
	removeMember: (userId: string) => void;
	grantMobileAccess: (userId: string) => void,
	grantWebAccess: (userId: string) => void,
	revokeMobileAccess: (userId: string) => void,
	revokeWebAccess: (userId: string) => void
}) {
	const isCurrentUser = auth.currentUser?.uid === member.uid;
	const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
	const [accessAnchor, setAccessAnchor] = useState<null | HTMLElement>(null);
	const { t } = useTranslation();

	return (
		<>
			<TableRow
				key={member.uid}
				id="member-row"
				aria-label="member-row"
				aria-haspopup={true}
				aria-expanded={anchorElement ? "true" : undefined}
				className={styles.row}
			>
				<TableCell
					className={styles.cell}
					component="th"
					scope="row"
				>
					{isCurrentUser ? <i>{t("you")}</i> : member.name}
				</TableCell>
				<TableCell
					className={styles.cell}
					align="left"
				>
					{member.email}
				</TableCell>
				<TableCell
					className={`${styles.cell} ${styles.activeCell}`}
					align="left"
					onClick={(event) => {
						setAccessAnchor(event.currentTarget);
					}}
				>
					{member.access.length > 0 ? member.access.join(", ") : "none"}
				</TableCell>
				<TableCell
					className={`${styles.cell} ${styles.activeCell}`}
					align="left"
					onClick={(event) => {
						setAnchorElement(event.currentTarget);
					}}
				>
					{member.isAdmin ? t("teamLicense.admin").toString() : t("teamLicense.member").toString() }
				</TableCell>
			</TableRow>
			<Menu open={Boolean(anchorElement)} anchorEl={anchorElement} onClose={() => setAnchorElement(null)}>
				<MenuItem onClick={() => promoteMember(member.uid)} style={{ display: "grid", gridTemplateColumns: "1fr 3fr 1fr" }}>
					<ListItemIcon>
						<AdminPanelSettingsIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText>Promote to Admin</ListItemText>
				</MenuItem>
				<MenuItem onClick={() => removeMember(member.uid)} style={{ display: "grid", gridTemplateColumns: "1fr 3fr 1fr" }} >
					<ListItemIcon>
						<PersonRemove fontSize="small" />
					</ListItemIcon>
					<ListItemText>Remove from Team</ListItemText>
				</MenuItem>
			</Menu>
			<Menu open={Boolean(accessAnchor)} anchorEl={accessAnchor} onClose={() => setAccessAnchor(null)}>
				<MenuItem onClick={() => member.access.includes("web") ? revokeMobileAccess(member.uid) : grantMobileAccess(member.uid)} style={{ display: "grid", gridTemplateColumns: "1fr 3fr 1fr" }}>
					<ListItemIcon>
						<PhoneAndroid fontSize="small" />
					</ListItemIcon>
					<ListItemText>Mobile</ListItemText>
					{member.access.includes("mobile") && <Check fontSize="small"/>}
				</MenuItem>
				<MenuItem style={{ display: "grid", gridTemplateColumns: "1fr 3fr 1fr" }} onClick={() => member.access.includes("web") ? revokeWebAccess(member.uid) : grantWebAccess(member.uid)}>
					<ListItemIcon>
						<Web fontSize="small" />
					</ListItemIcon>
					<ListItemText>Web</ListItemText>
					{member.access.includes("web") && <Check fontSize="small"/>}
				</MenuItem>
			</Menu>
		</>
	);
}
