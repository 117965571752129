import {Box, Typography, IconButton, Tooltip, Checkbox, Button, Popover } from "@mui/material";
import LibraryAddCheckOutlinedIcon from '@mui/icons-material/LibraryAddCheckOutlined';
import styles from "./sidebar.module.css";
import Entities, {Entity} from "../../types/entities";
import Area from "../../types/entities/area";
import Category, {isDefaultCategory} from "../../types/entities/category";
import ImageLayer from "../../types/entities/image-layer";
import LayerGroup from "../../types/entities/layer-group";
import Note from "../../types/entities/note";
import Path from "../../types/entities/path";
import TurningPoint from "../../types/entities/turning-point";
import EntityCard from "../../components/cards/entity-card";
import {useTranslation} from "react-i18next";
import {MouseEvent, useEffect, useMemo, useState} from "react";
import Media from "../../types/entities/media";
import useActions from "../../hooks/actions";
import HorizontalDivider from "../../components/dividers/horizontal-divider";
import Grid from "../../types/entities/grid";
import useSidebarParams from "./use-sidebar-params";
import { updateElementForEntityType } from "../../store/slices";
import { updateFirestoreElement } from "../../lib/firebase/firestore";
import { useAuth } from "../../hooks/use-auth";
import { User } from "firebase/auth";

/**
 * Props for the ItemList component.
 */
interface ItemListProps {
	tab: Entities;
	numOfItems: number;
	onItemClicked: (id: string, type: Entities) => void;
	sortFunction?: { name: string; function: (a: any, b: any) => number };
	reverse: boolean;
	metaSection?: JSX.Element;
	[Entities.Areas]: Area[];
	[Entities.Categories]: Category[];
	[Entities.ImageLayer]: ImageLayer[];
	[Entities.LayerGroup]: LayerGroup[];
	[Entities.Notes]: Note[];
	[Entities.Paths]: Path[];
	[Entities.TurningPoints]: TurningPoint[];
	[Entities.Grids]: Grid[];
	[Entities.Media]: Media[];
}

/**
 * ItemList component displays a list of entities based on the selected tab.
 * @param props ItemListProps
 * @returns JSX.Element
 */
export default function ItemList(props: ItemListProps) {
	const [isMultiSelect, setIsMultiSelect] = useState(false);
	const [selectedIds, setSelectedIds] = useState<string[]>([]);
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const {tab} = useSidebarParams();
	const user = useAuth().user as User | null;
	const items = useMemo(() => {
		const data = [...props[props.tab]];
		if (props.sortFunction) {
			data.sort(props.sortFunction.function);
			if (props.reverse) data.reverse();
		}
		return data;
	}, [props]);
	const { t } = useTranslation();

	useEffect(() => {
		setSelectedIds([]);
		setIsMultiSelect(false);
	}, [tab])

	const handleMultiDeleteClick = (event: MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	}

	const handleMultiDeletePopoverClose = () => {
		setAnchorEl(null);
	}

	const open = Boolean(anchorEl);
 	const id = open ? 'simple-popover' : undefined;

	return (
		<Box className={styles.itemList}>
			<Typography component="h2">{t(`general.${props.tab}`)}</Typography>
			<Typography component="p">
				{t("sidebar.itemCount", { count: props.numOfItems })}
			</Typography>
			{props.metaSection}
			<Box className={styles.listExtraButtonContainer}>
			<Tooltip title={t("multiSelect").toString()}>
                <IconButton
					disabled={tab === Entities.Categories}
					style={ isMultiSelect 
						? {
							backgroundColor: "var(--accent)",
							color: "var(--text-on-accent)",}
						: {}}
					onClick={() => {
						setIsMultiSelect(!isMultiSelect);
					}}
                >
                	<LibraryAddCheckOutlinedIcon/>
                </IconButton>
            </Tooltip>
			</Box>
			
			{isMultiSelect 
			? <Box>
				<span className={styles.multiSelectSection}>
                    <Typography className={styles.leftElement}>{t("selectAll")}</Typography>
                    <Checkbox className={styles.rightElement}
							  checked={selectedIds.length === props.numOfItems}
                              onChange={(event, checked) =>
								setSelectedIds(checked ? items.filter((el) => el).map((el) => el.firestoreUid) : [])}
                    />
                </span>
				<Button
					sx={{
						marginLeft: '55%',
						width: '44%'
					  }}
					variant="outlined"
					disabled={selectedIds.length === 0}
					onClick={handleMultiDeleteClick}
				>
					<Typography sx={{ fontSize: '14px' }}>{t("deleteSelected")}</Typography>
				</Button>
				<Popover
					id={id}
					open={open}
					anchorEl={anchorEl}
					onClose={handleMultiDeletePopoverClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					slotProps={{
						paper: {
							className: styles.popover
						},
					  }}
					>
						<Typography>{t("confirmCheck")}</Typography>
						<Button
								variant="contained"
								onClick={() => {
									for(let i = 0; i < selectedIds.length; i++) {
										const element = (items.filter(el => el.firestoreUid === selectedIds[i]))[0];
										updateElementForEntityType({...element, deletedAt: new Date().getTime()}, tab);
										updateFirestoreElement(user!.uid, tab, {...element, firestoreUid: selectedIds[i], deletedAt: new Date().getTime()});
									}
									setSelectedIds([]);
									setIsMultiSelect(false);
								}}
							>
								{t("confirm")}
							</Button>
				</Popover>
			</Box>
			: <></>
			}

			{props.numOfItems === 0 ?
				<Box className={styles.nothingHereBox}>
					<Box>
						{/* TODO Attribution https://www.freepik.com/free-vector/active-tourist-hiking-mountain-man-wearing-backpack-enjoying-trekking-looking-snowcapped-peaks-vector-illustration-nature-wilderness-adventure-travel-concept_10606146.htm#query=illustrations&position=1&from_view=keyword&track=sph */}
						<img alt={t("info.nothingHereYet")} src={"/images/nothing_here.png"} width={400} height={225} />
						<Typography component="h6">{t("info.nothingHereYet")}</Typography>
						<Typography component="p">{t("sidebar.hints.addElement", { entityType: t("general." + tab) })}</Typography>
					</Box>
				</Box>
				:
			props.tab === Entities.Categories ? (
				<>
					<ul>
						<HorizontalDivider label={"Standard"} />
						{items
							.filter((c) => isDefaultCategory(c.firestoreUid))
							.map((el) => (
								<EntityCard
									key={el.firestoreUid}
									item={el}
									onClick={props.onItemClicked}
								/>
							))}
						<HorizontalDivider />
						{items
							.filter((c) => !isDefaultCategory(c.firestoreUid))
							.map((el) => (
								<EntityCard
									key={el.firestoreUid}
									item={el}
									onClick={props.onItemClicked}
								/>
							))}
					</ul>
				</>
			) : (
				<ul>
					{items.map((el, index) => {
						if (isMultiSelect) {
							return (
								<EntityCard
								key={el.firestoreUid}
								item={el}
								checked={selectedIds.includes(el.firestoreUid)}
								onCheck={(checked) => {
									const si = [...selectedIds];
									if (checked) {
                                        si.push(el.firestoreUid);
                                    } else {
                                        si.splice(si.indexOf(el.firestoreUid), 1);
                                    }
                                    setSelectedIds(si);
								}}
								/>
							);
						} else {
							return (
								<ListItem key={index} entity={el} onClick={props.onItemClicked} />
							);
						}
					})}
				</ul>
			)
			}
		</Box>
	);
}

/**
 * ListItem component represents an item in the list.
 * @param entity Entity
 * @param onClick Function to handle click event
 * @returns JSX.Element
 */
function ListItem({
	entity,
	onClick,
}: {
	entity: Entity;
	onClick: (id: string, type: Entities) => void;
}) {
	// Get actions for the entity
	const actions = useActions(entity);

	return (
		// Render an EntityCard for the item
		<EntityCard
			key={entity.firestoreUid}
			item={entity}
			onClick={onClick}
			actions={actions}
		/>
	);
}
