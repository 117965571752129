import MapPage from "../../pages/map";
import Navigation from "../../features/navigation";
import useInitSnapshots from "../../lib/firebase/use-init-snapshots";


/**
 * Dedicated Map Route.
 * Renders a navigation component at the top of the screen in addition to the map content.
 * Invokes useInitSnapshot to begin loading user data.
 * @constructor
 * @category route
 */
export default function MapRoute() {
	useInitSnapshots();

	return (
		<>
			<Navigation />
			<MapPage />
		</>
	);
}
