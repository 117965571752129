import MetaData from "../types/entities/meta-data";
import Note from "../types/entities/note";
import { selectCategoryById } from "./slices/category-slice";
import store from "./store";
import { selectAreaById } from "./slices/area-slice";
import Area from "../types/entities/area";
import ImageLayer from "../types/entities/image-layer";
import { calculateLength, calculateSurfaceArea } from "../lib/model/utils/geo";
import { mapPositionArrayToLngLatArray } from "../types/map-position";
import Path from "../types/entities/path";
import Category, { isCategory } from "../types/entities/category";
import {
	selectNotesByAreaId,
	selectNotesByCategoryId,
} from "./slices/note-slice";

/**
 * This file defines a number of comparative functions to sort different entities while also reusing code and
 * often as possible. Not every function can be applied to all entities, the applicable ones are listed in each slice.
 * All results are ascending and can be made descending by reversing the array.
 */


/**
 * Alphabetical sorting
 * @param a
 * @param b
 * @category Comparator
 */
export function alphabetical(
	a: { name?: string; file_name?: string } & MetaData & any,
	b: { name?: string; file_name: string } & MetaData & any
) {
	if (a.name && b.name) return a.name.localeCompare(b.name);
	else if (a["file-name"] && b["file-name"])
		return a["file-name"].localeCompare(b["file-name"]);
	return 0;
}

/**
 * Alphabetical sorting by category name
 * @param a
 * @param b
 * @category Comparator
 */
export function byCategory(a: Note, b: Note) {
	const c1 = selectCategoryById(store.getState(), a.categoryUid);
	const c2 = selectCategoryById(store.getState(), b.categoryUid);
	if (!c1 || !c2) return 0;
	return c1.name.localeCompare(c2.name);
}

/**
 * Alphabetical sorting by area name
 * @param a
 * @param b
 * @category Comparator
 */
export function byArea(a: Note, b: Note) {
	const a1 = a.areaUid
		? selectAreaById(store.getState(), a.areaUid)
		: undefined;
	const a2 = b.areaUid
		? selectAreaById(store.getState(), b.areaUid)
		: undefined;
	if (!a1 || !a2) return 0;
	return a1.name.localeCompare(a2.name);
}

/**
 * Comparing by creation timestamp.
 * @param a
 * @param b
 * @category Comparator
 */
export function chronologicalCreated(a: MetaData, b: MetaData) {
	if (a.createdAt > b.createdAt) return 1;
	else if (a.createdAt < b.createdAt) return -1;
	return 0;
}

/**
 * Comparing by last update timestamp.
 * @param a
 * @param b
 * @category Comparator
 */
export function chronologicalUpdated(a: MetaData, b: MetaData) {
	if (a.updatedAt > b.updatedAt) return 1;
	else if (a.updatedAt < b.updatedAt) return -1;
	return 0;
}

/**
 * Comparing by covered area of the polygon.
 * @param a
 * @param b
 * @category Comparator
 */
export function bySize(a: Area | ImageLayer, b: Area | ImageLayer) {
	const s0 = calculateSurfaceArea(
		mapPositionArrayToLngLatArray("points" in a ? a.points : a.bounds)
	);
	const s1 = calculateSurfaceArea(
		mapPositionArrayToLngLatArray("points" in b ? b.points : b.bounds)
	);
	if (s0 > s1) return 1;
	else if (s0 < s1) return -1;
	return 0;
}

/**
 * Comparing by number of assigned notes to category or area
 * @param a
 * @param b
 * @category Comparator
 */
export function byNumOfAssignedNotes(a: Area | Category, b: Area | Category) {
	const s0 = (
		isCategory(a)
			? selectNotesByCategoryId(store.getState(), a.firestoreUid)
			: selectNotesByAreaId(store.getState(), a.firestoreUid)
	).length;
	const s1 = (
		isCategory(b)
			? selectNotesByCategoryId(store.getState(), b.firestoreUid)
			: selectNotesByAreaId(store.getState(), b.firestoreUid)
	).length;
	if (s0 > s1) return 1;
	else if (s0 < s1) return -1;
	return 0;
}

/**
 * Comparing by length of LineString of all points
 * @param a
 * @param b
 * @category Comparator
 */
export function byLength(a: Path, b: Path) {
	const s0 = calculateLength(a);
	const s1 = calculateLength(b);
	if (s0 > s1) return 1;
	else if (s0 < s1) return -1;
	return 0;
}
