// eslint-disable-next-line import/named
import Dexie, { Table } from "dexie";

/**
 * This file defines a class for usage of IndexDB.
 * IndexDB is used to cache Images that were requested from Static Maps by mapbox.
 * This is done to reduce the number of requests to that API (cost).
 */


export interface PreviewImage {
	id?: string;
	url: string;
}

export class Db extends Dexie {
	previewImages!: Table<PreviewImage>;

	constructor() {
		super("db");
		this.version(1).stores({
			previewImages: "id, url",
		});
	}
}

const db = new Db();
export default db;
