import {z} from "zod";

/**
 * Schema for all required meta information properties that every entity
 * is required to possess.
 * @category Schema
 */
export const MetaDataSchema = z.object({
	firestoreUid: z.string(),
	sharePartnerUid: z.string(),
	changedBy: z.string().optional(),
	createdAt: z.number(),
	deletedAt: z.number().nullable().optional(),
	updatedAt: z.number(),
	version: z.number().optional(),
});

/**
 * Type for Metadata in ForestManager.
 * Inferred from zod Schema.
 * @category Schema_Type
 */
type MetaData = z.infer<typeof MetaDataSchema>;

export default MetaData;