import { ShowcaseToken } from "../../../types/showcase-token";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { parsePotentialTimestamp } from "../../../lib/model/utils/time";
import { Box, IconButton, TableBody, TableCell, TableHead, TableRow, } from "@mui/material";
import Table from "../../../components/table";
import { ExpandMore } from "@mui/icons-material";
import TokenManagementDropdown from "./token-management-dropdown";
import styles from "./share.module.css";
import { useTranslation } from "react-i18next";
import tableStyles from "../../../components/table/table.module.css";

/**
 * Converts a token into a shareable link.
 * @param token - The token to convert into a shareable link.
 * @returns The shareable link.
 */
export function formatSharelinkFromToken(token: ShowcaseToken) {
	return `${window.location.protocol}//${window.location.host}/showcase/${token.key}`;
}

/**
 * Component for displaying a table of showcase tokens.
 * @param tokens - An array of showcase tokens to display in the table.
 * @param tokenToEdit - The token being edited.
 * @param setTokenToEdit - Function to set the token being edited.
 */
export default function TokenTable({ tokens, tokenToEdit, setTokenToEdit }: { tokens: ShowcaseToken[], tokenToEdit: ShowcaseToken | null, setTokenToEdit: Dispatch<SetStateAction<ShowcaseToken | null>> }) {
	const { t } = useTranslation();
	const [activeExpanded, setActiveExpanded] = useState(true);
	const [expiredExpanded, setExpiredExpanded] = useState(true);

	// Filter tokens into active and expired arrays
	const { activeTokens, expiredTokens} = useMemo(() => {
		const predicate = (t: ShowcaseToken) =>
			parsePotentialTimestamp(t.expires).toDate().getTime() > Date.now();
		const notPredicate = (t: ShowcaseToken) =>
			!(parsePotentialTimestamp(t.expires).toDate().getTime() > Date.now());
		return {
			activeTokens: tokens?.filter(predicate),
			expiredTokens: tokens?.filter(notPredicate),
		};
	}, [tokens]);

	return (
		<Box className={styles.tableContainer}>
			<Table>
				<>
					<TableHead>
						<TableRow>
							<TableCell>{t("comment")}</TableCell>
							<TableCell>{t("status")}</TableCell>
							<TableCell>{t("creationDate")}</TableCell>
							<TableCell align="left">{t("expires")}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow className={tableStyles.divider}>
							<TableCell>{t("shareOptions.recentTokens")}</TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>
							<TableCell>
								<IconButton
									onClick={() => setActiveExpanded(!activeExpanded)}
									aria-expanded={activeExpanded}
									aria-label="show more"
									size="small"
								>
									<ExpandMore />
								</IconButton>
							</TableCell>
						</TableRow>
						{activeExpanded &&
							activeTokens?.map((token: any) => (
								<TokenRow
									key={token.key}
									token={token}
									expired={false}
									editToken={setTokenToEdit}
									deleteToken={() => {}}
									toggleTokenStatus={() => {}}
								/>
							))}
						<TableRow className={tableStyles.divider} >
							<TableCell>{t("shareOptions.expiredTokens")}</TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>
							<TableCell>
								<IconButton
									onClick={() => setExpiredExpanded(!expiredExpanded)}
									aria-expanded={expiredExpanded}
									aria-label="show more"
									size="small"
								>
									<ExpandMore />
								</IconButton>
							</TableCell>
						</TableRow>
						{expiredExpanded &&
							expiredTokens?.map((token: any) => (
								<TokenRow
									key={token.key}
									token={token}
									editToken={setTokenToEdit}
									expired={true}
									deleteToken={() => {}}
									toggleTokenStatus={() => {}}
								/>
							))}
					</TableBody>
				</>
			</Table>
		</Box>
	);
}

/**
 * Component representing a single row in the token table.
 * @param token - The token data for this row.
 * @param expired - Boolean indicating whether the token has expired.
 * @param editToken - Function to handle editing the token.
 * @param toggleTokenStatus - Function to handle toggling the token status.
 * @param deleteToken - Function to handle deleting the token.
 */
export function TokenRow({
	token,
	expired,
	editToken,
	toggleTokenStatus,
	deleteToken,
}: {
	token: ShowcaseToken;
	expired: boolean;
	editToken: Dispatch<ShowcaseToken>;
	toggleTokenStatus: (token: ShowcaseToken) => void;
	deleteToken: (token: ShowcaseToken) => void;
}) {
	const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

	return (
		<>
			<TableRow
				id="token-row"
				aria-label="token-row"
				aria-haspopup={true}
				aria-expanded={anchorElement ? "true" : undefined}
				onClick={(event) => setAnchorElement(event.currentTarget)}
			>
				<TableCell component="th" scope="row">
					{token.comment}
				</TableCell>
				<TableCell component="th" scope="row">
					{expired ? "Expired" : token.active ? "Active" : "Disabled"}
				</TableCell>
				<TableCell component="th" scope="row">
					{parsePotentialTimestamp(token.created_at)
						.toDate()
						.toLocaleDateString()}
				</TableCell>
				<TableCell align="left">
					{parsePotentialTimestamp(token.expires).toDate().toLocaleDateString()}
				</TableCell>
			</TableRow>
			<TokenManagementDropdown
				anchorEl={anchorElement}
				open={Boolean(anchorElement)}
				setOpen={setAnchorElement}
				isActive={token.active}
				isExpired={expired}
				handleClose={() => setAnchorElement(null)}
				handleCopyClick={() =>
					void navigator.clipboard
						.writeText(formatSharelinkFromToken(token))
						.then(() => {})
				}
				handleEditClick={() => editToken(token)}
				handleDisableClick={() => toggleTokenStatus(token)}
				handleDeleteClick={() => deleteToken(token)}
			/>
		</>
	);
}
