import { z } from "zod";
import { MetaDataSchema } from "./meta-data";
import {MapPositionSchema} from "../map-position";

/**
 * Schema for ImageLayers in ForestManager.
 * @category Schema
 */
export const ImageLayerSchema = MetaDataSchema.extend({
	bounds: MapPositionSchema.array().length(4),
	"file-name": z.string(),
	"firestore-uri": z.string(),
});

/**
 * Type for ImageLayer in ForestManager.
 * Inferred from zod Schema.
 * @category Schema_Type
 */
type ImageLayer = z.infer<typeof ImageLayerSchema>;

export default ImageLayer;

/**
 * Is-Function to check whether the parameter is actually an ImageLayer
 * @param imageLayer
 * @returns {boolean}
 */
export function isImageLayer(
	imageLayer: unknown
): imageLayer is ImageLayer {
	return ImageLayerSchema.safeParse(imageLayer).success;
}
