import axios from "axios";

/**
 * Generates a URL for a WMS layer with the specified parameters.
 * @param provider - The WMS provider URL.
 * @param fileName - The name of the file representing the layer.
 * @param bbox - The bounding box coordinates: [minx, miny, maxx, maxy].
 * @param options - Additional options like workspace, width, and height.
 * @param options.workspace - The workspace for the layer.
 * @param options.width - The width of the image.
 * @param options.height - The height of the image.
 * @returns The URL for the WMS layer.
 */
export function formatGeoURL(
	provider: string,
	fileName: string,
	bbox: [number, number, number, number],
	options: { workspace?: string; width?: number; height?: number }
) {
	const { workspace, width, height } = options;
	const layerString = (workspace ? workspace + ":" : "") + fileName;
	return `${provider}/${
		workspace ?? ""
	}/wms?service=WMS&version=1.1.1&request=GetMap&format=image/png&transparent=true&srs=EPSG:4326&bbox=${bbox}&width=${
		width ?? 512
	}&height=${height ?? 512}&layers=${layerString}`;
}

/**
 * Extracts bounding box coordinates from a NamedNodeMap.
 * @param attr - The NamedNodeMap containing the bounding box attributes.
 * @returns The bounding box coordinates: [minx, miny, maxx, maxy].
 */
export function getBboxFromAttrNode(
	attr: NamedNodeMap | undefined
): [number, number, number, number] {
	// TODO: what do when undefined
	if (!attr) return [0, 0, 0, 0];
	return [
		Number(attr?.getNamedItem("minx")?.textContent),
		Number(attr?.getNamedItem("miny")?.textContent),
		Number(attr?.getNamedItem("maxx")?.textContent),
		Number(attr?.getNamedItem("maxy")?.textContent),
	];
}

/**
 * Represents a parsed layer from the WMS capabilities.
 */
type ParsedLayer =  {
	fileName: string,
	bounds: [number, number, number, number],
	workspace: string | undefined,
	styles: string[]
}

/**
 * Retrieves layers from the WMS capabilities of the specified provider.
 * @param provider - The URL of the WMS provider.
 * @returns A Promise that resolves to an array of ParsedLayer objects representing the WMS layers.
 */
export async function getLayersFromCapabilities(provider: string) {
	const parser = new DOMParser();
	const capabilities = await axios.get(
		`${provider}/wms?service=WMS&version=1.1.0&request=GetCapabilities&format=text/xml`,
		{ headers: { Authorization: "Basic " + window.btoa("lukas:rbitech") } }
	);
	const xmlDoc = parser.parseFromString(capabilities.data, "text/xml");
	const nodes = xmlDoc.getElementsByTagName("Layer");
	const elements = Array.from(nodes).filter(
		(el) => el.parentElement?.nodeName === "Layer"
	);
	const result: ParsedLayer[] = [];
	elements.forEach((l) => {
		const bounds = getBboxFromAttrNode(
			l.getElementsByTagName("LatLonBoundingBox")[0]?.attributes
		);
		const fileName = l.getElementsByTagName("Name")[0].textContent;
		const stylesList = Array.from(l.getElementsByTagName("Style")).map(
			(s) => s.getElementsByTagName("Name")[0].textContent
		);
		// @ts-ignore
		const styles: string[] = stylesList.filter((el) => el !== null);
		if (!fileName) return;
		let layer: ParsedLayer = {
			fileName,
			bounds,
			workspace: undefined,
			styles,
		};
		if (fileName.includes(":")) {
			const arr = fileName.split(":");
			layer = {
				fileName: arr[1],
				workspace: arr[0],
				bounds,
				styles,
			};
		}
		result.push(layer);
	})
	return result;
}
