import { Layer, Source, useMap } from "react-map-gl";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { selectPaths } from "../../../store/slices/path-slice";
import {useEffect, useMemo} from "react";
import { setPopoverInfo } from "../map-slice";
import Entities from "../../../types/entities";
import store from "../../../store/store";
import { PathType } from "../../../types/entities/path";
import {GeoJSON} from "geojson";

/**
 * Default colors for different types of paths.
 */
export const DefaultColors = {
	[PathType.Road]: "#873200",
	[PathType.Alley]: "#d05a5a",
	[PathType.Route]: "#58be58",
};

/**
 * Component rendering path lines on the map.
 * @returns {JSX.Element} PathLines component JSX element.
 */
export default function PathLines() {
	const paths = useAppSelector((state) => selectPaths(state));
	const layers = useMemo(() => paths.map((path) => (
		<LineLayer
			color={DefaultColors[path.type]}
			key={path.firestoreUid}
			id={path.firestoreUid}
			coordinates={path.points.map((p) => [p.longitude, p.latitude])}
			width={path.width ?? 1}
		/>
	)), [paths]);
	const { m1: map } = useMap();
	const dispatch = useAppDispatch();

	useEffect(() => {
		map &&
			map.on("click", [...paths.map((p) => p.firestoreUid)], (event) => {
				if (!event.features || store.getState().sidebar.present.formOpen)
					return;
				event.preventDefault();
				event.originalEvent.preventDefault();
				const id = event.features[0].source;
				if (id === undefined) return;
				dispatch(
					setPopoverInfo({
						id: String(id),
						type: Entities.Paths,
						lng: event.lngLat.lng,
						lat: event.lngLat.lat,
						closed: false,
					})
				);
			});
	}, [map, paths, dispatch]);

	return (
		<>
			{layers}
		</>
	);
}

/**
 * Component representing a line layer on the map.
 * @param {Object} props - Component props.
 * @param {string} props.id - Identifier for the layer.
 * @param {number[][]} props.coordinates - Array of coordinates for the line.
 * @param {number} props.width - Width of the line.
 * @param {string} props.color - Color of the line.
 * @returns {JSX.Element} LineLayer component JSX element.
 */
export function LineLayer({
	id,
	coordinates,
	width,
	color,
}: {
	id: string;
	coordinates: number[][];
	width: number;
	color: string;
}) {
	const geojson = useMemo(() => {
		return {
			type: "Feature",
			properties: {},
			geometry: {
				type: "LineString",
				coordinates,
			},
		} as GeoJSON;
	}, [coordinates]);

	return (
		<Source
			id={id}
			type="geojson"
			data={geojson}
		>
			<Layer
				type="line"
				id={id}
				layout={{ "line-join": "round", "line-cap": "round" }}
				paint={{
					"line-color": color,
					"line-width": width + 4,
				}}
			/>
		</Source>
	);
}
