import {Avatar, Box, Typography} from "@mui/material";
import styles from "./dashboard.module.css";
import {useAppSelector} from "../../store/hooks";
import {selectAllElements} from "../../store/slices";
import Entities from "../../types/entities";
import {ImageCardSkeleton} from "../../components/cards/image-card";
import {selectNotesByCategoryId} from "../../store/slices/note-slice";
import store from "../../store/store";
import {Suspense} from "react";
import CategoryCard from "../../components/cards/category-card";
import EntityCard from "../../components/cards/entity-card";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";
import useSidebarParams from "../sidebar/use-sidebar-params";

/**
 * Type representing a task.
 * Currently not in use, just an example.
 */
type Task = {
	title: string;
	start: Date;
	end: Date;
	participants: { name: string; email: string; avatar: string }[];
	label: string;
};

/** Sample tasks data. */
const sampleTasks: Task[] = [
	{
		title: "Abholzen",
		start: new Date(1675415110074),
		end: new Date(1675418791082),
		label: "Abholzen",
		participants: [
			{
				name: "Christian Hutzler",
				email: "christian.hutzler@gmail.com",
				avatar:
					"",
			},
		],
	},
	{
		title: "Weg erschließen",
		start: new Date(1675415110074),
		end: new Date(1675418791082),
		label: "Feinerschließung",
		participants: [
			{
				name: "Christian Hutzler",
				email: "christian.hutzler@gmail.com",
				avatar:
					"",
			},
		],
	},
	{
		title: "Käferbefall untersuchen",
		start: new Date(1675415110074),
		end: new Date(1675418791082),
		label: "Käfer",
		participants: [
			{
				name: "Christian Hutzler",
				email: "christian.hutzler@gmail.com",
				avatar:
					"",
			},
		],
	},
];

/**
 * Dashboard overview component.
 * Provides an overview of recent changes, common categories, shared partners, and tasks.
 */
export default function Overview() {
	const data = useAppSelector((state) => selectAllElements(state));
	const navigate = useNavigate();
	const { sharePartners } = useAppSelector((state) => state.dashboard);
	const { t } = useTranslation();
	const { setDetail, setTab } = useSidebarParams();

	/**
	 * Retrieves the most recent changes from the data.
	 * @returns An array of the most recent changes.
	 */
	function getMostRecentChanges() {
		const everything = [
			...data.areas,
			...data.categories,
			...data[Entities.ImageLayer],
			...data[Entities.LayerGroup],
			...data.notes,
			...data.paths,
			...data[Entities.TurningPoints],
			...data.grid
		];
		everything.sort((a, b) => {
			if (a.updatedAt < b.updatedAt) return 1;
			else if (a.updatedAt > b.updatedAt) return -1;
			return 0;
		});
		return everything;
	}

	/**
	 * Retrieves the most common categories with the number of associated notes.
	 * @returns An array of the most common categories.
	 */
	function getMostCommonCategories() {
		if (!data[Entities.Categories]) {
			return [];
		}
		return data[Entities.Categories]
			.map((c) => ({
				category: c,
				numOfNotes: selectNotesByCategoryId(store.getState(), c.firestoreUid)
					.length,
			}))
			.sort((a, b) => {
				if (a.numOfNotes < b.numOfNotes) return 1;
				if (a.numOfNotes > b.numOfNotes) return -1;
				return 0;
			})
			.map((el) => el.category);
	}

	return (
		<Box className={styles.overview}>
			<Box className={styles.recentChanges}>
				<Typography>{t("overview.recentlyChanged")}</Typography>
				<ul>
					<Suspense
						fallback={[...Array(4)].map((n) => (
							<ImageCardSkeleton key={n} />
						))}
					>
						{getMostRecentChanges()
							.slice(0, 4)
							.map((el) => (
								<EntityCard
									key={el.firestoreUid}
									item={el}
									onClick={(id, type) => {
										setTab(type);
										setDetail(id);
										navigate("/map" + window.location.search);
									}}
								/>
							))}
					</Suspense>
				</ul>
			</Box>
			<Box>
				<Typography>{t("overview.currentWoodLists")}</Typography>
				<Box className={styles.woodPile}>
					<img src={"/images/woodpile.png"} width={250} height={224} alt={"Woodpiles Vectorgraphics"} />
					<Typography>{t("overview.woodpileReminder")}</Typography>
				</Box>
			</Box>
			<Box className={styles.mostCommonCategory}>
				<Typography>{t("overview.mostCommonCategory")}</Typography>
				<ul>
					{getMostCommonCategories()
						.slice(0, 2)
						.map((el) => (
							<CategoryCard
								key={el.firestoreUid}
								onClick={(id) => {
									setTab(Entities.Categories);
									setDetail(id);
									navigate("/map");
								}}
								category={el}
								numOfNotes={
									selectNotesByCategoryId(store.getState(), el.firestoreUid)
										.length
								}
							/>
						))}
				</ul>
			</Box>
			<Box className={styles.sharePartners}>
				<Typography>{t("overview.yourSharepartners")}</Typography>
				{sharePartners && sharePartners.map((s) => (
					<Box key={s.uid}>
						<Box>
							<Avatar />
							<Box>
								<Typography>{s.name}</Typography>
								<Typography component="small">{s.email}</Typography>
							</Box>
						</Box>
						<Typography>{t("overview.sharingWithYou", { num: Object.values(s.sharedElementList).flat(2).length })}</Typography>
						<Typography>{t("overview.sharedByYou", { num: Object.values(s.ownElementList).flat(2).length, name: s.name })}</Typography>
					</Box>
				))}
			</Box>
			<Box className={styles.tasks}>
				<Typography>{t("overview.tasks")}</Typography>
				<ul>
					{sampleTasks.map((task, index) => (
						<Box
							key={index}
						>
							<Typography>
								{task.title}
							</Typography>
							<Typography>
								{new Intl.RelativeTimeFormat("en", {
									numeric: "auto",
								}).format(task.start.getDay() - new Date().getDay(), "day")}
							</Typography>
							<Typography>
								{task.start.getHours()} - {task.end.getHours()}
							</Typography>
							<Box>
								{task.participants.map((p, index) => (
									<Avatar key={index} src={p.avatar} />
								))}
							</Box>
							<Typography component="small">
								{task.label}
							</Typography>
						</Box>
					))}
				</ul>
			</Box>
			<Box className={styles.about}>
				<Typography>{t("overview.about")}</Typography>
				<Box>
					<Typography>{t("overview.aboutDescription")}</Typography>
					<a target="_blank" href={"https://play.google.com/store/apps/details?id=de.rbitech.apps.forestmanager"} rel="noreferrer">
						<img src={"/images/google_play.png"} width={200} />
					</a>
					<Box>
						<Typography>{t("overview.aboutLinks")}</Typography>
						<Box>
							<a target="_blank" href="https://www.linkedin.com/company/forestmanager/" rel="noreferrer">
								<img src={"/images/linkedin.png"} width={40} />
							</a>
							<a target="_blank" href="https://www.facebook.com/ForestManagerApp" rel="noreferrer">
								<img src={"/images/facebook.png"} width={40} />
							</a>
							<a target="_blank" href="https://www.instagram.com/forestmanager/" rel="noreferrer">
								<img src={"/images/instagram.png"} width={40} />
							</a>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
