import styles from "./navigation.module.css";
import { Avatar, Box, Tooltip } from "@mui/material";
import { TbMap2 } from "react-icons/tb";
import { Link } from "react-router-dom";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import Popover from "@mui/material/Popover";
import { useLocation } from "react-router";
import { DashboardOutlined } from "@mui/icons-material";
import { auth } from "../../lib/firebase";
import { useTranslation } from "react-i18next";

/**
 * Represents the navigation component for the application.
 * Renders navigation links and user account information.
 * @returns JSX for the Navigation component.
 */
export default function Navigation() {
	const location = useLocation();
	const { t } = useTranslation();

	// Conditional rendering based on the current path
	return location.pathname === "/login" ? (
		<></> // Empty fragment when on login page
	) : (
		<Box className={styles.navigation}>
			<PopupState variant="popover" popupId="demo-popup-popover">
				{(popupState) => (
					<div>
						<Avatar className={styles.avatar} {...bindTrigger(popupState)} />
						<Popover
							{...bindPopover(popupState)}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "center",
							}}
							transformOrigin={{
								vertical: -10,
								horizontal: "center",
							}}
							className={styles.popover}
						>
							{/* User account details and logout button */}
							<div className={styles.accountBox}>
								<div>
									<div className={styles.userInfo}>
										<Avatar
											src={""}
										/>
										<small>{auth.currentUser?.email}</small>
										<strong>{auth.currentUser?.displayName}</strong>
									</div>
									{/* Logout section */}
									<div className={styles.logoutBox}>
										<span>{t("navigation.notYou")}</span>
										<Link to={`/logout`}>
											{t("navigation.signOut")}
										</Link>
									</div>
								</div>
							</div>
						</Popover>
					</div>
				)}
			</PopupState>
			{/* Navigation links */}
			{/* Link to map page */}
			{location.pathname !== "/map" && (
				<Tooltip title={"Zur Karte"}>
					<Link to={"/map"}>
						<TbMap2 />
					</Link>
				</Tooltip>
			)}
			{/* Link to dashboard page */}
			{location.pathname !== "/dashboard" && (
				<Tooltip title={"Zum Dashboard"}>
					<Link to={"/dashboard"}>
						<DashboardOutlined />
					</Link>
				</Tooltip>
			)}
		</Box>
	);
}
