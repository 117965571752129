import { TableContainer, Table as TableView } from "@mui/material";
import styles from "./table.module.css";

/**
 * Table Component
 *
 * A component for rendering a table with customizable properties.
 *
 * @component
 * @param {Object} props - The properties of the Table component.
 * @param {JSX.Element} props.children - The content of the table.
 * @param {Object} [props.rest] - Additional properties to be spread to the underlying components.
 *
 * @returns {JSX.Element} - The rendered Table component.
 *
 * @example
 * // Usage of Table component
 * <Table>
 *   <TableHead>
 *     <TableRow>
 *       <TableCell>ID</TableCell>
 *       <TableCell>Name</TableCell>
 *       <TableCell>Actions</TableCell>
 *     </TableRow>
 *   </TableHead>
 *   <TableBody>
 *
 *   </TableBody>
 * </Table>
*/
export default function Table({
	children,
	...rest
}: {
	children: JSX.Element;
	[x: string]: any;
}): JSX.Element {
	return (
		<TableContainer {...rest} className={styles.container}>
			<TableView className={styles.table}>{children}</TableView>
		</TableContainer>
	);
}
