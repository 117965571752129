import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useAuth } from "../../hooks/use-auth";

/**
 * Custom hook for handling sign-in logic.
 * Provides state for form fields and functions that call Firebase Auth SDK flow.
 * @category hook
 */
export default function useLogin() {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const { login, loginWithGoogle, redirectPath } = useAuth();
	const [error, setError] = useState<string | null>(null);
	const navigate = useNavigate();

	// If VITE_DEMO is set, this will automatically redirect to /map using dummy credentials.
	// Originally implemented for Waldmesse 2023
	useEffect(() => {
		if (import.meta.env.VITE_DEMO) {
			console.log("Demo Environment: " + import.meta.env.VITE_DEMO);

			void login(import.meta.env.VITE_DEMO_USERNAME, import.meta.env.VITE_DEMO_PASSWORD)
				.then(() => {
					navigate(redirectPath ?? "/map");
				})
				.catch((error) => {
					setError(error.message);
				});
		}
	}, []);

	// Invoked when the user tries to login using self supplied credentials
	// If the user originally signed up using Google Auth, this will not work for them and they have to continue using that Method
	async function handleSubmit(redirectURL: string | undefined) {
		setError(null);
		await login(email, password)
			.then(() => {
				navigate(redirectURL ?? "/map");
			})
			.catch((error) => {
				setError(error.message);
			});
	}

	// Invoked when the user tries to login through Google's auth provider
	async function handleGoogleSubmit() {
		setError("");
		await loginWithGoogle()
			.then((user) => {
				navigate(redirectPath);
			})
			.catch((error) => {
				setError(error.message);
			});
	}

	const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
	};

	const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value);
	};

	return {
		email,
		handleEmailChange,
		password,
		handlePasswordChange,
		handleSubmit,
		handleGoogleSubmit,
		error,
		setError,
	};
}
