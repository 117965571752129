import { Box, Button, TableBody, TableCell, TableHead, TableRow, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import Table from "../../../components/table";
import License from "../../../types/license";
import LoadingScreen from "../../../components/loading/loading-screen";
import MemberTableRow from "./member-table-row";
import QRCode from "react-qr-code";
import { useState, useEffect } from "react";
import {
	fetchLicenseDataForUser,
	promoteMemberToAdmin,
	removeMemberFromLicense,
} from "../../../lib/firebase/functions";
import { firestore } from "../../../lib/firebase";
import { doc, setDoc, Timestamp, updateDoc } from "firebase/firestore";
import { useAppSelector } from "../../../store/hooks";
import { useTranslation } from "react-i18next";
import styles from "../dashboard.module.css";
import { useAuth } from "../../../hooks/use-auth";
import { useRouter } from "../../../hooks/use-router";
import { useFeedback } from "../../feedback/use-feedback";
import { leaveTeamForUser } from "../../../lib/firebase/functions";

/**
 * Fetches license data for the specified user ID.
 * @param userId - The user ID for which the license data is fetched.
 * @returns A Promise that resolves to the License data or null if not found.
 */
async function fetchLicenseData(userId: string): Promise<License | null> {
	const res = await fetchLicenseDataForUser({ uid: userId });
	// @ts-ignore
	const license = res.data.license as License;
	if (!license) return null;
	const { key, organizationName, possibleUserNum, possibleWebUserNum, members, webMembers } = license;
	members.sort((a) => {
		return a.uid === userId ? -1 : 1;
	});
	return {
		key,
		organizationName,
		possibleUserNum,
		possibleWebUserNum,
		members,
		webMembers,
		validTo: new Timestamp(
			license.validTo.seconds,
			license.validTo.nanoseconds
		),
		validFrom: new Timestamp(
			license.validFrom.seconds,
			license.validFrom.nanoseconds
		),
	};
}

/**
 * Component for rendering the team license details and members.
 */
export default function Index() {
	const {user} = useAuth();
	const license = useAppSelector((state) => state.dashboard.license);
	const [currentLicense, setCurrentLicense] = useState(license);
	const [isLoading, setIsLoading] = useState(false);
	const [leaveDialogOpen, setLeaveDialogOpen] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		if(license) {
			setCurrentLicense(license);
		}
	}, [license, currentLicense]);

	if(!license || !currentLicense) {
		return (
			<div>
				<strong>{t("teamLicense.loading")}</strong>
			</div>);
	}
	

	/**
	 * Requests to promote a member to admin.
	 * @param userId - The ID of the user to promote.
	 */
	function requestLicenseMemberPromotion(userId: string) {
		setIsLoading(true);
		void promoteMemberToAdmin({ userId, licenseUid: currentLicense?.key }).then(
			() => {
				if (user) {
					void fetchLicenseData(user.uid).then((license) => {
						license && setCurrentLicense(license);
					});
				}
				setIsLoading(false);
			}
		);
	}

	/**
	 * Requests to remove a member from the license.
	 * @param userId - The ID of the user to remove.
	 */
	function requestLicenseMemberRemoval(userId: string) {
		setIsLoading(true);
		void removeMemberFromLicense({
			userId,
			licenseUid: currentLicense?.key,
		}).then(() => {
			if (user) {
				void fetchLicenseData(user.uid).then((license) => {
					license && setCurrentLicense(license);
				});
			}
			setIsLoading(false);
		});
	}

	/**
	 * Grants mobile access to a user.
	 * @param userId - The ID of the user to grant access.
	 */
	function grantMobileAccess(userId: string) {
		setIsLoading(true);
		// @ts-ignore
		void setDoc(doc(firestore, "team-license-keys", currentLicense.key, "users", userId),
			{ accountUid: userId, active: true, comment: "User was added on " + new Date().toLocaleDateString() }
		).then(() => {
			if (user) {
				void fetchLicenseData(user.uid).then((license) => {
					license && setCurrentLicense(license);
				});
			}
			setIsLoading(false);
		});
	}

	/**
	 * Grants web access to a user.
	 * @param userId - The ID of the user to grant access.
	 */
	function grantWebAccess(userId: string) {
		setIsLoading(true);
		// @ts-ignore
		void setDoc(doc(firestore, "team-license-keys", currentLicense.key, "web-users", userId),
			{ accountUid: userId, active: true, comment: "User was added on " + new Date().toLocaleDateString() }
		).then(() => {
			if (user) {
				void fetchLicenseData(user.uid).then((license) => {
					license && setCurrentLicense(license);
				});
			}
			setIsLoading(false);
		});
	}

	/**
	 * Revokes mobile access from a user.
	 * @param userId - The ID of the user to revoke access.
	 */
	function revokeMobileAccess(userId: string) {
		setIsLoading(true);
		// @ts-ignore
		void updateDoc(doc(firestore, "team-license-keys", currentLicense.key, "users", userId),
			{ accountUid: userId, active: false, comment: "User was added on " + new Date().toLocaleDateString() }
		).then(() => {
			if (user) {
				void fetchLicenseData(user.uid).then((license) => {
					license && setCurrentLicense(license);
				});
			}
			setIsLoading(false);
		});
	}

	/**
	 * Revokes web access from a user.
	 * @param userId - The ID of the user to revoke access.
	 */
	function revokeWebAccess(userId: string) {
		setIsLoading(true);
		// @ts-ignore
		void updateDoc(doc(firestore, "team-license-keys", currentLicense.key, "web-users", userId),
			{ accountUid: userId, active: false, comment: "User was added on " + new Date().toLocaleDateString() }
		).then(() => {
			if (user) {
				void fetchLicenseData(user.uid).then((license) => {
					license && setCurrentLicense(license);
				});
			}
			setIsLoading(false);
		});
	}

	return (
		<Box id={styles.scrollable}>
			<Box className={styles.teamLicenseInfo}>
				<Box>
					<p>
						<strong>{t("teamLicense.organizationName")}</strong>{" "}
						{currentLicense.organizationName}
					</p>
					<p>
						<strong>{t("teamLicense.validUntil")}</strong>{" "}
						{/* @ts-ignore */}
						{new Timestamp(currentLicense.validTo._seconds, currentLicense.validFrom._nanoseconds)
							.toDate()
							.toLocaleDateString()}
					</p>
					<p>
						<strong>{t("teamLicense.freeSlots")}</strong>{" "}
						{currentLicense.possibleUserNum}
					</p>
					<p>
						<strong>{t("teamLicense.freeWebSlots")}</strong>{" "}
						{currentLicense.possibleWebUserNum}
					</p>
					
				</Box>
				<div className={styles.licenseCode}>
					<QRCode
						size={160}
						value={`https://forestmanager.de/app-link?a=team&k=${currentLicense.key}`}
					/>
				</div>
			</Box>

			<Button onClick={() => {
				setLeaveDialogOpen(true);
			}}>{t("teamLicense.leaveTeam")}</Button>

			<ConfirmLeaveDialog 
				open={leaveDialogOpen}
				onCancel={() => setLeaveDialogOpen(false)}
			/>
			{currentLicense.members.length > 0 && (
				<Box>
					<h2 className={styles.tableHeadline}>{t("teamLicense.members")}</h2>
					<Box className={styles.licenseMembers}>
						<LoadingScreen
							isVisible={isLoading}
							isFullscreen={false}
							isOpaque={false}
						/>
						<Table className={styles.licenseTable}>
							<>
								<TableHead>
									<TableRow className={styles.licenseTableRow}>
										<TableCell className={styles.licenseTableCell}>
											{t("name")}
										</TableCell>
										<TableCell align="left" className={styles.licenseTableCell}>
											{t("email")}
										</TableCell>
										<TableCell align="left" className={styles.licenseTableCell}>
											{t("teamLicense.access")}
										</TableCell>
										<TableCell align="left" className={styles.licenseTableCell}>
											{t("teamLicense.role")}
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{[...currentLicense.members.map((el) => ({...el, access: el.active ? ["mobile"] : [] })),
											...currentLicense.webMembers.map((el) => ({...el, access: el.active ? ["web"] : [] }))].map((member, index, arr) => {
												const withoutEl = [...arr];
												withoutEl.splice(index, 1);
												const duplicate = withoutEl.find((el) => el.uid === member.uid);
												if (duplicate) {
													if (arr.indexOf(duplicate) <= index) return <></>;
													member.access = [...member.access, ...duplicate.access];
												}
										return (
											<MemberTableRow
												member={member}
												promoteMember={requestLicenseMemberPromotion}
												removeMember={requestLicenseMemberRemoval}
												grantMobileAccess={grantMobileAccess}
												grantWebAccess={grantWebAccess}
												revokeMobileAccess={revokeMobileAccess}
												revokeWebAccess={revokeWebAccess}
												key={member.uid}
											/>
										)})
									}
								</TableBody>
							</>
						</Table>
					</Box>
					<Box className={styles.licenseUsage}>
						<p style={{ marginBottom: '2px' }}>
							{t("teamLicense.usage", {
								used: currentLicense.members.length,
								possible: currentLicense.possibleUserNum,
							})}
						</p>
					</Box>
					<Box className={styles.licenseUsage}>
						<p style={{ marginTop: '2px' }}>
							{t("teamLicense.webUsage", {
								used: currentLicense.webMembers.length,
								possible: currentLicense.possibleWebUserNum,
							})}
						</p>
					</Box>
				</Box>
			)}
		</Box>
	);
}

export interface ConfirmLeaveDialogProps {
	open: boolean;
	onCancel: () => void;
}

const ConfirmLeaveDialog = (props: ConfirmLeaveDialogProps) => {
	const { open, onCancel } = props;
	const {user} = useAuth();
	const {navigate} = useRouter();
	const license = useAppSelector((state) => state.dashboard.license);
	const [isButtonEnabled, setIsButtonEnabled] = useState(true);
	const [showError, setShowError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
	const { setFeedback } = useFeedback();
	const { t } = useTranslation();

	return (
	<Dialog
	  open={open}
	  onClose={onCancel}
  	>
	<DialogTitle>
		{t("teamLicense.leaveTitle")}
	</DialogTitle>
	<DialogContent>
	  <Typography>
	  {t("teamLicense.leaveSubtitle")}
	  </Typography>

	  { showError &&
        <Typography id={styles.errorText}>{t("errors.default")}</Typography>
      }
	</DialogContent>
	
	<DialogActions>
	  <Button onClick={onCancel}>{t("cancel")}</Button>
	  <Button disabled={!isButtonEnabled} onClick={() => {
		setIsButtonEnabled(false);
		setIsLoading(true);

		if(user) {
			void leaveTeamForUser({ accountUid: user.uid, licenseKey: license?.key }).then((res) => {
				setFeedback(t("teamLicense.leftTeam"), "info");
				setIsButtonEnabled(true);
            	setIsLoading(false);
				navigate("/map");
		  	}).catch((err) => {
				setIsButtonEnabled(true);
            	setIsLoading(false);
            	setShowError(true);
		  	})
		}
	  }} autoFocus>
		{isLoading ? t("loading") : t("confirm")}
	  </Button>
	</DialogActions>
  </Dialog>);
}