import { useLocation, useMatch, useNavigate, useParams } from "react-router";
import { useMemo } from "react";
import queryString from "query-string";

/**
 * Custom hook for accessing router functionality in React components.
 * Combines various React Router hooks and provides additional utility.
 * @returns An object containing router-related information and functions.
 */
export function useRouter() {
	// Retrieve route parameters
	const params = useParams();
	// Retrieve current location
	const location = useLocation();
	// Access navigation function
	const navigate = useNavigate();
	// Retrieve route match information
	const match = useMatch;

	// Return a memoized router object to prevent unnecessary re-renders
	return useMemo(() => {
		return {
			// Convenience methods for navigation
			push: navigate, // Function to push a new entry onto the history stack
			pathname: location.pathname, // Current pathname
			// Merge route parameters and parsed query string into a single "query" object
			// to enable interchangeable usage.
			// Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
			query: {
				...queryString.parse(location.search), // Convert query string to object
				...params, // Merge route parameters
			},
			// Include match, location, and navigate objects for additional router functionality
			match,
			location,
			navigate,
		};
	}, [params, match, location, navigate]); // Memoize based on dependencies
}
