import DashboardPage from "../../pages/dashboard";
import Navigation from "../../features/navigation";
import useInitSnapshots from "../../lib/firebase/use-init-snapshots";

/**
 * Dedicated Dashboard Route.
 * Renders a navigation component at the top of the screen in addition to the dashboard content.
 * Invokes useInitSnapshot to begin loading user data.
 * @constructor
 * @category route
 */
export default function DashboardRoute() {
	useInitSnapshots();

	return (
		<>
			<Navigation />
			<DashboardPage />
		</>
	);
}
