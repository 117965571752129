/// <reference types="vite-plugin-svgr/client" />
import IconApple from "./assets/apfel.svg?react";
import IconAlert from "./assets/ausrufezeichen.svg?react";
import IconAxe from "./assets/axt.svg?react";
import IconTreeTrunk from "./assets/baumstamm2.svg?react";
import IconWatch from "./assets/beobachten.svg?react";
import IconFire from "./assets/feuer.svg?react";
import IconQuestionMark from "./assets/fragezeichen.svg?react";
import IconFox from "./assets/fuchs2.svg?react";
import IconHarvester from "./assets/harvester.svg?react";
import IconPerchFront from "./assets/hochsitz.svg?react";
import IconPerchLaterally from "./assets/hochsitz-seitlich.svg?react";
import IconBeetle from "./assets/kaefer.svg?react";
import IconNoBeetle from "./assets/kaefer-durchgestrichen.svg?react";
import IconCampfire from "./assets/lagerfeuer.svg?react";
import IconDeciduousTree from "./assets/laubbaum.svg?react";
import IconChainsaw from "./assets/motorsaege.svg?react";
import IconTrash from "./assets/muell.svg?react";
import IconSoftwood from "./assets/nadelbaum.svg?react";
import IconNote from "./assets/notiz.svg?react";
import IconPaw from "./assets/pfote.svg?react";
import IconPawSmall from "./assets/pfoten-klein.svg?react";
import IconWoodPile from "./assets/polter.svg?react";
import IconWheelLoader from "./assets/radlader.svg?react";
import IconCrawler from "./assets/raupenbagger.svg?react";
import IconSafety from "./assets/rettungspunkt.svg?react";
import IconSprayCan from "./assets/spraydose.svg?react";
import IconTurningPoint from "./assets/wendepunkt.svg?react";
import IconDrone from "./assets/drohne.svg?react";
import IconDroneCapture from "./assets/drohnenaufnahme.svg?react";
import IconTractor from "./assets/rueckewagen.svg?react";
import IconCollectionPoint from "./assets/sammelstelle.svg?react";
import IconWaterSource from "./assets/wasserquelle.svg?react";
import IconGather from "./assets/einsammeln.svg?react"
import IconRefurbish from "./assets/aufarbeiten.svg?react";
import { useMemo } from "react";
import styles from "./icons.module.css";

/**
 * symbols
 */
export const symbols = [
	"apple",
	"alert",
	"campfire",
	"chainsaw",
	"crawler",
	"deciduous_tree",
	"axe",
	"fire",
	"fox",
	"harvester",
	"beetle",
	"no_beetle",
	"paw",
	"paw_small",
	"perch_front",
	"perch_lateral",
	"questionmark",
	"softwood",
	"spraycan",
	"trash",
	"tree_trunk",
	"wheel_loader",
	"woodpile",
	"tractor",
	"water_source",
];

/**
 * returns icon component
 * depending on name
 * @param name - String
 */
function getIconComponentForName(name?: string) {
	let identifier = name?.toLowerCase();
	if (name?.includes("icon_")) {
		identifier = identifier?.split("icon_")[1];
	}
	switch (identifier) {
		case "apple":
			return IconApple;
		case "alert":
		case "alert_triangle":
			return IconAlert;
		case "beobachten":
		case "watch":
			return IconWatch;
		case "campfire":
			return IconCampfire;
		case "chainsaw":
			return IconChainsaw;
		case "crawler":
			return IconCrawler;
		case "deciduous_tree":
			return IconDeciduousTree;
		case "axe":
		case "faellen":
			return IconAxe;
		case "fire":
			return IconFire;
		case "fox":
			return IconFox;
		case "harvester":
			return IconHarvester;
		case "perch_front":
			return IconPerchFront;
		case "perch_lateral":
			return IconPerchLaterally;
		case "beetle":
		case "kaeferbaum":
			return IconBeetle;
		case "no_beetle":
			return IconNoBeetle;
		case "note":
			return IconNote;
		case "paw":
			return IconPaw;
		case "paw_small":
			return IconPawSmall;
		case "questionmark":
			return IconQuestionMark;
		case "safety":
			return IconSafety;
		case "softwood":
			return IconSoftwood;
		case "spraycan":
			return IconSprayCan;
		case "trash":
			return IconTrash;
		case "tree_trunk":
			return IconTreeTrunk;
		case "turning_point":
			return IconTurningPoint;
		case "wheel_loader":
			return IconWheelLoader;
		case "woodpile":
			return IconWoodPile;
		case "drone":
			return IconDrone;
		case "drone_capture":
			return IconDroneCapture;
		case "tractor":
			return IconTractor;
		case "einsammeln":
		case "gather":
			return IconGather;
		case "sammelstelle":
		case "collect":
			return IconCollectionPoint;
		case "aufarbeiten":
		case "refurbish":
			return IconRefurbish;
		case "water_source":
			return IconWaterSource;
		default: {
			return IconQuestionMark;
		}
	}
}

/**
 *
 * @param iconName - string | undefined
 * @param width - number | undefined
 * @param height - number | undefined
 * @constructor
 */
export default function CategoryIcon({
	iconName,
	width,
	height,
}: {
	iconName?: string;
	width?: number;
	height?: number;
}) {
	const Icon = useMemo(() => {
		return getIconComponentForName(iconName);
	}, [iconName]);

	return (
		<Icon
			className={styles.icon}
			width={width ?? 24}
			height={height ?? 24}
			fill={"var(--text)"}
			stroke={"var(--text)"}
			color={"var(--text)"}
		/>
	);
}
