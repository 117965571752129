import useLocalStorage from "use-local-storage";
import { useEffect, useMemo } from "react";
import { createTheme, ThemeProvider } from "@mui/material";

/**
 * Available theme options.
 */
export const themes = ["dark", "light"];

/**
 * Dark theme configuration.
 */
const darkTheme = createTheme({
	palette: {
		mode: "dark",
		grey: {
			"900": "#383838",
			A100: "#464646",
		},
		primary: {
			main: "#00987B",
		},
	},
});

/**
 * Light theme configuration.
 */
const lightTheme = createTheme({
	palette: {
		mode: "light",
		grey: {
			"900": "#a0a0a0",
			A100: "#c0c0c0",
		},
		primary: {
			main: "#00987B",
		},
	},
});

/**
 * Component to wrap the application with the selected theme.
 * @param children - The child components.
 * @returns JSX element wrapping the children with the selected theme.
 */
export function ThemeWrapper({ children }: { children: JSX.Element }) {
	// Get the default theme based on the user's system preference
	const defaultDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
	// Retrieve the selected theme from local storage or use the default
	const [theme] = useLocalStorage("theme", defaultDark ? "dark" : "light");
	// Memoize the MUI theme based on the selected theme
	const muiTheme = useMemo(() => {
		return theme === "dark" ? darkTheme : lightTheme;
	}, [theme]);

	// Update the theme attribute of the HTML body element
	useEffect(() => {
		document.documentElement.setAttribute("data-theme", theme);
	}, [theme]);

	// Render the themed application
	return (
		<div id="body" data-theme={theme}>
			<ThemeProvider theme={muiTheme}>
				{children}
			</ThemeProvider>
		</div>
	);
}

/**
 * Custom hook to manage the application's theme.
 * @returns An object containing the current theme and a function to set the theme.
 */
export default function useCssTheme() {
	// Get the default theme based on the user's system preference
	const defaultDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
	// Retrieve the selected theme from local storage or use the default
	const [theme, setTheme] = useLocalStorage("theme", defaultDark ? "dark" : "light");

	/**
	 * Function to set the application theme.
	 * @param theme - The theme to set ("dark" or "light").
	 */
	function handleSetTheme(theme: string) {
		// Update the theme attribute of the HTML body element
		document.querySelector("#body")?.setAttribute("data-theme", theme);
		// Set the theme in local storage
		setTheme(theme);
		// TODO: Remove this, just a quick hack
		window.location.reload();
	}

	// Return the current theme and the function to set the theme
	return { theme, setTheme: handleSetTheme };
}
