import { alpha, Menu, MenuProps, styled } from "@mui/material";

/**
 * StyledMenu Component
 *
 * A styled version of the MUI Menu component with customized styles.
 *
 * @component
 * @param {MenuProps} props - The properties of the StyledMenu component, inherited from MUI Menu.
 *
 * @returns {JSX.Element} - The rendered StyledMenu component.
 *
 * @example
 * // Usage of StyledMenu component
 * const MyCustomMenu = () => {
 *   return (
 *     <StyledMenu
 *       anchorEl={anchorElement}
 *       open={isMenuOpen}
 *       onClose={handleMenuClose}
 *     >
 *       {/* Menu items go here *\/}
 *     </StyledMenu>
 *   );
 * };
 */
const StyledMenu = styled((props: MenuProps) => (
	<Menu
		elevation={0}
		transformOrigin={{
			vertical: "top",
			horizontal: "left",
		}}
		{...props}
	/>
))(({ theme }) => ({
	"& .MuiPaper-root": {
		borderRadius: 6,
		minWidth: "400px",
		color: theme.palette.text.primary,
		backgroundColor: theme.palette.background.default,
		boxShadow:
			"rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
		"& .MuiMenu-list": {
			padding: "4px 0",
		},
		"& .MuiMenuItem-root": {
			"& .MuiSvgIcon-root": {
				fontSize: 18,
				color: theme.palette.text.primary,
				marginRight: theme.spacing(1.5),
			},
			"&:active": {
				backgroundColor: alpha(
					theme.palette.primary.main,
					theme.palette.action.selectedOpacity
				),
			},
		},
	},
}));

export default StyledMenu;
