import {Box, Button, Input, Switch, Typography} from "@mui/material";
import {ReactNode, useEffect, useState} from "react";
import styles from "./filter.module.css";
import {useTranslation} from "react-i18next";
import {AnimatePresence, motion} from "framer-motion";
import {useAppSelector} from "../../store/hooks";
import EntityCard from "../../components/cards/entity-card";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {FilterOptions} from "../../types/filter-options";
import {de, enUS} from "date-fns/locale";
import {selectAllElements} from "../../store/slices";

/**
 * Animated wrapper component for sections with motion animation.
 * @param {open} open - Indicates whether the section is open or not.
 * @param {children} children - Child components to render within the section.
 */
function AnimatedSection({
	open,
	children,
}: {
	open: boolean;
	children: ReactNode;
}) {
	return (
		<AnimatePresence initial={open}>
			{open && (
				<motion.section
					key="content"
					initial="collapsed"
					animate="open"
					exit="collapsed"
					variants={{
						open: { opacity: 1, height: "auto" },
						collapsed: { opacity: 0, height: 0 },
					}}
					transition={{ duration: 0.4, ease: [0.04, 0.62, 0.23, 0.98] }}
					className={styles.filter}
				>
					{children}
				</motion.section>
			)}
		</AnimatePresence>
	);
}

const initialState = {
	...{
		contentFilter: {
			on: false,
			inName: "",
			notInName: "",
			inComment: "",
			notInComment: "",
		},
		areaFilter: {
			on: false,
			selectedIds: new Set<string>(),
		},
		categoryFilter: {
			on: false,
			selectedIds: new Set<string>(),
		},
		dateFilter: {
			on: false,
			from: new Date(),
			until: new Date(),
		},
	},
};

/**
 * Component for rendering a filter section with various filter options.
 * @param {onChange} onChange - Callback function invoked when filter options change.
 * @param {onSubmit} onSubmit - Callback function invoked when applying filter options.
 * @param {onReset} onReset - Callback function invoked when resetting filter options.
 * @param {hideTitle} hideTitle - Indicates whether to hide the title section.
 */
export default function FilterSection({ onChange, onSubmit, onReset, hideTitle }: { onChange?: (filterOptions: FilterOptions) => void, onSubmit: (filterOptions: FilterOptions) => void, onReset: () => void, hideTitle?: boolean }) {
	const { t, i18n } = useTranslation();
	const [contentFilter, setContentFilter] = useState({
		...initialState.contentFilter,
	});
	const [areaFilter, setAreaFilter] = useState<{
		on: boolean;
		selectedIds: Set<string>;
	}>({ ...initialState.areaFilter });
	const [categoryFilter, setCategoryFilter] = useState<{
		on: boolean;
		selectedIds: Set<string>;
	}>({ ...initialState.categoryFilter });
	const [dateFilter, setDateFilter] = useState({ ...initialState.dateFilter });
	const { areas, categories } = useAppSelector(selectAllElements);

	useEffect(() => {
		onChange && onChange(formatFilterOptions());
	}, [contentFilter, areaFilter, categoryFilter, dateFilter]);

	/**
	 * Formats the current filter options into the required format.
	 * @returns {FilterOptions} Formatted filter options.
	 */
	function formatFilterOptions(): FilterOptions {
		return {
			filterArea: areaFilter.on,
			filterCategory: categoryFilter.on,
			filterContent: contentFilter.on,
			filterDate: dateFilter.on,
			filterImageLayer: false,
			filterNotes: false,
			includeFulltext: contentFilter.inComment,
			includeTitle: contentFilter.inName,
			includedAreas: [],
			includedAreasFireStoreUid: [...areaFilter.selectedIds],
			includedCategories: [],
			includedCategoriesFirestoreUid: [...categoryFilter.selectedIds],
			includedImageLayer: [],
			includedImageLayerFirestoreUid: [],
			includedNotes: [],
			includedNotesFireStoreUid: [],
			until: dateFilter.until.getTime(),
			from: dateFilter.from.getTime(),
			updatedAt: new Date().getTime(),
		};
	}

	return (
		<Box className={!hideTitle ? styles.filterContainer : ""}>
			{!hideTitle && <Box className={styles.applySection}>
				<Typography>{t("filter")}</Typography>
				<Typography>{t("filterSection.showSpecificNotes")}</Typography>
				<Button
					variant="contained"
					onClick={() => {
						onSubmit(formatFilterOptions());
					}}
				>
					{t("apply")}
				</Button>
				<Button
					className={styles.resetButton}
					onClick={() => {
						setContentFilter({ ...initialState.contentFilter });
						setAreaFilter({ ...initialState.areaFilter });
						setCategoryFilter({ ...initialState.categoryFilter });
						setDateFilter({ ...initialState.dateFilter });
						onReset();
					}}
				>
					{t("reset")}
				</Button>
			</Box>}
			<Box className={styles.filterSection}>
				<Box>
					<Box>
						<Typography>{t("filterSection.requiredContent")}</Typography>
						<Switch
							checked={contentFilter.on}
							onChange={(event, checked) =>
								setContentFilter({ ...contentFilter, on: checked })
							}
						/>
					</Box>
					<AnimatedSection open={contentFilter.on}>
						{Object.keys(contentFilter)
							.filter((key) => key !== "on")
							.map((key, index) => (
								<Input
									key={index}
									className={styles.filterInput}
									placeholder={t(`sidebar.${key}`).toString()}
									value={(contentFilter as any)[key]}
									onChange={(event) =>
										setContentFilter({
											...contentFilter,
											[key]: event.target.value,
										})
									}
								/>
							))}
					</AnimatedSection>
				</Box>
				<Box>
					<Box>
						<Typography>{t("filterSection.selectAreas")}</Typography>
						<Switch
							checked={areaFilter.on}
							onChange={(event, checked) =>
								setAreaFilter({ ...areaFilter, on: checked })
							}
						/>
					</Box>
					<AnimatedSection open={areaFilter.on}>
						<ul className={styles.itemList}>
							{areas.map((a) => (
								<EntityCard
									key={a.firestoreUid}
									item={a}
									checked={areaFilter.selectedIds.has(a.firestoreUid)}
									onCheck={(checked) => {
										if (checked) {
											areaFilter.selectedIds.add(a.firestoreUid);
										} else {
											areaFilter.selectedIds.delete(a.firestoreUid);
										}
										setAreaFilter({
											...areaFilter,
											selectedIds: new Set([...areaFilter.selectedIds]),
										});
									}}
								/>
							))}
						</ul>
					</AnimatedSection>
				</Box>
				<Box>
					<Box>
						<Typography>{t("filterSection.selectCategories")}</Typography>
						<Switch
							checked={categoryFilter.on}
							onChange={(event, checked) =>
								setCategoryFilter({ ...categoryFilter, on: checked })
							}
						/>
					</Box>
					<AnimatedSection open={categoryFilter.on}>
						<ul className={styles.itemList}>
							{categories.map((c) => (
								<EntityCard
									key={c.firestoreUid}
									item={c}
									checked={categoryFilter.selectedIds.has(c.firestoreUid)}
									onCheck={(checked) => {
										if (checked) {
											categoryFilter.selectedIds.add(c.firestoreUid);
										} else {
											categoryFilter.selectedIds.delete(c.firestoreUid);
										}
										setCategoryFilter({
											...categoryFilter,
											selectedIds: new Set([...categoryFilter.selectedIds]),
										});
									}}
								/>
							))}
						</ul>
					</AnimatedSection>
				</Box>
				<Box>
					<Box>
						<Typography>{t("filterSection.selectDateRange")}</Typography>
						<Switch
							checked={dateFilter.on}
							onChange={(event, checked) =>
								setDateFilter({ ...dateFilter, on: checked })
							}
						/>
					</Box>
					{/* TODO: Bei Datepicker Werten auf 00:00 kontrollieren */}
					<AnimatedSection open={dateFilter.on}>
						<LocalizationProvider adapterLocale={i18n.resolvedLanguage === "de" ? de : enUS } dateAdapter={AdapterDateFns}>
							<DatePicker
								label={t("filterSection.earliest")}
								value={dateFilter.from}
								onChange={(value) =>
									value !== null &&
									setDateFilter({ ...dateFilter, from: value })
								}
							/>
							<DatePicker
								label={t("filterSection.latest")}
								value={dateFilter.until}
								onChange={(value) =>
									value !== null &&
									setDateFilter({ ...dateFilter, until: value })
								}
							/>
						</LocalizationProvider>
					</AnimatedSection>
				</Box>
			</Box>
		</Box>
	);
}
