import {useAuth} from "./use-auth";
import {useLocation} from "react-router";
import {useRouter} from "./use-router";
import {useEffect} from "react";
import {User} from "firebase/auth";

export default function useRequireAuth(redirectUrl = "/login") {
	const { user } = useAuth();
	const currentLocation = useLocation();
	const {navigate} = useRouter();
	// If auth.user is false that means we're not
	// logged in and should redirect.
	useEffect(() => {
		if (user === null) {
			navigate( (currentLocation.state && currentLocation.state.from) ? currentLocation.state.from : redirectUrl);
		}
	}, [user]);

	return user as User;
}
