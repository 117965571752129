import imageCache from "../../lib/image-cache";
import { useRef } from "react";
import { hash } from "../../lib/model/utils/strings";
import styles from "./loading.module.css";
import { useLiveQuery } from "dexie-react-hooks";
import db from "../../lib/db";

/**
 * SuspenseImage Component
 *
 * A component for displaying images with built-in suspense loading and caching.
 *
 * @component
 * @param {Object} props - The properties of the SuspenseImage component.
 * @param {string} props.src - The source URL of the image.
 * @param {string} props.alt - The alternative text for the image.
 * @param {string} [props.className] - Additional classes to be applied to the image element.
 * @param {...any} [props.rest] - Additional properties to be spread onto the underlying image element.
 *
 * @returns {JSX.Element} - The rendered SuspenseImage component.
 *
 * @example
 * // Usage of SuspenseImage component
 * <SuspenseImage
 *   src="https://example.com/image.jpg"
 *   alt="A beautiful image"
 *   className="custom-class"
 *   onClick={handleImageClick}
 * />
 */
export default function SuspenseImage({
	src,
	alt,
	className,
	...rest
}: {
	src: string;
	alt: string;
	className?: string,
	[x: string]: any;
}): JSX.Element {
	const ref = useRef<HTMLImageElement>(null);
	/*const dataUrl = localStorage.getItem(String(hash(src)));*/
	let dataUrl: string | undefined = "";
	useLiveQuery(() =>
		db.previewImages.get(String(hash(src))).then((res) => {
			dataUrl = res?.url;
		})
	);

	function handleLoad() {
		if (dataUrl === "" || !ref.current || dataUrl) return;
		const canvas: HTMLCanvasElement = document.createElement(
			"CANVAS"
		) as HTMLCanvasElement;
		const ctx = canvas.getContext("2d");
		if (!ctx) return;
		try {
			canvas.height = ref.current.naturalHeight;
			canvas.width = ref.current.naturalWidth;
			ctx.drawImage(ref.current, 0, 0);
			const imageDataURL = canvas.toDataURL("image/png");
			void db.previewImages.add(
				{ id: String(hash(src)), url: imageDataURL },
				String(hash(src))
			);
			/*localStorage.setItem(String(hash(src)), imageDataURL);*/
		} catch (err) {
			console.error(err);
		}
	}

	!dataUrl && imageCache.read(src);

	return (
		<img
			className={`${styles.suspenseImage} ${className}`}
			crossOrigin="anonymous"
			ref={ref}
			onLoad={handleLoad}
			alt={alt}
			src={dataUrl ? dataUrl : src}
			{...rest}
		/>
	);
}
