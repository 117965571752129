import MapPosition from "../../../types/map-position";
import { encode } from "@googlemaps/polyline-codec";
import simplify from "simplify-js";
import {bboxPolygon, simplify as turfSimplify} from "@turf/turf";
import {getGeoJsonForGrid} from "../../../features/map/items/grids";
import {selectAreaById} from "../../../store/slices/area-slice";
import store from "../../../store/store";
import Grid from "../../../types/entities/grid";

// The actual byte limit is 8192, but we
const URI_BYTE_LIMIT = 8000;

export enum PreviewType {
	Marker,
	Polygon,
	Line,
	Grid,
}

interface PreviewOptions {
	style?: string;
	width?: number;
	height?: number;
	color?: string;
	markerNumber?: number;
	grid?: Grid;
}

/**
 * Generates a static map preview URL based on provided points, type, and options.
 * @param points - Array of MapPosition objects representing geographical coordinates.
 * @param type - PreviewType enum specifying the type of preview (Marker, Polygon, Line, Grid).
 * @param options - Optional preview options.
 * @returns URL string for the static map preview.
 */
export default function getStaticMapPreview(
	points: MapPosition[],
	type: PreviewType,
	options?: PreviewOptions
): string {
	let uriComponent: string | null = null;
	let padding = 0;
	const style = options?.style ?? "satellite-streets-v12";
	const width = options?.width ?? 300;
	const height = options?.height ?? 300;
	const color = options?.color ? options.color : "#208C20";
	const markerNumber = options?.markerNumber;
	switch (type) {
		case PreviewType.Polygon: {
			let coordinates: Array<Array<Array<number>>> =
				[points.map((p) => [Number.parseFloat(p.longitude.toFixed(5)), Number.parseFloat(p.latitude.toFixed(5))])];
			const geojson = {
				type: "Feature",
				properties: {
					fill: color,
					"fill-opacity": "0.6",
					"stroke-opacity": "0.0",
				},
				geometry: {
					type: "Polygon",
					coordinates,
				},
			};
			uriComponent = `geojson(${encodeURIComponent(JSON.stringify(geojson))})`;
			coordinates = [simplify(coordinates[0].map((c) => ({ x: c[0], y: c[1] })), 0.001).map((p) => ([p.y, p.x]))];

			while (new Blob([uriComponent]).size >= URI_BYTE_LIMIT) {
				// delete every 2nd coordinate from preview image to shrink blob size
				const coordNum = geojson.geometry.coordinates[0].length;
				const newCoords = new Array(Math.floor(coordNum / 2));
				newCoords[0] = geojson.geometry.coordinates[0][0];
				for(let newInd = 1, oldInd = 1; oldInd < coordNum - 1; oldInd += 2, newInd++) {
					newCoords[newInd] = geojson.geometry.coordinates[0][oldInd];
				}
				newCoords.push(geojson.geometry.coordinates[0][coordNum - 1]);
				geojson.geometry.coordinates[0] = newCoords;
				uriComponent = `geojson(${encodeURIComponent(JSON.stringify(geojson))})`;
			}
			padding = 20;
			break;
		}
		case PreviewType.Line: {
			let coordinates: Array<Array<number>> = points.map((p) => [
				Number.parseFloat(p.latitude.toFixed(5)),
				Number.parseFloat(p.longitude.toFixed(5))
			]);
			uriComponent = encodeURIComponent(
				`pin-s-a+9ed4bd(${coordinates[0][1]},${
					coordinates[0][0]
				}),pin-s-b+000(${coordinates[coordinates.length - 1][1]},${
					coordinates[coordinates.length - 1][0]
				}),path-5+f44-0.5(${encode(coordinates)})`
			);
			if (new Blob([uriComponent]).size >= URI_BYTE_LIMIT) {
				coordinates = simplify(coordinates.map((c) => ({ x: c[0], y: c[1] })), 0.001).map((p) => ([p.x, p.y]));
				uriComponent = encodeURIComponent(
					`pin-s-a+9ed4bd(${coordinates[0][1]},${
						coordinates[0][0]
					}),pin-s-b+000(${coordinates[coordinates.length - 1][1]},${
						coordinates[coordinates.length - 1][0]
					}),path-5+f44-0.5(${encode(coordinates)})`
				);
			}
			break;
		}
		case PreviewType.Marker:
			if (markerNumber !== undefined) {
				uriComponent = encodeURIComponent(
					`pin-s-${markerNumber}+${color.split("#")[1]}(${
						points[0].longitude
					},${points[0].latitude})`
				);
			} else {
				uriComponent = encodeURIComponent(
					`pin-s+36a7a3(${points[0].longitude},${points[0].latitude})`
				);
			}
			padding = 50;
			break;
		case PreviewType.Grid:
			const grid = options?.grid;
			if (!grid) return "";
			const geojson = turfSimplify(getGeoJsonForGrid(grid), { tolerance: 1 });
			geojson.features = geojson.features.map((f) => ({...f, properties: { fill: grid.color } }));
			let url = `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v12/static/geojson(${encodeURIComponent(JSON.stringify(geojson))})/auto/300x300?padding=20&access_token=${
				import.meta.env.VITE_MAPBOX_ACCESS_TOKEN
			}`;
			if (new Blob([url]).size >= 8192) {
				if (grid.areaUid) {
					const area = selectAreaById(store.getState(), grid.areaUid);
					if (area) {
						return getStaticMapPreview(area.points, PreviewType.Polygon, {
							...options,
							color: grid.color,
						})
					} else {
						const bboxPoly = bboxPolygon(geojson.bbox!);
						url = `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v12/static/geojson(${encodeURIComponent(JSON.stringify(bboxPoly))})/auto/300x300?padding=20&access_token=${
							import.meta.env.VITE_MAPBOX_ACCESS_TOKEN
						}`
					}
				} else {
					const bboxPoly = bboxPolygon(geojson.bbox!);
					url = `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v12/static/geojson(${encodeURIComponent(JSON.stringify(bboxPoly))})/auto/300x300?padding=20&access_token=${
						import.meta.env.VITE_MAPBOX_ACCESS_TOKEN
					}`
				}
			}
			return url;
	}
	return `https://api.mapbox.com/styles/v1/mapbox/${style}/static/${uriComponent}/auto/${width}x${height}?padding=${padding}&access_token=${
		import.meta.env.VITE_MAPBOX_ACCESS_TOKEN
	}`;
}
