import {cloneElement} from "react";
import {useLocation, useRoutes} from "react-router";
import Root from "./routes/root";
import ErrorPage from "./pages/error";
import ForwardAuthenticated from "./routes/utils/forward-authenticated";
import LoginRoute from "./routes/user/login";
import Protected from "./routes/utils/protected";
import LogoutRoute from "./routes/user/logout";
import DashboardRoute from "./routes/dashboard";
import MapRoute from "./routes/map";
import {AnimatePresence} from "framer-motion";
import ShowcaseRoute from "./routes/showcase";
import {ErrorBoundary} from "@sentry/react";
import PageError from "./pages/error/page-error";
import PurgeAccountRoute from "./routes/user/purge";
import FallbackComponent from "./lib/sentry/fallback-component";


/**
 * Main App Component for React Component Tree.
 * Defines a number of routes to expose as endpoints using react-router.
 * Routes can be added by changing the children array provided to useRoutes.
 * TODO: Change paths to route to Enum values for better referencing throughout App
 * @constructor
 */
export default function App() {
	const element = useRoutes([
		{
			path: "/",
			element: <Root />,
			errorElement: <ErrorPage />,
			children: [
				{
					path: "login",
					element: (
						<ForwardAuthenticated>
							<LoginRoute />
						</ForwardAuthenticated>
					),
				},
				{
					path: "logout",
					element: (
						<Protected>
							<LogoutRoute />
						</Protected>
					),
				},
				{
					path: "dashboard",
					element: (
						<Protected>
							<DashboardRoute />
						</Protected>
					),
				},
				{
					path: "map",
					element: (
						<Protected>
							<MapRoute />
						</Protected>
					),
				},
				{
					path: "showcase/:tokenId",
					element: <ShowcaseRoute />,
				},
				{
					path: "purgeAccount",
					element: <Protected>
						<PurgeAccountRoute />
					</Protected>
				},
				{
					path: "*",
					element: <PageError />
				}
			],
		},
	]);
	const location = useLocation();

	if (!element) return null;

	return (
		<AnimatePresence mode="wait">
			<ErrorBoundary fallback={FallbackComponent} showDialog>
				{cloneElement(element, { key: location.pathname })}
			</ErrorBoundary>
		</AnimatePresence>
	);
}
