import {Box, IconButton, Input} from "@mui/material";
import {Close, Search as SearchIcon} from "@mui/icons-material";
import {Suspense, useRef, useState} from "react";
import {selectAllElements} from "../../store/slices";
import {useAppSelector} from "../../store/hooks";
import Entities from "../../types/entities";
import {selectCategoryById} from "../../store/slices/category-slice";
import store from "../../store/store";
import {selectAreaById} from "../../store/slices/area-slice";
import EntityCard from "../cards/entity-card";
import styles from "./search.module.css";
import {useLocation, useNavigate} from "react-router";
import {useTranslation} from "react-i18next";
import useSidebarParams from "../../features/sidebar/use-sidebar-params";

/**
 * Search Component
 *
 * A component for handling search functionality and displaying search results.
 *
 * @component
 * @param {Object} props - The properties of the Search component.
 * @param {() => void} [props.onFocus] - A function to be called when the search input is focused.
 * @param {() => void} [props.onBlur] - A function to be called when the search input loses focus.
 *
 * @returns {JSX.Element} - The rendered Search component.
 *
 * @example
 * // Usage of Search component
 * <Search
 *   onFocus={() => console.log('Search input focused')}
 *   onBlur={() => console.log('Search input blurred')}
 * />
 */
export default function Search({
	onFocus,
	onBlur,
}: {
	onFocus?: () => void;
	onBlur?: () => void;
}) {
	const { t } = useTranslation();
	const input = useRef<HTMLInputElement>(null);
	const [searchInput, setSearchInput] = useState("");
	const data = useAppSelector(selectAllElements);
	const navigate = useNavigate();
	const location = useLocation();
	const { setDetail, setTab } = useSidebarParams();

	function getDataBySearchInput() {
		const everything = [
			...data.areas,
			...data.categories,
			...data[Entities.ImageLayer],
			...data[Entities.LayerGroup],
			...data.notes,
			...data.paths,
			...data[Entities.TurningPoints],
			...data.grid
		];
		return everything.filter((el) => {
			if (
				"name" in el &&
				el.name &&
				el.name.toLowerCase().includes(searchInput.toLowerCase())
			)
				return true;
			else if (
				"description" in el &&
				el.description &&
				el.description.toLowerCase().includes(searchInput.toLowerCase())
			)
				return true;
			else if ("categoryUid" in el) {
				const category = selectCategoryById(store.getState(), el.categoryUid);
				if (
					category &&
					category.name.toLowerCase().includes(searchInput.toLowerCase())
				)
					return true;
			} else if ("areaUid" in el && el.areaUid !== undefined) {
				// @ts-ignore
				const area = selectAreaById(store.getState(), el.areaUid);
				if (
					area &&
					area.name.toLowerCase().includes(searchInput.toLowerCase()) &&
					area.name.toLowerCase().includes(searchInput.toLowerCase())
				)
					return true;
			}
			return false;
		});
	}

	return (
		<Box className={styles.searchContainer}>
			<Input
				onBlur={() => searchInput === "" && onBlur && onBlur()}
				onFocus={onFocus}
				inputRef={input}
				disableUnderline={true}
				className={styles.searchInput}
				value={searchInput}
				onChange={(event) => setSearchInput(event.target.value)}
				inputMode={"text"}
				placeholder={t("search").toString()}
			/>
			{searchInput !== "" && (
				<Box className={styles.resultContainer}>
					<Suspense>
						<ul>
							{getDataBySearchInput().map((el, index) => (
								<EntityCard
									key={index}
									item={el}
									onClick={(id, type) => {
										setSearchInput("");
										onBlur && onBlur();
										setTab(type);
										setDetail(id);
										if (location.pathname === "showcase") return;
										location.pathname !== "/map" && navigate("/map");
									}}
								/>
							))}
						</ul>
					</Suspense>
				</Box>
			)}
			{searchInput !== "" ? (
				<IconButton
					onClick={() => {
						setSearchInput("");
						input.current?.blur();
						onBlur && onBlur();
					}}
				>
					<Close />
				</IconButton>
			) : (
				<IconButton onClick={() => input.current?.focus()}>
					<SearchIcon />
				</IconButton>
			)}
		</Box>
	);
}
