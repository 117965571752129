import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import License from "../../types/license";
import { ShowcaseToken } from "../../types/showcase-token";
import SharePartner from "../../types/share-partner";

/** Represents the state of the dashboard. */
interface DashboardState {
	/** The license information. */
	license: License | null;
	/** Array of showcase tokens. */
	tokens: ShowcaseToken[];
	/** Array of share partners. */
	sharePartners: SharePartner[];
}

/** Initial state for the dashboard slice. */
const initialState: DashboardState = {
	license: null,
	tokens: [],
	sharePartners: [],
};

/** Dashboard slice for managing dashboard-related state. */
const dashboardSlice = createSlice({
	name: "dashboard",
	initialState,
	reducers: {
		/**
		 * Initializes the dashboard state with license, tokens, and share partners.
		 * @param state - The current dashboard state.
		 * @param action - The payload action containing license, tokens, and share partners.
		 */
		initDashboard(
			state,
			action: PayloadAction<{
				license: License;
				tokens: ShowcaseToken[];
				sharePartners: SharePartner[];
			}>
		) {
			state.license = action.payload.license;
			state.tokens = action.payload.tokens;
			state.sharePartners = action.payload.sharePartners;
		},

		/**
		 * Sets the showcase tokens.
		 * @param state - The current dashboard state.
		 * @param action - The payload action containing the showcase tokens.
		 */
		setTokens(state, action: PayloadAction<ShowcaseToken[]>) {
			state.tokens = action.payload;
		}
	},
});

export const { initDashboard, setTokens } = dashboardSlice.actions;

export default dashboardSlice.reducer;
