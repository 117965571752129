/* eslint-disable react/prop-types */
import {Popup} from "react-map-gl";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {DirectionsWalk, Public, QuestionMark, Room, SwapVert,} from "@mui/icons-material";
import {useMemo} from "react";
import Entities from "../../../types/entities";
import Note from "../../../types/entities/note";
import Area from "../../../types/entities/area";
import {TbDrone, TbPolygon} from "react-icons/tb";
import {IconType} from "react-icons";
import Path from "../../../types/entities/path";
import TurningPoint from "../../../types/entities/turning-point";
import {closePopover} from "../map-slice";
import Popover from "./popover-element";
import ImageLayer from "../../../types/entities/image-layer";
import LayerGroup from "../../../types/entities/layer-group";
import {useTranslation} from "react-i18next";
import useSidebarParams from "../../sidebar/use-sidebar-params";
import {selectElementForEntityId} from "../../../store/slices";
import store from "../../../store/store";
import { selectCategoryById } from "../../../store/slices/category-slice";

/**
 * Represents the properties required for rendering a PopoverWrapper component.
 */
interface Props {
	lng: number;
	lat: number;
	title: string;
	subtitle?: string;
	Icon: IconType | any;
}

/**
 * PopoverWrapper component responsible for rendering a popover on the map.
 */
export default function PopoverWrapper() {
	const {t} = useTranslation();
	const popoverInfo = useAppSelector((state) => state.map.popoverInfo);
	const {setTab, setDetail} = useSidebarParams();
	const element = useAppSelector((state) =>
		popoverInfo !== null
			? selectElementForEntityId(state, popoverInfo.id, popoverInfo.type)
			: undefined
	);
	const dispatch = useAppDispatch();
	const props: Props | undefined = useMemo(() => {
		if (!popoverInfo || !element) return;
		let lng = popoverInfo.lng ?? 0,
			lat = popoverInfo.lat ?? 0,
			title = "",
			subtitle = undefined,
			Icon: IconType | any = QuestionMark;
		switch (popoverInfo.type) {
			case Entities.Notes:
				const note = element as Note;
				lng = note.lng;
				lat = note.lat;
				title = note.name;
				Icon = Room;
				subtitle = t("model.category") + ": " + selectCategoryById(store.getState(), note.categoryUid)?.name;
				break;
			case Entities.TurningPoints:
				const tp = element as TurningPoint;
				lng = tp.long;
				lat = tp.lat;
				title = tp.name || String(tp.number);
				Icon = SwapVert;
				break;
			case Entities.Areas:
				title = (element as Area).name;
				Icon = TbPolygon;
				break;
			case Entities.Paths:
				const path = element as Path;
				title = path.name
					? path.name
					: t(`path.${path.type}`).toString();
				Icon = DirectionsWalk;
				break;
			case Entities.ImageLayer:
				title = (element as ImageLayer)["file-name"];
				Icon = TbDrone;
				break;
			case Entities.LayerGroup:
				title = (element as LayerGroup).name;
				Icon = Public;
				if (popoverInfo.childIndex !== undefined) {
					subtitle = (element as LayerGroup).layers[popoverInfo.childIndex]
						.name;
				}
				break;
			default:
				return undefined;
		}
		return { lng, lat, title, subtitle, Icon };
	}, [popoverInfo, element]);

	return popoverInfo && !popoverInfo.closed && element && props ? (
		<Popup
			closeButton={false}
			closeOnClick={false}
			closeOnMove={false}
			anchor="top"
			longitude={props.lng}
			latitude={props.lat}
		>
			<Popover
				onMoreClicked={() => {
					setTab(popoverInfo.type);
					setDetail(element.firestoreUid);
					dispatch(closePopover());
				}}
				title={props.title}
				Icon={props.Icon}
				subtitle={props.subtitle}
			/>
		</Popup>
	) : (
		<></>
	);
}
