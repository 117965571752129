import {createAsyncThunk, createEntityAdapter, createSlice,} from "@reduxjs/toolkit";
import type {RootState} from "../store";
import ImageLayer from "../../types/entities/image-layer";
import {alphabetical, bySize, chronologicalCreated, chronologicalUpdated,} from "../sort-comparers";
import {entityAsyncThunk} from "./index";
import Entities from "../../types/entities";


/**
 * This file defines the slice for the ImageLayer Entity.
 * The general layout will be the same for all entity slices.
 *
 * Adapter: Manages list of all elements for this entity and supplies standard functions like addOne and updateOne
 * AsyncThunks: Asynchronously fetches data for this collection name from firestore and populates list initially
 * Selectors: Exports reusable selectors for this slice
 * Comparators: Exports the comparators that are applicable to this entity type
 *
 * @category Slice
 */

const imageLayerAdapter = createEntityAdapter<ImageLayer>({
	selectId: (imageLayer) => imageLayer.firestoreUid,
	sortComparer: (a, b) => a["file-name"].localeCompare(b["file-name"]),
});

export const fetchImageLayers = createAsyncThunk(
	"imageLayers/fetchImageLayers",
	async (userId: string) => entityAsyncThunk(Entities.ImageLayer)(userId)
);

const initialState = imageLayerAdapter.getInitialState({
	status: "idle",
});

const imageLayerSlice = createSlice({
	name: "imageLayers",
	initialState,
	reducers: {
		addImageLayer: imageLayerAdapter.addOne,
		updateImageLayer: imageLayerAdapter.updateOne,
		removeImageLayer: imageLayerAdapter.removeOne,
		addImageLayers: imageLayerAdapter.addMany
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchImageLayers.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchImageLayers.fulfilled, (state, action) => {
				imageLayerAdapter.setAll(state, action.payload as ImageLayer[]);
				state.status = "idle";
			});
	},
});

export const { addImageLayer, updateImageLayer, removeImageLayer, addImageLayers } = imageLayerSlice.actions;

export default imageLayerSlice.reducer;

export const {
	selectAll: selectImageLayers,
	selectById: selectImageLayerById,
} = imageLayerAdapter.getSelectors<RootState>(
	(state) => state.data.imageLayers
);

const imageLayerSortComparers = [
	{ name: "Alphabetically", function: alphabetical },
	{ name: "Creation Date", function: chronologicalCreated },
	{ name: "Last Update", function: chronologicalUpdated },
	{ name: "Size", function: bySize },
];

export { imageLayerSortComparers };
