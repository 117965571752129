import {z} from "zod";
import {MetaDataSchema,} from "./meta-data";
import {latitude, longitude, MapPositionSchema} from "../map-position";
import {DomainData} from "./domain-data";

/**
 * Schema for Notes in ForestManager.
 * @category Schema
 */
export const NoteSchema = MetaDataSchema.extend({
	accuracy: z.number(),
	areaUid: z.string().optional().nullable(),
	categoryUid: z.string(),
	description: z.string(),
	name: z.string(),
	lat: latitude,
	lng: longitude,
	lastChangedBy: z.string().optional(),
	center: MapPositionSchema,
	domainData: DomainData.array().optional()
});

/**
 * Type for Notes in ForestManager.
 * Inferred from zod Schema.
 * @category Schema_Type
 */
type Note = z.infer<typeof NoteSchema>;
export default Note;

/**
 * Is-Function to check whether the parameter is actually a Note
 * @param note
 * @returns {boolean}
 */
export function isNote(note: unknown): note is Note {
	return NoteSchema.safeParse(note).success;
}
