import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useAuth } from "../../hooks/use-auth";

/**
 * Page for Logout Flow. Won't actually render any content to the user.
 * Drop-off after logout is currently set to '/login'. Change this below if necessary.
 * @constructor
 * @category Page
 */
export default function LogoutPage() {
	const { logout } = useAuth();
	const navigate = useNavigate();

	useEffect(() => {
		void logout().then(() => {
			navigate("/login", { replace: true });
		});
	}, []);

	return <></>;
}
