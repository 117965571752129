import {AppBar, Box, Drawer, IconButton, Tooltip, Typography,} from "@mui/material";
import {Add, ChevronLeft, ChevronRight, FilterList, Menu, Sort,} from "@mui/icons-material";
import TabOverview from "./tab-overview";
import ItemList from "./item-list";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import DetailView from "./detail-view";
import styles from "./sidebar.module.css";
import SidebarForm from "./form";
import {getNumberOfItemsForEntityType, getSortFunctionsByEntityType,} from "../../store/slices";
import {useEffect, useMemo, useState} from "react";
import Search from "../../components/search";
import {hideFilterBar, selectAllElementsWithFilter, showFilterBar,} from "../filter/filter-slice";
import FilterBar from "../filter";
import {FaChevronDown, FaChevronUp} from "react-icons/fa";
import {useAuth} from "../../hooks/use-auth";
import {useTranslation} from "react-i18next";
import Entities from "../../types/entities";
import {useFeedback} from "../feedback/use-feedback";
import useSidebarParams from "./use-sidebar-params";

/**
 * Width of the sidebar drawer.
 */
export const drawerWidth = 500;

/**
 * Sidebar component containing navigation and item list.
 * @returns JSX.Element
 */
export default function Sidebar() {
    // Hooks and context
    const user = useAuth().user;
    const {
        tab,
        sidebarOpen: isOpen,
        elementId,
        formOpen,
        openSidebar,
        setTab,
        setDetail,
        openForm
    } = useSidebarParams();
    const {filterBarVisible, filterOn} = useAppSelector(
        (state) => state.filter
    );
    const data = useAppSelector(selectAllElementsWithFilter);
    const numOfElements = useAppSelector((state) =>
        getNumberOfItemsForEntityType(state, tab)
    );
    const sortFunctions = useMemo(() => {
        return getSortFunctionsByEntityType(tab);
    }, [tab]);
    const [selectedSortFunctionIndex, setSelectedSortFunctionIndex] =
        useState<number>(-1);
    const [reverseSort, setReverseSort] = useState(false);
    const [searchFocused, setSearchFocused] = useState(false);
    const {setFeedback} = useFeedback();
    const dispatch = useAppDispatch();
    const {t} = useTranslation();

    useEffect(() => {
        selectedSortFunctionIndex !== -1 && setSelectedSortFunctionIndex(0);
    }, [tab]);

    return (
        <>
            <AppBar
                position="fixed"
                id={styles.appbar}
                style={searchFocused ? {width: "40vw"} : {}}
            >
                <IconButton
                    onClick={() => openSidebar()}
                    aria-label="open-drawer"
                    edge="start"
                >
                    <Menu/>
                </IconButton>
                <Search
                    onFocus={() => setSearchFocused(true)}
                    onBlur={() => setSearchFocused(false)}
                />
            </AppBar>
            <Drawer
                id={styles.drawer}
                PaperProps={{className: styles.drawerPaper}}
                variant="persistent"
                anchor="left"
                open={isOpen}
            >
                <Box>
                    {formOpen ? (
                        <SidebarForm/>
                    ) : elementId !== null ? (
                        <DetailView/>
                    ) : (
                        <ItemList
                            tab={tab}
                            numOfItems={numOfElements}
                            onItemClicked={(id, type) => {
                                setTab(type);
                                setDetail(id);
                            }}
                            sortFunction={sortFunctions[selectedSortFunctionIndex !== -1
                                ? selectedSortFunctionIndex
                                : 0]}
                            reverse={reverseSort}
                            {...data}
                            metaSection={<>
                                <Box className={styles.listButtonContainer}>
                                    {user && (
                                        <Tooltip title={t("add").toString()}>
                                            <IconButton
                                                onClick={() => {
                                                    if (tab === Entities.ImageLayer) {
                                                        setFeedback(t("feedback.cantUploadDroneImgs"), "info");
                                                        return;
                                                    } else if (tab === Entities.Notes && data.categories.length === 0) {
                                                        setFeedback(t("feedback.haveToAddCategory"), "info");
                                                        return;
                                                    }
                                                    openForm();
                                                    dispatch(hideFilterBar());
                                                }}
                                            >
                                                <Add/>
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    <Tooltip title={t("sort").toString()}>
                                        <IconButton
                                            style={selectedSortFunctionIndex !== -1
                                                ? {
                                                    backgroundColor: "var(--accent)",
                                                    color: "var(--text-on-accent)",
                                                }
                                                : {}}
                                            onClick={() => setSelectedSortFunctionIndex(
                                                selectedSortFunctionIndex === -1 ? 0 : -1
                                            )}
                                        >
                                            <Sort/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={t("filter").toString()}>
                                        <IconButton
                                            style={filterOn
                                                ? {
                                                    backgroundColor: "var(--accent)",
                                                    color: "var(--text-on-accent)",
                                                }
                                                : {}}
                                            onClick={() => {
                                                if (filterBarVisible) {
                                                    dispatch(hideFilterBar());
                                                } else {
                                                    dispatch(showFilterBar());
                                                }
                                            }}
                                        >
                                            {filterBarVisible ? (
                                                <ChevronLeft/>
                                            ) : filterOn ? (
                                                <ChevronRight/>
                                            ) : (
                                                <FilterList/>
                                            )}
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                                {selectedSortFunctionIndex !== -1 && (
                                    <Box className={styles.sortFunctions}>
                                        <Typography component={"h6"}>
                                            {t("sidebar.sort.label")}
                                        </Typography>
                                        <Box>
                                            {sortFunctions.map((sf, index) => (
                                                <span
                                                    onClick={() => selectedSortFunctionIndex === index
                                                        ? setReverseSort(!reverseSort)
                                                        : setSelectedSortFunctionIndex(index)}
                                                    key={index}
                                                    style={{
                                                        border: index === selectedSortFunctionIndex
                                                            ? "1px solid var(--accent)"
                                                            : "none",
                                                    }}
                                                >
                                                            {sf.name}
                                                    {selectedSortFunctionIndex === index && (
                                                        <span>
                                                                    {reverseSort ? (
                                                                        <FaChevronUp/>
                                                                    ) : (
                                                                        <FaChevronDown/>
                                                                    )}
                                                                </span>
                                                    )}
                                                        </span>
                                            ))}
                                        </Box>
                                    </Box>
                                )}
                            </>}/>
                        )}  
                </Box>
            </Drawer>
            <FilterBar/>
            <TabOverview/>
        </>
    );
}
