import useLogin from "./use-login";
import {useTranslation} from "react-i18next";
import {Box, Button, IconButton, InputAdornment, Link, TextField, Typography} from "@mui/material";
import styles from "./login.module.css";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useState} from "react";
import {useLocation} from "react-router";

/**
 * Renders the Login Page.
 * State logic for all form elements is outsourced to the useLogin-Hook.
 * Authentication is handled using firebase auth, default drop-off after successful sign-in is handled as callback in useLogin.
 * @constructor
 * @category Page
 */
export default function LoginPage() {
	const {
		email,
		handleEmailChange,
		password,
		handlePasswordChange,
		handleSubmit,
		handleGoogleSubmit,
		error,
		setError,
	} = useLogin();
	const [showPassword, setShowPassword] = useState(false);
	const {state} = useLocation();
	const { t } = useTranslation();

	return (
		<Box className={styles.login}>
			<Box className={styles.blur} />
			<Box className={styles.loginContainer}>
				<Box className={styles.logo}>
					<img alt={"company-logo"} src={`/images/fm-logo.png`} />
				</Box>
				<form
					onSubmit={(event) => {
						event.preventDefault();
						handleSubmit(state?.from).catch((err) => {
							setError(err.message);
						});
					}}
				>
					<TextField
						label={t("login.email")}
						placeholder={t("login.emailPrompt").toString()}
						fullWidth
						required
						value={email}
						onChange={handleEmailChange}
						className={styles.textField}
					/>
					<TextField
						label={t("login.password")}
						placeholder={t("login.passwordPrompt").toString()}
						type={showPassword ? "text" : "password"}
						fullWidth
						required
						value={password}
						onChange={handlePasswordChange}
						className={styles.textField}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={() => setShowPassword(!showPassword)}
										onMouseDown={() => setShowPassword(!showPassword)}
									>
										{showPassword ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</InputAdornment>
							)
						}}
					/>
					{error && (
						<Typography className={styles.errorText}>{error}</Typography>
					)}
					<Button type="submit" color="primary" variant="contained" fullWidth>
						{t("login.part1")}
					</Button>
				</form>
				<Box className={styles.divider}>
					<span />
					<Typography>OR</Typography>
				</Box>
				<Box className={styles.altLoginContainer}>
					<button
						onClick={(event) => {
							event.preventDefault();
							void handleGoogleSubmit();
						}}
					>
						<Box>
							<img
								width={30}
								height={30}
								src={
									"/images/google_logo.png"
								}
								alt="Google Logo"
							/>
						</Box>
						<Typography>{t("login.part2")}</Typography>
					</button>
				</Box>
				<Box className={styles.companyInfo}>
					<Typography component="p">{t("login.part3")}</Typography>
					<Typography component="p">
						{t("login.part4")}{" "}
						<Link target="_blank" href="https://forestmanager.de/kontakt/">
							{t("login.part5")}
						</Link>
					</Typography>
					<Link href="https://forestmanager.de/impressum/"> {t("login.part6")}</Link>
				</Box>
			</Box>
		</Box>
	);
}
