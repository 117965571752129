import {Box, Typography} from "@mui/material";
import {TbPolygon} from "react-icons/tb";
import {MarkerElement} from "../../features/map/items/note-markers";
import Note from "../../types/entities/note";
import Category from "../../types/entities/category";
import Area from "../../types/entities/area";
import {getRgb} from "../../lib/model/utils/colors";
import styles from "./cards.module.css";
import CardProps from "./card-props";
import Entities from "../../types/entities";
import {useTranslation} from "react-i18next";
import useSidebarParams from "../../features/sidebar/use-sidebar-params";

/**
 * card for notes
 * @param note - note: {firestoreUid: string, sharePartnerUid: string, createdAt: number, updatedAt: number, accuracy: number, categoryUid: string, description: string, name: string, lat: number, lng: number, center: {}, ...}
 * @param category - category: {firestoreUid: string, sharePartnerUid: string, createdAt: number, updatedAt: number, name: string, icon: number, hue: number, lightness: number, saturation: number, changedBy?: string | undefined, deletedAt?: number | ... 1 more ... | undefined, ...} | undefined
 * @param area - area: {firestoreUid: string, sharePartnerUid: string, createdAt: number, updatedAt: number, description: string, name: string, points: {longitude: number, latitude: number, altitude: number}[], changedBy?: string | undefined, deletedAt?: number | ... 1 more ... | undefined, version?: number | undefined, color?: string | undefined} | undefined
 * @param onClick - (id: string) => void
 * @param children -  JSX.Element | undefined
 * @constructor
 */
export default function NoteCard({
	note,
	category,
	area,
	onClick,
	children,
}: CardProps & { note: Note; category: Category | undefined; area: Area | undefined }) {
	const { t } = useTranslation();
	const { setTab, setDetail } = useSidebarParams();

	return (
		<Box className={styles.card} onClick={() => onClick(note.firestoreUid)}>
			<Box className={styles.iconContainer}>
				<span
					onClick={(event) => {
						event.stopPropagation();
						if (!category) return;
						setTab(Entities.Categories);
						setDetail(category.firestoreUid);
					}}
					className={styles.cardLink}
				>
					<MarkerElement
						color={category ? getRgb(category) : "#ffffff"}
						iconName={category && category.symbol_name ? category.symbol_name : "questionmark"}
					/>
				</span>
			</Box>
			<Box className={styles.textContainer}>
				<Typography component="h4">{note.name}</Typography>
				<Typography
					onClick={(event) => {
						event.stopPropagation();
						if (area) {
							setTab(Entities.Areas);
							setDetail(area?.firestoreUid);
						}
					}}
					className={area ? styles.cardLink : ""}
					component="p"
				>
					<TbPolygon /> {area ? area.name : t("cards.notAssignedToArea")}
				</Typography>
			</Box>
			<Box className={styles.moreContainer}>{children}</Box>
		</Box>
	);
}
