import { useEffect } from "react";
import { User } from "firebase/auth";
import initFirestoreSnapshots from "./firestore";
import store from "../../store/store";
import { initDashboard, setTokens } from "../../features/dashboard/dashboard-slice";
import { fetchLicenseDataForUser, fetchSharePartnersForUser, fetchTokens, } from "./functions";
import { auth, firestore } from "./index";
import { ShowcaseToken } from "../../types/showcase-token";
import SharePartner from "../../types/share-partner";
import { collection, onSnapshot, query } from "firebase/firestore";


/**
 * Initializes Firestore Snapshot Integration from within a react component.
 * Use this in all Routes that require userData to be loaded.
 */
export default function useInitSnapshots() {
	useEffect(() => {
		async function init(user: User) {
			await initFirestoreSnapshots(user);
			store.dispatch(
				initDashboard({
					license: (
						(await fetchLicenseDataForUser({ uid: user.uid })).data as {
							license: any;
						}
					).license ?? [],
					tokens: ((await fetchTokens()).data as { tokens: ShowcaseToken[] }).tokens ?? [],
					sharePartners: (
						(await fetchSharePartnersForUser()).data as { partners: SharePartner[] }
					).partners ?? [],
				})
			);
			const q = query(
				collection(firestore, `users/${user.uid}/showcase-tokens`),
			);
			const unsub = onSnapshot(q, (snapshot) => {
				snapshot.docChanges().forEach((change) => {
					const data = change.doc.data();
					if (!data) return;
					fetchTokens().then((data) => {
						store.dispatch(setTokens((data.data as { tokens: ShowcaseToken[] }).tokens))
					}).catch((err) => {
						console.error(err);
					})
				});
			});
		}

		const user = auth.currentUser;
		if (user) {
			void init(user);
		} else {
			const unsubscribe = auth.onAuthStateChanged((user) => {
				if (user) {
					void init(user);
					unsubscribe();
				}
			});
		}
	}, []);
}
