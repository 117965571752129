import { Entity } from "../../types/entities";
import { useCallback } from "react";
import { isPath } from "../../types/entities/path";
import { calculateBoundingBox } from "../../lib/model/utils/geo";
import { LngLatLike, useMap } from "react-map-gl";
import { isArea } from "../../types/entities/area";
import { mapPositionArrayToLngLatArray } from "../../types/map-position";
import { isNote } from "../../types/entities/note";
import { isTurningPoint } from "../../types/entities/turning-point";
import { isImageLayer } from "../../types/entities/image-layer";
import { isLayerGroup } from "../../types/entities/layer-group";
import { isGrid } from "../../types/entities/grid";

/**
 * Custom hook to focus the map on a specific entity when selected.
 * @param {Entity} entity - The entity to focus the map on.
 * @returns An object containing a function to focus the map on the specified entity.
 */
export default function useMapFocus(entity: Entity) {
	// Access the map instance from React Map GL
	const { m1: map } = useMap();

	// Define a memoized callback function to focus the map
	const focus = useCallback(() => {
		if (!map) return; // Return early if map is not available

		// Determine the type of entity and adjust map focus accordingly
		if (isPath(entity)) {
			// Focus on a path entity by adjusting map bounds
			const points = entity.points.map((p) => [p.longitude, p.latitude]);
			const bbox = calculateBoundingBox([...points, points[1], points[0]]);
			map.fitBounds(bbox, { padding: 300 });
		} else if (isArea(entity)) {
			// Focus on an area entity by adjusting map bounds
			const bbox = calculateBoundingBox(
				mapPositionArrayToLngLatArray(entity.points)
			);
			map.fitBounds(bbox, { padding: 300 });
		} else if (isNote(entity)) {
			// Focus on a note entity by flying to its location
			const center = [entity.lng, entity.lat] as LngLatLike;
			map.flyTo({ center });
		} else if (isTurningPoint(entity)) {
			// Focus on a turning point entity by flying to its location
			const center = [entity.long, entity.lat] as LngLatLike;
			map.flyTo({ center });
		} else if (isImageLayer(entity)) {
			// Focus on an image layer entity by adjusting map bounds
			const bbox = calculateBoundingBox(
				mapPositionArrayToLngLatArray(entity.bounds)
			);
			map.fitBounds(bbox, { padding: 300 });
		} else if (isLayerGroup(entity)) {
			// Focus on a layer group entity by adjusting map bounds
			const bbox = entity.layers[0].bounds;
			map.fitBounds(bbox, { padding: 300 });
		} else if (isGrid(entity)) {
			// Focus on a grid entity by flying to its center
			const center = [entity.center.longitude, entity.center.latitude] as LngLatLike;
			map.flyTo({ center })
		}
	}, [entity, map]); // Dependency array includes entity and map

	// Return the focus function
	return {
		focus,
	};
}
