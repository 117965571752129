import LoginPage from "../../../pages/login";


/**
 * Dedicated Login Route
 * @constructor
 * @category route
 */
export default function LoginRoute() {
	return <LoginPage />;
}
