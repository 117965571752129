import Category from "../../../types/entities/category";

/**
 * Converts RGB color components to a hexadecimal color code.
 * @param r - The red color component (0-255).
 * @param g - The green color component (0-255).
 * @param b - The blue color component (0-255).
 * @returns The hexadecimal color code.
 */
function rgbToHex(r: number, g: number, b: number): string {
	return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

/**
 * Converts HSV (Hue, Saturation, Value) color components to a hexadecimal color code.
 * @param h - The hue component (0-1).
 * @param s - The saturation component (0-1).
 * @param v - The value component (0-1).
 * @returns The hexadecimal color code.
 */
export function hsvToHex(h: number, s: number, v: number): string {
	const i = Math.floor(h * 6);
	const f = h * 6 - i;
	const p = v * (1 - s);
	const q = v * (1 - f * s);
	const t = v * (1 - (1 - f) * s);

	const mod = i % 6;
	const colors = [
		[v, t, p],
		[q, v, p],
		[p, v, t],
		[p, q, v],
		[t, p, v],
		[v, p, q]
	];

	const [r, g, b] = colors[mod];

	return rgbToHex(
		Math.round(r * 255),
		Math.round(g * 255),
		Math.round(b * 255)
	);
}

/**
 * Converts a single RGB color component to a hexadecimal string.
 * @param c - The color component (0-255).
 * @returns The hexadecimal string representation of the color component.
 */
function componentToHex(c: number): string {
	const hex = c.toString(16);
	return hex.length === 1 ? "0" + hex : hex;
}

/**
 * Converts a hexadecimal color code to RGBA format.
 * @param hex - The hexadecimal color code.
 * @returns The RGBA representation of the color.
 */
export function hexToRgba(hex: string): string {
	const hexRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i;
	const match = hexRegex.exec(hex);
	if (match) {
		const red = parseInt(match[1], 16);
		const green = parseInt(match[2], 16);
		const blue = parseInt(match[3], 16);
		const alpha = match[4] !== undefined ? parseInt(match[4], 16) / 255 : 1;

		return `rgba(${red},${green},${blue},${alpha})`;
	}
	return "rgba(0,0,0,1)";
}

/**
 * Gets the RGB representation of a category based on its HSV components.
 * @param category - The category object containing HSV values.
 * @returns The RGB representation of the category.
 */
export function getRgb(category: Category): string {
	return hsvToHex(
		category.hue / 360,
		category.saturation / 100,
		category.lightness / 100
	);
}

/**
 * Converts a hexadecimal color code to RGB color components.
 * @param hex - The hexadecimal color code.
 * @returns An object containing the RGB color components.
 */
export function hexToRgb(hex: string): { r: number; g: number; b: number } {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result
		? {
				r: parseInt(result[1], 16),
				g: parseInt(result[2], 16),
				b: parseInt(result[3], 16),
		  }
		: { r: 0, g: 0, b: 0 };
}

/**
 * Converts RGB color components to HSV (Hue, Saturation, Value) color components.
 * @param r - The red color component (0-255).
 * @param g - The green color component (0-255).
 * @param b - The blue color component (0-255).
 * @returns An object containing the HSV color components.
 */
export function rgbToHsv(r: number, g: number, b: number): { h: number; s: number; v: number } {
	const rabs = r / 255,
		gabs = g / 255,
		babs = b / 255,
		v = Math.max(rabs, gabs, babs),
		diff = v - Math.min(rabs, gabs, babs),
		diffc = (c: number) => (v - c) / 6 / diff + 1 / 2,
		percentRoundFn = (num: number) => Math.round(num * 100) / 100;
	let rr,
		gg,
		bb,
		h = 0,
		s: number;
	if (diff === 0) {
		h = s = 0;
	} else {
		s = diff / v;
		rr = diffc(rabs);
		gg = diffc(gabs);
		bb = diffc(babs);
		if (rabs === v) {
			h = bb - gg;
		} else if (gabs === v) {
			h = 1 / 3 + rr - bb;
		} else if (babs === v) {
			h = 2 / 3 + gg - rr;
		}
		if (h < 0) {
			h += 1;
		} else if (h > 1) {
			h -= 1;
		}
	}
	return {
		h: Math.round(h * 360),
		s: percentRoundFn(s * 100),
		v: percentRoundFn(v * 100),
	};
}
