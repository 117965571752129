import {createAsyncThunk, createEntityAdapter, createSlice,} from "@reduxjs/toolkit";
import type {RootState} from "../store";
import Note from "../../types/entities/note";
import {alphabetical, byArea, byCategory, chronologicalCreated, chronologicalUpdated,} from "../sort-comparers";
import {entityAsyncThunk} from "./index";
import Entities from "../../types/entities";

/**
 * This file defines the slice for the Notes Entity.
 * The general layout will be the same for all entity slices.
 *
 * Adapter: Manages list of all elements for this entity and supplies standard functions like addOne and updateOne
 * AsyncThunks: Asynchronously fetches data for this collection name from firestore and populates list initially
 * Selectors: Exports reusable selectors for this slice
 * Comparators: Exports the comparators that are applicable to this entity type
 *
 * @category Slice
 */

const notesAdapter = createEntityAdapter<Note>({
	selectId: (note) => note.firestoreUid,
	sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const fetchNotes = createAsyncThunk(
	"notes/fetchNotes",
	async (userId: string) => entityAsyncThunk(Entities.Notes)(userId)
);

const initialState = notesAdapter.getInitialState({
	status: "idle",
});

const noteSlice = createSlice({
	name: "notes",
	initialState,
	reducers: {
		addNote: notesAdapter.addOne,
		updateNote: notesAdapter.updateOne,
		removeNote: notesAdapter.removeOne,
		addNotes: notesAdapter.addMany
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchNotes.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchNotes.fulfilled, (state, action) => {
				notesAdapter.setAll(state, action.payload as Note[]);
				state.status = "idle";
			});
	},
});

export const { addNote, updateNote, removeNote, addNotes } = noteSlice.actions;

export default noteSlice.reducer;

export const { selectAll: selectNotes, selectById: selectNoteById } =
	notesAdapter.getSelectors<RootState>((state) => state.data.notes);

export const selectNotesByCategoryId = (state: RootState, id: string) => {
	return notesAdapter
		.getSelectors<RootState>((state) => state.data.notes)
		.selectAll(state)
		.filter((n) => n.categoryUid === id);
};

export const selectNotesByAreaId = (state: RootState, id: string) => {
	return notesAdapter
		.getSelectors<RootState>((state) => state.data.notes)
		.selectAll(state)
		.filter((n) => n.areaUid === id);
};

const noteSortComparers = [
	{ name: "Alphabetically", function: alphabetical },
	{ name: "Creation Date", function: chronologicalCreated },
	{ name: "Last Update", function: chronologicalUpdated },
	{ name: "By Category", function: byCategory },
	{ name: "By Area", function: byArea },
];

export { noteSortComparers };
