import { MetaDataSchema } from "./meta-data";
import { z } from "zod";

/**
 * Schema for Media in ForestManager.
 * These are used to display pictures in Notes.
 * @category Schema
 */
export const MediaSchema = MetaDataSchema.extend({
	name: z.string().nullable(),
	firestoreUri: z.string(),
	noteUid: z.string(),
	uri: z.string(),
});

/**
 * Type for Media in ForestManager.
 * Inferred from zod Schema.
 * @category Schema_Type
 */
type Media = z.infer<typeof MediaSchema>;
export default Media;

/**
 * Is-Function to check whether the parameter is actually Media
 * @param media
 * @returns {boolean}
 */
export function isMedia(media: unknown): media is Media {
	return MediaSchema.safeParse(media).success;
}
