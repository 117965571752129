import { app } from "./index";
import { getFunctions, httpsCallable } from "firebase/functions";

/**
 * This file loads all necessary cloud functions.
 * They can be called using the exported name.
 * Each function is asynchronous by default and returns a success if executed without error.
 * TODO: Specify return types and parameters based on cloud function repository
 */

export const region: string =
	import.meta.env.VITE_CLOUD_FUNCTIONS_REGION || "europe-west3";
const functions = getFunctions(app, region);

export const fetchDataURL = `https://${region}-${
	import.meta.env.VITE_PROJECT_ID
}.cloudfunctions.net/api/elements`;
export const fetchLicenseDataForUser = httpsCallable(
	functions,
	"fetchLicenseDataForUser"
);
export const promoteMemberToAdmin = httpsCallable(
	functions,
	"promoteMemberToAdmin"
);
export const removeMemberFromLicense = httpsCallable(
	functions,
	"removeMemberFromLicense"
);
export const generateToken = httpsCallable(functions, "generateToken");
export const updateToken = httpsCallable(functions, "updateToken");
export const deleteToken = httpsCallable(functions, "deleteToken");
export const setTokenStatus = httpsCallable(functions, "setTokenStatus");
export const accessShowcase = httpsCallable(functions, "accessShowcase");
export const fetchTokens = httpsCallable(functions, "fetchTokens");
export const fetchSharePartnersForUser = httpsCallable(
	functions,
	"fetchSharePartnersForUser"
);

export const checkValidWebLicense = httpsCallable(
	functions,
	"checkValidWebLicense"
);

export const activateUserForWebLicense = httpsCallable(
	functions,
	"activateUserForWebLicense"
);

export const leaveTeamForUser = httpsCallable(functions, "leaveTeamForUser");

export const deleteUser = httpsCallable(
	functions,
	"deleteUserFromDatabase"
)
