import {createAsyncThunk, createEntityAdapter, createSlice,} from "@reduxjs/toolkit";
import Category from "../../types/entities/category";
import type {RootState} from "../store";
import {alphabetical, byNumOfAssignedNotes, chronologicalCreated, chronologicalUpdated,} from "../sort-comparers";
import {entityAsyncThunk} from "./index";
import Entities from "../../types/entities";

/**
 * This file defines the slice for the Category Entity.
 * The general layout will be the same for all entity slices.
 *
 * Adapter: Manages list of all elements for this entity and supplies standard functions like addOne and updateOne
 * AsyncThunks: Asynchronously fetches data for this collection name from firestore and populates list initially
 * Selectors: Exports reusable selectors for this slice
 * Comparators: Exports the comparators that are applicable to this entity type
 *
 * @category Slice
 */

const categoryAdapter = createEntityAdapter<Category>({
	selectId: (category) => category.firestoreUid,
	sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const fetchCategories = createAsyncThunk(
	"categories/fetchCategories",
	async (userId: string) => entityAsyncThunk(Entities.Categories)(userId)
);

const initialState = categoryAdapter.getInitialState({
	status: "idle",
});

const categorySlice = createSlice({
	name: "categories",
	initialState,
	reducers: {
		addCategory: categoryAdapter.addOne,
		updateCategory: categoryAdapter.updateOne,
		removeCategory: categoryAdapter.removeOne,
		addCategories: categoryAdapter.addMany
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchCategories.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchCategories.fulfilled, (state, action) => {
				categoryAdapter.setAll(state, action.payload as Category[]);
				state.status = "idle";
			});
	},
});

export const { addCategory, updateCategory, removeCategory, addCategories } = categorySlice.actions;

export default categorySlice.reducer;

export const { selectAll: selectCategories, selectById: selectCategoryById } =
	categoryAdapter.getSelectors<RootState>((state) => state.data.categories);

const categorySortComparers = [
	{ name: "Alphabetically", function: alphabetical },
	{ name: "Creation Date", function: chronologicalCreated },
	{ name: "Last Update", function: chronologicalUpdated },
	{ name: "Assigned Notes", function: byNumOfAssignedNotes },
];

export { categorySortComparers };
