import {StrictMode} from "react";
import {createRoot} from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import store from "./store/store";
import "./lib/localization/i18n";
import {ThemeWrapper} from "./hooks/use-css-theme";
import App from "./App";
import initSentry from "./lib/sentry";

/**
 * This is the entry point for the React Project 'ForestManager Webapp'
 */

initSentry();
const root = createRoot(document.getElementById("root") as HTMLElement);

/**
 * Renders the actual Component Tree starting at 'App'
 * Add additional Context Providers here if necessary
 */
root.render(
	<StrictMode>
		<ThemeWrapper>
			<Provider store={store}>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</Provider>
		</ThemeWrapper>
	</StrictMode>
);
