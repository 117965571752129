import {
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useState,
} from "react";
import {
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	signInWithPopup,
	User,
} from "firebase/auth";
import { auth, googleAuthProvider } from "../lib/firebase";
import { unsubscribeFromSnapshots } from "../lib/firebase/firestore";
import { useAppDispatch } from "../store/hooks";
import { logout as resetSession } from "../store/store";
import { useLocation, useNavigate } from "react-router";
import { activateUserForWebLicense, checkValidWebLicense } from "../lib/firebase/functions";

// Default redirect path after authentication
const deafaultRedirectPath = "/map";
let redirectPath = deafaultRedirectPath;
const loginPath = "/login";

/**
 * Defines the shape of the authentication context.
 */
export interface AuthContextValue {
	/**
	 * The current authenticated user, or `null` if there's no user or authentication is still loading.
	 */
	user: User | false | null;
	/**
	 * Indicates whether the user has a valid license.
	 */
	validLicense: boolean | null;
	/**
	 * The path to redirect the user after successful authentication.
	 */
	redirectPath: string;
	/**
	 * Function to perform email/password login.
	 * @param email - The user's email.
	 * @param password - The user's password.
	 * @returns A promise resolving to the authenticated user.
	 */
	login: (email: string, password: string) => Promise<User>;
	/**
	 * Function to perform login using Google authentication.
	 * @returns A promise resolving to the authenticated user.
	 */
	loginWithGoogle: () => Promise<User>;
	/**
	 * Function to sign up a new user.
	 * @param email - The user's email.
	 * @param password - The user's password.
	 * @returns A promise resolving to the newly created user.
	 */
	signup: (email: string, password: string) => Promise<User>;
	/**
	 * Function to log the current user out.
	 * @returns A promise resolving once the user is logged out.
	 */
	logout: () => Promise<void>;
	/**
	 * Function to create an ID token for the current user.
	 * @returns A promise resolving to the ID token string, or `undefined` if the user is not authenticated.
	 */
	createIdToken: () => Promise<string> | undefined;
	/**
	 * Function to activate a license for the current user.
	 * @param key - The license key to activate.
	 */
	activateLicense: (key: string) => void;
}

// Context for managing authentication state
const AuthContext = createContext<AuthContextValue | undefined>(undefined);

/**
 * Provides the authentication context to its children.
 * @param children - The children components to be wrapped by the authentication context.
 * @returns JSX element containing the children wrapped by the authentication context provider.
 */
export function ProvideAuth({ children }: { children: ReactNode }) {
	const auth = useProvideAuth();

	return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

/**
 * Hook to access the authentication context.
 * @returns The authentication context.
 * @throws Error if the context is consumed outside of AuthProvider.
 */
export function useAuth() {
	const context = useContext(AuthContext);
	if (!context) {
		throw Error("Context can only be consumed by children of AuthProvider.");
	}
	return context;
}

/**
 * Custom hook to handle authentication logic.
 * @returns Authentication context values and functions.
 */
function useProvideAuth() {
	const [user, setUser] = useState<User | false | null>(false);
	const [validLicense, setValidLicense] = useState<boolean | null>(null);
	const location = useLocation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	// Function to handle email/password login
	async function login(email: string, password: string) {
		const response = await signInWithEmailAndPassword(auth, email, password);
		setUser(response.user);
		return response.user;
	}

	// Function to handle Google login
	async function loginWithGoogle() {
		const response = await signInWithPopup(auth, googleAuthProvider);
		setUser(response.user);
		return response.user;
	}

	// Function to handle user signup
	async function signup(email: string, password: string) {
		const response = await createUserWithEmailAndPassword(auth, email, password);
		setUser(response.user);
		return response.user;
	}

	// Function to handle user logout
	async function logout() {
		await auth.signOut();
		setUser(false);
	}

	// Function to create an ID token
	function createIdToken() {
		return auth.currentUser?.getIdToken(/* */ true);
	}

	// Function to activate a user license
	function activateLicense(key: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			if (!user) {
				reject("User not found");
				return;
			}

			void activateUserForWebLicense({ key, uid: user.uid }).then((res) => {
				// @ts-ignore
				if (res.data.successful) {
					setValidLicense(true);
					navigate("/map");
					resolve(true);
				}
			})
			.catch((err) => {
				reject(err);
			});
		})
	}

	// Effect hook to manage authentication state
	useEffect(() => {
		void auth.authStateReady().then(() => {
			const user = auth.currentUser;
			if (user === null) {
				setUser(null);
				if(window.location.pathname != loginPath)
					redirectPath = window.location.pathname;
				unsubscribeFromSnapshots();
				dispatch(resetSession());
			} else {
				setUser(user);
				void checkValidWebLicense({ uid: user.uid }).then((res) => {
					// @ts-ignore
					if (res && res.data && res.data.valid) {
						setValidLicense(true);
					} else {
						setValidLicense(false);
					}
				}).catch(err => {
					console.error(err);
				});
			}
		});
	}, []);

	// Return authentication context values
	return {
		user: location.pathname.includes("showcase") ? null : user,
		validLicense,
		login,
		loginWithGoogle,
		signup,
		logout,
		createIdToken,
		redirectPath,
		activateLicense,
	};
}
