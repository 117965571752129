import {Box, Button, Popover, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {deleteUser} from "../../lib/firebase/functions";
import useRequireAuth from "../../hooks/use-require-auth";
import styles from "../login/login.module.css";
import errorStyles from "../error/error.module.css";
import purgeStyles from "./purge.module.css";
import {bindPopover, usePopupState, bindTrigger} from "material-ui-popup-state/hooks";
import {useTranslation} from "react-i18next";
import {useFeedback} from "../../features/feedback/use-feedback";
import {useRouter} from "../../hooks/use-router";
import { useState } from "react";

/**
 * Page to allow users to delete their accounts.
 * @constructor
 * @category Page
 */
export default function PurgeAccount() {
    const user = useRequireAuth();
    const popupState = usePopupState({ variant: "popover", popupId: "confirm" });
    const {t} = useTranslation();
    const {setFeedback} = useFeedback();
    const {navigate} = useRouter();
    const [isLoading, setIsLoading] = useState(false);
    const [resultDialogOpen, setResultDialogOpen] = useState(false);

    return (
        <Box className={styles.login}>
            <Box className={styles.blur} />
            <Box className={errorStyles.errorBox}>
                <Box className={styles.logo}>
                    <img alt={"company-logo"} src={`/images/fm-logo.png`} />
                </Box>
                <Box className={purgeStyles.content}>
                    <Typography id={purgeStyles.title}>{t("preferences.deleteAccountPage.title")}</Typography>
                    <Typography id={purgeStyles.subtitle}>{t("preferences.deleteAccountPage.subtitle")}</Typography>
                    <Box className={purgeStyles.buttonGrid}>
                        <Button onClick={() => navigate("/dashboard")} variant="contained">
                        {t("preferences.deleteAccountPage.back")}
                        </Button>
                        <>
                            <Button {...bindTrigger(popupState)} variant="outlined">
                             {t("preferences.deleteAccountPage.confirmDelete")}
                            </Button>
                            <Popover
                                {...bindPopover(popupState)}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                }}
                                slotProps={{
                                    paper: {
                                      className: styles.popover
                                    }
                                  }}
                            >
                                <Typography>{t("confirmCheck")}</Typography>
                                <Button
                                    variant="contained"
                                    disabled={isLoading}
                                    onClick={() => {
                                        setIsLoading(true);
                                        void deleteUser({ accountUid: user.uid }).then((res) => {
                                            popupState.close();
                                            setResultDialogOpen(true);
                                        })
                                            .catch((err) => {
                                                setFeedback(t("feedback.accountDeleteError"), "error");
                                                console.error(err);
                                                popupState.close();
                                            });
                                    }}
                                >
                                    {isLoading ? t("loading") : t("confirm")}
                                </Button>
                            </Popover>
                        </>
                    </Box>
                    {resultDialogOpen && 
                    <ResultDialog />
                    }
                </Box>
            </Box>
        </Box>
    );
}

/**
 * Popup Dialog which shows a confirmation message if the account deletion was successfull.
 */
const ResultDialog = () => {
    const {navigate} = useRouter();
    const {t} = useTranslation();

    const handleClose = () => {
        navigate("/logout");
    }

    return (
        <>
          <Dialog
            open={true}
            onClose={() => handleClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t("preferences.deleteAccountPage.deleteSuccessTitle")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
              {t("preferences.deleteAccountPage.deleteSuccessSubtitle")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleClose()} autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
}