import { Layer, Source, useMap } from "react-map-gl";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {useEffect, useMemo} from "react";
import { setPopoverInfo } from "../map-slice";
import Entities from "../../../types/entities";
// eslint-disable-next-line import/named
import { Position } from "@turf/turf";
import { hexToRgba } from "../../../lib/model/utils/colors";
import store from "../../../store/store";
import { selectFilteredAreas } from "../../filter/filter-slice";

/**
 * Component to render area polygons on the map.
 * @param {boolean} disableClick - Flag to disable click interaction with polygons.
 * @returns {JSX.Element} AreaPolygons component JSX element.
 */
export default function AreaPolygons({
	disableClick,
}: {
	disableClick?: boolean;
}) {
	const areas = useAppSelector((state) => selectFilteredAreas(state));
	const entityOpacity = useAppSelector((state) => state.map.entityOpacity);
	/**
	 * Memoized array of JSX elements representing polygon layers.
	 */
	const polygons = useMemo(() => areas.map((a) => (
		<PolygonLayer
			coordinates={[a.points.map((p) => [p.longitude, p.latitude])]}
			color={
				a.color ? hexToRgba(a.color + ( (Math.round(entityOpacity.areas * 255)).toString(16).toUpperCase() )) : `rgba(32, 140, 32, ${entityOpacity.areas})`
			}
			key={a.firestoreUid}
			id={a.firestoreUid}
		/>
	)), [areas, entityOpacity]);
	const { m1: map } = useMap();
	const dispatch = useAppDispatch();

	/**
	 * Effect that registers a click-Listener to the main map element, that is triggered whenever a layer with an id that exists in the list of areas is clicked.
	 * Will dispatch new popover information show on the map whenever invoked.
	 */
	useEffect(() => {
		if (disableClick) return;
		map &&
			map.on("click", [...areas.map((a) => a.firestoreUid)], (event) => {
				if (store.getState().sidebar.present.formOpen) return;
				if (
					!event.features ||
					event.defaultPrevented ||
					event.originalEvent.defaultPrevented
				)
					return;
				event.originalEvent.preventDefault();
				event.preventDefault();
				const id = event.features[0].source;
				if (id === undefined) return;
				dispatch(
					setPopoverInfo({
						id: String(id),
						type: Entities.Areas,
						lng: event.lngLat.lng,
						lat: event.lngLat.lat,
						closed: false,
					})
				);
			});
	}, [map, areas, dispatch, disableClick]);

	return (
		<>
			{polygons}
		</>
	);
}

/**
 * Renders a polygon layer on the map.
 * @param {string} id - Identifier for the layer.
 * @param {Position[][]} coordinates - Array of polygon coordinates.
 * @param {string} color - Fill color for the polygon.
 * @returns {JSX.Element} PolygonLayer component JSX element.
 */
export function PolygonLayer({
	id,
	coordinates,
	color,
}: {
	id: string;
	coordinates: Position[][];
	color: string;
}) {
	return (
		<Source
			id={id}
			type="geojson"
			data={{
				type: "Feature",
				properties: {},
				geometry: {
					type: "Polygon",
					coordinates,
				},
			}}
			tolerance={2}
		>
			<Layer type={"fill"} id={id} paint={{ "fill-color": color }} />
			<Layer type={"line"} id={`${id}-line`} paint={{"line-color": "white", "line-width": 1}} />
		</Source>
	);
}
