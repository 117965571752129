import { z } from "zod";
import { MetaDataSchema } from "./meta-data";
import {MapPositionSchema} from "../map-position";

/**
 * Schema for Areas in ForestManager.
 * @category Schema
 */
export const AreaSchema = MetaDataSchema.extend({
	name: z.string(),
	description: z.string(),
	color: z.string().optional(),
	points: MapPositionSchema.array(),
});

/**
 * Type for Area in ForestManager.
 * Inferred from zod Schema.
 * @category Schema_Type
 */
type Area = z.infer<typeof AreaSchema>;

export default Area;

/**
 * Is-Function to check whether the parameter is actually an Area
 * @param area
 * @returns {boolean}
 */
export function isArea(area: unknown): area is Area {
	return AreaSchema.safeParse(area).success;
}
