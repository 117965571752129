import { useCallback, useMemo } from "react";
import useMapFocus from "./use-map-focus";
import { isCategory, isDefaultCategory } from "../../types/entities/category";
import { User } from "firebase/auth";
import { useAppDispatch } from "../../store/hooks";
import { useAppSelector } from "../../store/hooks";
import Entities, { Entity } from "../../types/entities";
import {
	getEntityTypeForElement,
	updateElementForEntityType,
} from "../../store/slices";
import { updateFirestoreElement } from "../../lib/firebase/firestore";
import { Delete, Edit, GpsFixed, Share } from "@mui/icons-material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material";
import { useFeedback } from "../../features/feedback/use-feedback";
import { useTranslation } from "react-i18next";
import { useAuth } from "../use-auth";
import useSidebarParams from "../../features/sidebar/use-sidebar-params";
import { selectGrids } from "../../store/slices/grid-slice";

/**
 * Action interface representing an action available for an entity.
 */
export type Action = {
	callback: () => void; // Callback function triggered by the action
	name: string; // Name of the action
	symbol: OverridableComponent<SvgIconTypeMap>; // Icon component representing the action
	disabled?: boolean; // Flag indicating whether the action is disabled
	requiresConfirm?: boolean; // Flag indicating whether the action requires confirmation
};

/**
 * Custom hook to generate a list of actions for a given entity.
 * @param {Entity} entity - The entity for which actions are generated.
 * @returns {Action[]} - An array of actions available for the entity.
 */
export default function useActions(entity: Entity): Action[] {
	// Access necessary hooks and context
	const { setEditElement, setDetail } = useSidebarParams();
	const user = useAuth().user as User | null;
	const { setFeedback } = useFeedback();
	const { t } = useTranslation();
	const { focus } = useMapFocus(entity);
	const grids = useAppSelector((state) => selectGrids(state));

	// Check if the entity is editable by the current user
	const editable = useMemo(() => {
		return (
			user &&
			user.uid === entity.sharePartnerUid &&
			!isDefaultCategory(entity.firestoreUid)
		);
	}, [user, entity]);

	// Access Redux dispatch function
	const dispatch = useAppDispatch();

	// Callback function to handle editing an entity
	const handleEdit = useCallback(() => {
		if (!editable) {
			setFeedback(t("feedback.notAllowedToEditItem"), "warning");
			return;
		}
		setEditElement(entity.firestoreUid);
	}, [dispatch, editable, entity.firestoreUid]);

	// Callback function to handle deleting an entity
	const handleDelete = useCallback(() => {
		if (!editable || !user) {
			setFeedback(t("feedback.notAllowedToDeleteItem"), "warning");
			return;
		}
		const entityType = getEntityTypeForElement(entity);
		if(entityType === Entities.Areas) {
			const gridsToDelete = grids.filter(grid => grid.areaUid === entity.firestoreUid);
			for(let i = 0; i < gridsToDelete.length; i++) {
				updateElementForEntityType({...gridsToDelete[i], deletedAt: new Date().getTime()}, Entities.Grids);
				updateFirestoreElement(user.uid, Entities.Grids, {...gridsToDelete[i], deletedAt: new Date().getTime()});
			}
		}
		updateElementForEntityType(
			{ ...entity, deletedAt: new Date().getTime() },
			entityType
		);
		updateFirestoreElement(user.uid, entityType, {
			...entity,
			deletedAt: new Date().getTime(),
		});
		setDetail(null);
	}, [editable, entity, user, dispatch, setFeedback]);

	// Callback function to handle sharing an entity
	const handleShare = useCallback(() => {
		setFeedback(t("feedback.quickshareNotImplemented"), "info");
	}, [setFeedback]);

	// Generate and return the list of actions for the entity
	return [
		{ name: "focus", symbol: GpsFixed, callback: focus, disabled: isCategory(entity) },
		{ name: "edit", symbol: Edit, callback: handleEdit, disabled: !editable },
		{ name: "share", symbol: Share, callback: handleShare, disabled: !editable },
		{ name: "delete", symbol: Delete, callback: handleDelete, disabled: !editable, requiresConfirm: true },
	];
}
