/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import Area, { AreaSchema } from "./area";
import Category, { CategorySchema } from "./category";
import ImageLayer, { ImageLayerSchema } from "./image-layer";
import LayerGroup, { LayerGroupSchema } from "./layer-group";
import Note, { NoteSchema } from "./note";
import Path, { PathSchema } from "./path";
import TurningPoint, { TurningPointSchema } from "./turning-point";
import Media from "./media";
import Grid, {GridSchema} from "./grid";

/**
 * Union Type for all Entities. Used to generify functions when they work with different entities or when the type does not matter.
 */
export type Entity =
	| Area
	| Category
	| ImageLayer
	| LayerGroup
	| Note
	| Path
	| TurningPoint
	| Grid
	| Media;

/**
 * Enum for Entity Names. Corresponds to identifiers of firestore collections
 */
enum Entities {
	Areas = "areas",
	Categories = "categories",
	ImageLayer = "image_layer",
	LayerGroup = "layer_groups",
	Notes = "notes",
	Paths = "paths",
	TurningPoints = "turning_point",
	Grids = "grid",
	Media = "media",
}

/**
 * An object with key=EntityName and value=list of corresponding entities.
 */
export type EntityList = {
	[Entities.Areas]: Area[];
	[Entities.Categories]: Category[];
	[Entities.ImageLayer]: ImageLayer[];
	[Entities.Notes]: Note[];
	[Entities.Paths]: Path[];
	[Entities.TurningPoints]: TurningPoint[];
	[Entities.LayerGroup]: LayerGroup[];
	[Entities.Grids]: Grid[];
	[Entities.Media]: Media[];
};

/**
 * An object with key=EntityName and value=list of corresponding entities.
 * Some collections may not exist.
 */
export type EntityListWithUncertainties = {
	[Entities.Areas]?: Area[];
	[Entities.Categories]?: Category[];
	[Entities.ImageLayer]?: ImageLayer[];
	[Entities.Notes]?: Note[];
	[Entities.Paths]?: Path[];
	[Entities.TurningPoints]?: TurningPoint[];
	[Entities.LayerGroup]?: LayerGroup[];
	[Entities.Grids]?: Grid[];
	[Entities.Media]?: Media[];
};

/**
 * Takes an Item and returns the name of the matching entity.
 * @param item {Entity}
 * @returns Entities | null
 */
export function resolveEntityTypeForItem(item: Entity) {
	if (PathSchema.safeParse(item).success) return Entities.Paths;
	if (AreaSchema.safeParse(item).success) return Entities.Areas;
	if (CategorySchema.safeParse(item).success) return Entities.Categories;
	if (ImageLayerSchema.safeParse(item).success) return Entities.ImageLayer;
	if (NoteSchema.safeParse(item).success) return Entities.Notes;
	if (TurningPointSchema.safeParse(item).success) return Entities.TurningPoints;
	if (LayerGroupSchema.safeParse(item).success) return Entities.LayerGroup;
	if (GridSchema.safeParse(item).success) return Entities.Grids;
	return null;
}

export default Entities;
