import {Marker} from "react-map-gl";
import {Box} from "@mui/material";
import CategoryIcon from "../../../components/icons";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {selectCategories} from "../../../store/slices/category-slice";
import {getRgb} from "../../../lib/model/utils/colors";
import Note from "../../../types/entities/note";
import styles from "../map.module.css";
import {setPopoverInfo} from "../map-slice";
import Entities from "../../../types/entities";
import {selectFilteredNotes} from "../../filter/filter-slice";
import {useMemo} from "react";
import useSidebarParams from "../../sidebar/use-sidebar-params";

/**
 * Component representing a marker element.
 * @param {Object} props - Component props.
 * @param {string} props.color - Color of the marker.
 * @param {string} [props.iconName] - Name of the icon for the marker.
 * @param {number} [props.scale] - Scale of the marker.
 * @param {boolean} [props.inMap] - Flag indicating if the marker is in the map.
 * @returns {JSX.Element} MarkerElement component JSX element.
 */
export function MarkerElement({
	color,
	iconName,
	scale,
	inMap,
}: {
	color: string;
	iconName?: string;
	scale?: number;
	inMap?: boolean;
}) {
	const markerSize = useAppSelector((state) => state.map.markerSize);
	const markerScale = useMemo(() => {
		return markerSize * (scale ?? 1);
	}, [scale, markerSize])

	return (
		<Box
			className={styles.markerElement}
			style={{
				borderColor: color ?? "#fff",
				transform: `${
					inMap ? `translateY(-${markerScale * 60}%)` : ""
				} rotate(-45deg) scale(${markerScale})`,
			}}
		>
			<Box>
				<CategoryIcon iconName={iconName} />
			</Box>
		</Box>
	);
}

/**
 * Component responsible for rendering note markers on the map.
 * @returns {JSX.Element} NoteMarkers component JSX element.
 */
export default function NoteMarkers() {
	const notes = useAppSelector(selectFilteredNotes);
	const categories = useAppSelector(selectCategories);
	const {elementId, formOpen} = useSidebarParams();
	const markers = useMemo(() => notes.filter((n) => n.firestoreUid !== elementId || !formOpen).map((n) => {
		const category = categories.find((c) => c.firestoreUid === n.categoryUid);
		return (
			<Marker
				key={n.firestoreUid}
				onClick={(event) => {
					event.originalEvent.preventDefault();
					handleMarkerClicked(n);
				}}
				longitude={n.lng}
				latitude={n.lat}
			>
				<MarkerElement
					inMap={true}
					color={category ? getRgb(category) : "#fff"}
					iconName={category && category.symbol_name ? category.symbol_name : "questionmark"}
				/>
			</Marker>
		);
	}), [notes, elementId]);
	const entityOpacity = useAppSelector((state) => state.map.entityOpacity);
	const dispatch = useAppDispatch();

	/**
	 * Handler function for when a marker is clicked.
	 * @param {Note} note - The note object associated with the clicked marker.
	 */
	function handleMarkerClicked(note: Note) {
		dispatch(
			setPopoverInfo({
				id: note.firestoreUid,
				type: Entities.Notes,
				closed: false,
			})
		);
	}

	return (
		<>
			{entityOpacity.notes && markers}
		</>
	);
}
