import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import styles from "./help.module.css";

/**
 * Component for displaying help information.
 * Renders an image and provides contact information.
 */
export default function Help() {
	const { t } = useTranslation();

	return (
		<Box className={styles.helpContainer}>
			 <img src={"/images/help.png"} width={500} height={333} alt={"Help Vektorgraphic"} />
			<Typography>{t("help.contact")} <a href={`mailto:${t("help.infoMail")}`}>{t("help.infoMail")}</a></Typography>
		</Box>
	);
}
