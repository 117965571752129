import shp, {parseShp, ShpJSBuffer} from "shpjs";
import utmObj from 'utm-latlng';

type SupportedType = "Polygon" | "MultiPolygon" | "LineString" | "Point";

/**
 * Transforms the array buffer of a Shape ZIP file into GeoJSON. It supports LatLng and UTM coordinate system (Zone 32U).
 * @param arrayBuffer - The array buffer of the Sahpe ZIP file.
 * @returns An object containing the parsed GeoJSON, the number of features and if the coordinates are in LatLng.
 */
export async function shapeZipToGeojson(arrayBuffer: ArrayBuffer | string  | ShpJSBuffer) {
    let data: any;
    try {
        data = await shp(arrayBuffer);
    }
    catch(err) {
        throw err;
    }

    let featureCount = 0;
    let isLatLng = true;

    if(Array.isArray(data)) {
        for(let i = 0; i < data.length; i++) {
            delete data[i].fileName;
        }

        featureCount = 0;
        for(let i = 0; i < data.length; i++) {
            for(let j = 0; j < data[i].features.length;  j++) {
                featureCount++;
            }
        }

        let geometryType: SupportedType = data[0].features[0].geometry.type;
        if(geometryType == "MultiPolygon" && !isUTM(data[0].features[0].geometry.coordinates[0][0][0])) {
            isLatLng = isCoordLatLng(data[0].features[0].geometry.coordinates[0][0][0]);
            return {data, featureCount, isLatLng};
        }
        if(geometryType == "Polygon" && !isUTM(data[0].features[0].geometry.coordinates[0][0])) {
            isLatLng = isCoordLatLng(data[0].features[0].geometry.coordinates[0][0]);
            return {data, featureCount, isLatLng};
        }
        if(geometryType == "LineString" && !isUTM(data[0].features[0].geometry.coordinates[0])) {
            isLatLng = isCoordLatLng(data[0].features[0].geometry.coordinates[0]);
            return {data, featureCount, isLatLng};
        }
        if(geometryType == "Point" && !isUTM(data[0].features[0].geometry.coordinates)) {
            isLatLng = isCoordLatLng(data[0].features[0].geometry.coordinates);
            return {data, featureCount, isLatLng};
        }
        
        // transform UTM coordinates to LatLng coordinates
        for(let i = 0; i < data.length; i++) {
            const featureNum = data[i].features.length;
            for(let j = 0; j < featureNum; j++) {
                geometryType = data[i].features[j].geometry.type;
                if(geometryType == "Point") {
                    const xCoord = data[i].features[j].geometry.coordinates[0];
                    const yCoord = data[i].features[j].geometry.coordinates[1];
                    const convertedCoord: any = convertCoordFromUtmToLatLng(xCoord, yCoord);
                    data[i].features[j].geometry.coordinates[0] = convertedCoord.lng;
                    data[i].features[j].geometry.coordinates[1] = convertedCoord.lat;
                    continue;
                }
                if(geometryType == "LineString") {
                    convertBboxFromUtmToLatLng(data[i].features[j].geometry.bbox);
                    const coordnum = data[i].features[j].geometry.coordinates.length;
                    for(let k = 0; k < coordnum; k++) {
                        const xCoord = data[i].features[j].geometry.coordinates[k][0];
                        const yCoord = data[i].features[j].geometry.coordinates[k][1];
                        const convertedCoord: any = convertCoordFromUtmToLatLng(xCoord, yCoord);
                        data[i].features[j].geometry.coordinates[k][0] = convertedCoord.lng;
                        data[i].features[j].geometry.coordinates[k][1] = convertedCoord.lat;
                    }
                    continue;
                }
                if(geometryType == "Polygon") {
                    convertBboxFromUtmToLatLng(data[i].features[j].geometry.bbox);
                    const coordnum = data[i].features[j].geometry.coordinates[0].length;
                    for(let k = 0; k < coordnum; k++) {
                        const xCoord = data[i].features[j].geometry.coordinates[0][k][0];
                        const yCoord = data[i].features[j].geometry.coordinates[0][k][1];
                        const convertedCoord: any = convertCoordFromUtmToLatLng(xCoord, yCoord);
                        data[i].features[j].geometry.coordinates[0][k][0] = convertedCoord.lng;
                        data[i].features[j].geometry.coordinates[0][k][1] = convertedCoord.lat;
                    }
                }
                // TODO Multipolygon
            }
        }
        switch(geometryType) {
            case "MultiPolygon":
                isLatLng = isCoordLatLng(data[0].features[0].geometry.coordinates[0][0][0]);
                break;
            case "Polygon":
                isLatLng = isCoordLatLng(data[0].features[0].geometry.coordinates[0][0]);
                break;
            case "LineString":
                isLatLng = isCoordLatLng(data[0].features[0].geometry.coordinates[0]);
                break;
            case "Point":
                isLatLng = isCoordLatLng(data[0].features[0].geometry.coordinates);
                break;
            default:
                isLatLng = false;
        }
    }
    else {
        delete data.fileName;

        featureCount = 0;
        for(let i = 0; i < data.features.length; i++) {
            featureCount++;
        }

        let geometryType: SupportedType = data.features[0].geometry.type;
        if(geometryType == "MultiPolygon" && !isUTM(data.features[0].geometry.coordinates[0][0][0])) {
            isLatLng = isCoordLatLng(data.features[0].geometry.coordinates[0][0][0]);
            return {data, featureCount, isLatLng};
        }
        if(geometryType == "Polygon" && !isUTM(data.features[0].geometry.coordinates[0][0])) {
            isLatLng = isCoordLatLng(data.features[0].geometry.coordinates[0][0]);
            return {data, featureCount, isLatLng};
        }
        if(geometryType == "LineString" && !isUTM(data.features[0].geometry.coordinates[0])) {
            isLatLng = isCoordLatLng(data.features[0].geometry.coordinates[0]);
            return {data, featureCount, isLatLng};
        }
        if(geometryType == "Point" && !isUTM(data.features[0].geometry.coordinates)) {
            isLatLng = isCoordLatLng(data.features[0].geometry.coordinates);
            return {data, featureCount, isLatLng};
        }

        // transform UTM coordinates to LatLng coordinates
        const featureNum = data.features.length;
        for(let i = 0; i < featureNum; i++) {
            geometryType = data.features[i].geometry.type;
            if(geometryType == "Point") {
                const xCoord = data.features[i].geometry.coordinates[0];
                const yCoord = data.features[i].geometry.coordinates[1];
                const convertedCoord: any = convertCoordFromUtmToLatLng(xCoord, yCoord);
                data.features[i].geometry.coordinates[0] = convertedCoord.lng;
                data.features[i].geometry.coordinates[1] = convertedCoord.lat;
                continue;
            }
            if(geometryType == "LineString") {
                convertBboxFromUtmToLatLng(data.features[i].geometry.bbox);
                const coordnum = data.features[i].geometry.coordinates.length;
                for(let j = 0; j < coordnum; j++) {
                    const xCoord = data.features[i].geometry.coordinates[j][0];
                    const yCoord = data.features[i].geometry.coordinates[j][1];
                    const convertedCoord: any = convertCoordFromUtmToLatLng(xCoord, yCoord);
                    data.features[i].geometry.coordinates[j][0] = convertedCoord.lng;
                    data.features[i].geometry.coordinates[j][1] = convertedCoord.lat;
                }
                continue;
            }
            if(geometryType == "Polygon") {
                convertBboxFromUtmToLatLng(data.features[i].geometry.bbox);
                const coordnum = data.features[i].geometry.coordinates[0].length;
                for(let j = 0; j < coordnum; j++) {
                    const xCoord = data.features[i].geometry.coordinates[0][j][0];
                    const yCoord = data.features[i].geometry.coordinates[0][j][1];
                    const convertedCoord: any = convertCoordFromUtmToLatLng(xCoord, yCoord);
                    data.features[i].geometry.coordinates[0][j][0] = convertedCoord.lng;
                    data.features[i].geometry.coordinates[0][j][1] = convertedCoord.lat;
                }
            }
            // TODO Multipolygon
        }
        switch(geometryType) {
            case "MultiPolygon":
                isLatLng = isCoordLatLng(data.features[0].geometry.coordinates[0][0][0]);
                break;
            case "Polygon":
                isLatLng = isCoordLatLng(data.features[0].geometry.coordinates[0][0]);
                break;
            case "LineString":
                isLatLng = isCoordLatLng(data.features[0].geometry.coordinates[0]);
                break;
            case "Point":
                isLatLng = isCoordLatLng(data.features[0].geometry.coordinates);
                break;
            default:
                isLatLng = false;
        }
    }
    return {data, featureCount, isLatLng};
}

/**
 * Transforms the array buffer of a Shape SHP file into GeoJSON. It supports LatLng and UTM coordinate system (Zone 32U).
 * @param arrayBuffer - The array buffer of the Sahpe SHP file.
 * @returns An object containing the parsed GeoJSON, the number of features and if the coordinates are in LatLng.
 */
export function shapeShpToGeojson(arrayBuffer: ArrayBuffer | ShpJSBuffer) {
    let data: any;
    try {
        data = parseShp(arrayBuffer);
    }
    catch(err) {
        throw err;
    }

    // convert geometry array to GeoJSON FeatureCollection
    const geojson: any = {
        "type": "FeatureCollection",
        "features": []
    }

    for(let i = 0; i < data.length; i++) {
        geojson["features"][i] = {
            "geometry": data[i],
            "type": "Feature"
        };
    }

    let isLatLng = true;
    let featureCount = 0;
    for(let i = 0; i < geojson.features.length; i++) {
        featureCount++;
    }

    let geometryType: SupportedType = geojson.features[0].geometry.type;
    if(geometryType == "MultiPolygon" && !isUTM(geojson.features[0].geometry.coordinates[0][0][0])) {
        isLatLng = isCoordLatLng(geojson.features[0].geometry.coordinates[0][0][0]);
        return {geojson, featureCount, isLatLng};
    }
    if(geometryType == "Polygon" && !isUTM(geojson.features[0].geometry.coordinates[0][0])) {
        isLatLng = isCoordLatLng(geojson.features[0].geometry.coordinates[0][0]);
        return {geojson, featureCount, isLatLng};
    }
        
    if(geometryType == "LineString" && !isUTM(geojson.features[0].geometry.coordinates[0])) {
        isLatLng = isCoordLatLng(geojson.features[0].geometry.coordinates[0]);
        return {geojson, featureCount, isLatLng};
    }
    if(geometryType == "Point" && !isUTM(geojson.features[0].geometry.coordinates)) {
        isLatLng = isCoordLatLng(geojson.features[0].geometry.coordinates);
        return {geojson, featureCount, isLatLng};
    }

    // transfrom coords
    const featureNum = geojson.features.length;
    for(let i = 0; i < featureNum; i++) {
        geometryType = geojson.features[i].geometry.type;
        if(geometryType == "Point") {
            const xCoord = geojson.features[i].geometry.coordinates[0];
            const yCoord = geojson.features[i].geometry.coordinates[1];
            const convertedCoord: any = convertCoordFromUtmToLatLng(xCoord, yCoord);
            geojson.features[i].geometry.coordinates[0] = convertedCoord.lng;
            geojson.features[i].geometry.coordinates[1] = convertedCoord.lat;
            continue;
        }
        if(geometryType == "LineString") {
            convertBboxFromUtmToLatLng(geojson.features[i].geometry.bbox);
            const coordnum = geojson.features[i].geometry.coordinates.length;
                for(let j = 0; j < coordnum; j++) {
                    const xCoord = geojson.features[i].geometry.coordinates[j][0];
                    const yCoord = geojson.features[i].geometry.coordinates[j][1];
                    const convertedCoord: any = convertCoordFromUtmToLatLng(xCoord, yCoord);
                    geojson.features[i].geometry.coordinates[j][0] = convertedCoord.lng;
                    geojson.features[i].geometry.coordinates[j][1] = convertedCoord.lat;
                }
                continue;
        }
        if(geometryType == "Polygon") {
            convertBboxFromUtmToLatLng(geojson.features[i].geometry.bbox);
            const coordnum = geojson.features[i].geometry.coordinates[0].length;
            for(let j = 0; j < coordnum; j++) {
                const xCoord = geojson.features[i].geometry.coordinates[0][j][0];
                const yCoord = geojson.features[i].geometry.coordinates[0][j][1];
                const convertedCoord: any = convertCoordFromUtmToLatLng(xCoord, yCoord);
                geojson.features[i].geometry.coordinates[0][j][0] = convertedCoord.lng;
                geojson.features[i].geometry.coordinates[0][j][1] = convertedCoord.lat;
            }
        }
        // TODO Multipolygon
    }

    switch(geometryType) {
        case "MultiPolygon":
            isLatLng = isCoordLatLng(geojson.features[0].geometry.coordinates[0][0][0]);
            break;
        case "Polygon":
            isLatLng = isCoordLatLng(geojson.features[0].geometry.coordinates[0][0]);
            break;
        case "LineString":
            isLatLng = isCoordLatLng(geojson.features[0].geometry.coordinates[0]);
            break;
        case "Point":
            isLatLng = isCoordLatLng(geojson.features[0].geometry.coordinates);
            break;
        default:
            isLatLng = false;
    }
    return {geojson, featureCount, isLatLng};
}

/**
 * Checks if a given coordinate is in UTM coordinate system.
 * @param coordinate - coordinate to check.
 * @returns true, if coordinate is in UTM coordinate system.
 */
function isUTM(coordinate: Array<number>) {
    const UTM_EASTING_MIN = 167000;
    const UTM_EASTING_MAX = 833000;
    return ((coordinate[0] > UTM_EASTING_MIN && coordinate[0] < UTM_EASTING_MAX));
}

/**
 * Checks if a given coordinate is in LatLng coordinate system.
 * @param coordinate - coordinate to check.
 * @returns true, if coordinate is in LatLng coordinate system.
 */
function isCoordLatLng(coordinate: Array<number>) {
    return ((coordinate[0] > -180 && coordinate[0] < 180) &&
            (coordinate[1] > -180 && coordinate[1] < 180));
}

/**
 * Converts the bounding box of a feature in UTM coordinates to LatLng coordinates.
 * @param bbox - Reference to the feature's bounding box.
 */
function convertBboxFromUtmToLatLng(bbox: number[]) {
    const bboxCoordX1 = bbox[0];
    const bboxCoordY1 = bbox[1];
    const bboxCoordX2 = bbox[2];
    const bboxCoordY2 = bbox[3];
    const convertedBboxCoords1: any = convertCoordFromUtmToLatLng(bboxCoordX1, bboxCoordY1);
    const convertedBboxCoords2: any = convertCoordFromUtmToLatLng(bboxCoordX2, bboxCoordY2);
    bbox[0] = convertedBboxCoords1.lng;
    bbox[1] = convertedBboxCoords1.lat;
    bbox[2] = convertedBboxCoords2.lng;
    bbox[3] = convertedBboxCoords2.lat;
}

/**
 * Converts UTM coordinates from Zone 32U into LatLng coordinates.
 * @param x - Easting value of the UTM coordinate.
 * @param y - Northing value of the UTM coordinate.
 * @returns Object with lat and lng values
 */
function convertCoordFromUtmToLatLng(x: number, y: number) {
    let utm = new utmObj();
    return utm.convertUtmToLatLng(x, y, 32, 'U');
}