import { Box, Typography } from "@mui/material";
import {useTranslation} from "react-i18next";
import styles from "../dashboard.module.css";

/**
 * AGB View depending on translations
 * @constructor
 */
export default function AGB() {

    const { t } = useTranslation();

    return (
        <Box className={styles.legalOverviewContent}>
            <Typography>
                {t("agb.paragraph1")}
            </Typography>
            <Typography>
                {t("agb.paragraph2")}
            </Typography>
            <Typography>
                {t("agb.paragraph3")}
            </Typography>
            <Typography>
                {t("agb.paragraph4")}
            </Typography>
            <Typography>
                {t("agb.paragraph5")}
            </Typography>
            <Typography>
                {t("agb.paragraph6")}
            </Typography>
            <Typography>
                {t("agb.paragraph7")}
            </Typography>
            <Typography>
                {t("agb.paragraph8")}
            </Typography>
            <Typography>
                {t("agb.paragraph9")}
            </Typography>
            <Typography>
                {t("agb.paragraph10")}
            </Typography>
            <Typography>
                {t("agb.paragraph11")}
            </Typography>
            <Typography>
                {t("agb.paragraph12")}
            </Typography>
            <Typography>
                {t("agb.paragraph13")}
            </Typography>
            <Typography>
                {t("agb.closure")}
            </Typography>
        </Box>
    )
}