import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

/**
 * Configures language localization and translation.
 */
void i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		debug: false,
		fallbackLng: "de",
		interpolation: {
			escapeValue: false,
		},
		load: "languageOnly"
	});

export const languageKeys = ["en", "de"];

export default i18n;
