import {MetaDataSchema} from "./meta-data";
import {z} from "zod";
import {MapPositionSchema} from "../map-position";

/**
 * Enum of available Grid Types
 * @category Enum
 */
export enum GridType {
    RECTANGLE = "rectangle",
    HEXAGON = "hexagon",
}

/**
 * Schema for Grids in ForestManager.
 * @category Schema
 */
export const GridSchema = MetaDataSchema.extend({
    name: z.string(),
    description: z.string(),
    color: z.string(),
    center: MapPositionSchema,
    width: z.number(),
    height: z.number(),
    rotation: z.number().min(0).max(360).default(0),
    cellWidth: z.number(),
    cellHeight: z.number(),
    type: z.nativeEnum(GridType).default(GridType.RECTANGLE),
    lineThickness: z.number(),
    areaUid: z.string().optional()
});

/**
 * Type for Grid in ForestManager.
 * Inferred from zod Schema.
 * @category Schema_Type
 */
type Grid = z.infer<typeof GridSchema>;

export default Grid;


/**
 * Is-Function to check whether the parameter is actually a grid
 * @param grid
 * @returns {boolean}
 */
export function isGrid(grid: unknown): grid is Grid {
    return GridSchema.safeParse(grid).success;
}
