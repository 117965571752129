import LayerGroup from "../../../types/entities/layer-group";
import { SuspenseImageSource } from "./image-sources";
import { calculateBoundingBoxFromMinMaxBounds, getBoundsZoomLevel } from "../../../lib/model/utils/geo";
import { Fragment, Suspense, useEffect, useMemo, useState } from "react";
import { Marker, useMap } from "react-map-gl";
import store from "../../../store/store";
import { setPopoverInfo } from "../map-slice";
import Entities from "../../../types/entities";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { center, points } from "@turf/turf";
import { Box, CircularProgress, Typography } from "@mui/material";
import styles from "../map.module.css";
import { useTranslation } from "react-i18next";
import useSidebarParams from "../../sidebar/use-sidebar-params";

/**
 * Component responsible for rendering layers from layer groups on the map.
 * @param {Object} props - Component props.
 * @param {LayerGroup[]} props.layerGroups - An array of layer groups to render.
 * @param {boolean} [props.disableClick] - Flag to disable click events on the layers.
 * @returns {JSX.Element} LayerGroupSources component JSX element.
 */
export default function LayerGroupSources({
											  layerGroups,
											  disableClick,
										  }: {
	layerGroups: LayerGroup[];
	disableClick?: boolean;
}) {
	const { t } = useTranslation();
	const { m1: map } = useMap();
	const { selectedLayerStyles } = useAppSelector((state) => state.map);
	const { hiddenIndividualLayers } = useAppSelector((state) => state.filter);
	const { titleImageIndex, elementId } = useSidebarParams();
	const [currentZoom, setCurrentZoom] = useState(map ? map.getZoom() : 1);
	const loadingIndicatorSize = useMemo(() => {
		return map ? map.getZoom() * 20 : 0;
	}, [currentZoom]);
	const minZoomLevels = useMemo(() => {
		return layerGroups.map((lg) => lg.layers.length > 0 ? getBoundsZoomLevel(lg.layers[0].bounds) - 5 : 25);
	}, [layerGroups]);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (disableClick || !map) return;
		map.on("zoom", (event) => {
			setCurrentZoom(event.target.getZoom());
		});
		map.on(
			"click",
			[
				...layerGroups
					.map((lg) =>
						lg.layers.map((l, index) => lg.firestoreUid + "_" + index)
					)
					.flat(1),
			],
			(event) => {
				if (store.getState().sidebar.present.formOpen) return;
				if (
					!event.features ||
					event.defaultPrevented ||
					event.originalEvent.defaultPrevented
				)
					return;
				event.originalEvent.preventDefault();
				event.preventDefault();
				const [id, childIndex] = event.features[0].source.split("_");
				if (id === undefined) return;
				dispatch(
					setPopoverInfo({
						id: String(id),
						type: Entities.LayerGroup,
						lng: event.lngLat.lng,
						lat: event.lngLat.lat,
						closed: false,
						childIndex: store.getState().sidebar.present.elementId === id ? store.getState().sidebar.present.titleImageIndex : Number(childIndex),
					})
				);
			}
		);
	}, [disableClick, dispatch, layerGroups, map]);

	return (
		<>
			{layerGroups.map((lg, lgIndex) => {
				return (
					<Fragment key={lg.firestoreUid}>
						{lg.layers
							.filter((el) => (elementId !== lg.firestoreUid && lg.layers.indexOf(el) === 0) || (lg.firestoreUid === elementId && lg.layers.indexOf(el) === titleImageIndex) )
							.filter((el) => !hiddenIndividualLayers.includes(el.firestoreUid))
							.map((layer, index) => {
								const layerString =
									(layer.workspace ? layer.workspace + ":" : "") +
									layer.fileName;
								const bbox = layer.bounds.join(",");
								const styleIndex = selectedLayerStyles.find(
									(el) => el.id === layer.firestoreUid
								)?.styleIndex;
								const style =
									styleIndex !== undefined ? layer.styles[styleIndex] : "";
								const url = `${lg.provider}/${
									layer.workspace ?? ""
								}/wms?service=WMS&version=1.1.1&request=GetMap&format=image/png&transparent=true&srs=EPSG:4326&bbox=${bbox}&width=512&height=512&layers=${layerString}&styles=${style}`;
								const lngLat = center(
									points(calculateBoundingBoxFromMinMaxBounds(layer.bounds))
								);
								return (
									<Suspense
										key={layer.firestoreUid}
										fallback={
											<Marker
												longitude={lngLat.geometry.coordinates[0]}
												latitude={lngLat.geometry.coordinates[1]}
											>
												<Box style={{ visibility: map && minZoomLevels[lgIndex] <= currentZoom ? "visible": "hidden" }} className={styles.layerSuspense}>
													<Typography style={{ fontSize: currentZoom }}>{t("loading")} {lg.name}</Typography>
													<CircularProgress thickness={1.5} size={loadingIndicatorSize} />
												</Box>
											</Marker>
										}
									>
										<SuspenseImageSource
											key={layer.firestoreUid}
											id={lg.firestoreUid + "_" + index}
											imgSrc={url}
											points={calculateBoundingBoxFromMinMaxBounds(
												layer.bounds
											)}
										/>
									</Suspense>
								);
							})}
					</Fragment>
				);
			})}
		</>
	);
}
