import { Layer, LngLatLike, Source, useMap } from "react-map-gl";
import type ImageLayer from "../../../types/entities/image-layer";
import {
	mapPositionArrayToLngLatArray,
	mapPositionToLngLat,
} from "../../../types/map-position";
import { Fragment, useEffect, useMemo, useState } from "react";
import store from "../../../store/store";
import { setPopoverInfo } from "../map-slice";
import Entities from "../../../types/entities";
import { useAppDispatch } from "../../../store/hooks";
import { useLiveQuery } from "dexie-react-hooks";
import db from "../../../lib/db";
import { hash } from "../../../lib/model/utils/strings";
import imageCache from "../../../lib/image-cache";
import {
	calculateBoundingBox,
	getBoundsZoomLevel,
} from "../../../lib/model/utils/geo";
import {GeoJSON} from "geojson";

/**
 * Component to render image sources on the map.
 * @param {Object} props - Component properties.
 * @param {ImageLayer[]} props.imageLayers - Array of image layers to render.
 * @param {boolean} [props.disableClick] - Flag to disable click interactions.
 * @returns {JSX.Element} ImageSources component JSX element.
 */
export default function ImageSources({
	imageLayers,
	disableClick,
}: {
	imageLayers: ImageLayer[];
	disableClick?: boolean;
}) {
	const { m1: map } = useMap();
	const [bounds, setBounds] = useState(map?.getBounds());
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (!map) return;
		map.on("moveend", (event) => {
			setBounds(event.target.getBounds());
		});
	}, [map]);

	useEffect(() => {
		if (disableClick) return;
		map &&
			map.on("click", [...imageLayers.map((a) => a.firestoreUid)], (event) => {
				if (store.getState().sidebar.present.formOpen) return;
				if (
					!event.features ||
					event.defaultPrevented ||
					event.originalEvent.defaultPrevented
				)
					return;
				event.originalEvent.preventDefault();
				event.preventDefault();
				const id = event.features[0].source;
				if (id === undefined) return;
				dispatch(
					setPopoverInfo({
						id: String(id),
						type: Entities.ImageLayer,
						lng: event.lngLat.lng,
						lat: event.lngLat.lat,
						closed: false,
					})
				);
			});
	}, [map, imageLayers, dispatch, disableClick]);

	return (
		<>
			{imageLayers.map((el) => {
				if (!bounds || !el["firestore-uri"]) return <></>;
				let visible = false;
				el.bounds.forEach((point) => {
					if (bounds.contains(mapPositionToLngLat(point) as LngLatLike)) {
						visible = true;
						return;
					}
				});
				return visible ? (
					<ImageSource
						key={el.firestoreUid}
						id={el.firestoreUid}
						imgSrc={el["firestore-uri"]}
						points={mapPositionArrayToLngLatArray(el.bounds)}
					/>
				) : (
					<Fragment key={el.firestoreUid}></Fragment>
				);
			})}
		</>
	);
}

/**
 * Component to render an image source on the map.
 * @param {Object} props - Component properties.
 * @param {string} props.id - The unique identifier of the image source.
 * @param {string} props.imgSrc - The URL of the image source.
 * @param {number[][]} props.points - The array of points representing the image source location.
 * @returns {JSX.Element} ImageSource component JSX element.
 */
export function ImageSource({
	id,
	imgSrc,
	points,
}: {
	id: string;
	imgSrc: string;
	points: number[][];
}) {
	const minzoom = useMemo(() => {
		return getBoundsZoomLevel(calculateBoundingBox(points)) - 5;
	}, [points]);
	const bgLayer = useMemo(() => {
		return {
			type: "Feature",
			properties: {},
			geometry: {
				type: "Polygon",
				coordinates: [points],
			},
		} as GeoJSON;
	}, [])

	return (
		<>
			<Source
				id={id}
				type="geojson"
				data={bgLayer}
			>
				<Layer
					minzoom={minzoom}
					maxzoom={22}
					type={"fill"}
					id={id}
					paint={{ "fill-color": "rgba(0,0,0,0)" }}
				/>
			</Source>
			<Source type="image" id={"i_" + id} url={imgSrc} coordinates={points}>
				<Layer minzoom={minzoom} maxzoom={22} type="raster" id={"i_" + id} />
			</Source>
		</>
	);
}

/**
 * Component to render an image source within a suspense boundary.
 * @param {Object} props - Component properties.
 * @param {string} props.id - The unique identifier of the image source.
 * @param {string} props.imgSrc - The URL of the image source.
 * @param {number[][]} props.points - The array of points representing the image source location.
 * @returns {JSX.Element} SuspenseImageSource component JSX element.
 */
export function SuspenseImageSource({
	id,
	imgSrc,
	points,
}: {
	id: string;
	imgSrc: string;
	points: number[][];
}) {
	const dataUrl = useLiveQuery(() =>
		db.previewImages.get(String(hash(imgSrc)))
	)?.url;

	!dataUrl && imageCache.read(imgSrc);

	return <ImageSource id={id} imgSrc={dataUrl ?? imgSrc} points={points} />;
}
