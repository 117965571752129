import { Box, Typography } from "@mui/material";
import SuspenseImage from "../loading/suspense-image";
import ContentLoader from "react-content-loader";
import styles from "./cards.module.css";
import CardProps from "./card-props";
import { Suspense } from "react";
import { PiPolygonLight } from "react-icons/pi";
import { PiPathLight } from "react-icons/pi";
import { PiGridFourLight } from "react-icons/pi";
import Entities from "../../types/entities";
import IconAvatar from "../icons/icon-avatar";

/**
 * Skeleton which is used in building Imagecard
 * @constructor
 */
export function ImageCardSkeleton() {
	return (
		<ContentLoader
			speed={2}
			width={"100%"}
			height={120}
			backgroundColor={"var(--skeleton-background)"}
			foregroundColor={"var(--skeleton-foreground)"}
			className={styles.skeleton}
		>
			<rect x={0} y={0} rx={0} ry={0} width={120} height={120} />
			<rect x="140" y="50" rx="5" ry="5" width="320" height="10" />
			<rect x="140" y="70" rx="5" ry="5" width="320" height="10" />
		</ContentLoader>
	);
}

/**
 * card for images
 * @param id
 * @param imageSrc
 * @param name
 * @param type
 * @param color
 * @param metaText
 * @param onClick
 * @param children
 * @constructor
 */
export default function ImageCard({
	id,
	imageSrc,
	name,
	type,
	color,
	metaText,
	onClick,
	children,
}: CardProps & {
	id: string;
	imageSrc?: string;
	name: string;
	type: Entities;
	color?: string;
	metaText: string | JSX.Element;
}) {

	return (
		<Suspense fallback={<ImageCardSkeleton />}>
			<Box className={styles.card} onClick={() => onClick(id)}>
				

				{imageSrc ? (
					<Box className={styles.imageContainer}>
        				<SuspenseImage alt={`${name} preview`} src={imageSrc} height={120} />
					</Box>
      				) : (
					<Box className={styles.iconContainer}>
						<IconForTpye type={type} color={color} />
					</Box>
      			)}

				
				<Box className={styles.textContainer}>
					<Typography variant="h4">{name}</Typography>
					{metaText instanceof String ? (
						<Typography component="p">{metaText}</Typography>
					) : (
						metaText
					)}
				</Box>
				<Box
					className={styles.moreContainer}
					style={!children ? { padding: 0 } : {}}
				>
					{children}
				</Box>
			</Box>
		</Suspense>
	);
}

function IconForTpye({type, color} : {type: Entities; color: string | undefined}) {
	switch(type) {
		case Entities.Areas:
			return <PiPolygonLight size={48} color={color ?? "white"} />;
		case Entities.Paths:
			return <PiPathLight size={48} color={color ?? "orange"} />
		case Entities.TurningPoints:
			return <IconAvatar color="#2bcfb1" symbol="turning_point" />;
		case Entities.Grids:
			return <PiGridFourLight size={56} color={color ?? "#59c26e"} />;
	}

	return <IconAvatar color="white" symbol="questionmark" />
}