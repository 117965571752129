/**
 * This code defines a simple image caching mechanism using an object named imgCache. It allows you to asynchronously load and cache images from given sources. The read method of imgCache checks if an image with a given source is cached. If not, it loads the image asynchronously and caches it using a Promise. Once the image is loaded, subsequent calls to read for the same source will return the cached image.
 */
const imgCache = {
	// Object to store cached images
	__cache: {},

	// Function to read and cache an image from a given source
	read(src: string) {
		// Check if the image is not already cached
		// @ts-ignore
		if (!this.__cache[src]) {
			// Cache the image asynchronously using a Promise
			// @ts-ignore
			this.__cache[src] = new Promise((resolve) => {
				const img = new Image();
				// When the image is loaded, resolve the Promise and mark it as cached
				img.onload = () => {
					// @ts-ignore
					this.__cache[src] = true;
					// @ts-ignore
					resolve(this.__cache[src]);
				};
				img.src = src; // Set the image source to start loading
			}).then(() => {
				// Once the Promise is resolved, mark the image as cached
				// @ts-ignore
				this.__cache[src] = true;
			});
		}
		// If the image is still being cached (Promise), throw the Promise
		// @ts-ignore
		if (this.__cache[src] instanceof Promise) {
			// @ts-ignore
			throw this.__cache[src];
		}
		// If the image is already cached, return it
		// @ts-ignore
		return this.__cache[src];
	},
};

export default imgCache; // Export the image cache object
