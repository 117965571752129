import { useEffect, useState } from "react";
import SharePartner from "../../../types/share-partner";
import { Box, Table, TableBody, TableCell, TableHead, TableRow, } from "@mui/material";
import SharePartnerRow from "./share-partner-row";
import { useTranslation } from "react-i18next";
import styles from "./share.module.css";

/**
 * Displays a table of share partners.
 * @param sharePartners - The list of share partners to display.
 */
export default function SharePartnerTable({
	sharePartners,
}: {
	sharePartners: SharePartner[];
}) {
	/** State to manage the dialog's open state */
	const [open, setOpen] = useState(false);
	/** State to manage the share partner being edited */
	const [partnerToEdit, setPartnerToEdit] = useState<SharePartner | null>(null);
	const { t } = useTranslation();

	/**
	 * Effect hook to handle opening the dialog when a partner is being edited.
	 */
	useEffect(() => {
		if (partnerToEdit) {
			setOpen(true);
		}
	}, [partnerToEdit]);

	/**
	 * Effect hook to reset the partnerToEdit state when the dialog is closed.
	 */
	useEffect(() => {
		if (!open) {
			setPartnerToEdit(null);
		}
	}, [open]);

	return (
		<Box className={`${styles.tableContainer} ${styles.sharepartnerTable}`}>
			<Table>
				<>
					<TableHead>
						<TableRow style={{ color: "var(--accent)" }}>
							<TableCell style={{ color: "inherit" }}>{t("name")}</TableCell>
							<TableCell style={{ color: "inherit" }}>{t("teamLicense.email")}</TableCell>
							<TableCell style={{ color: "inherit" }}>Mit Ihnen geteilt</TableCell>
							<TableCell style={{ color: "inherit" }}>Von Ihnen geteilt</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{sharePartners.map((partner) => (
							<SharePartnerRow key={partner.uid} partner={partner} />
						))}
					</TableBody>
				</>
			</Table>
		</Box>
	);
}
