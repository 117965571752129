import { Box, Typography } from "@mui/material";
import { Room } from "@mui/icons-material";
import Category from "../../types/entities/category";
import { getRgb } from "../../lib/model/utils/colors";
import IconAvatar from "../icons/icon-avatar";
import styles from "./cards.module.css";
import CardProps from "./card-props";
import { useTranslation } from "react-i18next";

/**
 * CategoryCard Component
 *
 * A card component representing a category with its associated details.
 *
 * @component
 * @param {CardProps & { category: Category; numOfNotes: number }} props - The properties of the CategoryCard component.
 * @param {Category} props.category - The category object containing details like name, symbol, etc.
 * @param {number} props.numOfNotes - The number of notes assigned to the category.
 * @param {(uid: string) => void} props.onClick - The function to be called when the card is clicked, providing the category's UID.
 * @param {ReactNode} props.children - Additional content to be rendered inside the card.
 *
 * @returns {JSX.Element} - The rendered CategoryCard component.
 *
 * @example
 * // Usage of CategoryCard component
 * <CategoryCard
 *   category={{ name: 'Category 1', symbol_name: 'symbol1', firestoreUid: '123' }}
 *   numOfNotes={5}
 *   onClick={(uid) => handleCategoryClick(uid)}
 * >
* </CategoryCard>
*/
export default function CategoryCard({
	category,
	numOfNotes,
	onClick,
	children,
}: CardProps & { category: Category; numOfNotes: number }): JSX.Element {
	const { t } = useTranslation();

	return (
		<Box className={styles.card} onClick={() => onClick(category.firestoreUid)}>
			<Box className={styles.iconContainer}>
				<IconAvatar color={getRgb(category)} symbol={category.symbol_name} />
			</Box>
			<Box className={styles.textContainer}>
				<Typography component="h4">{category.name}</Typography>
				<Typography component="p">
					<Room /> {t("cards.assignedNotes", { num: numOfNotes })}
				</Typography>
			</Box>
			<Box className={styles.moreContainer}>{children}</Box>
		</Box>
	);
}
