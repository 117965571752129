import { IconType } from "react-icons";
import { Box, Typography } from "@mui/material";
import { ReactNode } from "react";
import styles from "./sections.module.css";

/**
 * OverviewItem Component
 *
 * A component representing an item in an overview section with an icon and text.
 *
 * @component
 * @param {Object} props - The properties of the OverviewItem component.
 * @param {string} props.text - The text to be displayed alongside the icon.
 * @param {IconType | any} props.Icon - The icon component or type to be displayed.
 *
 * @returns {JSX.Element} - The rendered OverviewItem component.
 *
 * @example
 * // Usage of OverviewItem component
 * <OverviewItem text="Total Users" Icon={UserIcon} />
 */
export function OverviewItem({
	text,
	Icon,
	onClick
}: {
	text: string;
	Icon: IconType | any;
	onClick?: () => void;
}): JSX.Element {
	
	if(onClick) {
		return (
			<Box className={`${styles.overviewItem} ${styles.clickableDetail}`}>
				<Icon />
				<Typography onClick={onClick}>{text}</Typography>
			</Box>
		);
	}

	return (
		<Box className={styles.overviewItem}>
			<Icon />
			<Typography>{text}</Typography>
		</Box>
	);
}

/**
 * OverviewSection Component
 *
 * A component representing a section for displaying overview items.
 *
 * @component
 * @param {Object} props - The properties of the OverviewSection component.
 * @param {ReactNode} props.children - The child components to be rendered inside the OverviewSection.
 *
 * @returns {JSX.Element} - The rendered OverviewSection component.
 *
 * @example
 * // Usage of OverviewSection component
 * <OverviewSection>
 *   <OverviewItem text="Total Users" Icon={UserIcon} />
 *   <OverviewItem text="Active Users" Icon={ActiveUserIcon} />
 * </OverviewSection>
 */
export default function OverviewSection({ children }: { children: ReactNode }): JSX.Element {
	return (
		<Box className={`${styles.section} ${styles.overview}`}>{children}</Box>
	);
}
