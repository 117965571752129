import Path from "../../../types/entities/path";
import * as turf from "@turf/turf";
import { LngLatBoundsLike, LngLatLike } from "react-map-gl";
// @ts-ignore
import extent from "turf-extent";
import MapPosition from "../../../types/map-position";
import * as mapboxgl from "mapbox-gl";

/**
 * Converts degrees to radians.
 * @param deg - Degrees value.
 * @returns Radians value.
 */
function deg2rad(deg: number): number {
	return deg * (Math.PI / 180);
}

/**
 * Calculates the distance between two points on the Earth's surface using the Haversine formula.
 * @param lat1 - Latitude of the first point.
 * @param lon1 - Longitude of the first point.
 * @param lat2 - Latitude of the second point.
 * @param lon2 - Longitude of the second point.
 * @returns Distance in meters.
 */
function getDistanceFromLatLonInM(
	lat1: number,
	lon1: number,
	lat2: number,
	lon2: number
): number {
	const R = 6371; // Radius of the Earth in kilometers
	const dLat = deg2rad(lat2 - lat1);
	const dLon = deg2rad(lon2 - lon1);
	const a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.cos(deg2rad(lat1)) *
			Math.cos(deg2rad(lat2)) *
			Math.sin(dLon / 2) *
			Math.sin(dLon / 2);
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	const d = R * c * 1000; // Convert to meters
	return d;
}

/**
 * Calculates the length of a path represented by an array of geographical points.
 * @param path - Path object containing an array of points.
 * @returns Length of the path in meters.
 */
export function calculateLength(path: Path): number {
	const points = path.points;
	if (points.length <= 1) return 0;
	let sum = getDistanceFromLatLonInM(
		points[0].latitude,
		points[0].longitude,
		points[1].latitude,
		points[1].longitude
	);
	for (let i = 1; i <= points.length - 2; i++) {
		sum += getDistanceFromLatLonInM(
			points[i].latitude,
			points[i].longitude,
			points[i + 1].latitude,
			points[i + 1].longitude
		);
	}
	return Number.parseFloat(sum.toFixed(2));
}

/**
 * Checks if there are any line intersections within a polygon defined by an array of points.
 * @param points - Array of points defining the polygon.
 * @returns True if there are line intersections, otherwise false.
 */
export function checkPolygonLineIntersections(points: number[][]): boolean {
	if (points.length <= 3) return false;
	const lines: turf.helpers.Feature<
		turf.helpers.LineString,
		turf.helpers.Properties
	>[] = [];
	for (let i = 0; i < points.length - 1; i++) {
		const line = turf.lineString([points[i], points[i + 1]]);
		lines.push(line);
	}
	const line = turf.lineString([points[points.length - 1], points[0]]);
	lines.push(line);
	for (let i = 0; i < lines.length - 1; i++) {
		for (let j = i + 1; j < lines.length; j++) {
			const intersects = turf.lineIntersect(lines[i], lines[j]);
			if (intersects.features.length > 0) {
				for (let k = 0; k < intersects.features.length; k++) {
					const coordinates = intersects.features[k].geometry.coordinates;
					if (
						!points.find(
							(point) =>
								point[0] === coordinates[0] && point[1] === coordinates[1]
						)
					) {
						return true;
					}
				}
			}
		}
	}
	return false;
}

/**
 * Calculates the surface area of a polygon defined by an array of points.
 * @param points - Array of points defining the polygon.
 * @returns Surface area of the polygon.
 */
export function calculateSurfaceArea(points: number[][]): number {
	try {
		if (checkPolygonLineIntersections(points)) return 0;
		const coordinates = [...points];
		const c = closePolygonRing(padPointsArray(coordinates));
		const polygon = turf.polygon([c]);
		return turf.area(polygon);
	} catch (e) {
		return 0;
	}
}

/**
 * Compares two points for equality.
 * @param point1 - First point to compare.
 * @param point2 - Second point to compare.
 * @returns True if points are equal, otherwise false.
 */
export function comparePoints(
	point1: number[] | MapPosition,
	point2: number[] | MapPosition
): boolean {
	const p0 = Array.isArray(point1) ? point1 : mapPositionToLngLat(point1);
	const p1 = Array.isArray(point2) ? point2 : mapPositionToLngLat(point2);
	return p0[0] === p1[0] && p0[1] === p1[1];
}

/**
 * Calculates the bounding box coordinates from a min-max bounds array.
 * @param bounds - Min-max bounds array [minLongitude, minLatitude, maxLongitude, maxLatitude].
 * @returns Bounding box coordinates.
 */
export function calculateBoundingBoxFromMinMaxBounds(
	bounds: [number, number, number, number]
): number[][] {
	const lngLatBounds = new mapboxgl.LngLatBounds(bounds);
	return [
		lngLatBounds.getNorthWest().toArray(),
		lngLatBounds.getNorthEast().toArray(),
		lngLatBounds.getSouthEast().toArray(),
		lngLatBounds.getSouthWest().toArray(),
	];

}

/**
 * Calculates the bounding box of a polygon defined by an array of points.
 * @param points - Array of points defining the polygon.
 * @returns Bounding box coordinates.
 */
export function calculateBoundingBox(points: number[][]): LngLatBoundsLike {
	const coordinates = points.map((p) =>
		Array.isArray(p) ? p : mapPositionToLngLat(p)
	);
	const c = closePolygonRing(padPointsArray(coordinates));
	const polygon = turf.polygon([c]);
	const bbox = extent(polygon);
	return [
		[bbox[0], bbox[1]],
		[bbox[2], bbox[3]],
	];
}

/**
 * Calculates the bounding box of a line string defined by an array of points.
 * @param points - Array of points defining the line string.
 * @returns Bounding box coordinates.
 */
export function calculateLineStringBoundingBox(
	points: number[][]
): LngLatBoundsLike {
	const line = turf.lineString(points);
	const bbox = turf.bbox(line);
	return bbox as LngLatBoundsLike;
}

/**
 * Calculates the center point of a polygon defined by an array of points.
 * @param points - Array of points defining the polygon.
 * @returns Center coordinates.
 */
export function calculateCenter(
	points: number[][] | MapPosition[]
): LngLatLike {
	const coordinates = points.map((p) =>
		Array.isArray(p) ? p : mapPositionToLngLat(p)
	);
	const c = closePolygonRing(padPointsArray(coordinates));
	const polygon = turf.polygon([c]);
	const center = turf.center(polygon);
	return center.geometry.coordinates as [number, number];
}

/**
 * Pads an array of points to ensure it has at least 4 points, closing the polygon if needed.
 * @param points - Array of points to pad.
 * @returns Padded array of points.
 */
export function padPointsArray(points: number[][]): number[][] {
	const coordinates = [...points];
	while (coordinates.length < 4) {
		coordinates.push(coordinates[coordinates.length - 1]);
	}
	return coordinates;
}

/**
 * Closes a polygon ring by adding the first point to the end if necessary.
 * @param points - Array of points defining the polygon ring.
 * @returns Closed polygon ring.
 */
export function closePolygonRing(points: number[][]): number[][] {
	const currentPoints = [...points];
	if (!comparePoints(points[0], points[points.length - 1])) {
		currentPoints.push(points[0]);
	}
	return currentPoints;
}

/**
 * Converts a mapbox LngLat to a MapPosition.
 * @param lngLat - LngLat or array representing longitude and latitude.
 * @returns MapPosition object.
 */
export function lngLatToMapPosition(
	lngLat: LngLatLike | number[]
): MapPosition {
	if (Array.isArray(lngLat)) {
		return { longitude: lngLat[0], latitude: lngLat[1], altitude: 0 };
	} else if ("lng" in lngLat) {
		return { longitude: lngLat.lng, latitude: lngLat.lat, altitude: 0 };
	} else {
		return { longitude: lngLat.lon, latitude: lngLat.lat, altitude: 0 };
	}
}

/**
 * Converts an array of mapbox LngLat to an array of MapPosition.
 * @param lngLats - Array of LngLat or array representing longitude and latitude.
 * @returns Array of MapPosition objects.
 */
export function lngLatArrayToMapPositionArray(lngLats: number[][]): MapPosition[] {
	return lngLats.map((lngLat) => lngLatToMapPosition(lngLat));
}

/**
 * Converts a MapPosition to a mapbox LngLat.
 * @param point - MapPosition object.
 * @returns Array containing longitude and latitude.
 */
export function mapPositionToLngLat(point: MapPosition): number[] {
	return [point.longitude, point.latitude];
}

/**
 * Calculates the zoom level of a map view based on a given bounding box.
 * @param bounds - Bounding box coordinates.
 * @returns Zoom level.
 */
export function getBoundsZoomLevel(bounds: LngLatBoundsLike): number {
	const map = document.querySelector(".mapboxgl-canvas") as HTMLCanvasElement;
	const mapDim = { width: map.width, height: map.height };
	const neSw = getNorthEastSouthWest(bounds);
	if (!neSw) return 10;
	const { ne, sw } = neSw;
	const WORLD_DIM = { height: 256, width: 256 };
	const ZOOM_MAX = 21;
	function latRad(lat: number) {
		const sin = Math.sin((lat * Math.PI) / 180);
		const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
		return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
	}
	function zoom(mapPx: number, worldPx: number, fraction: number) {
		return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
	}
	const latFraction = (latRad(ne.lat) - latRad(sw.lat)) / Math.PI;
	const lngDiff = ne.lng - sw.lng;
	const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;
	const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
	const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);
	return Math.min(latZoom, lngZoom, ZOOM_MAX);

}

/**
 * Compares two arrays of MapPosition for equality.
 * @param p1 - First array of MapPosition.
 * @param p2 - Second array of MapPosition.
 * @returns True if arrays are equal, otherwise false.
 */
export function compareMapPositionArrays(p1: MapPosition[], p2: MapPosition[]): boolean {
	if (p1.length !== p2.length) return false;
	const s1 = [...p1].sort((a, b) => b.longitude - a.longitude),
		s2 = [...p2].sort((a, b) => b.longitude - a.longitude);
	for (let i = 0; i < s1.length; i++) {
		if (
			s1[i].longitude !== s2[i].longitude ||
			s1[i].latitude !== s2[i].latitude
		) {
			return false;
		}
	}
	return true;
}

function getNorthEastSouthWest(bounds: LngLatBoundsLike) {
	if ("getNorthEast" in bounds) {
		return { ne: bounds.getNorthEast(), sw: bounds.getSouthWest() };
	} else if (Array.isArray(bounds) && bounds.length === 4) {
		return {
			ne: { lng: bounds[2], lat: bounds[3] },
			sw: { lng: bounds[0], lat: bounds[1] },
		};
	} else if (
		Array.isArray(bounds) &&
		bounds.length === 2 &&
		typeof bounds[0] !== "number"
	) {
		const points = bounds.map((lngLat) =>
			lngLatToMapPosition(lngLat as LngLatLike)
		);
		return {
			ne: { lng: points[1].longitude, lat: points[1].latitude },
			sw: { lng: points[0].longitude, lat: points[0].latitude },
		};
	} else {
		return null;
	}
}

