import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "../dashboard.module.css";

/**
 * Terms of Service View
 * @constructor
 */
export default function TOS() {

        const { t } = useTranslation();

        return (
            <Box className={styles.legalOverviewContent}>
                    <Typography>
                            {t("tos.Intro")}
                    </Typography>
                    <Typography>
                            {t("tos.1")}<br/>
                            {t("tos.1_1")}<br/>
                            {t("tos.1.2")}<br/>
                            {t("tos.1_3")}<br/>
                            {t("tos.1_4")}<br/>

                    </Typography>
                    <Typography>
                            {t("tos.2")}<br/>
                            {t("tos.2_1")}<br/>
                            {t("tos.2_2")}<br/>
                            {t("tos.2_3")}<br/>
                            {t("tos.2_4")}<br/>
                    </Typography>
                    <Typography>
                            {t("tos.3")}<br/>
                            {t("tos.3_1")}<br/>
                            {t("tos.3_2")}<br/>
                            {t("tos.3_3")}<br/>
                            {t("tos.3_4")}<br/>
                            {t("tos.3_5")}<br/>
                    </Typography>
                    <Typography>
                            {t("tos.4")}<br/>
                            {t("tos.4_1")}<br/>
                            {t("tos.4_2")}<br/>
                            {t("tos.4_3")}<br/>
                            {t("tos.4_4")}<br/>
                            {t("tos.4_5")}<br/>
                    </Typography>
                    <Typography>
                            {t("tos.5")}<br/>
                            {t("tos.5_1")}<br/>
                            {t("tos.5_2")}<br/>
                            {t("tos.5_3")}<br/>
                    </Typography>
                    <Typography>
                            {t("tos.6")}<br/>
                            {t("tos.6_1")}<br/>
                            {t("tos.6_2")}<br/>
                    </Typography>
                    <Typography>
                            {t("tos.7")}<br/>
                            {t("tos.7_1")}<br/>
                            {t("tos.7_2")}<br/>
                            {t("tos.7_3")}<br/>
                            {t("tos.7_4")}<br/>
                            {t("tos.7_5")}<br/>
                    </Typography>
                    <Typography>
                            {t("tos.8")}<br/>
                            {t("tos.8_1")}<br/>
                            {t("tos.8_2")}<br/>
                            {t("tos.8_3")}<br/>
                            {t("tos.8_4")}<br/>
                            {t("tos.8_4a")}<br/>
                            {t("tos.8_4b")}<br/>
                            {t("tos.8_4c")}<br/>
                            {t("tos.8_4e")}<br/>
                            {t("tos.8_4f")}<br/>
                            {t("tos.8_4g")}<br/>
                            {t("tos.8_5")}<br/>
                    </Typography>
                    <Typography>
                            {t("tos.9")}<br/>
                            {t("tos.9_1")}<br/>
                            {t("tos.9_2")}<br/>
                            {t("tos.9_3")}<br/>
                            {t("tos.9_4")}<br/>
                    </Typography>
                    <Typography>
                            {t("tos.10")}<br/>
                            {t("tos.10_1")}<br/>
                            {t("tos.10_2")}<br/>
                    </Typography>
                    <Typography>
                            {t("tos.11")}<br/>
                            {t("tos.11_1")}<br/>
                    </Typography>
                    <Typography>
                            {t("tos.12")}<br/>
                            {t("tos.12_1")}<br/>
                            {t("tos.12_2")}<br/>
                            {t("tos.12_3")}<br/>
                            {t("tos.12_4")}<br/>
                    </Typography>

                    <Typography>
                            {t("tos.tosState")}
                    </Typography>
            </Box>
        )
}