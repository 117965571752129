import { z } from "zod";
import { MetaDataSchema } from "./meta-data";
import {latitude, longitude} from "../map-position";

/**
 * Schema for Paths in TurningPoints.
 * @category Schema
 */
export const TurningPointSchema = MetaDataSchema.extend({
	accuracy: z.number(),
	name: z.string().nullable(),
	long: longitude,
	lat: latitude,
	number: z.string().nullable(),
});

/**
 * Type for TurningPoint in ForestManager.
 * Inferred from zod Schema.
 * @category Schema_Type
 */
type TurningPoint = z.infer<typeof TurningPointSchema>;
export default TurningPoint;

/**
 * Is-Function to check whether the parameter is actually a TurningPoint
 * @param turningPoint
 * @returns {boolean}
 */
export function isTurningPoint(
	turningPoint: TurningPoint | any
): turningPoint is TurningPoint {
	return TurningPointSchema.safeParse(turningPoint).success;
}
