import { ListItem, ListItemText, MenuList } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import {
	CheckCircleOutline,
	CopyAll,
	Delete,
	DoDisturb,
	Edit,
} from "@mui/icons-material";
import ManagementDropDown from "../../../components/popovers/management";
import styles from "../dashboard.module.css";
import { useTranslation } from "react-i18next";

/**
 * Dropdown component for managing tokens.
 * @param anchorEl - The anchor element to position the dropdown.
 * @param open - Boolean state indicating whether the dropdown is open.
 * @param setOpen - Function to set the open state of the dropdown.
 * @param isActive - Boolean indicating whether the token is active.
 * @param isExpired - Boolean indicating whether the token is expired.
 * @param handleClose - Function to handle closing the dropdown.
 * @param handleCopyClick - Function to handle the copy action.
 * @param handleEditClick - Function to handle the edit action.
 * @param handleDisableClick - Function to handle the disable action.
 * @param handleDeleteClick - Function to handle the delete action.
 */
export default function TokenManagementDropdown({
	anchorEl,
	open,
	setOpen,
	isActive,
	isExpired,
	handleClose,
	handleCopyClick,
	handleEditClick,
	handleDisableClick,
	handleDeleteClick,
}: {
	anchorEl: HTMLElement | null;
	open: boolean;
	setOpen: Dispatch<SetStateAction<HTMLElement | null>>;
	isActive: boolean;
	isExpired: boolean;
	handleClose: () => void;
	handleCopyClick: () => void;
	handleEditClick: () => void;
	handleDisableClick: () => void;
	handleDeleteClick: () => void;
}) {
	const {t} = useTranslation();

	return (
		<ManagementDropDown
			MenuListProps={{ "aria-labelledby": "token-row" }}
			id="token-row-dropdown"
			anchorEl={anchorEl}
			open={open}
			onClose={() => handleClose()}
			className={styles.dropdown}
		>
			<MenuList>
				<ListItem
					onClick={(event: { preventDefault: () => void }) => {
						event.preventDefault();
						handleCopyClick();
						setOpen(null);
					}}
					component="a"
				>
					<CopyAll className={styles.dropdownIcon} />
					<ListItemText primary={t("sidebar.actions.copy")} />
				</ListItem>
				<ListItem
					onClick={(event: { preventDefault: () => void }) => {
						event.preventDefault();
						handleEditClick();
						setOpen(null);
					}}
					component="a"
				>
					<Edit className={styles.dropdownIcon} />
					<ListItemText primary={t("sidebar.actions.edit")} />
				</ListItem>
				{!isExpired && (
					<ListItem
						onClick={(event: { preventDefault: () => void }) => {
							event.preventDefault();
							handleDisableClick();
							setOpen(null);
						}}
						component="a"
					>
						{isActive ? (
							<DoDisturb className={styles.dropdownIcon} />
						) : (
							<CheckCircleOutline className={styles.dropdownIcon} />
						)}
						<ListItemText primary={isActive ? t("sidebar.actions.disable") : t("sidebar.actions.enable")} />
					</ListItem>
				)}
				<ListItem
					onClick={(event: { preventDefault: () => void }) => {
						event.preventDefault();
						handleDeleteClick();
						setOpen(null);
					}}
					component="a"
				>
					<Delete className={styles.dropdownIcon} />
					<ListItemText primary={t("sidebar.actions.delete")} />
				</ListItem>
			</MenuList>
		</ManagementDropDown>
	);
}
