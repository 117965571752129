import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Input,
    styled,
    Tab,
    Tabs
} from "@mui/material";
import {Close} from "@mui/icons-material";
import {Dispatch, ReactNode, SetStateAction, useState} from "react";
import FilterSection from "../../filter/filter-section";
import Entities, {Entity} from "../../../types/entities";
import {useTranslation} from "react-i18next";
import {TabPanel} from "../../../components/layout/tab-panel";
import EntityCard from "../../../components/cards/entity-card";
import {useAppSelector} from "../../../store/hooks";
import {selectAllElements} from "../../../store/slices";
import store from "../../../store/store";
import useLocalStorage from "use-local-storage";
import filterStyles from "../../filter/filter.module.css";
import {de, enUS} from "date-fns/locale";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import i18n from "i18next";
import {FilterOptions} from "../../../types/filter-options";
import LoadingScreen from "../../../components/loading/loading-screen";
import styles from "./share.module.css";

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        width: 500,
        height: "90vh"
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: ReactNode;
    onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const {children, onClose, ...other} = props;

    return (
        <DialogTitle sx={{m: 0, p: 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close/>
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

function toChecked(el: Entity) {
    return {firestoreUid: el.firestoreUid, checked: false}
}

export interface SelectedShare {
    comment: string;
    expires: Date;
    filteroptions: FilterOptions;
    elementList: { [e in Entities]: { firestoreUid: string, checked: boolean }[] }
}

/**
 * Component for creating a dialog to generate showcases.
 * It allows users to select elements, add comments, and set expiration dates.
 * @param open - Boolean indicating whether the dialog is open.
 * @param setOpen - Function to set the dialog's open state.
 * @param onSubmit - Function to handle form submission.
 * @param loading - Boolean indicating loading state.
 */
export default function ShareCreationDialog({ open, setOpen, onSubmit, loading }: { open: boolean, setOpen: Dispatch<SetStateAction<boolean>>, onSubmit: (data: SelectedShare) => Promise<void>, loading: boolean }) {
    /** Current step in the dialog */
    const [currentStep, setCurrentStep] = useState<"select" | "comment">("select");
    /** Current tab index in the dialog */
    const [currentTab, setCurrentTab] = useState(0);
    /** Boolean indicating whether to show geo data */
    const [showGeoData] = useLocalStorage("showGeoData", false);
    /** Filter options for selecting elements */
    const [filteroptions, setFilteroptions] = useState<FilterOptions | null>(null);
    /** Selected elements */
    const data = useAppSelector((state) => selectAllElements(state));
    /** Checked cards for each entity */
    const [checkedCards, setCheckedCards] = useState(
        {
            [Entities.Areas]: data[Entities.Areas].map(toChecked),
            [Entities.Categories]: data[Entities.Categories].map(toChecked),
            [Entities.ImageLayer]: data[Entities.ImageLayer].map(toChecked),
            [Entities.Notes]: data[Entities.Notes].map(toChecked),
            [Entities.Paths]: data[Entities.Paths].map(toChecked),
            [Entities.TurningPoints]: data[Entities.TurningPoints].map(toChecked),
            [Entities.Media]: data[Entities.Media].map(toChecked),
            [Entities.LayerGroup]: data[Entities.LayerGroup].map(toChecked),
            [Entities.Grids]: data[Entities.Grids].map(toChecked),
        }
    );
    /** Comment for the showcase */
    const [comment, setComment] = useState("");
    /** Expiration date for the showcase */
    const [expires, setExpires] = useState(new Date(new Date().getTime() + (24 * 60 * 60 * 1000)));
    const {t} = useTranslation();

    /**
     * Handles form submission.
     * Validates the form data and calls the onSubmit function.
     */
    async function handleSubmit() {
        if (!filteroptions) return;
        const elementList = {...checkedCards};
        Object.keys(elementList).forEach((key) => {
            // @ts-ignore
            elementList[key] = elementList[key].filter((el) => el.checked).map((el) => el.firestoreUid);
        });
        await onSubmit({
            comment,
            expires,
            filteroptions,
            elementList
        });
    }

    return (
        <BootstrapDialog
            onClose={() => setOpen(false)}
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth="md"
            fullWidth={true}
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
                {t("shareOptions.showcaseGenerate")}
            </BootstrapDialogTitle>
            <DialogContent className={styles.showcaseDialog} dividers>
                {currentStep === "select" ?
                <>
                    <Tabs variant="scrollable" value={currentTab}
                          onChange={(event, nextValue) => setCurrentTab(nextValue)}>
                        <Tab label={t("shareOptions.noteFilter").toString()}/>
                        {Object.values(Entities).filter((n) => n !== Entities.LayerGroup || showGeoData).filter((n) => n !== Entities.Media).map((entityName, index) => (
                            <Tab label={t(`general.${entityName}`)} key={index}/>
                        ))}
                    </Tabs>
                    <Box className={styles.showcaseDialogContent}>
                        <TabPanel className={styles.dialogTabPanel} value={currentTab} index={0}>
                            <Box>
                                <FilterSection
                                    onReset={() => {}}
                                    onSubmit={() => {}}
                                    onChange={(filterOptions) => setFilteroptions(filterOptions)}
                                    hideTitle={true}/>
                            </Box>
                        </TabPanel>
                        {Object.values(Entities).filter((n) => n !== Entities.LayerGroup || showGeoData).filter((n) => n !== Entities.Media).map((entityName, index) => {
                            return (
                                <TabPanel className={styles.dialogTabPanel}
                                          value={currentTab} index={index + 1} key={index + 1}>
                                    <ul>
                                        {selectAllElements(store.getState())[entityName as Entities].map((el: Entity, index: number) => {
                                            return (
                                                <EntityCard item={el} key={index}
                                                            checked={checkedCards[entityName].find((ch) => el.firestoreUid === ch.firestoreUid)?.checked
                                                            }
                                                            onCheck={(checked) => {
                                                                const copy = {...checkedCards};
                                                                const card = copy[entityName].find((c: { firestoreUid: string; checked: boolean }) => c.firestoreUid === el.firestoreUid);
                                                                if (!card) return;
                                                                card.checked = checked;
                                                                setCheckedCards(copy);
                                                            }}
                                                />
                                            )
                                        })}
                                    </ul>
                                </TabPanel>
                            )
                        })}
                    </Box>
                </>
                    :
                    <>
                        {loading && <LoadingScreen isVisible={true} isFullscreen={false} isOpaque={false} />}
                        <Box className={styles.showcaseMeta}>
                            <Input
                                className={filterStyles.filterInput}
                                placeholder={t("comment").toString()}
                                value={comment}
                                onChange={(event) => setComment(event.target.value)}
                            />
                            <LocalizationProvider adapterLocale={i18n.resolvedLanguage === "de" ? de : enUS } dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label={t("validUntil").toString()}
                                    value={expires}
                                    onChange={(value) => value !== null && setExpires(value)}
                                />
                            </LocalizationProvider>
                        </Box>
                    </>
                }
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={() => {
                    if (currentStep === "select") {
                        setCurrentStep("comment");
                        return;
                    }
                    void handleSubmit();
                }}>
                    {currentStep === "select" ? t("continue") : t("create")}
                </Button>
            </DialogActions>
        </BootstrapDialog>
    )
}
