import {createAsyncThunk, createEntityAdapter, createSlice,} from "@reduxjs/toolkit";
import type {RootState} from "../store";
import {alphabetical, chronologicalCreated, chronologicalUpdated,} from "../sort-comparers";
import Grid from "../../types/entities/grid";
import {entityAsyncThunk} from "./index";
import Entities from "../../types/entities";


/**
 * This file defines the slice for the Grid Entity.
 * The general layout will be the same for all entity slices.
 *
 * Adapter: Manages list of all elements for this entity and supplies standard functions like addOne and updateOne
 * AsyncThunks: Asynchronously fetches data for this collection name from firestore and populates list initially
 * Selectors: Exports reusable selectors for this slice
 * Comparators: Exports the comparators that are applicable to this entity type
 *
 * @category Slice
 */

const gridAdapter = createEntityAdapter<Grid>({
    selectId: (grid) => grid.firestoreUid,
    sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const fetchGrids = createAsyncThunk(
    "grids/fetchGrids",
    async (userId: string) => entityAsyncThunk(Entities.Grids)(userId)
);

const initialState = gridAdapter.getInitialState({
    status: "idle",
});

const gridSlice = createSlice({
    name: "grids",
    initialState,
    reducers: {
        addGrid: gridAdapter.addOne,
        updateGrid: gridAdapter.updateOne,
        removeGrid: gridAdapter.removeOne,
        addGrids: gridAdapter.addMany
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchGrids.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchGrids.fulfilled, (state, action) => {
                gridAdapter.setAll(state, action.payload as Grid[]);
                state.status = "idle";
            });
    },
});

export const { addGrid, updateGrid, removeGrid, addGrids } = gridSlice.actions;

export default gridSlice.reducer;

export const { selectAll: selectGrids, selectById: selectGridById } =
    gridAdapter.getSelectors<RootState>((state) => state.data.grids);

const gridSortComparers = [
    { name: "Alphabetically", function: alphabetical },
    { name: "Creation Date", function: chronologicalCreated },
    { name: "Last Update", function: chronologicalUpdated },
];

export { gridSortComparers };
