import {DashboardRounded, DataArrayOutlined, Help as HelpIcon, Settings,} from "@mui/icons-material";
import {Box, SvgIconTypeMap, Typography} from "@mui/material";
import {OverridableComponent} from "@mui/material/OverridableComponent";
import {motion} from "framer-motion";
import Overview from "./overview";
import Share from "./share";
import Team from "./team";
import Preferences from "./preferences";
import Help from "./help/help";
import Search from "../../components/search";
import styles from "./dashboard.module.css";
import SharePartnerTab from "./share/share-partner-tab";
import {FaUserFriends} from "react-icons/fa";
import {TbBuildingBank, TbSlideshow} from "react-icons/tb";
import {useTranslation} from "react-i18next";
import {IconType} from "react-icons";
import DataManagement from "./data-management";
import {useSearchParams} from "react-router-dom";
import LegalOverview from "./legal/legal-overview";

/** Represents a tab in the dashboard. */
type Tab = {
	/** The translation key for the tab name. */
	nameKey: string;
	/** The icon component for the tab. */
	icon: OverridableComponent<SvgIconTypeMap> & { muiName: string } | IconType;
	/** The component to render for the tab. */
	component: JSX.Element;
};

/** Array of dashboard tabs. */
const tabs: Tab[] = [
		{ nameKey: "overview", icon: DashboardRounded, component: <Overview /> },
		{ nameKey: "sharepartner", icon: FaUserFriends, component: <SharePartnerTab />  },
		{ nameKey: "showcase", icon: TbSlideshow, component: <Share /> },
		{ nameKey: "team", icon: TbBuildingBank, component: <Team /> },
		{ nameKey: "dataManagement.title", icon: DataArrayOutlined, component: <DataManagement />  },
		{ nameKey: "preferences", icon: Settings, component: <Preferences /> },
		{ nameKey: "help", icon: HelpIcon, component: <Help /> },
		{ nameKey: "legalOverview", icon: HelpIcon, component: <LegalOverview />}
];

/**
 * Dashboard component.
 * Renders the dashboard interface with tabs for various functionalities.
 */
export default function Dashboard() {
	const [searchParams, setSearchParams] = useSearchParams();
	const selectedTabIndex = Number.parseInt(searchParams.get("selectedTabIndex") ?? "0");
	const {t} = useTranslation();

	return (
		<Box className={styles.dashboard}>
			<Box className={styles.logo}>
				<img src="/images/fm-logo.png" width={200} alt="product-logo" />
			</Box>
			<Box className={styles.menu}>
				<Box>
					{tabs.map((tab, index) => {
						const Icon = tab.icon;
						return (
							<Box
								key={index}
								onClick={() => {
									searchParams.set("selectedTabIndex", String(index));
									setSearchParams(searchParams);
								}}
								className={
									selectedTabIndex === index ? styles.menuSelected : ""
								}
							>
								<Icon />
								<Typography>{t(`dashboard.${tab.nameKey}`)}</Typography>
							</Box>
						);
					})}
					<motion.span
						className={styles.menuIndicator}
						animate={{ top: 10 + selectedTabIndex * 80 }}
					/>
				</Box>
			</Box>
			<Box className={styles.search}>
				<Search />
			</Box>
			<Box className={styles.content}>{tabs[selectedTabIndex].component}</Box>
		</Box>
	);
}
