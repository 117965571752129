import { useParams } from "react-router";
import { useEffect } from "react";
import { initShowcaseSnapshots } from "../../lib/firebase/firestore";
import MapPage from "../../pages/map";
import { ProvideAuth } from "../../hooks/use-auth";


/**
 * Dedicated Showcase Route.
 * Requires a token id to be passed as parameter in the URL.
 * Loads content if token is valid and active, then shows a Map without Navigation.
 * @constructor
 * @category route
 */
export default function ShowcaseRoute() {
	const { tokenId } = useParams();

	useEffect(() => {
		tokenId && initShowcaseSnapshots(tokenId);
	}, [tokenId]);

	return (
		<ProvideAuth>
			<MapPage />
		</ProvideAuth>
	);
}
