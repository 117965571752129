import {useSearchParams} from "react-router-dom";
import Entities from "../../types/entities";
import {RootState} from "../../store/store";
import {selectElementForEntityId} from "../../store/slices";

/**
 * Custom hook for managing sidebar parameters.
 * Manages parameters related to sidebar state, such as open/close status, active tab, element ID, and form state.
 * Provides functions to update these parameters.
 * @returns Object containing sidebar parameters and functions to update them.
 */
export default function useSidebarParams() {
    // Get search parameters from the URL
    const [searchParams, setSearchParams] = useSearchParams({});
    // Extract sidebar parameters from searchParams
    const sidebarOpen = searchParams.get("sidebarOpen") === "true";
    let tab = (searchParams.get("tab") ?? Entities.Notes) as Entities;
    const elementId = searchParams.get("elementId") !== "null" ? searchParams.get("elementId") : null;
    const formOpen = searchParams.get("formOpen") === "true";
    const titleImageIndex = Number(searchParams.get("titleImageIndex") ?? 0);

    // Function to open the sidebar
    function openSidebar() {
        searchParams.set("sidebarOpen", "true");
        setSearchParams(searchParams);
    }

    // Function to close the sidebar
    function closeSidebar() {
        searchParams.delete("sidebarOpen");
        setSearchParams(searchParams);
    }

    // Function to set the active tab
    function setTab(tab: Entities) {
        setSearchParams({ sidebarOpen: "true", tab });
        updateStateTab(tab);
    }

    // helperfunction to set state tab of this hook (bc nameconflict in setTab)
    function updateStateTab(t: Entities) {
        tab = t;
    }

    // Function to set the active element detail
    function setDetail(elementId: string | null) {
        setSearchParams({ sidebarOpen: "true", tab, elementId: `${elementId}` });
    }

    // Function to open the form
    function openForm() {
        searchParams.set("formOpen", "true");
        setSearchParams(searchParams);
    }

    // Function to close the form
    function closeForm() {
        searchParams.delete("formOpen");
        setSearchParams(searchParams);
    }

    // Function to set the element being edited in the form
    function setEditElement(elementId: string | null) {
        searchParams.set("formOpen", "true");
        searchParams.set("elementId", `${elementId}`);
        setSearchParams(searchParams);
    }

    // Function to set the index of the title image
    function setTitleImageIndex(index: number) {
        searchParams.set("titleImageIndex", index.toString());
        setSearchParams(searchParams);
    }

    // Function to select the current element based on the sidebar state
    function selectCurrentElement(state: RootState) {
        if (!elementId) return undefined;
        return selectElementForEntityId(state, elementId, tab);
    }

    // Return an object containing sidebar parameters and functions to update them
    return {
        sidebarOpen,
        openSidebar,
        closeSidebar,
        tab,
        setTab,
        elementId,
        setDetail,
        formOpen,
        openForm,
        closeForm,
        setEditElement,
        titleImageIndex,
        setTitleImageIndex,
        selectCurrentElement
    }
}
