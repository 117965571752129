import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useMemo, useState} from "react";
import AGB from "./agb";
import TOS from "./tos";
import Contact from "./contact";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import styles from "../dashboard.module.css";

enum Pages {
    TOS,
    Legal,
    Contact
}





export default function LegalOverview() {
    const [overviewState, setOverviewState] = useState<Pages | null>(Pages.Contact)
    const { t } = useTranslation();


    function setState(state: Pages) {
        setOverviewState(state);
    }

    // switch between different views depending on users choice
    const Page = useMemo(() => {
        switch (overviewState) {
            default:
                return <></>
            case Pages.Legal:
                return <AGB></AGB>
            case Pages.TOS:
                return <TOS></TOS>
            case Pages.Contact:
                return <Contact></Contact>
        }
    }, [overviewState])

    return (
        <Box className={styles.legalOverviewContainer}>
            <div className={styles.flex}>
                {/* CONTACT */}
                <Card sx={{ minWidth: 275 }} className={styles.legalOverviewCard}>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            {t("dashboard.contact")}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button onClick={() => setState(Pages.Contact)} size="small">{t("dashboard.more")}</Button>
                    </CardActions>
                </Card>
                {/* TEARMS OF SERVICE */}
                <Card sx={{ minWidth: 275 }} className={styles.legalOverviewCard}>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            {t("dashboard.tos")}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button onClick={() => setState(Pages.TOS)} size="small">{t("dashboard.more")}</Button>
                    </CardActions>
                </Card>
                {/* LEGAL */}
                <Card sx={{ minWidth: 275 }} className={styles.legalOverviewCard}>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            {t("dashboard.legal")}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button onClick={() => setState(Pages.Legal)} size="small">{t("dashboard.more")}</Button>
                    </CardActions>
                </Card>
            </div>
            {/* Dynamic Content depending on users choice */}
            {Page}
        </Box>
    );
}