import { Box, FormControl, IconButton, Input, InputLabel, MenuItem, Select, } from "@mui/material";
import styles from "../sidebar.module.css";
import { CancelRounded, Check } from "@mui/icons-material";
import { useAppDispatch } from "../../../store/hooks";
import { useMemo, useState } from "react";
import Category from "../../../types/entities/category";
import { SliderPicker } from "react-color";
import { getRgb, hexToRgb, rgbToHsv } from "../../../lib/model/utils/colors";
import { symbols } from "../../../components/icons";
import IconAvatar from "../../../components/icons/icon-avatar";
import { v4 } from "uuid";
import { addCategory, updateCategory, } from "../../../store/slices/category-slice";
import { addFirestoreElement, updateFirestoreElement, } from "../../../lib/firebase/firestore";
import Entities from "../../../types/entities";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../hooks/use-auth";
import useSidebarParams from "../use-sidebar-params";
import { useFeedback } from "../../feedback/use-feedback";

/**
 * Renders a form for creating or updating a category.
 * @param category - The category object to be updated.
 */
export default function CategoryForm({ category }: { category: Category }) {
	// Local state for form inputs
	const [name, setName] = useState(category ? category.name : "");
	const [symbolName, setSymbolName] = useState(
		category ? category.symbol_name : symbols[0]
	);
	// Determines if the form can be submitted
	const [color, setColor] = useState(category ? getRgb(category) : "#006fff");
	const canSubmit = useMemo(() => {
		if (category) {
			return (
				name !== category.name ||
				symbolName !== category.symbol_name ||
				color !== category.color
			);
		}
		return name !== "";
	}, [category, name, symbolName, color]);
	const auth = useAuth(); // Authentication hook
	const dispatch = useAppDispatch(); // Redux dispatch function
	const { t } = useTranslation(); // Translation hook
	const { closeForm } = useSidebarParams(); // Sidebar parameters hook
	const {setFeedback} = useFeedback();

	function handleSubmit() {
		if (!canSubmit || !auth.user) {
			setFeedback(t("errors.default"), "error");
			return;
		}
		if (!symbolName) {
			setFeedback(t("errors.default"), "error");
			return;
		}
		const { r, g, b } = hexToRgb(color);
		const hsv = rgbToHsv(r, g, b);
		if (category) {
			const changes = {
				version: category.version ? category.version + 1 : 1,
				name,
				symbol_name: symbolName?.includes("icon_")
					? symbolName
					: "icon_" + symbolName,
				color,
				hue: hsv.h,
				saturation: hsv.s,
				lightness: hsv.v,
				updatedAt: Date.now(),
				changedBy: auth.user.uid,
			};
			dispatch(
				updateCategory({
					id: category.firestoreUid,
					changes,
				})
			);
			updateFirestoreElement(auth.user.uid, Entities.Categories, {
				...category,
				...changes,
			});
		} else {
			const newCategory: Category = {
				firestoreUid: v4(),
				sharePartnerUid: auth.user.uid,
				version: 0,
				name,
				symbol_name: "icon_" + symbolName,
				color,
				icon: -1,
				hue: hsv.h,
				saturation: hsv.s,
				lightness: hsv.v,
				createdAt: Date.now(),
				deletedAt: null,
				updatedAt: Date.now(),
				changedBy: auth.user.uid,
			};
			dispatch(addCategory(newCategory));
			addFirestoreElement(
				auth.user.uid,
				Entities.Categories,
				newCategory
			);
		}
		closeForm();
	}

	return (
		<>
			<Box className={styles.listButtonContainer}>
				<IconButton
					disabled={!canSubmit}
					style={canSubmit ? { color: "var(--accent)" } : {}}
					onClick={handleSubmit}
				>
					<Check />
				</IconButton>
				<IconButton onClick={() => closeForm()}>
					<CancelRounded />
				</IconButton>
			</Box>
			<Box className={styles.formControlContainer}>
				<FormControl fullWidth>
					<InputLabel className={styles.inputLabel} htmlFor="name">
						{t("name")}
					</InputLabel>
					<Input
						required={true}
						className={styles.input}
						id="name"
						type="text"
						value={name}
						onChange={(event) => setName(event.target.value)}
					/>
				</FormControl>
				<FormControl className={styles.iconSelect} fullWidth>
					<InputLabel className={styles.inputLabel} htmlFor="category">
						{t("sidebar.form.selectSymbol")}
					</InputLabel>
					<Select
						MenuProps={{
							MenuListProps: { className: `${styles.selectMenu} ${styles.selectMenuGrid}` },
						}}
						className={styles.formSelect}
						id="category"
						value={symbolName}
						onChange={(event) => {
							setSymbolName(event.target.value);
						}}
					>
						{symbols.map((s, index) => (
							<MenuItem key={index} value={s}>
								<IconAvatar color={color} symbol={s} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<Box className={styles.colorSelection}>
					<label className={styles.label}>{t("color")}</label>
					<SliderPicker
						color={color}
						onChange={(c) => {
							setColor(c.hex);
						}}
						className={styles.colorSlider}
					/>
				</Box>
			</Box>
		</>
	);
}
