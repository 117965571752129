import { useAppSelector } from "../store/hooks";
import ElementList from "../types/element-list";
import { Entity } from "../types/entities";
import { useMemo } from "react";
import { useAuth } from "./use-auth";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

/**
 * Utility function to reduce the element list to an array of IDs.
 * @param list - The element list to reduce.
 * @returns An array of element IDs.
 */
function reduceElementList(list: ElementList): string[] {
	if (!list) return [];
	const allIds: string[] = [];
	Object.values(list).forEach((arr) => {
		allIds.push(...arr);
	});
	return allIds;
}

/**
 * Hook to determine the owner of a specific element.
 * @param element - The element for which to determine the owner.
 * @returns The owner of the element or a placeholder value if no owner is found.
 */
export default function useElementOwner(element: Entity | undefined) {
	// Retrieve authentication information
	const auth = useAuth();
	// Retrieve share partners from the store
	const sharePartners = useAppSelector(
		(state) => state.dashboard.sharePartners
	);

	// Find the share partner that owns the element
	const partner = useMemo(() => {
		return sharePartners.find((sp) =>
			reduceElementList(sp.sharedElementList).find(
				(id) => element?.firestoreUid === id
			)
		);
	}, [sharePartners, element]);

	// Get the current location
	const location = useLocation();
	// Get translation function
	const { t } = useTranslation();

	// Return the owner if found; otherwise, return a placeholder value based on the location and authentication status
	if (partner) {
		return partner;
	}
	return location.pathname.includes("showcase")
		? "Vorführer"
		: auth.user && typeof auth.user !== "boolean"
			? auth.user
			: { name: t("errors.noOwner").toString(), email: t("errors.noEmail").toString() };
}
