import {createAsyncThunk, createEntityAdapter, createSlice,} from "@reduxjs/toolkit";
import type {RootState} from "../store";
import TurningPoint from "../../types/entities/turning-point";
import {entityAsyncThunk} from "./index";
import Entities from "../../types/entities";

/**
 * This file defines the slice for the TurningPoint Entity.
 * The general layout will be the same for all entity slices.
 *
 * Adapter: Manages list of all elements for this entity and supplies standard functions like addOne and updateOne
 * AsyncThunks: Asynchronously fetches data for this collection name from firestore and populates list initially
 * Selectors: Exports reusable selectors for this slice
 * Comparators: Exports the comparators that are applicable to this entity type
 *
 * @category Slice
 */

const turningPointAdapter = createEntityAdapter<TurningPoint>({
	selectId: (turningPoint) => turningPoint.firestoreUid,
	sortComparer: (a, b) => {
		if (a.name && b.name) return a.name.localeCompare(b.name);
		else if (a.number !== null && b.number === null) return 1;
		else if (a.number === null && b.number !== null) return -1;
		else if (a.number === null && b.number === null) return 0;
		// @ts-ignore
		return a.number > b.number ? 1 : a.number < b.number ? -1 : 0;
	},
});

export const fetchTurningPoints = createAsyncThunk(
	"turningPoints/fetchTurningPoints",
	async (userId: string) => entityAsyncThunk(Entities.TurningPoints)(userId)
);

const initialState = turningPointAdapter.getInitialState({
	status: "idle",
});

const turningPointSlice = createSlice({
	name: "turningPoints",
	initialState,
	reducers: {
		addTurningPoint: turningPointAdapter.addOne,
		updateTurningPoint: turningPointAdapter.updateOne,
		removeTurningPoint: turningPointAdapter.removeOne,
		addTurningPoints: turningPointAdapter.addMany
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchTurningPoints.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchTurningPoints.fulfilled, (state, action) => {
				turningPointAdapter.setAll(state, action.payload as TurningPoint[]);
				state.status = "idle";
			});
	},
});

export const { addTurningPoint, updateTurningPoint, removeTurningPoint, addTurningPoints } =
	turningPointSlice.actions;

export default turningPointSlice.reducer;

export const {
	selectAll: selectTurningPoints,
	selectById: selectTurningPointById,
} = turningPointAdapter.getSelectors<RootState>(
	(state) => state.data.turningPoints
);
