import {Suspense, useEffect, useMemo, useState} from "react";
import Grid, {GridType} from "../../../types/entities/grid";
import {useAuth} from "../../../hooks/use-auth";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {useTranslation} from "react-i18next";
import {useMap} from "react-map-gl";
import {Box, Button, FormControl, IconButton, Input, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import styles from "../sidebar.module.css";
import {CancelRounded, Check, Room} from "@mui/icons-material";
import FormItemCover from "../../../components/sections/form-item-cover";
import {TbColorPicker, TbPolygon} from "react-icons/tb";
import {SliderPicker} from "react-color";
import {calculateBboxForCenterWidthHeight} from "../../map/items/grids";
import {removeTempGrid, setTempGrid} from "../../map/map-slice";
import {calculateCenter, lngLatToMapPosition} from "../../../lib/model/utils/geo";
import ImageCard, {ImageCardSkeleton} from "../../../components/cards/image-card";
import getStaticMapPreview, {PreviewType} from "../../../lib/model/utils/static-maps";
import {selectNotesByAreaId} from "../../../store/slices/note-slice";
import store from "../../../store/store";
import Entities from "../../../types/entities";
import {selectAreaById, selectAreas} from "../../../store/slices/area-slice";
import {selectGrids} from "../../../store/slices/grid-slice";
import {addFirestoreElement, updateFirestoreElement} from "../../../lib/firebase/firestore";
import {v4} from "uuid";
import {addGrid, updateGrid} from "../../../store/slices/grid-slice";
import useSidebarParams from "../use-sidebar-params";
import { useFeedback } from "../../feedback/use-feedback";

const MAX_CELLS = 10000;

/**
 * Renders a form for creating or updating a grid.
 * @param grid - The grid object to be updated.
 */
export default function GridForm({ grid }: { grid?: Grid }) {
    // Local state and Redux hooks
    const tempGrid = useAppSelector((state) => state.map.tempGrid);
    const areas = useAppSelector((state) => selectAreas(state));
    const grids = useAppSelector((state) => selectGrids(state));
    const areaBeforeEdit = grid?.areaUid;
    const {setFeedback} = useFeedback();
    const [name, setName] = useState(grid ? grid.name : "");
    const [description, setDescription] = useState(grid ? grid.description : "");
    const [areaSelectCovered, setAreaSelectCovered] = useState(Boolean(grid && grid.areaUid));
    const [areaSelectOpen, setAreaSelectOpen] = useState(false);

    const cellCount = useMemo(() => {
        if(tempGrid?.width && tempGrid?.cellWidth && tempGrid?.height && tempGrid?.cellHeight) {
            const rows = tempGrid.width / tempGrid.cellWidth;
            const cols = tempGrid.height / tempGrid.cellHeight;
            return rows * cols * 4; // renders rows and cols from center -> times 4
        }
        return 0;
    }, [tempGrid?.width, tempGrid?.height, tempGrid?.cellWidth, tempGrid?.cellHeight]);

    const canSubmit = useMemo(() => {
        const areaAlreadyHasGrid = grids.some((grid) => {
            return (grid.areaUid === tempGrid?.areaUid) && (grid.areaUid !== areaBeforeEdit);
        })
        if(name === "" || tempGrid?.areaUid === undefined || areaAlreadyHasGrid || cellCount > MAX_CELLS) {
            return false;
        }
        return true;
    }, [name, tempGrid?.areaUid, cellCount]);

    const { m1: map } = useMap();
    const auth = useAuth();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { closeForm, setTab, setEditElement } = useSidebarParams();

    // Initialize tempGrid when map is available
    useEffect(() => {
        if (!tempGrid && map) {
            // Set initial tempGrid state based on grid or map center
            dispatch(
                setTempGrid({
                    center: grid ? grid.center : lngLatToMapPosition(map.getCenter()),
                    width: grid ? grid.width : 2000,
                    height: grid ? grid.height : 2000,
                    cellWidth: grid ? grid.cellWidth : 200,
                    cellHeight: grid ? grid.cellHeight : 200,
                    rotation: grid ? grid.rotation : 0,
                    color: grid ? grid.color : "#208C20",
                    type: grid ? grid.type : GridType.RECTANGLE,
                    lineThickness: grid ? grid.lineThickness : 2,
                    areaUid: grid ? grid.areaUid : undefined
                })
            );
            // Fit map to grid bounds if grid exists
            grid &&
            map.fitBounds(
                calculateBboxForCenterWidthHeight(grid ? grid.center : lngLatToMapPosition(map.getCenter()), 3000, 3000) as [number, number, number, number],
                { padding: 200 }
            );
        }
    }, []);

    // Set areaSelectCovered based on tempGrid's areaUid
    useEffect(() => {
        if (tempGrid && tempGrid.areaUid) {
            const areaAlreadyHasGrid = grids.some((grid) => {
                return (grid.areaUid === tempGrid?.areaUid) && (grid.areaUid !== areaBeforeEdit);
            })
            if(!areaAlreadyHasGrid) {
                setAreaSelectCovered(true);
            }
            else {
                setFeedback(t("sidebar.form.feedbackAreaAlreadyHasGrid"), "error");
                setAreaSelectCovered(false);
            }
        }
    }, [tempGrid]);

    // Clean up tempGrid on unmount
    useEffect(() => {
        return () => {
            dispatch(removeTempGrid())
        }
    }, []);

    // Handles form submission
    function handleSubmit() {
        if (!canSubmit || !tempGrid || !auth.user) {
            return;
        }
        if (grid) {
            const updatedGrid = {
                name,
                description,
                updatedAt: Date.now(),
                version: grid.version ? grid.version + 1 : 0,
                changedBy: auth.user.uid,
                ...tempGrid
            };
            dispatch(
                updateGrid({
                    id: grid.firestoreUid,
                    changes: updatedGrid,
                })
            );
            updateFirestoreElement(auth.user.uid, Entities.Grids, {
                ...grid,
                ...updatedGrid,
            });
        } else {
            const newGrid: Grid = {
                firestoreUid: v4(),
                sharePartnerUid: auth.user.uid,
                deletedAt: null,
                name,
                description,
                createdAt: Date.now(),
                updatedAt: Date.now(),
                changedBy: auth.user.uid,
                version: 0,
                ...tempGrid
            };
            dispatch(addGrid(newGrid));
            addFirestoreElement(auth.user.uid, Entities.Grids, newGrid);
        }
        closeForm();
    }

    // Return Empty if tempGrid is not initialized
    if (!tempGrid) return <></>

    return (
        <>
            <Box className={styles.listButtonContainer}>
                <IconButton
                    disabled={!canSubmit}
                    style={canSubmit ? { color: "var(--accent)" } : {}}
                    onClick={handleSubmit}
                >
                    <Check />
                </IconButton>
                <IconButton onClick={() => closeForm()}>
                    <CancelRounded />
                </IconButton>
            </Box>
            <Box className={styles.formControlContainer}>
                <FormControl fullWidth>
                    <InputLabel className={styles.inputLabel} htmlFor="name">
                        {t("name")}
                    </InputLabel>
                    <Input
                        required={true}
                        className={styles.input}
                        id="name"
                        type="text"
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel className={styles.inputLabel} htmlFor="description">
                        {t("description")}
                    </InputLabel>
                    <Input
                        className={styles.input}
                        id="description"
                        type="text"
                        value={description}
                        onChange={(event) => setDescription(event.target.value)}
                    />
                </FormControl>
                <FormItemCover label={t("sidebar.form.customColor")} Icon={TbColorPicker}>
                    <Box className={styles.colorSelection}>
                        <label className={styles.label}>{t("color")}</label>
                        <SliderPicker
                            color={tempGrid.color}
                            onChange={(c) => {
                                tempGrid && dispatch(setTempGrid({...tempGrid, color: c.hex }));
                            }}
                            className={styles.colorSlider}
                        />
                    </Box>
                </FormItemCover>
                <FormControl fullWidth>
                    <InputLabel className={styles.input} htmlFor="type">
                        {t("sidebar.form.gridType")}
                    </InputLabel>
                    <Select
                        MenuProps={{
                            MenuListProps: { className: styles.selectMenu },
                        }}
                        id="type"
                        value={tempGrid.type}
                        onChange={(event) => {
                            tempGrid && dispatch(setTempGrid({ ...tempGrid, type: event.target.value as GridType }))
                        }}
                    >
                        {Object.values(GridType)
                            .map((value, index) => (
                                <MenuItem key={index} value={value}>
                                    {value}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel className={styles.inputLabel} htmlFor="width">
                        {t("sidebar.form.width")}
                    </InputLabel>
                    <Box className={styles.pathWidthInput}>
                        <Input
                            required={true}
                            id="width"
                            type="number"
                            value={tempGrid.width}
                            onChange={(event) => {
                                tempGrid && dispatch(setTempGrid({ ...tempGrid, width: Number(event.target.value) }))
                            }}
                            onFocus={(event) => event.target.select()}
                        />
                        <div>{t("measurements.meters")}</div>
                    </Box>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel className={styles.inputLabel} htmlFor="height">
                        {t("sidebar.form.height")}
                    </InputLabel>
                    <Box className={styles.pathWidthInput}>
                        <Input
                            required={true}
                            id="height"
                            type="number"
                            value={tempGrid.height}
                            onChange={(event) => {
                                tempGrid && dispatch(setTempGrid({ ...tempGrid, height: Number(event.target.value) }))
                            }}
                            onFocus={(event) => event.target.select()}
                        />
                        <div>{t("measurements.meters")}</div>
                    </Box>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel className={styles.inputLabel} htmlFor="cellWidth">
                        {t("sidebar.form.cellWidth")}
                    </InputLabel>
                    <Box className={styles.pathWidthInput}>
                        <Input
                            required={true}
                            id="cellWidth"
                            type="number"
                            value={tempGrid.cellWidth}
                            onChange={(event) => {
                                if(Number(event.target.value) < 1) {
                                    const n = 1;
                                    event.target.value = n.toString();
                                }
                                tempGrid && dispatch(setTempGrid({ ...tempGrid, cellWidth: Number(event.target.value) }))
                            }}
                            onFocus={(event) => event.target.select()}
                        />
                        <div>{t("measurements.meters")}</div>
                    </Box>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel className={styles.inputLabel} htmlFor="cellHeight">
                        {t("sidebar.form.cellHeight")}
                    </InputLabel>
                    <Box className={styles.pathWidthInput}>
                        <Input
                            required={true}
                            id="cellHeight"
                            type="number"
                            value={tempGrid.cellHeight}
                            onChange={(event) => {
                                if(Number(event.target.value) < 1) {
                                    const n = 1;
                                    event.target.value = n.toString();
                                }
                                tempGrid && dispatch(setTempGrid({ ...tempGrid, cellHeight: Number(event.target.value) }))
                            }}
                            onFocus={(event) => event.target.select()}
                        />
                        <div>{t("measurements.meters")}</div>
                    </Box>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel className={styles.inputLabel} htmlFor="rotation">
                        {t("sidebar.form.rotation")}
                    </InputLabel>
                    <Box className={styles.pathWidthInput}>
                        <Input
                            required={true}
                            id="rotation"
                            type="number"
                            value={tempGrid.rotation}
                            onChange={(event) => {
                                tempGrid && dispatch(setTempGrid({ ...tempGrid, rotation: Number(event.target.value) }))
                            }}
                            onFocus={(event) => event.target.select()}
                        />
                        <div>{t("measurements.meters")}</div>
                    </Box>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel className={styles.inputLabel} htmlFor="lineThickness">
                        {t("sidebar.form.lineThickness")}
                    </InputLabel>
                    <Box className={styles.pathWidthInput}>
                        <Input
                            required={true}
                            id="lineThickness"
                            type="number"
                            value={tempGrid.lineThickness}
                            onChange={(event) => {
                                if(Number(event.target.value) < 0) {
                                    const n = 0;
                                    event.target.value = n.toString();
                                }
                                tempGrid && dispatch(setTempGrid({ ...tempGrid, lineThickness: Number(event.target.value) }))
                            }}
                            onFocus={(event) => event.target.select()}
                        />
                        <div>{t("sidebar.form.pixels")}</div>
                    </Box>
                </FormControl>
                <FormItemCover
                    initialVisibility={areaSelectCovered}
                    label={t("sidebar.form.assignArea")}
                    Icon={TbPolygon}
                    onDisplay={() => {
                        if (areas.length > 0) {
                            setAreaSelectOpen(true);
                        }
                    }}
                >
                    <FormControl fullWidth>
                        <InputLabel className={`${styles.inputLabel} ${styles.inputLabelOpaque}`} htmlFor="area">
                            {t("sidebar.form.chooseArea")}
                        </InputLabel>
                        {areas.length > 0 ? (
                            <Select
                                open={areaSelectOpen}
                                onOpen={() => setAreaSelectOpen(true)}
                                onClose={() => setAreaSelectOpen(false)}
                                MenuProps={{
                                    MenuListProps: { className: styles.cardSelectMenu },
                                }}
                                className={styles.cardSelect}
                                id="area"
                                value={tempGrid.areaUid}
                                onChange={(event) => {
                                    const areaUid = event.target.value;
                                    if (!areaUid) return;
                                    if(grids.some((grid) => (grid.areaUid === areaUid) && grid.areaUid !== areaBeforeEdit)) {
                                        setFeedback(t("sidebar.form.feedbackAreaAlreadyHasGrid"), "error");
                                        return;
                                    }
                                    const area = selectAreaById(store.getState(), areaUid);
                                    if (!area) return;
                                    const c = calculateCenter(area.points);
                                    tempGrid && event.target.value && dispatch(setTempGrid({ ...tempGrid, areaUid,
                                        // TODO: Fix this typing
                                        // @ts-ignore
                                        center: { longitude: c[0], latitude: c[1], altitude: 0 } }
                                    ));
                                    map?.fitBounds(calculateBboxForCenterWidthHeight(lngLatToMapPosition(c), tempGrid.width, tempGrid.height) as [number, number, number, number]);
                                    setAreaSelectOpen(false);
                                }}
                            >
                                <MenuItem className={styles.noSelection} onClick={() => {
                                    setAreaSelectOpen(false);
                                    dispatch(setTempGrid({...tempGrid, areaUid: undefined }));
                                    setFeedback(t("sidebar.form.feedbackAreaNeeded"), "warning");
                                }}>
                                    {t("sidebar.form.dontAssign", { entity: t("model.area") })}
                                </MenuItem>
                                {areas.map((a, index) => (
                                    <MenuItem key={index} value={a.firestoreUid}>
                                        <Suspense fallback={<ImageCardSkeleton />}>
                                            <ImageCard
                                                id={a.firestoreUid}
                                                name={a.name}
                                                type={Entities.Areas}
                                                color={a.color}
                                                metaText={
                                                    <Typography className={styles.metaText}>
                                                        <Room />{" "}
                                                        {t("cards.assignedNotes", {
                                                            num: selectNotesByAreaId(
                                                                store.getState(),
                                                                a.firestoreUid
                                                            ).length,
                                                        })}
                                                    </Typography>
                                                }
                                                onClick={() => {}}
                                            />
                                        </Suspense>
                                    </MenuItem>
                                ))}
                            </Select>
                        ) : (
                            <Box>
                                <Typography>{t("sidebar.form.noAreasYet")}</Typography>
                                <Button
                                    onClick={() => {
                                        setTab(Entities.Areas);
                                        setEditElement(null);
                                    }}
                                >
                                    {t("sidebar.form.toAreaForm")}
                                </Button>
                            </Box>
                        )}
                    </FormControl>
                </FormItemCover>
            </Box>
        </>
    )
}
