import Entities, {Entity, EntityListWithUncertainties} from "../../types/entities";
import {collection, doc, getDoc, onSnapshot, query, setDoc, Timestamp, updateDoc, where} from "firebase/firestore";
import {firestore} from "./index";
import store from "../../store/store";
import MetaData from "../../types/entities/meta-data";
import {User} from "firebase/auth";
import {accessShowcase, fetchSharePartnersForUser} from "./functions";
import {translateDatesToTimestamps, translateTimestampsToDate,} from "../model/utils/time";
import EntityFirestoreActions, {Migrations} from "./entity-actions";
import getBrowserFingerprint from 'get-browser-fingerprint';
import axios from "axios";
import DeviceRecord from "../../types/device-record";
import UAParser from "ua-parser-js";

const unsubscribe: (() => void)[] = [];

/**
 * Generic function for all Entity Types. Takes a Type as generic, an Entity Name, a userId and a number of callback options that
 * are invoked when a change in the firestore DB is detected.
 * This function is supposed to generify handling of these Snapshot Listeners for all Entity Types.
 * @param entity {Entities}
 * @param userId {string}
 * @param onAdded {(T) => void} - Is called when an Item is added to the collection; This function will be invoked for each item in the collection on initialization.
 * @param onChanged {(T) => void} - Is called each time an Item in the collection changes.
 * @param onDeleted {(T) => void} - Is Called each time an Item is deleted
 * @param options - Additional properties that explain how an Item should be validated regarding a schema and possible Migrations that need to be applied
 */
function setSnapshotListener<T>(
	entity: Entities,
	userId: string,
	onAdded: (e: T) => void,
	onChanged: (e: T) => void,
	onDeleted: (e: T) => void,
	options: {
		validate?: (e: T | any) => boolean;
		elementId?: string;
		migrate?: (e: any) => any;
	}
) {
	const { validate, migrate, elementId } = options;
	if (elementId) {
		const d = doc(firestore, `users/${userId}/${entity}/${elementId}`);
		const unsub = onSnapshot(d, (snapshot) => {
			const data = snapshot.data();
			if (!data) return;
			migrate && migrate(data);
			translateTimestampsToDate(data);
			if (validate && !validate(data)) return;
			onAdded(data as T);
		});
		unsubscribe.push(unsub);
		return;
	}
	const q = query(
		collection(firestore, `users/${userId}/${entity}`),
		where("deletedAt", "==", null)
	);
	const unsub = onSnapshot(q, (snapshot) => {
		snapshot.docChanges().forEach((change) => {
			const data = change.doc.data();
			if (!data) return;
			migrate && migrate(data);
			translateTimestampsToDate(data);
			if (validate && !validate(data)) return;
			if (change.type === "added") {
				onAdded(data as T);
			}
			if (change.type === "modified") {
				onChanged(data as T);
			}
			if (change.type === "removed") {
				onDeleted(data as T);
			}
		});
	});
	unsubscribe.push(unsub);
}

/**
 * Calls setSnapshotListener for each Entity Type and registers the actions defined in entity-actions.ts.
 * Also gathers some information about the user and their current device.
 * @param user {User}
 */
export default async function initFirestoreSnapshots(user: User) {
	const userId = user.uid;
	void fetchSharePartnersForUser().then((res: any) => {
		if (res.data.success) {
			const partner = res.data.partners;
			partner.forEach(
				(p: {
					uid: string;
					ownElementList: { [key in Entities]: string[] };
					sharedElementList: { [key in Entities]: string[] };
				}) => {
					// TODO: Object.keys type überschreiben
					Object.keys(p.sharedElementList).forEach((entity) => {
						p.sharedElementList[entity as Entities].forEach(
							(elementId: string) => {
								const action = EntityFirestoreActions.find(
									(a) => a.type === entity
								);
								action &&
									setSnapshotListener<typeof action.schema>(
										action.type,
										p.uid,
										action.onAdded,
										action.onChanged,
										action.onDeleted,
										{ ...action.options, elementId }
									);
							}
						);
					});
				}
			);
		}
	});
	for (const action of EntityFirestoreActions) {
		if (action.type === Entities.Areas) {
			// @ts-ignore
			await store.dispatch(action.fetch(userId));
		}
		setSnapshotListener<typeof action.schema>(
			action.type,
			userId,
			action.onAdded,
			action.onChanged,
			action.onDeleted,
			action.options
		);
	}
	// TODO: Fingerprint und Device eintrag auslagern z.B. in Cloud Function
	const fingerprint = getBrowserFingerprint({ enableWebgl: true }).toString();
	// TODO: Evaluieren ob Externe API hier notwendig
	const location = (await axios.get(`https://freeipapi.com/api/json`, { headers: { "Access-Control-Allow-Origin": "*" } }));
	const lastUsage = Timestamp.now();
	const entry = await getDoc(doc(firestore, "users", userId, "web-devices", fingerprint));
	const fmVersion = String(import.meta.env.VITE_FM_VERSION ?? "unknown");
	if (entry.exists()) {
		void updateDoc(entry.ref, { location: `${location.data.cityName}, ${location.data.countryName}`, lastUsage, "fm-version": fmVersion });
	} else {
		const browserData = new UAParser(navigator.userAgent).getResult();
		delete browserData.browser.major;
		if (browserData.os.name === "Windows" && browserData.os.version === '10') {
			if ("userAgentData" in navigator) {
				const { platformVersion} = await (navigator.userAgentData as { getHighEntropyValues: (args: string[]) => Promise<{
						platformVersion: string
					}> } ).getHighEntropyValues(["platformVersion"]);
				const majorPlatformVersion = parseInt(platformVersion.split('.')[0]);
				if (majorPlatformVersion >= 13) {
					browserData.os.version = "11";
				}
			} else {
				browserData.os.version = "10/11";
			}
		}
		const deviceRecord: DeviceRecord = {
			fingerprint,
			location: `${location.data.cityName}, ${location.data.countryName}`,
			browser: browserData.browser,
			os: browserData.os,
			firstUsage: lastUsage,
			lastUsage,
			"fm-version": fmVersion
		}
		void setDoc(doc(firestore, "users", userId, "web-devices", deviceRecord.fingerprint), deviceRecord);
	}
}

/**
 * Initializes snapshots for collections that were shared using a showcase link.
 * @param tokenId {string}
 */
export function initShowcaseSnapshots(tokenId: string) {
	void accessShowcase({ tokenId }).then((result) => {
		const data = (result.data as any).showcaseData as EntityListWithUncertainties;
		Object.keys(data).map((entityType ) => {
			const action = EntityFirestoreActions.find((a) => a.type === entityType as Entities);
			if (!action) return;
			const list = data[entityType as Entities] ?? ([] as Entity[]);
			const result = list.filter((e) => action.options.validate && action.options.validate(e)).map((e) => {
				translateTimestampsToDate(e);
				entityType in Migrations && Migrations[entityType as "paths" | "notes"](e);
				return e;
			});
			store.dispatch(action.addMultiple(result));
		});
	});
}

export function unsubscribeFromSnapshots() {
	unsubscribe.forEach((u) => {
		u();
	});
}

/**
 * Function is used when an Element is updated within the application and triggers an update in the corresponding element in Firestore.
 * As deletions are currently only marked with a timestamp in the deletedAt-Field, this function covers that usecase as well.
 * @param userId {string}
 * @param entity {Entities}
 * @param data - The properties and their values that should be updated in the firestore element
 */
export function updateFirestoreElement(
	userId: string,
	entity: Entities,
	data: MetaData & { [x: string]: any }
) {
	if (import.meta.env.VITE_DEMO) {
		console.log("Not saving in Demo Environment");
		return;
	}
	const transformedData = runMigrations(data, entity);
	void updateDoc(
		doc(firestore, "users", userId, entity, data.firestoreUid),
		transformedData
	).then(() => {
	});
}


/**
 * Function is used when an Element is added within the application and triggers an addition of that element to Firestore Collection.
 * @param userId {string}
 * @param entity {Entities}
 * @param data - The properties and their values that should be updated in the firestore element
 */
export function addFirestoreElement(
	userId: string,
	entity: Entities,
	data: MetaData & { [x: string]: any }
) {
	if (import.meta.env.VITE_DEMO) {
		console.log("Not saving in Demo Environment");
		return;
	}
	const transformedData = runMigrations(data, entity);
	void setDoc(
		doc(firestore, "users", userId, entity, data.firestoreUid),
		transformedData
	).then(() => {
	});
}

/**
 * Reverses the changes during download of data to preserve schema in firestore collections.
 * @param data
 * @param entity {Entities}
 */
function runMigrations(data: MetaData & { [x: string]: any }, entity: Entities) {
	const el = {...data};
	switch (entity) {
		case Entities.Notes:
			if (el.domainData) el.domain_data = JSON.stringify(el.domainData);
			delete el.domainData;
	}
	return translateDatesToTimestamps(el);
}
