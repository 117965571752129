import { IconType } from "react-icons";
import { useState } from "react";
import styles from "./sections.module.css";
import { Box, SvgIconTypeMap, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

/**
 * FormItemCover Component
 *
 * A component that serves as a cover for a form item, providing an option to reveal its content.
 *
 * @component
 * @param {Object} props - The properties of the FormItemCover component.
 * @param {string} props.label - The label to be displayed on the cover.
 * @param {(OverridableComponent<SvgIconTypeMap> & { muiName: string }) | IconType} props.Icon - The icon component or type to be displayed on the cover.
 * @param {JSX.Element} props.children - The content of the form item.
 * @param {() => void} [props.onDisplay] - A function to be called when the form item content is displayed.
 * @param {boolean} [props.initialVisibility] - The initial visibility state of the form item content.
 *
 * @returns {JSX.Element} - The rendered FormItemCover component.
 *
 * @example
 * // Usage of FormItemCover component
 * <FormItemCover
 *   label="Covered Content"
 *   Icon={VisibilityIcon}
 *   children={<FormContent />}
 *   onDisplay={() => console.log('Content displayed')}
 *   initialVisibility={false}
 * />
 */
export default function FormItemCover({
	label,
	Icon,
	children,
	onDisplay,
	initialVisibility,
}: {
	label: string;
	Icon: (OverridableComponent<SvgIconTypeMap> & { muiName: string }) | IconType;
	children: JSX.Element;
	onDisplay?: () => void;
	initialVisibility?: boolean;
}): JSX.Element {
	const [showChild, setShowChild] = useState(initialVisibility ?? false);

	return (
		<>
			{initialVisibility || showChild ? (
				children
			) : (
				<Box
					className={styles.showSelect}
					onClick={() => {
						setShowChild(true);
						onDisplay && onDisplay();
					}}
				>
					<Icon />
					<Typography>{label}</Typography>
				</Box>
			)}
		</>
	);
}
