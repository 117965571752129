import {useState} from "react";
import ViewportPosition from "../../types/viewport-position";
import {getMapPositionFromViewportPosition,} from "../../types/map-position";

/**
 * Represents the format of a Mapbox style.
 */
export type MapboxStyleFormat = {
	url: string;
	previewUrl: string;
	name: string;
};

/**
 * Object containing predefined Mapbox styles.
 */
export const MapStyles = {
	Dark: {
		url: `mapbox://styles/rbitechug/clpmh7n1e00yf01po3rdjddyb`,
		previewUrl: "mapbox://styles/rbitechug/clpmh9bkp00xz01r50xejbqb6",
		name: "Dark",
	},
	Navigation: {
		url: "mapbox://styles/rbitechug/clpmh0mkv00w401pjhvez6p9r",
		previewUrl: "mapbox://styles/rbitechug/clpmh1i5u00xz01p986ede0xu",
		name: "Navigation"
	},
	Satellite: {
		url: `mapbox://styles/rbitechug/clpmh3i1d00w501pjccpoc5me`,
		previewUrl: "mapbox://styles/rbitechug/clpmh48id00yi01pg6oda6wck",
		name: "Satellite",
	},
	Outdoor: {
		url: `mapbox://styles/rbitechug/clpmh629400wi01pk93xe0frc`,
		previewUrl: "mapbox://styles/rbitechug/clpmh6dmi00xy01r527ij4n9o",
		name: "Outdoor",
	},
};

/**
 * Maps a style name to a MapboxStyleFormat object.
 * @param {string | null} str - MapStyle name
 * @returns {MapboxStyleFormat} - The MapboxStyleFormat object corresponding to the style name
 */
export function getStyleForKey(str: string | null): MapboxStyleFormat {
	switch (str) {
		case "Dark":
			return MapStyles.Dark;
		case "Navigation":
			return MapStyles.Navigation;
		case "Satellite":
			return MapStyles.Satellite;
		case "Outdoor":
			return MapStyles.Outdoor;
		default:
			return MapStyles.Satellite;
	}
}

Object.freeze(MapStyles);

/**
 * Props for a map component.
 */
export interface MapProps {
	style: MapboxStyleFormat;
	setStyle: (style: MapboxStyleFormat) => void;
	mapPosition: ViewportPosition;
	setMapPosition: (mapPosition: ViewportPosition) => void;
}

/**
 * Custom hook to manage map properties.
 * @returns {MapProps} - The map properties
 */
export default function useMapProps(): MapProps {
	const [style, setStyle] = useState(
		getStyleForKey(localStorage.getItem("mapboxStyle"))
	);

	const [mapPosition, setMapPosition] = useState<ViewportPosition>(JSON.parse(
		localStorage.getItem("lastCoordinates") ??
			'{"longitude": 12, "latitude": 49, "zoom": 9}'
	));

	/**
	 * Handles setting the map style.
	 * @param {MapboxStyleFormat} nextStyle - The next map style to set
	 */
	function handleSetStyle(nextStyle: MapboxStyleFormat) {
		localStorage.setItem("mapboxStyle", nextStyle.name);
		setStyle(nextStyle);
	}

	/**
	 * Handles setting the map position.
	 * @param {ViewportPosition} nextPosition - The next map position to set
	 */
	function handleSetMapPosition(nextPosition: ViewportPosition) {
		setMapPosition(nextPosition);
		localStorage.setItem(
			"lastCoordinates",
			JSON.stringify(getMapPositionFromViewportPosition(nextPosition))
		);
	}

	return {
		style,
		setStyle: handleSetStyle,
		mapPosition,
		setMapPosition: handleSetMapPosition,
	};
}
