import Entities, {Entity} from "../../types/entities";
import {ActionCreatorWithPayload, AsyncThunk} from "@reduxjs/toolkit";
import {ZodSchema} from "zod";
import {addArea, addAreas, fetchAreas, removeArea, updateArea} from "../../store/slices/area-slice";
import Area, {AreaSchema, isArea} from "../../types/entities/area";
import store from "../../store/store";
import {
    addCategories,
    addCategory,
    fetchCategories,
    removeCategory,
    updateCategory
} from "../../store/slices/category-slice";
import Category, {CategorySchema, isCategory} from "../../types/entities/category";
import {
    addImageLayer, addImageLayers,
    fetchImageLayers,
    removeImageLayer,
    updateImageLayer
} from "../../store/slices/image-layer-slice";
import ImageLayer, {ImageLayerSchema, isImageLayer} from "../../types/entities/image-layer";
import {addNote, addNotes, fetchNotes, removeNote, updateNote} from "../../store/slices/note-slice";
import Note, {isNote, NoteSchema} from "../../types/entities/note";
import {addPath, addPaths, fetchPaths, removePath, updatePath} from "../../store/slices/path-slice";
import Path, {isPath, PathSchema, PathType} from "../../types/entities/path";
import {
    addTurningPoint, addTurningPoints,
    fetchTurningPoints,
    removeTurningPoint,
    updateTurningPoint
} from "../../store/slices/turning-point-slice";
import TurningPoint, {isTurningPoint, TurningPointSchema} from "../../types/entities/turning-point";
import {
    addLayerGroup, addLayerGroups,
    fetchLayerGroups,
    removeLayerGroup,
    updateLayerGroup
} from "../../store/slices/layer-group-slice";
import LayerGroup, {isLayerGroup, LayerGroupSchema} from "../../types/entities/layer-group";
import {addMedia, addMedias, fetchMedia, removeMedia, updateMedia} from "../../store/slices/media-slice";
import Media, {isMedia, MediaSchema} from "../../types/entities/media";
import {addGrid, addGrids, fetchGrids, removeGrid, updateGrid} from "../../store/slices/grid-slice";
import Grid, {GridSchema, isGrid} from "../../types/entities/grid";

/**
 * This file defines Actions for each Entity that are used all callbacks for firestore snapshot listeners.
 * Additionally, some migrations are available when a Schema cannot be applied to firestore data, e.g. parsing Numbers to Enum values.
 */

export type Options = {
    validate?: (e: Entity) => boolean;
    migrate?: (e: any) => any;
};

export const Migrations = {
    [Entities.Paths]: (el: Path | any) => {
        if (!el) return el;
        if (!("name" in el)) el.name = null;
        if (!("type" in el)) el.type = PathType.Road;
    },
    [Entities.Notes]: (el: Note | any) => {
        if (!el) return;
        if ("domain_data" in el) {
            el.domainData = JSON.parse(el.domain_data);
        }
    }
};

const EntityFirestoreActions: {
    fetch: AsyncThunk<Entity[], string, any>;
    schema: ZodSchema;
    type: Entities;
    onAdded: (e: any) => void;
    onChanged: (e: any) => void;
    onDeleted: (e: any) => void;
    addMultiple:  ActionCreatorWithPayload<any>;
    options: Options;
}[] = [
    {
        fetch: fetchAreas,
        schema: AreaSchema,
        type: Entities.Areas,
        onAdded: (e) => store.dispatch(addArea(e as Area)),
        onChanged: (e) =>
            store.dispatch(
                updateArea({ id: e.firestoreUid, changes: { ...(e as Area) } })
            ),
        onDeleted: (e) => store.dispatch(removeArea((e as Area).firestoreUid)),
        addMultiple: addAreas,
        options: { validate: isArea },
    },
    {
        fetch: fetchCategories,
        schema: CategorySchema,
        type: Entities.Categories,
        onAdded: (e) => store.dispatch(addCategory(e as Category)),
        onChanged: (e) =>
            store.dispatch(
                updateCategory({ id: e.firestoreUid, changes: { ...(e as Category) } })
            ),
        onDeleted: (e) => store.dispatch(removeCategory((e as Category).firestoreUid)),
        addMultiple: addCategories,
        options: { validate: isCategory },
    },
    {
        fetch: fetchImageLayers,
        schema: ImageLayerSchema,
        type: Entities.ImageLayer,
        onAdded: (e) => store.dispatch(addImageLayer(e as ImageLayer)),
        onChanged: (e) =>
            store.dispatch(
                updateImageLayer({
                    id: e.firestoreUid,
                    changes: { ...(e as ImageLayer) },
                })
            ),
        onDeleted: (e) => store.dispatch(removeImageLayer((e as ImageLayer).firestoreUid)),
        addMultiple: addImageLayers,
        options: { validate: isImageLayer },
    },
    {
        fetch: fetchNotes,
        schema: NoteSchema,
        type: Entities.Notes,
        onAdded: (e) => store.dispatch(addNote(e as Note)),
        onChanged: (e) =>
            store.dispatch(
                updateNote({ id: e.firestoreUid, changes: { ...(e as Note) } })
            ),
        onDeleted: (e) => store.dispatch(removeNote((e as Note).firestoreUid)),
        addMultiple: addNotes,
        options: { validate: isNote, migrate: Migrations[Entities.Notes] },
    },
    {
        fetch: fetchPaths,
        schema: PathSchema,
        type: Entities.Paths,
        onAdded: (e) => store.dispatch(addPath(e as Path)),
        onChanged: (e) =>
            store.dispatch(
                updatePath({ id: e.firestoreUid, changes: { ...(e as Path) } })
            ),
        onDeleted: (e) => store.dispatch(removePath((e as Path).firestoreUid)),
        addMultiple: addPaths,
        options: { validate: isPath, migrate: Migrations[Entities.Paths] },
    },
    {
        fetch: fetchTurningPoints,
        schema: TurningPointSchema,
        type: Entities.TurningPoints,
        onAdded: (e) => store.dispatch(addTurningPoint(e as TurningPoint)),
        onChanged: (e) =>
            store.dispatch(
                updateTurningPoint({
                    id: e.firestoreUid,
                    changes: { ...(e as TurningPoint) },
                })
            ),
        onDeleted: (e) => store.dispatch(removeTurningPoint((e as TurningPoint).firestoreUid)),
        addMultiple: addTurningPoints,
        options: { validate: isTurningPoint },
    },
    {
        fetch: fetchLayerGroups,
        schema: LayerGroupSchema,
        type: Entities.LayerGroup,
        onAdded: (e) => store.dispatch(addLayerGroup(e as LayerGroup)),
        onChanged: (e) =>
            store.dispatch(
                updateLayerGroup({
                    id: e.firestoreUid,
                    changes: { ...(e as LayerGroup) },
                })
            ),
        onDeleted: (e) => store.dispatch(removeLayerGroup((e as LayerGroup).firestoreUid)),
        addMultiple: addLayerGroups,
        options: { validate: isLayerGroup },
    },
    {
        fetch: fetchGrids,
        schema: GridSchema,
        type: Entities.Grids,
        onAdded: (e) => store.dispatch(addGrid(e as Grid)),
        onChanged: (e) =>
            store.dispatch(
                updateGrid({ id: e.firestoreUid, changes: { ...(e as Grid) } })
            ),
        onDeleted: (e) => store.dispatch(removeGrid((e as Grid).firestoreUid)),
        addMultiple: addGrids,
        options: { validate: isGrid },
    },
    {
        fetch: fetchMedia,
        schema: MediaSchema,
        type: Entities.Media,
        onAdded: (e) => store.dispatch(addMedia(e as Media)),
        onChanged: (e) =>
            store.dispatch(
                updateMedia({ id: e.firestoreUid, changes: { ...(e as Media) } })
            ),
        onDeleted: (e) => store.dispatch(removeMedia((e as Media).firestoreUid)),
        addMultiple: addMedias,
        options: { validate: isMedia },
    },
];

export default EntityFirestoreActions;
