import styles from "./filter.module.css";
import { drawerWidth } from "../sidebar";
import { Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { motion } from "framer-motion";
import FilterSection from "./filter-section";
import { hideFilterBar, resetFilter, setFilterOptions } from "./filter-slice";
import useSidebarParams from "../sidebar/use-sidebar-params";

/**
 * Component rendering the filter bar for applying filters to the map.
 * @returns {JSX.Element} FilterBar component JSX element.
 */
export default function FilterBar() {
	const isOpen = useAppSelector((state) => state.filter.filterBarVisible);
	const {sidebarOpen} = useSidebarParams();
	const dispatch = useAppDispatch();

	return (
		<motion.div
			transition={{ type: "spring", bounce: 0, duration: 0.4 }}
			initial={{ x: "-300%" }}
			animate={{ x: isOpen && sidebarOpen ? 0 : "-300%" }}
			className={styles.filterBar}
			style={{ width: drawerWidth }}
		>
			<Box>
				<FilterSection
					/**
					 * Handles the submission of filter options.
					 * @param {FilterOptions} filterOptions - The filter options to be applied.
					 */
					onSubmit={(filterOptions) => {
						dispatch(setFilterOptions(filterOptions));
						dispatch(hideFilterBar());
					}}
					/**
					 * Handles the reset of filters.
					 */
					onReset={() => {
						dispatch(resetFilter());
						dispatch(hideFilterBar());
					}}
				/>
			</Box>
		</motion.div>
	);
}
