import {Feature, GeoJSON} from "geojson";
import xml2js from "xml2js";

/**
 * Generates a GPX XML string from a GeoJSON object.
 * @param geojson - The GeoJSON object to convert to GPX.
 * @param options - Options for GPX generation.
 * @returns A GPX XML string.
 */
export default function togpx( geojson: GeoJSON, options: { [x:string]: any } = {} ) {
    // Merge options with defaults
    options = (function (defaults: {[x:string]: any }, options: {[x:string]: any }) {
        for (const k in defaults) {
            if (k in options) {
                    defaults[k] = options[k];
            }
        }
        return defaults;
    })({
        creator: "forestmanager",
        metadata: undefined,
        featureTitle: getFeatureTitle,
        featureDescription: getFeatureDescription,
        featureLink: undefined,
        featureCoordTimes: getFeatureCoordTimes,
    }, options || {});
    // If custom featureCoordTimes field is defined, assign the function

    if (typeof options.featureCoordTimes === 'string') {
        const customTimesFieldKey = options.featureCoordTimes;
        options.featureCoordTimes = (feature: any) => {
            return feature.properties[customTimesFieldKey];
        }
    }

    // Functions to extract title, description, and coordinate times from feature properties
    function getFeatureTitle(props: { tags: any, name: string, ref: string, id: string }): string {
        if (!props) return "";
        if (typeof props.tags === "object") {
            const tagsTitle = getFeatureTitle(props.tags);
            if (tagsTitle !== "")
                return tagsTitle;
        }
        if (props.name)
            return props.name;
        if (props.ref)
            return props.ref;
        if (props.id)
            return props.id;
        return "";
    }
    function getFeatureDescription(props: any): string {
        if (!props) return "";
        if (typeof props.tags === "object")
            return getFeatureDescription(props.tags);
        let res = "";
        for (const k in props) {
            if (typeof props[k] === "object")
                continue;
            res += k+"="+props[k]+"\n";
        }
        return res.substring(0,res.length-1);
    }
    function getFeatureCoordTimes(feature: Feature) {
        if (!feature.properties) return null;
        return feature.properties.times || feature.properties.coordTimes || null;
    }
    function addFeatureLink(o: any, f: any) {
        if (options.featureLink)
            o.link = { "@href": options.featureLink(f.properties) }
    }

    // Initialize GPX object
    const gpx = {"gpx": {
            $: {
                "xmlns": "http://www.topografix.com/GPX/1/1",
                "xmlns:xsi": "http://www.w3.org/2001/XMLSchema-instance",
                "xsi:schemaLocation": "http://www.topografix.com/GPX/1/1 http://www.topografix.com/GPX/1/1/gpx.xsd",
                "version": "1.1",
                "creator": options.creator ?? ""
            },
            "metadata": null,
            "wpt": [] as { [x: string]: any }[],
            "trk": [] as { [x: string]: any }[],
        }
    };

    // Set metadata if provided
    if (options.metadata)
        gpx.gpx.metadata = options.metadata;
    else
        delete options.metadata;

    let features;
    // Extract features from GeoJSON
    if (geojson.type === "FeatureCollection")
        features = geojson.features;
    else if (geojson.type === "Feature")
        features = [geojson];
    else
        features = [{type:"Feature", properties: {}, geometry: geojson}];
    // Map GeoJSON features to GPX elements
    features.forEach(function mapFeature(f) {
        let o: any,
            times: any;
        switch (f.geometry.type) {
            // POIs
            case "Point":
            case "MultiPoint": {
                const coords = f.geometry.type == "Point" ? [f.geometry.coordinates] : f.geometry.coordinates;
                coords.forEach(function (coordinates) {
                    o = {$: {"lat": coordinates[1], "lon": coordinates[0]}};
                    addFeatureLink(o, f);
                    gpx.gpx.wpt.push(o);
                });
                break;
            }
            case "LineString":
            case "MultiLineString": {
                const coords = f.geometry.type == "LineString" ? [f.geometry.coordinates] : f.geometry.coordinates;
                times = options.featureCoordTimes(f);
                o = {
                    "name": options.featureTitle(f.properties),
                };
                addFeatureLink(o, f);
                o.trkseg = [];
                coords.forEach(function (coordinates) {
                    const seg: { trkpt: { $: { lat: number, lon: number }}[] } = {trkpt: []};
                    if (typeof coordinates !== "number") {
                        coordinates.forEach(function (c) {
                            const o = {
                                $: {"lat": c[1], "lon": c[0]}
                            };
                            seg.trkpt.push(o);
                        });
                    }
                    o.trkseg.push(seg);
                });
                gpx.gpx.trk.push(o);
                break;
            }
            case "Polygon":
            case "MultiPolygon":
                o = {
                    "name": options.featureTitle(f.properties),
                };
                addFeatureLink(o,f);
                o.trkseg = [];
                const coords = f.geometry.type == "Polygon" ? [f.geometry.coordinates] : f.geometry.coordinates;
                times = options.featureCoordTimes(f);
                coords.forEach(function(poly) {
                    if (typeof poly !== "number") {
                        poly.forEach(function (ring) {
                            const seg: { trkpt: { $: { lat: number, lon: number }}[] } = {trkpt: []};
                            let i = 0;
                            if (typeof ring !== "number") {
                                ring.forEach(function (c) {
                                    const o = { $: { "lat": c[1], "lon": c[0] } };
                                    i++;
                                    seg.trkpt.push(o);
                                });
                            }
                            o.trkseg.push(seg);
                        });
                    }
                });
                gpx.gpx.trk.push(o);
                break;
            default:
                // eslint-disable-next-line no-console
                console.log("warning: unsupported geometry type: "+f.geometry.type);
        }
    });
    // Convert GPX object to XML string
    const builder = new xml2js.Builder();

    return builder.buildObject(gpx);
};
