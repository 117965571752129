import {Layer, LngLat, Source, useMap} from "react-map-gl";
import {
    bbox,
    bboxPolygon,
    booleanContains,
    destination,
    featureCollection,
    hexGrid,
    intersect,
    lineString,
    point,
    polygon,
    transformRotate
} from "@turf/turf";
import store from "../../../store/store";
import {selectAreaById, selectAreas} from "../../../store/slices/area-slice";
import Grid, {GridType, isGrid} from "../../../types/entities/grid";
import MapPosition, {mapPositionArrayToLngLatArray, mapPositionToLngLat} from "../../../types/map-position";
import {useEffect, useMemo} from "react";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {calculateCenter, lngLatToMapPosition} from "../../../lib/model/utils/geo";
import {MapMouseEvent} from "mapbox-gl";
import {setTempGrid, TempGrid} from "../map-slice";
import {selectGrids} from "../../../store/slices/grid-slice";
import rectangleGrid from "@turf/rectangle-grid";
import {useFeedback} from "../../feedback/use-feedback";
import { useTranslation } from "react-i18next";
import {GeoJSON} from "geojson";

const MAX_CELLS = 10000;

/**
 * Calculates the bounding box coordinates for a given center, width, and height.
 * @param {MapPosition} center - The center of the bounding box.
 * @param {number} width - The width of the bounding box.
 * @param {number} height - The height of the bounding box.
 * @returns {number[]} The bounding box coordinates.
 */
export function calculateBboxForCenterWidthHeight(center: MapPosition, width: number, height: number) {
    const c = point(mapPositionToLngLat(center));
    return bbox(
        featureCollection([0, 90, 180, 270].map((bearing) =>
            lineString([
                c.geometry.coordinates,
                destination(c, (bearing / 90) % 2 === 1 ? width : height, bearing, {units: "meters"}).geometry.coordinates
            ])
        )))
}

/**
 * Generates GeoJSON data for a given grid.
 * @param {Grid | TempGrid} grid - The grid data.
 * @returns {GeoJSON} GeoJSON data representing the grid.
 */
export function getGeoJsonForGrid(grid: Grid |TempGrid) {
    const area = "areaUid" in grid && grid.areaUid ? selectAreaById(store.getState(), grid.areaUid) : undefined;
    const boundingBox = calculateBboxForCenterWidthHeight(grid.center, grid.width, grid.height);
    if (area) {
        const pg = polygon([area.points.map((p) => [p.longitude, p.latitude])]);
        const feature = transformRotate(grid.type === GridType.RECTANGLE ?
            rectangleGrid(boundingBox, grid.cellWidth, grid.cellHeight, {
                units: "meters",
            })
            :
            hexGrid(boundingBox, grid.cellWidth,{
                units: "meters",
            })
            ,
            grid.rotation);
        // @ts-ignore
        feature.features = feature.features.map((f) => intersect(f, pg)).filter((f) => f);
        return feature;
    }
    return transformRotate(
        grid.type === GridType.RECTANGLE ?
            rectangleGrid(boundingBox, grid.cellWidth, grid.cellHeight, {units: "meters"})
        :
            hexGrid(boundingBox, grid.cellWidth, {units: "meters"})
        ,
        grid.rotation);
}

/**
 * Component to render grid layers on the map.
 * @returns {JSX.Element} Grids component JSX element.
 */
export default function Grids() {
    const grids = useAppSelector((state) => selectGrids(state));
    const areas = useAppSelector((state) => selectAreas(state));
    const tempGrid = useAppSelector((state) => state.map.tempGrid);
    const { m1: map } = useMap();
    const canvas = useMemo(() => {
        return map?.getCanvasContainer();
    }, [map]);
    const dispatch = useAppDispatch();
    const layers = useMemo(() => grids.map((grid) => (
        <GridLayer key={grid.firestoreUid} grid={grid} />
    )), [grids]);

    function updateTempGrid(lngLat: LngLat) {
        if (!tempGrid) return;
        const area = areas.find((a) =>
            booleanContains(polygon([mapPositionArrayToLngLatArray(a.points)]), point([lngLat.lng, lngLat.lat]))
        );
        if (area && !tempGrid.areaUid) {
            const c = calculateCenter(area.points);
            dispatch(setTempGrid({ ...tempGrid, areaUid: area.firestoreUid,
                // TODO: Fix this typing
                // @ts-ignore
                center: { longitude: c[0], latitude: c[1], altitude: 0 } }
            ));
        } else {
            dispatch(setTempGrid({...tempGrid, center: lngLatToMapPosition(lngLat), areaUid: area ? area.firestoreUid : undefined }));
        }
    }

    /**
     * This effect is responsible for the drag-and-drop effect of the temporary grid during creation.
     */
    useEffect(() => {
        if (!tempGrid) return;
        function onMove(e: MapMouseEvent) {
            if (!canvas || !tempGrid) return;
            canvas.style.cursor = 'grabbing';
            updateTempGrid(e.lngLat)
        }

        function onUp(e: MapMouseEvent) {
            if (!map || !canvas || !tempGrid) return;
            canvas.style.cursor = '';
            updateTempGrid(e.lngLat);
            map.off('mousemove', onMove);
            map.off('touchmove', onMove);
        }

        map &&
        map.on("mouseenter", "temp_grid", () => {
            if (!map || !canvas) return;
            canvas.style.cursor = 'move';
        })
        &&
        map.on("mouseleave", "temp_grid", () => {
            if (!map || !canvas) return;
            canvas.style.cursor = '';
        })
        &&
        map.on("mousedown", "temp_grid", (event) => {
            if (!map || !canvas) return;
            event.preventDefault();
            canvas.style.cursor = 'grab';
            map.on('mousemove', onMove);
            map.once('mouseup', onUp);
        })
        &&
        map.on("touchstart", "temp_grid", (event) => {
            event.preventDefault();
            map.on('touchmove', onMove);
            map.once('touchend', onUp);
        }); 
    }, [tempGrid, map, dispatch]);


    return (
        <>
            {!tempGrid && layers}
            {tempGrid && <GridLayer grid={tempGrid}/>}
        </>
    )
}

/**
 * Component to render a grid layer on the map.
 * @param {Object} props - Component properties.
 * @param {Grid | TempGrid} props.grid - The grid data.
 * @returns {JSX.Element} GridLayer component JSX element.
 */
export function GridLayer({ grid }: { grid: Grid | TempGrid }) {
    const { id, geojson, bgLayer } = useMemo(() => {
        const area = grid.areaUid ? selectAreaById(store.getState(), grid.areaUid) : null;
        return {
            id : isGrid(grid) ? grid.firestoreUid : "temp_grid",
            geojson: getGeoJsonForGrid(grid),
            bgLayer: {
                type: "Feature",
                properties: {},
                geometry: {
                    type: "Polygon",
                    coordinates: area ?
                        [area.points.map((p) => [p.longitude, p.latitude])]
                        :
                        transformRotate(bboxPolygon(calculateBboxForCenterWidthHeight(grid.center, grid.width + 500, grid.height + 500)), grid.rotation).geometry.coordinates
                },
            } as GeoJSON
        }
    }, [grid]);
    const { setFeedback } = useFeedback();
    const { t } = useTranslation();

    useEffect(() => {
        if (!isGrid(grid) && geojson.features.length > MAX_CELLS) {
            setFeedback(t("feedback.gridTooLarge"), "warning");
        }
    }, [geojson])

    return (isGrid(grid) || geojson.features.length <= MAX_CELLS) ? (
        <>
            <Source
                id={id}
                type="geojson"
                data={bgLayer}
            >
                <Layer
                    type={"fill"}
                    id={id}
                    paint={{"fill-color": isGrid(grid) ? "rgba(0,0,0,0)" : "rgba(80, 80, 80, 0.5)"}}
                />
            </Source>
            <Source
                type="geojson"
                id={"g_" + id}
                data={geojson}
            >
            <Layer
                type="line"
                id={"g_" + id}
                paint={{
                    "line-color": grid.color,
                    "line-width": grid.lineThickness,
                    "line-opacity": isGrid(grid) ? 0.8 : 1
                }}
            />
            </Source>
        </>
    ) : <></>
}
