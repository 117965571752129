import {combineReducers, createSelector} from "@reduxjs/toolkit";
import noteReducer, {noteSortComparers, selectNoteById, selectNotes, updateNote,} from "./note-slice";
import areaReducer, {areaSortComparers, selectAreaById, selectAreas, updateArea,} from "./area-slice";
import categoryReducer, {
	categorySortComparers,
	selectCategories,
	selectCategoryById,
	updateCategory,
} from "./category-slice";
import imageLayerReducer, {
	imageLayerSortComparers,
	selectImageLayerById,
	selectImageLayers,
	updateImageLayer,
} from "./image-layer-slice";
import pathReducer, {pathSortComparers, selectPathById, selectPaths, updatePath,} from "./path-slice";
import turningPointReducer, {
	selectTurningPointById,
	selectTurningPoints,
	updateTurningPoint,
} from "./turning-point-slice";
import layerGroupReducer, {selectLayerGroupById, selectLayerGroups, updateLayerGroup,} from "./layer-group-slice";
import gridReducer, {gridSortComparers, selectGridById, selectGrids, updateGrid} from "./grid-slice";
import mediaReducer, {selectMedia, selectMediaById, updateMedia,} from "./media-slice";
import type {RootState} from "../store";
import store from "../store";
import Entities, {Entity, EntityList} from "../../types/entities";
import {alphabetical, chronologicalCreated, chronologicalUpdated,} from "../sort-comparers";
import {isPath} from "../../types/entities/path";
import {isArea} from "../../types/entities/area";
import {isNote} from "../../types/entities/note";
import {isTurningPoint} from "../../types/entities/turning-point";
import {isImageLayer} from "../../types/entities/image-layer";
import {isLayerGroup} from "../../types/entities/layer-group";
import {isMedia} from "../../types/entities/media";
import {isGrid} from "../../types/entities/grid";
import {collection, getDocs} from "firebase/firestore";
import {firestore} from "../../lib/firebase";
import {translateTimestampsToDate} from "../../lib/model/utils/time";
import {Migrations} from "../../lib/firebase/entity-actions";

/**
 * This file groups all slices into a single dataReducer,
 * provides some generic reusable selectors and other functions like creating an AsyncThunk for all entities.
 */

const dataReducer = combineReducers({
	notes: noteReducer,
	areas: areaReducer,
	categories: categoryReducer,
	imageLayers: imageLayerReducer,
	paths: pathReducer,
	turningPoints: turningPointReducer,
	layerGroups: layerGroupReducer,
	grids: gridReducer,
	media: mediaReducer,
});

/**
 * Creates an AsyncThunk that creates and fetches data for a specific collection from firestore.
 * Also runs some Migrations to make sure the result is compatible with this applications schemas.
 * @param entityName {Entities}
 */
export function entityAsyncThunk(entityName: Entities) {
	return async (userId: string) => {
		const querySnapshot = await getDocs(collection(firestore, `users/${userId}/${entityName}`));
		const entities: Entity[] = [];
		querySnapshot.forEach((doc) => {
			const data = doc.data();
			translateTimestampsToDate(data);
			if (entityName in Migrations) {
				// @ts-ignore
				Migrations[entityName](data);
			}
			entities.push(<Entity>data)
		});
		return entities.filter((e) => !e.deletedAt);
	}
}

/**
 * Runs a relatively exhaustive test by safeParsing an Element through all Entity Types until a matching type is found.
 * @param entity {Entity}
 * @returns {Entities}
 */
export function getEntityTypeForElement(entity: Entity) {
	if (isPath(entity)) {
		return Entities.Paths;
	} else if (isArea(entity)) {
		return Entities.Areas;
	} else if (isNote(entity)) {
		return Entities.Notes;
	} else if (isTurningPoint(entity)) {
		return Entities.TurningPoints;
	} else if (isImageLayer(entity)) {
		return Entities.ImageLayer;
	} else if (isLayerGroup(entity)) {
		return Entities.LayerGroup;
	} else if (isGrid(entity)) {
		return Entities.Grids;
	} else if (isMedia(entity)) {
		return Entities.Media;
	} else {
		return Entities.Categories;
	}
}

/**
 *
 * @param state {RootState}
 * @param id {string}
 * @param entityType {Entities}
 * @returns {Entity}
 */
export function selectElementForEntityId(
	state: RootState,
	id: string,
	entityType: Entities
) {
	switch (entityType) {
		case Entities.Notes:
			return selectNoteById(state, id);
		case Entities.Categories:
			return selectCategoryById(state, id);
		case Entities.Areas:
			return selectAreaById(state, id);
		case Entities.Paths:
			return selectPathById(state, id);
		case Entities.TurningPoints:
			return selectTurningPointById(state, id);
		case Entities.ImageLayer:
			return selectImageLayerById(state, id);
		case Entities.LayerGroup:
			return selectLayerGroupById(state, id);
		case Entities.Grids:
			return selectGridById(state, id);
		case Entities.Media:
			return selectMediaById(state, id);
		default:
			return undefined;
	}
}

/**
 *
 * @param element {Entity}
 * @param entityType {Entities}
 */
export function updateElementForEntityType(
	element: Entity,
	entityType: Entities
) {
	let action;
	switch (entityType) {
		case Entities.Areas:
			action = updateArea;
			break;
		case Entities.Categories:
			action = updateCategory;
			break;
		case Entities.ImageLayer:
			action = updateImageLayer;
			break;
		case Entities.LayerGroup:
			action = updateLayerGroup;
			break;
		case Entities.Notes:
			action = updateNote;
			break;
		case Entities.Paths:
			action = updatePath;
			break;
		case Entities.TurningPoints:
			action = updateTurningPoint;
			break;
		case Entities.Grids:
			action = updateGrid;
			break;
		case Entities.Media:
			action = updateMedia;
	}
	action &&
		store.dispatch(
			// @ts-ignore
			action({ id: element.firestoreUid, changes: { ...element } })
		);
}

/**
 * @param state {RootState}
 * @returns {EntityList}
 */
export const selectAllElements: (state: RootState) => EntityList = createSelector(
	[selectAreas, selectCategories, selectImageLayers, selectLayerGroups, selectNotes, selectPaths, selectTurningPoints, selectGrids, selectMedia],
	(areas, categories, imageLayers, layerGroups, notes, paths, turningPoints, grids, media) => {
		return {
			[Entities.Areas]: areas,
			[Entities.Categories]: categories,
			[Entities.ImageLayer]: imageLayers,
			[Entities.LayerGroup]: layerGroups,
			[Entities.Notes]: notes,
			[Entities.Paths]: paths,
			[Entities.TurningPoints]: turningPoints,
			[Entities.Grids]: grids,
			[Entities.Media]: media,
		};
	}
);

/**
 *
 * @param state {RootState}
 * @param entityType {Entities}
 * @returns {number}
 */
export function getNumberOfItemsForEntityType(
	state: RootState,
	entityType: Entities
) {
	switch (entityType) {
		case Entities.Notes:
			return selectNotes(state).length;
		case Entities.Categories:
			return selectCategories(state).length;
		case Entities.Areas:
			return selectAreas(state).length;
		case Entities.Paths:
			return selectPaths(state).length;
		case Entities.TurningPoints:
			return selectTurningPoints(state).length;
		case Entities.ImageLayer:
			return selectImageLayers(state).length;
		case Entities.LayerGroup:
			return selectLayerGroups(state).length;
		case Entities.Grids:
			return selectGrids(state).length;
		case Entities.Media:
			return selectMedia(state).length;
		default:
			return -1;
	}
}

/**
 *
 * @param entityType {Entities}
 * @returns - A number of comparator functions depending on the argument
 */
export function getSortFunctionsByEntityType(entityType: Entities) {
	switch (entityType) {
		case Entities.Areas:
			return areaSortComparers;
		case Entities.Categories:
			return categorySortComparers;
		case Entities.ImageLayer:
			return imageLayerSortComparers;
		case Entities.Notes:
			return noteSortComparers;
		case Entities.Paths:
			return pathSortComparers;
		case Entities.Grids:
			return gridSortComparers;
		default:
			return [
				{ name: "Alphabetically", function: alphabetical },
				{ name: "Creation Date", function: chronologicalCreated },
				{ name: "Last Update", function: chronologicalUpdated },
			];
	}
}

export default dataReducer;
