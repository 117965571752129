import { Navigate, useLocation } from "react-router";
import { ProvideAuth, useAuth } from "../../hooks/use-auth";
import LoadingScreen from "../../components/loading/loading-screen";
import NoLicensePage from "../../pages/error/no-license";

/**
 * Can be wrapped around an existing route to protect it from unauthenticated access.
 * Uses the Authentication Context Provide to determine session status.
 * Currently, always reroutes to /login. This can be changed by providing a redirectURL.
 *
 * @param children {JSX.Element}
 * @param {string} [redirectURL] - The URL that will be forwarded to; defaults to '/login'
 * @constructor
 * @category Middleware
 */
export default function Protected({ children, redirectURL }: { children: JSX.Element, redirectURL?: string }) {
	return (
		<ProvideAuth>
			<Inner redirectURL={redirectURL}>{children}</Inner>
		</ProvideAuth>
	);
}

/**
 * Wrapped Component Container with a Loading Screen covering children until user object is resolved.
 * Also checks for a valid license when the user is authenticated.
 * If no valid license exists, a different page is shown.
 * @param children
 * @param redirectURL {string} - The URL that will be forwarded to when not authenticated; defaults to '/login'
 * @constructor
 */
function Inner({ children, redirectURL }: { children: JSX.Element, redirectURL?: string}) {
	const auth = useAuth();
	const currentLocation = useLocation();

	if (auth.user === null) {
		return <Navigate to={redirectURL ?? "/login"} state={{ from: currentLocation }} />;
	}

	if (auth.validLicense === false) {
		return <NoLicensePage />
	}

	return (
		<>
			<LoadingScreen
				isSplash={true}
				isVisible={auth.user === false}
				isFullscreen={true}
				isOpaque={true}
			/>
			{children}
		</>
	);
}
