import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store/store";
import Entities from "../../types/entities";
import { selectElementForEntityId } from "../../store/slices";
import undoable from "redux-undo";

/**
 * Interface for the Sidebar state.
 */
interface SidebarState {
	isOpen: boolean;
	tab: Entities;
	elementId: string | null;
	formOpen: boolean;
	titleImageIndex: number;
}

// Initial state for the Sidebar
const initialState: SidebarState = {
	isOpen: false,
	tab: Entities.Notes,
	elementId: null,
	formOpen: false,
	titleImageIndex: 0,
};

// Slice for managing Sidebar state
const sidebarSlice = createSlice({
	name: "sidebar",
	initialState,
	reducers: {
		openSidebar(state) {
			state.isOpen = true;
		},
		closeSidebar(state) {
			state.isOpen = false;
		},
		setTab(state, action: PayloadAction<Entities>) {
			state.isOpen = true;
			state.tab = action.payload;
			state.elementId = null;
			state.formOpen = false;
		},
		setDetail(state, action: PayloadAction<string | null>) {
			state.isOpen = true;
			state.formOpen = false;
			state.elementId = action.payload;
		},
		openForm(state) {
			state.formOpen = true;
		},
		closeForm(state) {
			state.formOpen = false;
		},
		setEditElement(state, action: PayloadAction<string | null>) {
			state.elementId = action.payload;
			state.formOpen = true;
		},
		setTitleImageIndex(state, action: PayloadAction<number>) {
			state.titleImageIndex = action.payload;
		},
	},
});

// Make the Sidebar reducer undoable
const undoableSidebar = undoable(sidebarSlice.reducer);

// Export action creators
export const {
	openSidebar,
	closeSidebar,
	setTab,
	setDetail,
	openForm,
	closeForm,
	setEditElement,
	setTitleImageIndex,
} = sidebarSlice.actions;

/**
 * Selects the current element from the store's state.
 * @param state RootState
 * @returns Entity | undefined
 */
export function selectCurrentElement(state: RootState) {
	const { elementId, tab } = state.sidebar.present;
	if (!elementId) return undefined;
	return selectElementForEntityId(state, elementId, tab);
}

export default undoableSidebar;
