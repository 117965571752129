import {Marker} from "react-map-gl";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {selectTurningPoints} from "../../../store/slices/turning-point-slice";
import TurningPoint from "../../../types/entities/turning-point";
import CategoryIcon from "../../../components/icons";
import {Box} from "@mui/material";
import {setPopoverInfo} from "../map-slice";
import Entities from "../../../types/entities";
import styles from "../map.module.css";
import {useMemo} from "react";

/**
 * Component rendering turning point markers on the map.
 * @returns {JSX.Element} TurningMarkers component JSX element.
 */
export default function TurningMarkers() {
	const dispatch = useAppDispatch();
	const turningPoints = useAppSelector((state) => selectTurningPoints(state));
	/**
	 * Memoized array of markers representing turning points.
	 */
	const markers = useMemo(() => turningPoints.map((t) => (
		<Marker
			key={t.firestoreUid}
			onClick={() => handleMarkerClicked(t)}
			longitude={t.long}
			latitude={t.lat}>
			<Box className={styles.turningPointMarker}>
				<CategoryIcon iconName={"turning_point"} />
			</Box>
		</Marker>
	)), [turningPoints]);

	/**
	 * Handles the click event on a turning point marker.
	 * @param {TurningPoint} turningPoint - The turning point object.
	 */
	function handleMarkerClicked(turningPoint: TurningPoint) {
		dispatch(
			setPopoverInfo({
				id: turningPoint.firestoreUid,
				type: Entities.TurningPoints,
				closed: false,
			})
		);
	}

	return (
		<>
			{markers}
		</>
	);
}
