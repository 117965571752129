import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Entities from "../../types/entities";
import MapPosition from "../../types/map-position";
import {GridType} from "../../types/entities/grid";

/**
 * Represents information about a popover.
 */
export type PopoverInfo = {
	type: Entities;
	id: string;
	lng?: number;
	lat?: number;
	closed: boolean;
	childIndex?: number;
};

/**
 * Represents temporary marker information.
 */
export type TempMarker = {
	categoryUid?: string;
	lng: number;
	lat: number;
};

/**
 * Represents temporary area information.
 */
export type TempArea = {
	points: number[][][];
	color: string;
};

/**
 * Represents temporary path information.
 */
export type TempPath = {
	points: number[][][];
	width: number;
};

/**
 * Represents temporary grid information.
 */
export type TempGrid = {
	center: MapPosition;
	width: number;
	height: number;
	rotation: number;
	color: string;
	cellWidth: number;
	cellHeight: number;
	type: GridType;
	lineThickness: number;
	areaUid?: string;
}

/**
 * Represents the initial state of the map slice.
 */
interface MapState {
	popoverInfo: PopoverInfo | null;
	tempMarker: TempMarker | null;
	tempArea: TempArea | null;
	tempPath: TempPath | null;
	tempGrid: TempGrid | null;
	selectedLayerStyles: { id: string; styleIndex: number }[];
	markerSize: number;
	entityOpacity: { [entityName in Entities]: number }
}

const initialState: MapState = {
	popoverInfo: null,
	tempMarker: null,
	tempArea: null,
	tempPath: null,
	tempGrid: null,
	selectedLayerStyles: [],
	markerSize: 1,
	entityOpacity: Object.fromEntries((Object.keys(Entities) as Array<keyof typeof Entities>).reduce((acc, entityName) => acc.set(Entities[entityName], 0.7), new Map())) as { [entityName in Entities]: number }
};

const mapSlice = createSlice({
	name: "map",
	initialState,
	reducers: {
		setPopoverInfo(state, action: PayloadAction<PopoverInfo | null>) {
			state.popoverInfo = action.payload;
		},
		closePopover(state) {
			if (state.popoverInfo) {
				state.popoverInfo = { ...state.popoverInfo, closed: true };
			}
		},
		setTempMarker(state, action: PayloadAction<TempMarker>) {
			state.tempMarker = action.payload;
		},
		removeTempNote(state) {
			state.tempMarker = null;
		},
		setTempArea(state, action: PayloadAction<TempArea>) {
			state.tempArea = action.payload;
		},
		removeTempArea(state) {
			state.tempArea = null;
		},
		setTempPath(state, action: PayloadAction<TempPath>) {
			state.tempPath = action.payload;
		},
		removeTempPath(state) {
			state.tempPath = null;
		},
		setTempGrid(state, action: PayloadAction<TempGrid>) {
			state.tempGrid = action.payload;
		},
		removeTempGrid(state) {
			state.tempGrid = null;
		},
		setStyleForLayer(
			state,
			action: PayloadAction<{ id: string; styleIndex: number }>
		) {
			const index = state.selectedLayerStyles.findIndex(
				(el) => el.id === action.payload.id
			);
			if (index !== -1) {
				state.selectedLayerStyles[index] = action.payload;
			} else {
				state.selectedLayerStyles.push(action.payload);
			}
		},
		resetStyleForLayer(state, action: PayloadAction<string>) {
			const index = state.selectedLayerStyles.findIndex(
				(el) => el.id === action.payload
			);
			if (index !== -1) {
				state.selectedLayerStyles.splice(index, 1);
			}
		},
		setMarkerSize(state, action: PayloadAction<number>) {
			state.markerSize = action.payload;
		},
		setEntityOpacity(state, action: PayloadAction<{ entityName: Entities, value: number }>) {
			const { entityName, value } = action.payload;
			const cp = {...state.entityOpacity}
			cp[entityName] = value;
			state.entityOpacity = cp;
		}
	},
});

export const {
	setPopoverInfo,
	closePopover,
	setTempMarker,
	removeTempNote,
	setTempArea,
	removeTempArea,
	setTempPath,
	removeTempPath,
	setTempGrid,
	removeTempGrid,
	setStyleForLayer,
	resetStyleForLayer,
	setMarkerSize,
	setEntityOpacity
} = mapSlice.actions;

export default mapSlice.reducer;
