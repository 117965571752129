import { Box, Typography } from "@mui/material";
import styles from "./sections.module.css";

/**
 * DescriptionSection Component
 *
 * A component for rendering a section containing a description.
 *
 * @component
 * @param {Object} props - The properties of the DescriptionSection component.
 * @param {string} props.description - The description to be displayed.
 *
 * @returns {JSX.Element} - The rendered DescriptionSection component.
 *
 * @example
 * // Usage of DescriptionSection component
 * <DescriptionSection description="This is a sample description." />
 */
export default function DescriptionSection({
	description,
}: {
	description: string;
}): JSX.Element {
	return (
		<Box className={`${styles.section} ${styles.description}`}>
			<Typography>{description}</Typography>
		</Box>
	);
}
