import { z } from "zod";
import { MetaDataSchema } from "./meta-data";

/**
 * Schema for Categories in ForestManager.
 * @category Schema
 */
export const CategorySchema = MetaDataSchema.extend({
	name: z.string(),
	icon: z.number(),
	symbol_name: z.string().optional(),
	hue: z.number(),
	lightness: z.number(),
	saturation: z.number(),
	color: z.union([z.number(), z.string()]).optional(),
});


/**
 * Type for Category in ForestManager.
 * Inferred from zod Schema.
 * @category Schema_Type
 */
type Category = z.infer<typeof CategorySchema>;

export default Category;

/**
 * Is-Function to check whether the parameter is actually a Category
 * @param category
 * @returns {boolean}
 */
export function isCategory(category: unknown): category is Category {
	return CategorySchema.safeParse(category).success;
}

/**
 * Compares an id to a list of categoryUids that match the ids of categories that are created for users by default.
 * @param firestoreUid {string}
 * @returns {boolean}
 */
export function isDefaultCategory(firestoreUid: string) {
	const defaults = [
		"9kJsFKj-Ra@9FboBkePlxblH",
		"@G99pNP-IKgLr!7Et3bgb@Gr",
		"IhhIKOmb!nrYjdXPjTWlhMu%",
		"pfhd9BfA2fa5FSjSO&QdAdl4",
		"v-7BVsPs@l3fk76GNj4V2EvH",
		"v-gTqi!tuNrdQXy82v3uvh0O",
		"vXz%01K01DujXLQPB5C5i&CO",
		"IkOsFsj-Ra@9FvoBkmPrxslY"
	];
	return defaults.includes(firestoreUid);
}
