import ViewportPosition from "./viewport-position";
import {LngLat, LngLatLike} from "react-map-gl";
import {padPointsArray} from "../lib/model/utils/geo";
import {z} from "zod";

/**
 * This file defines a type for a MapPosition as used in Firestore Entities like areas and paths,
 * as well as some helper functions to parse from and to Mapbox Types like LngLat.
 */

/**
 * More precisely specified schemata for longitude
 * @category schema
 */
export const longitude = z.number().gte(-180).lte(180);
/**
 * More precisely specified schemata for latitude
 * @category schema
 */
export const latitude = z.number().gte(-90).lte(90);

export const MapPositionSchema = z.object({
	longitude,
	latitude,
	altitude: z.number(),
});

type MapPosition = z.infer<typeof MapPositionSchema>;
export default MapPosition;

/**
 *
 * @param viewportPosition {ViewportPosition}
 * @returns {MapPosition}
 * @category Utils
 */
export function getMapPositionFromViewportPosition(
	viewportPosition: ViewportPosition
) {
	return {
		longitude: viewportPosition.longitude,
		latitude: viewportPosition.latitude,
		zoom: viewportPosition.zoom,
	};
}

/**
 *
 * @param lngLat {LngLatLike}
 * @returns {MapPosition}
 * @category Utils
 */
export function mapPositionFromLngLat(
	lngLat: LngLatLike | number[]
): MapPosition {
	if (Array.isArray(lngLat)) {
		return { longitude: lngLat[0], latitude: lngLat[1], altitude: 0 };
	} else if ((lngLat as LngLat).lng !== undefined) {
		// @ts-ignore
		return { longitude: lngLat.lng, latitude: lngLat.lat, altitude: 0 };
	} else {
		// @ts-ignore
		return { longitude: lngLat.lon, latitude: lngLat.lat, altitude: 0 };
	}
}

/**
 *
 * @param arr {LngLatLike[] | number[][]}
 * @returns {MapPosition[]}
 * @category Utils
 */
export function mapPositionArrayFromLngLatArray(
	arr: LngLatLike[] | number[][]
): MapPosition[] {
	return arr.map((p) => mapPositionFromLngLat(p));
}

/**
 *
 * @param mapPosition {MapPosition}
 * @returns {[number, number]}
 * @category Utils
 */
export function mapPositionToLngLat(mapPosition: MapPosition): [number, number] {
	return [mapPosition.longitude, mapPosition.latitude] as [number, number];
}

/**
 *
 * @param arr {MapPosition[]}
 * @returns {number[][]}
 * @category Utils
 */
export function mapPositionArrayToLngLatArray(arr: MapPosition[]): number[][] {
	return padPointsArray(arr.map((p) => mapPositionToLngLat(p)));
}
