import { Box, Typography } from "@mui/material";
import styles from "./dividers.module.css";

/**
 *
 * @param label - string | undefined
 * @param width - string | number | undefined
 * @constructor
 */
export default function HorizontalDivider({ label, width }: { label?: string, width?: string | number }) {

    return (
        <Box className={styles.dividerContainer} >
            {label && <Typography>{label}</Typography>}
            <span style={width ? { width } : {}}/>
        </Box>
    )
}