import {createAsyncThunk, createEntityAdapter, createSlice,} from "@reduxjs/toolkit";
import type {RootState} from "../store";
import LayerGroup from "../../types/entities/layer-group";
import {entityAsyncThunk} from "./index";
import Entities from "../../types/entities";

/**
 * This file defines the slice for the LayerGroup Entity.
 * The general layout will be the same for all entity slices.
 *
 * Adapter: Manages list of all elements for this entity and supplies standard functions like addOne and updateOne
 * AsyncThunks: Asynchronously fetches data for this collection name from firestore and populates list initially
 * Selectors: Exports reusable selectors for this slice
 * Comparators: Exports the comparators that are applicable to this entity type
 *
 * @category Slice
 */

const layerGroupAdapter = createEntityAdapter<LayerGroup>({
	selectId: (layerGroup) => layerGroup.firestoreUid,
	sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const fetchLayerGroups = createAsyncThunk(
	"layerGroups/fetchLayerGroups",
	async (userId: string) => entityAsyncThunk(Entities.LayerGroup)(userId)
);

const initialState = layerGroupAdapter.getInitialState({
	status: "idle",
});

const layerGroupSlice = createSlice({
	name: "layerGroups",
	initialState,
	reducers: {
		addLayerGroup: layerGroupAdapter.addOne,
		updateLayerGroup: layerGroupAdapter.updateOne,
		removeLayerGroup: layerGroupAdapter.removeOne,
		addLayerGroups: layerGroupAdapter.addMany
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchLayerGroups.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchLayerGroups.fulfilled, (state, action) => {
				layerGroupAdapter.setAll(state, action.payload as LayerGroup[]);
				state.status = "idle";
			});
	},
});

export const { addLayerGroup, updateLayerGroup, removeLayerGroup, addLayerGroups } = layerGroupSlice.actions;

export default layerGroupSlice.reducer;

export const {
	selectAll: selectLayerGroups,
	selectById: selectLayerGroupById,
} = layerGroupAdapter.getSelectors<RootState>(
	(state) => state.data.layerGroups
);
