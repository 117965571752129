import {DomainDataTemplateType} from "../../../types/entities/domain-data";

/**
 * DomainData Template corresponding to the file in Android Assets.
 * Only covers the English Version. Keys are translated instead.
 */
const DomainDataAvailable: { version: number, data: DomainDataTemplateType[] } = {
  "version": 2,
  "data": [
    {
      "id": "section_upright",
      "isAdditional": false,
      "inputType": "Section",
      "visible": true,
      "labelText": "Standing timber",
      "values": [
        "diameter_breast_height",
        "height",
        "treespecies"
      ],
      "calculations": [
        "standing_solid_meter"
      ]
    },
    {
      "id": "section_lying",
      "isAdditional": false,
      "inputType": "Section",
      "visible": true,
      "labelText": "Lying timber",
      "values": [
        "diameter_medium",
        "length"
      ],
      "calculations": [
        "lying_solid_meter"
      ]
    },
    {
      "id": "lying_solid_meter",
      "isAdditional": true,
      "inputType": "Calculation",
      "visible": true,
      "labelText": "Lying Solid Meter",
      "calcString": "(pi/4*({diameter_medium|m})^2)*{length|m}"
    },
    {
      "id": "standing_solid_meter",
      "isAdditional": true,
      "inputType": "Calculation",
      "visible": true,
      "labelText": "Standing Solid Meter",
      "additionalCalcValues": {
        "default": {
          "normal_height": "25",
          "volume_correct": "0.03"
        },
        "spruce": {
          "normal_height": "19+2*{diameter_breast_height|dm}",
          "volume_correct": "0.04"
        },
        "fir": {
          "normal_height": "21+{diameter_breast_height|dm}",
          "volume_correct": "0.04"
        },
        "larch": {
          "normal_height": "17+3*{diameter_breast_height|dm}",
          "volume_correct": "0.05"
        },
        "pine": {
          "normal_height": "28",
          "volume_correct": "0.04"
        },
        "beech": {
          "normal_height": "25",
          "volume_correct": "0.03"
        },
        "oak": {
          "normal_height": "24",
          "volume_correct": "0.03"
        },
        "birch": {
          "normal_height": "31",
          "volume_correct": "0.03"
        }
      },
      "calcString": "({diameter_breast_height|cm}^2/1000)+({diameter_breast_height|cm}^2/1000)*({height|m}-({additional|treespecies|normal_height}))*{additional|treespecies|volume_correct}"
    },
    {
      "id": "length",
      "isAdditional": false,
      "inputType": "Integer",
      "visible": true,
      "labelText": "Length",
      "additional": {
        "item": "unit",
        "defaultValue": "m",
        "withLabel": true
      }
    },
    {
      "id": "height",
      "isAdditional": false,
      "inputType": "Integer",
      "visible": true,
      "labelText": "Height",
      "additional": {
        "item": "unit",
        "defaultValue": "m",
        "withLabel": true
      }
    },
    {
      "id": "width",
      "isAdditional": false,
      "inputType": "Integer",
      "visible": true,
      "labelText": "Width",
      "additional": {
        "item": "unit",
        "defaultValue": "cm",
        "withLabel": true
      }
    },
    {
      "id": "diameter_breast_height",
      "isAdditional": false,
      "inputType": "Integer",
      "visible": true,
      "labelText": "Diameter at breast height DBH",
      "additional": {
        "item": "unit",
        "defaultValue": "cm",
        "withLabel": true
      }
    },
    {
      "id": "diameter_medium",
      "isAdditional": false,
      "inputType": "Integer",
      "visible": true,
      "labelText": "Medium diameter MDM",
      "additional": {
        "item": "unit",
        "defaultValue": "cm",
        "withLabel": true
      }
    },
    {
      "id": "unit",
      "isAdditional": true,
      "inputType": "List",
      "visible": true,
      "labelText": "Unit",
      "values": [
        {
          "id": "cm",
          "label": "cm"
        },
        {
          "id": "dm",
          "label": "dm"
        },
        {
          "id": "m",
          "label": "m"
        }
      ]
    },
    {
      "id": "treespecies",
      "isAdditional": false,
      "inputType": "Map",
      "visible": true,
      "labelText": "Tree species",
      "values": {
        "conifer": {
          "label": "conifer",
          "values": [
            {
              "id": "spruce",
              "label": "spruce"
            },
            {
              "id": "fir",
              "label": "fir"
            },
            {
              "id": "pine",
              "label": "pine"
            },
            {
              "id": "larch",
              "label": "larch"
            },
            {
              "id": "swiss_pine",
              "label": "swiss pine"
            },
            {
              "id": "yew",
              "label": "yew"
            },
            {
              "id": "douglas_fir",
              "label": "douglas fir"
            },
            {
              "id": "cedar",
              "label": "cedar"
            }
          ]
        },
        "deciduous_tree": {
          "label": "deciduous tree",
          "values": [
            {
              "id": "beech",
              "label": "beech"
            },
            {
              "id": "oak",
              "label": "oak"
            },
            {
              "id": "birch",
              "label": "birch"
            },
            {
              "id": "maple",
              "label": "maple"
            },
            {
              "id": "hornbeam",
              "label": "hornbeam"
            },
            {
              "id": "alder",
              "label": "alder"
            },
            {
              "id": "basswood",
              "label": "basswood"
            },
            {
              "id": "ash",
              "label": "ash"
            },
            {
              "id": "hazel",
              "label": "hazel"
            },
            {
              "id": "popular",
              "label": "popular"
            }
          ]
        }
      }
    }
  ]
}

export default DomainDataAvailable;
