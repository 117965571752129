/* eslint-disable no-inline-styles/no-inline-styles */
import Map, {MapLayerMouseEvent, Marker, NavigationControl,} from "react-map-gl";
import useMapProps, {MapProps, MapStyles} from "./use-map-props";
import {useCallback, useEffect, useState} from "react";
import Items from "./items";
import PopoverWrapper from "./popover";
import {closePopover, setEntityOpacity, setMarkerSize, setTempMarker} from "./map-slice";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {selectCategoryById} from "../../store/slices/category-slice";
import store from "../../store/store";
import {MarkerElement} from "./items/note-markers";
import {getRgb} from "../../lib/model/utils/colors";
import GpsControl from "./control/gps-control";
import DrawUI from "./draw-ui";
import {Box, IconButton, Slider, Typography} from "@mui/material";
import styles from "./map.module.css";
import {Layers, Tune} from "@mui/icons-material";
import Entities from "../../types/entities";
import { useTranslation } from "react-i18next";

/**
 * Represents the map component for rendering and interacting with the map.
 * @returns JSX for the MapComponent.
 */
export default function MapComponent() {
	const mapProps = useMapProps();
	const { tempMarker, tempArea, tempPath } = useAppSelector((state) => state.map);
	const dispatch = useAppDispatch();

	/**
	 * Renders the temporary marker on the map.
	 * @returns JSX for the temporary marker.
	 */
	const TempMarker = useCallback(() => {
		if (!tempMarker) return <></>;
		const category = selectCategoryById(store.getState(), tempMarker.categoryUid ?? "");
		if (!category) return (
			<Marker longitude={tempMarker.lng} latitude={tempMarker.lat} />
		);
		return (
			<Marker longitude={tempMarker.lng} latitude={tempMarker.lat}>
				<MarkerElement
					scale={2}
					color={getRgb(category)}
					inMap={true}
					iconName={category.symbol_name}
				/>
			</Marker>
		);
	}, [tempMarker]);

	/**
	 * Handles the click event on the map.
	 * Updates the temporary marker position or closes the popover based on the event.
	 * @param event - The MapLayerMouseEvent object representing the click event.
	 */
	function handleMapClick(event: MapLayerMouseEvent) {
		if (event.defaultPrevented !== event.originalEvent.defaultPrevented) return;
		if (tempMarker) {
			const { lng, lat } = event.lngLat;
			dispatch(setTempMarker({ ...tempMarker, lng, lat }));
		} else {
			dispatch(closePopover());
		}
	}

	useEffect(() => {
		if (tempMarker) {
			dispatch(closePopover());
		}
	}, [dispatch, tempMarker]);

	return (
		<Box className={styles.mapContainer}>
			<Map
				id="m1"
				mapboxAccessToken={import.meta.env.VITE_MAPBOX_ACCESS_TOKEN}
				reuseMaps
				initialViewState={mapProps.mapPosition}
				onMove={(event) => {
					mapProps.setMapPosition({
						...event.viewState,
					});
				}}
				onClick={handleMapClick}
				mapStyle={mapProps.style.url}
			>
				<GpsControl />
				<PopoverWrapper />
				<TempMarker />
				<NavigationControl
					showCompass={false}
					position="bottom-right"
					style={{
						marginBottom: 5,
						backgroundColor: "var(--background-transparent)",
						color: "var(--text)",
					}}
				/>
				{tempArea || tempPath ? <DrawUI /> : <Items />}
				<StyleControl {...mapProps} />
				<AdditionalSettings />
			</Map>
		</Box>
	);
}

/**
 * Represents the control component for selecting map styles and adjusting map settings.
 * @param {MapProps} props - The props containing map style, setStyle function, and map position.
 * @returns JSX for the StyleControl component.
 */
function StyleControl({ style, setStyle, mapPosition }: MapProps) {
	const [open, setOpen] = useState(false);
	const [focus, setFocus] = useState(false);
	const { markerSize, entityOpacity} = useAppSelector((state) => state.map);
	const dispatch = useAppDispatch();

	return (
		<>
			{/* Style and settings control box */}
			<Box onMouseLeave={() => setFocus(false)} style={{ alignItems: "center", position: "absolute", bottom: 40, right: 60, width: 400, height: 80, display: "grid", gridTemplateColumns: "auto 80px", gap: 12 }}>
				{/* Style selector grid */}
				<Box style={{ alignItems: "center", gap: 2, padding: 8, display: "grid", gridTemplateColumns: "repeat(4, 1fr)", transition: "visibility 0.4ms ease-in-out", visibility: focus ? "visible" : "hidden", backgroundColor: "var(--card)", borderRadius: 4 }}>
					{/* Iterate over available map styles */}
					{(Object.keys(MapStyles) as Array<keyof typeof MapStyles>).map((style, index) => (
						<Box onClick={() => setStyle(MapStyles[style])} key={index} style={{ width: 64, height: 64, overflowY: "clip", position: "relative", cursor: "pointer", borderRadius: 4, border: "2px solid #888" }}>
							{/* Render map preview for each style */}
							<Map
								style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "200%" }}
								interactive={false}
								mapStyle={MapStyles[style].previewUrl}
								mapboxAccessToken={import.meta.env.VITE_MAPBOX_ACCESS_TOKEN}
								initialViewState={{ latitude: mapPosition.latitude, longitude: mapPosition.longitude, zoom: 2 }}
								attributionControl={false}
								logoPosition={undefined}
								id={style}
							/>
						</Box>
					))}
				</Box>
				{/* Map style preview */}
				<Box onMouseEnter={() => {
					setFocus(true);
				}} style={{ maxHeight: 80, overflowY: "clip", position: "relative", cursor: "pointer", height: "100%", borderRadius: 4, border: "2px solid #888" }}>
					{/* Map preview */}
					<Map
						style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "200%" }}
						interactive={false}
						mapStyle={style.previewUrl}
						mapboxAccessToken={import.meta.env.VITE_MAPBOX_ACCESS_TOKEN}
						initialViewState={{ latitude: mapPosition.latitude, longitude: mapPosition.longitude, zoom: 4 }}
						attributionControl={false}
						logoPosition={undefined}
						id={"preview"}
					/>
					{/* Layer label */}
					<Box style={{ bottom: 0, position: "absolute", zIndex: 5, color: "#ccc", display: "flex", justifyContent: "center", gap: 6, alignItems: "end", width: "100%", height: "70%", backgroundImage: "linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0.4) 60%, rgba(0, 0, 0, 0.6) 80%, rgba(0, 0, 0, 0.6))" }}>
						<Layers style={{ fontSize: 18, marginBottom: 6 }} />
						<span style={{ marginBottom: 5, fontSize: 14 }}>Layers</span>
					</Box>
					{/* Dummy button for better styling */}
					<button />
				</Box>
			</Box>
		</>
	)
}

/**
 * Option to show additional display settings, like marker size or area transparency.
 * @returns JSX for AdditionalSettings.
 */
function AdditionalSettings() {
	const [open, setOpen] = useState(false);
	const { markerSize, entityOpacity} = useAppSelector((state) => state.map);
	const dispatch = useAppDispatch();
	const {t} = useTranslation();

	return (
	  <>
		<IconButton id={styles.additionalSettingsButton} onClick={() => setOpen(prev => !prev)} > 
			<Tune />
		</IconButton>

		{open && 
		<Box onMouseLeave={() => setOpen(false)} style={{  color: "var(--text)", padding: 20, zIndex: 10, position: "absolute", bottom: 35, right: 50, height: 100, width: 500, backgroundColor: "var(--card)", borderRadius: 8 }}>
			<Typography style={{ fontWeight: "bold", fontSize: 16, paddingBottom: 10 }}>{t("map.additionalDisplaySettings")}</Typography>
			{/* Marker opacity setting */}
			<span style={{ display: "grid", gridTemplateColumns: "2fr 5fr" }}>
				<Typography style={{ fontSize: 15 }}>{t("map.markerSize")}</Typography>
				<Slider step={0.1} value={markerSize} max={2} min={0} onChange={(_event, newValue) => dispatch(setMarkerSize(newValue as number))} />
			</span>
			{/* Area opacity setting */}
			<span style={{ display: "grid", gridTemplateColumns: "2fr 5fr" }}>
			<Typography style={{ fontSize: 15 }}>{t("map.areaOpacity")}</Typography>
				<Slider step={0.1} value={entityOpacity.areas} max={1} min={0} onChange={(_event, newValue) => dispatch(setEntityOpacity({ entityName: Entities.Areas, value: newValue as number }))} />
			</span>
		</Box>}
	  </>
	);
}