import {Box, Button, Typography} from "@mui/material";
import styles from "../login/login.module.css";
import errorStyles from "./error.module.css";
import { useTranslation } from "react-i18next";
import {Navigate, useLocation} from "react-router";


/**
 * Should be displayed when an Error occurs during routing.
 * Currently conflicts with ./index.tsx.
 * TODO: Unify this and ./index.tsx using Error Handling from it and Layout from this
 * TODO: Organize CSS
 * @constructor
 * @category Page
 */
export default function PageError() {
    const {t} = useTranslation();

    return (
        <Box className={styles.login}>
            <Box className={styles.blur} />
            <Box className={errorStyles.errorBox}>
                <Box className={styles.logo}>
                    <img alt={"company-logo"} src={`/images/fm-logo.png`} />
                </Box>
                
                <Box className={errorStyles.errorPageContent} >
                    <Typography id={errorStyles.errorPageTitle} >404</Typography>
                    <Typography id={errorStyles.errorPageSubtitle} >{t("errors.defaultAlt")}</Typography>
                    <Typography id={errorStyles.errorPageParagraph} >{t("errors.404info1")} <i>ForestManager Die Webapp</i> {t("errors.404info2")}</Typography>
                    <Box id={errorStyles.errorPageButtonGrid} >
                        <Button variant="contained">{t("back")}</Button>
                        <Button variant="outlined">{t("errors.sendDetails")}</Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
