import {Box, IconButton, SvgIconTypeMap, Typography} from "@mui/material";
import {OverridableComponent} from "@mui/material/OverridableComponent";
import styles from "./buttons.module.css";
import * as React from "react";

/**
 * BorderButtonProps Interface
 *
 * Properties for configuring the BorderButton component.
 *
 * @interface
 * @property {OverridableComponent<SvgIconTypeMap>} Icon - The icon component for the button.
 * @property {string} label - The label or text content for the button.
 * @property {(event: React.MouseEvent<HTMLDivElement>) => void} [onClick] - The function to be called when the button is clicked.
 * @property {any} [key] - Additional properties to be spread to the underlying components.
 */
interface BorderButtonProps {
	Icon: OverridableComponent<SvgIconTypeMap>;
	label: string;
	onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
	[key: string]: any;
}

/**
 * BorderButton Component
 *
 * A customizable button component with an icon, label, and optional onClick handler.
 *
 * @component
 * @param {BorderButtonProps} props - The properties of the BorderButton component.
 * @returns {JSX.Element} - The rendered BorderButton component.
 *
 * @example
 * // Usage of BorderButton component
 * <BorderButton
 *   Icon={AddIcon}
 *   label="Add Item"
 *   onClick={() => handleAddItem()}
 *   disabled={false}
 * />
 */
export default function BorderButton({
	Icon,
	label,
	onClick,
	...rest
}: BorderButtonProps): JSX.Element {
	return (
		<Box
			className={`${styles.borderButton} ${
				rest.disabled ? styles.disabledButton : ""
			}`}
			onClick={onClick}
		>
			<IconButton {...rest}>
				<Icon />
			</IconButton>
			<Typography component="p">{label}</Typography>
		</Box>
	);
}
