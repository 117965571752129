import AreaPolygons from "./area-polygons";
import NoteMarkers from "./note-markers";
import PathLines from "./path-lines";
import TurningMarkers from "./turning-markers";
import { useAppSelector } from "../../../store/hooks";
import ImageSources from "./image-sources";
import { selectImageLayers } from "../../../store/slices/image-layer-slice";
import LayerGroupSources from "./layer-groups-sources";
import { selectLayerGroups } from "../../../store/slices/layer-group-slice";
import Grids from "./grids";

/**
 * Component to render various map items such as area polygons, note markers, path lines, etc.
 * @returns {JSX.Element} Items component JSX element.
 */
export default function Items() {
	const imageLayers = useAppSelector((state) => selectImageLayers(state));
	const layerGroups = useAppSelector((state) => selectLayerGroups(state));

	return (
		<>
			<ImageSources imageLayers={imageLayers} />
			<LayerGroupSources layerGroups={layerGroups} />
			<AreaPolygons />
			<PathLines />
			<NoteMarkers />
			<TurningMarkers />
			<Grids />
		</>
	);
}
