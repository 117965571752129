import {Box, Typography} from "@mui/material";
import IconAvatar from "../icons/icon-avatar";
import styles from "./sections.module.css";
import SuspenseImage from "../loading/suspense-image";
import {drawerWidth} from "../../features/sidebar";
import {BiImages} from "react-icons/bi";
import Slider from "react-slick";
import {useTranslation} from "react-i18next";
import useSidebarParams from "../../features/sidebar/use-sidebar-params";

/**
 * TitleSection Component
 *
 * A component for rendering a section with a title, subtitle, and optional image or icon.
 *
 * @component
 * @param {Object} props - The properties of the TitleSection component.
 * @param {string | string[] | undefined} props.imgSrc - The source URL or URLs of the image(s) to be displayed.
 * @param {string} props.title - The main title to be displayed.
 * @param {string} props.subtitle - The subtitle or description to be displayed.
 * @param {string | undefined} props.symbol - The symbol to be displayed as an icon.
 * @param {string | undefined} props.color - The color of the icon or avatar.
 *
 * @returns {JSX.Element} - The rendered TitleSection component.
 *
 * @example
 * // Usage of TitleSection component
 * <TitleSection
 *   imgSrc="https://example.com/image.jpg"
 *   title="Sample Title"
 *   subtitle="A brief description of the title."
 *   symbol="🌐"
 *   color="#00f"
 * />
 */
export default function TitleSection({
	imgSrc,
	title,
	subtitle,
	symbol,
	color,
}: {
	imgSrc: string | string[] | undefined;
	title: string;
	subtitle: string;
	symbol: string | undefined;
	color: string | undefined;
}): JSX.Element {
	const { t } = useTranslation();
	const { titleImageIndex, setTitleImageIndex} = useSidebarParams();

	return (
		<>
			<Box className={`${styles.preview} ${imgSrc ? styles.withImage : ""}`}>
				{imgSrc ? (
					Array.isArray(imgSrc) ? (
						<Box className={styles.layerPreview}>
							<Slider
								afterChange={(currentSlide) => {
									setTitleImageIndex(currentSlide);
								}}
								infinite={false}
								dots={false}
								initialSlide={titleImageIndex}
							>
								{imgSrc.map((url, index) => (
									<div key={index} className={styles.layerPreviewItem}>
										<SuspenseImage src={url} alt={"Vorschau"} height={250} />
									</div>
								))}
							</Slider>
							<span>
								<BiImages />
								<Typography>
									{t("sidebar.formats", { num: imgSrc.length })}
								</Typography>
							</span>
						</Box>
					) : (
						<SuspenseImage
							src={imgSrc}
							alt={"satellite-preview"}
							className={styles.titleImage}
							width={drawerWidth}
							height={250}
						/>
					)
				) : (
					<IconAvatar width={96} height={96} color={color ?? "var(--text)"} symbol={symbol} />
				)}
			</Box>
			<Box className={`${styles.section} ${styles.title}`}>
				<Typography component="h2">{title}</Typography>
				<Typography component="p">{subtitle}</Typography>
			</Box>
		</>
	);
}
