import { Box, Typography } from "@mui/material";
import styles from "../dashboard.module.css";
import { useTranslation } from "react-i18next";

/**
 * Contact Data of rBITech
 * @constructor
 */
export default function Contact() {

    const { t } = useTranslation();

    return (
        <Box className={styles.legalOverviewContent}>
            <Typography>
                {t("dashboard_contact.contact").split('\n').map((line, index) => (
                    <Typography key={index}>{line}</Typography>
                ))}
            </Typography>
            <Typography>
                {t("dashboard_contact.contact2").split('\n').map((line, index) => (
                    <Typography key={index}>{line}</Typography>
                ))}
            </Typography>
            <Typography>
                <strong>{t("dashboard_contact.contact3_header")}</strong>
                {t("dashboard_contact.contact3").split('\n').map((line, index) => (
                    <Typography key={index}>{line}</Typography>
                ))}
            </Typography>
            <Typography>
                <strong>{t("dashboard_contact.contact4_header")}</strong>
                {t("dashboard_contact.contact4").split('\n').map((line, index) => (
                    <Typography key={index}>{line}</Typography>
                ))}
            </Typography>
            <Typography>
                <strong>{t("dashboard_contact.contact5_header")}</strong><br />
                {t("dashboard_contact.contact5")}<br />
            </Typography>
            <Typography>
                {t("dashboard_contact.contact6").split('\n').map((line, index) => (
                    <Typography key={index}>{line}</Typography>
                ))}
            </Typography>
            <Typography>
                <strong>{t("dashboard_contact.contact7_header")}</strong><br />
                {t("dashboard_contact.contact7").split('\n').map((line, index) => (
                    <Typography key={index}>{line}</Typography>
                ))}
            </Typography>
            <Typography>
                <strong>{t("dashboard_contact.contact8_header")}</strong><br />
            </Typography>
            <Typography>
                <strong>{t("dashboard_contact.contact9_header")}</strong><br />
                {t("dashboard_contact.contact9").split('\n').map((line, index) => (
                    <Typography key={index}>{line}</Typography>
                ))}
            </Typography>
        </Box>
    )
}