import Dashboard from "../../features/dashboard";
import styles from "../page.module.css";
import {Box} from "@mui/material";

/**
 * Renders the Dashboard Component in a container with applied css class
 * @constructor
 * @category Page
 */
export default function DashboardPage() {
	return (
		<Box className={styles.page}>
			<Dashboard />
		</Box>
	);
}
