import { Navigate, useLocation } from "react-router";
import { useAuth, ProvideAuth } from "../../hooks/use-auth";

/**
 * Can be wrapped around an existing route when it doesn't make sense for it to be displayed to authenticated users, e.g. login page.
 * Uses the Authentication Context Provide to determine session status.
 * Currently, always reroutes to /map. This can be changed by
 *
 * @param children {JSX.Element}
 * @param {string} [redirectURL] - The URL that will be forwarded to; defaults to '/map'
 * @constructor
 * @category Middleware
 */
export default function ForwardAuthenticated({
	children,
	redirectURL
}: {
	children: JSX.Element;
	redirectURL?: string; // TODO: Use an Enum here in the future
}) {
	return (
		<ProvideAuth>
			<Inner redirectURL={redirectURL}>{children}</Inner>
		</ProvideAuth>
	);
}

/**
 * Wrapped Component. Must be wrapped to have access to useAuth-Hook.
 * @param children {JSX.Element}
 * @param redirectURL {string} - The URL that will be forwarded to; defaults to '/map'
 * @constructor
 */
function Inner({ children, redirectURL }: { children: JSX.Element, redirectURL?: string }) {
	const { user } = useAuth();
	const currentLocation = useLocation();

	if (user && currentLocation.pathname === "/login") {
		return <Navigate to={redirectURL ?? "/map"} state={{ from: currentLocation.pathname }} />;
	}
	return children;
}
