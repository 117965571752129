import PurgeAccount from "../../../pages/purge";

/**
 * This route is used to provide an option for users that want to delete their account.
 * Legally required
 * @constructor
 * @category route
 */
export default function PurgeAccountRoute() {

    return (
        <PurgeAccount />
    )
}
