import styles from "../sidebar.module.css";
import { Box, IconButton, Typography } from "@mui/material";
import { CancelRounded, Check } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo } from "react";
import TurningPoint from "../../../types/entities/turning-point";
import Map, { Marker, useMap } from "react-map-gl";
import useMapProps from "../../map/use-map-props";
import { removeTempNote, setTempMarker } from "../../map/map-slice";
import { addFirestoreElement, updateFirestoreElement } from "../../../lib/firebase/firestore";
import Entities from "../../../types/entities";
import { v4 } from "uuid";
import { addTurningPoint, updateTurningPoint } from "../../../store/slices/turning-point-slice";
import { useAuth } from "../../../hooks/use-auth";
import useSidebarParams from "../use-sidebar-params";

/**
 * Represents a form component for creating or editing a turning point.
 * @param turningPoint The turning point object to edit, if provided.
 */
export default function TurningPointForm({ turningPoint }: { turningPoint?: TurningPoint }) {
	const tempMarker = useAppSelector((state) => state.map.tempMarker);
	const { m1: map, m_form: formMap } = useMap();
	const { mapPosition, style } = useMapProps();
	const canSubmit = useMemo(() => {
		if (!tempMarker) return false;
		return turningPoint ? (turningPoint.long !== tempMarker.lng || turningPoint.lat !== tempMarker.lat) : true;
	}, [tempMarker, turningPoint]);
	const auth = useAuth();
	const {closeForm} = useSidebarParams();
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	/**
	 * Renders a temporary marker on the map.
	 * @returns JSX for the temporary marker.
	 */
	const TempMarker = useCallback(() => {
		if (!tempMarker) return <></>;
		return (
			<Marker
				onClick={() => map?.panTo([tempMarker.lng, tempMarker.lat])}
				longitude={tempMarker.lng}
				latitude={tempMarker.lat}
			/>
		);
	}, [map, tempMarker]);

	/**
	 * Initializes a temporary marker when the component is first rendered.
	 */
	useEffect(() => {
		if (!tempMarker && map) {
			const { lng, lat } = turningPoint ? { lng: turningPoint.long, lat: turningPoint.lat } : map.getCenter();
			dispatch(setTempMarker({ lng, lat }));
		}
	}, []);

	/**
	 * Removes the temporary marker from state when the component is removed from the tree.
	 */
	useEffect(() => {
		return () => {
			dispatch(removeTempNote());
		};
	}, []);

	/**
	 * Pan the preview map to the updated position of the marker whenever it changes.
	 */
	useEffect(() => {
		if (!tempMarker || !formMap) return;
		formMap.panTo([tempMarker.lng, tempMarker.lat]);
	}, [formMap, tempMarker]);

	/**
	 * Adds or Updates a TurningPoint depending on whether an element was passed into the component.
	 */
	function handleSubmit() {
		if (!canSubmit || !tempMarker|| !auth || !auth.user) return;
		if (turningPoint) {
			const changes = {
				long: tempMarker.lng,
				lat: tempMarker.lat,
				lastChangedBy: auth.user.uid,
				version: turningPoint.version ? turningPoint.version + 1 : 0,
				updatedAt: Date.now(),
			};
			dispatch(
				updateTurningPoint({
					id: turningPoint.firestoreUid,
					changes,
				})
			);
			updateFirestoreElement(auth.user.uid, Entities.TurningPoints, {
				...turningPoint,
				...changes,
			});
		} else {
			const newTurningPoint: TurningPoint = {
				firestoreUid: v4(),
				sharePartnerUid: auth.user.uid,
				accuracy: 1,
				name: null,
				number: null,
				long: tempMarker.lng,
				lat: tempMarker.lat,
				createdAt: Date.now(),
				updatedAt: Date.now(),
				deletedAt: null,
				changedBy: auth.user.uid,
				version: 0,
			};
			dispatch(addTurningPoint(newTurningPoint));
			addFirestoreElement(auth.user.uid, Entities.TurningPoints, newTurningPoint);
		}
		closeForm();
	}

	return (
		<>
			<Box className={styles.listButtonContainer}>
				<IconButton
					disabled={!canSubmit}
					className={canSubmit ? styles.buttonSubmittable : ""}
					onClick={handleSubmit}
				>
					<Check />
				</IconButton>
				<IconButton onClick={() => closeForm()}>
					<CancelRounded />
				</IconButton>
			</Box>
			<Box className={styles.formControlContainer}>
				<Box className={styles.mapPreview}>
					<label>{t("preview")}</label>
					<Map
						mapboxAccessToken={import.meta.env.VITE_MAPBOX_ACCESS_TOKEN}
						interactive={false}
						id="m_form"
						mapStyle={style.url}
						initialViewState={mapPosition}
					>
						<TempMarker />
					</Map>
					<Box className={styles.locationInfo}>
						<Typography>
							{t("map.longitude")} {tempMarker?.lng.toFixed(4)}
						</Typography>
						<Typography>
							{t("map.latitude")} {tempMarker?.lat.toFixed(4)}
						</Typography>
					</Box>
				</Box>
			</Box>
		</>
	);
}
